import { AnalyticsService } from './../../general/services/analytics.service';
import { environment } from './../../../environments/environment';
import { GradesService } from './../../general/services/grades.service';
import { DpsSalesProperty } from './../../general/models/data/dpsSalesProperty';
import { DpsSalesRange } from './../../general/models/filter/dpsFilterSalesProperty';
import { FavoritesService } from './../../general/services/favorites.service';
import { GlobalData } from './../../general/services/global-data.service';
import { DpsFilterIdName } from './../../general/models/filter/dpsFilterIdName';
import { DpsGrade } from './../../general/models/data/dpsGrade';
import { Component, OnInit, Input } from '@angular/core';
import { _ } from 'underscore';
import { AnalyticsEventsService } from 'src/app/general/services/analytics-events.service';
import { EventTypes } from 'src/app/general/enums/analytics/event-types';

@Component({
  selector: 'dps-datatable-sales-table',
  templateUrl: './datatable-sales-table.component.html',
  styleUrls: ['./datatable-sales-table.component.scss']
})
export class DatatableSalesTableComponent implements OnInit {
  @Input() grades: DpsGrade[];
  @Input() properties: DpsSalesProperty[];
  @Input() gradeProperties: any;
  @Input() selectedGrades: DpsGrade[];
  @Input() selectedProperties: DpsSalesProperty[];
  @Input() sortProperty: DpsSalesProperty;
  @Input() sortRange: DpsSalesRange;
  @Input() sortOrder: string;
  @Input() propertiesChanged: any;
  @Input() gradesChanged: any;
  @Input() productTypes: DpsFilterIdName[];
  @Input() brands: DpsFilterIdName[];

  requestASampleUrl: string;

  constructor(public globalData: GlobalData,
    private _gradesService: GradesService,
    private _favoritesService: FavoritesService,
    private _analyticsService: AnalyticsService,
    private _analyticsEventsService: AnalyticsEventsService) {
    this.requestASampleUrl = environment.requestASampleUrl;
  }

  ngOnInit() {
  }

  isTouchDevice() {
    return 'ontouchstart' in document.documentElement;
  }

  toggleSort(property: DpsSalesProperty) {
    if (this.globalData.sortSalesProperty === property) {
      if (this.globalData.sortSalesOrder === 'desc') {
        this.globalData.sortSalesProperty = null;
        this.globalData.sortSalesOrder = null;
      } else {
        this.globalData.sortSalesOrder = 'desc';
      }
    } else {
      this.globalData.sortSalesProperty = property;
      this.globalData.sortSalesOrder = 'asc';
    }
    this._favoritesService.updateCurrentFavorite();

    this._analyticsService.sendEvent(this._analyticsEventsService.getEvent(EventTypes.DATATABLE_SORT_PROPERTY), property.name);
  }

  togglePropertySelection(property: DpsSalesProperty) {
    let propertyIndex = _.indexOf(this.selectedProperties, property);
    if (propertyIndex > -1) {
      this.selectedProperties.splice(propertyIndex, 1);
    } else {
      if (this.selectedProperties.length > 1) {
        this.selectedProperties.splice(0, 1);
      }
      this.selectedProperties.push(property);

      this._analyticsService.sendEvent(this._analyticsEventsService.getEvent(EventTypes.DATATABLE_ADD_TO_XY), property.name);
    }
    this._favoritesService.updateCurrentFavorite();
  }

  toggleGradeSelection(grade: DpsGrade) {
    let gradeIndex = _.indexOf(this.selectedGrades, grade);
    if (gradeIndex > -1) {
      grade.color = null;
      this.selectedGrades.splice(gradeIndex, 1);
    } else {
      if (this.selectedGrades.length > 4) {
        this.selectedGrades.splice(4, 1);
      }
      grade.color = this.globalData.getFirstAvailableGradeColor();
      this.selectedGrades.unshift(grade);
      this._analyticsService.sendEvent(this._analyticsEventsService.getEvent(EventTypes.DATATABLE_ADD_TO_SPIDER), grade.tradeName);
    }
    this.globalData.gradesChanged = {};
    this._favoritesService.updateCurrentFavorite();
  }

  onScroll(a, b, c, d) {
    this.globalData.limit += 15;
  }

  trackByIndex(index) {
    return index;
  }

  restoreDefaults() {
    let loadData = this.globalData.restoreDefaults();
    this._favoritesService.updateCurrentFavorite();
    if (loadData) {
      this._gradesService.getData();
    }
  }
}
