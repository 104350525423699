import { Pipe, PipeTransform } from '@angular/core';
import { _ } from 'underscore';

@Pipe({
  name: 'nameById'
})
export class NameByIdPipe implements PipeTransform {

  transform(source: any, id: any): any {
    if (source.length > 0) {
      let item = _.findWhere(source, { id: id });
      return item ? item.name : null;
    } else {
      return null;
    }
  }

}
