import { Component, OnInit } from '@angular/core';
import { GlobalData } from './../../general/services/global-data.service';

@Component({
  selector: 'dps-grade-detail-product-datasheet',
  templateUrl: './grade-detail-product-datasheet.component.html',
  styleUrls: ['./grade-detail-product-datasheet.component.scss']
})

export class GradeDetailProductDatasheetComponent implements OnInit {
  zoom: number;
  fitToPage: boolean;
  autoResize: boolean;
  originalSize: boolean;

  constructor(public globalData: GlobalData) {
    this.zoom = 1;
    this.fitToPage = true;
    this.autoResize = true;
    this.originalSize = true;
  }

  ngOnInit() {
  }

  adjustZoom(step) {
    this.zoom += step;
  }
}
