import { GlobalData } from '../../general/services/global-data.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dps-succes-dialog',
  templateUrl: './succes-dialog.component.html',
  styleUrls: ['./succes-dialog.component.scss']
})
export class SuccesDialogComponent implements OnInit {

  constructor(public globalData: GlobalData) { }

  ngOnInit() {

  }

}
