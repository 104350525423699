import { DpsFilterIdName } from './../../models/filter/dpsFilterIdName';
import { Pipe, PipeTransform } from '@angular/core';
import { _ } from 'underscore';

@Pipe({
  name: 'filterApplications'
})
export class FilterApplicationsPipe implements PipeTransform {

  transform(applications: DpsFilterIdName[], applicationsFilters: string[]): DpsFilterIdName[] {
    if (applicationsFilters.length > 0) {
      let result = _.filter(applications, (a) => {
        return _.indexOf(applicationsFilters, a.id) > -1;
      });
      return result;
    } else {
      return applications;
    }
  }

}
