import { DpsRange } from './../../../general/models/filter/dpsFilterSalesProperty';
import { DpsFilterProperty } from './../../../general/models/filter/dpsFilterProperty';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TimeoutError } from 'rxjs';

@Component({
  selector: 'dps-datatable-range-editor',
  templateUrl: './datatable-range-editor.component.html',
  styleUrls: ['./datatable-range-editor.component.scss']
})
export class DatatableRangeEditorComponent implements OnInit {
  @Input() range: any;
  @Input() unitOfMeasure: string;
  @Input() propertyId: string;
  @Input() type: string;
  private _selectedRange: any;

  @Input()
  set selectedRange(range: any) {
    this._selectedRange = range;
    if (this._selectedRange) {
      this.minimum = this.selectedRange.minimum;
      this.maximum = this.selectedRange.maximum;
    } else {
      this.minimum = null;
      this.maximum = null;
    }
  }
  get selectedRange(): any {
    return this._selectedRange;
  }

  @Input() customError: any;
  @Output() rangeChanged: EventEmitter<any> = new EventEmitter();

  minimum: string;
  maximum: string;

  blurTimer;

  constructor() { }

  ngOnInit() {
    // this.minimum = this.selectedRange.minimum;
    // this.maximum = this.selectedRange.maximum;
  }

  applyFilters(field) {
    if (field.name === 'min') {
      if (!this.minimum) {
        this.minimum = null;
        this.customError.id = null;
      } else if (parseFloat(this.minimum) < parseFloat(this.range.minimum)) {
        this.customError.id = this.propertyId + this.type + field.name;
        this.minimum = this.range.minimum;
      } else if (parseFloat(this.minimum) > parseFloat((this.selectedRange.maximum ? this.selectedRange.maximum : this.range.maximum))) {
        this.customError.id = this.propertyId + this.type + field.name;
        this.minimum = (this.selectedRange.maximum ? this.selectedRange.maximum : this.range.maximum);
      } else {
        this.customError.id = null;
      }
    } else {
      if (!this.maximum) {
        this.maximum = null;
        this.customError.id = null;
      } else if (parseFloat(this.maximum) > parseFloat(this.range.maximum)) {
        this.customError.id = this.propertyId + this.type + field.name;
        this.maximum = this.range.maximum;
      } else if (parseFloat(this.maximum) < parseFloat((this.selectedRange.minimum ? this.selectedRange.minimum : this.range.minimum))) {
        this.customError.id = this.propertyId + this.type + field.name;
        this.maximum = this.selectedRange.minimum ? this.selectedRange.minimum : this.range.minimum;
      } else {
        this.customError.id = null;
      }
    }
    this.selectedRange.minimum = this.minimum;
    this.selectedRange.maximum = this.maximum;
    this.rangeChanged.emit();


    // if (field.errors) {
    //   this.customError.id = this.propertyId + this.type + field.name;
    //   if (field.errors.min) {
    //       if (this.minimum) {
    //         if (parseFloat(this.minimum) > parseFloat(this.maximum)) {
    //           this.maximum = this.minimum;
    //         } else {
    //           this.minimum = this.range.minimum;
    //         }
    //       } else {
    //         this.minimum = this.range.minimum;
    //       }
    //   }
    //   if (field.errors.max) {
    //       if (this.maximum) {
    //         if (parseFloat(this.minimum) > parseFloat(this.maximum)) {
    //           this.minimum = this.maximum;
    //         } else {
    //           this.maximum = this.range.maximum;
    //         }
    //       } else {
    //         this.maximum = this.range.maximum;
    //       }
    //   }
    // } else {
    //   this.customError.id = null;
    // }
    // this.selectedRange.minimum = this.minimum;
    // this.selectedRange.maximum = this.maximum;
    // this.rangeChanged.emit();
  }

  setBlurTimer($event) {
    clearTimeout(this.blurTimer);

    this.blurTimer = setTimeout(() => {
      $event.target.blur();
    }, 1500);
  }

}
