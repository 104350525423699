<dps-datatable-table
  *ngIf="globalData.data && globalData.data.gradeGlobalProperties && globalData.applicationState.propertyType.id === 'GP' && !globalData.globalError"
  [grades]="globalData.data.filteredGrades"
  [properties]="globalData.data.globalProperties | filterProperties:globalData.selectedFilters.propertyFilters:globalData.filtersChanged"
  [gradeProperties]="globalData.data.gradeGlobalProperties"
  [selectedGrades]="globalData.selectedGrades"
  [selectedProperties]="globalData.selectedProperties"
  [sortOrder]="globalData.sortOrder"
  [sortProperty]="globalData.sortProperty"
  [propertiesChanged]="globalData.propertiesChanged"
  [gradesChanged]="globalData.gradesChanged"
  [productTypes]="globalData.filters.productTypes"
  [brands]="globalData.filters.brands">
</dps-datatable-table>
<dps-datatable-sales-table
  *ngIf="globalData.data && globalData.data.gradeSalesProperties && globalData.applicationState.propertyType.id === 'SP' && !globalData.globalError"
  [grades]="globalData.data.filteredSalesGrades"
  [properties]="globalData.data.salesProperties | filterSalesProperties:globalData.selectedFilters.salesPropertyFilters:globalData.filtersChanged"
  [gradeProperties]="globalData.data.gradeSalesProperties"
  [selectedGrades]="globalData.selectedGrades"
  [selectedProperties]="globalData.selectedSalesProperties"
  [sortOrder]="globalData.sortSalesOrder"
  [sortProperty]="globalData.sortSalesProperty"
  [propertiesChanged]="globalData.propertiesChanged"
  [gradesChanged]="globalData.gradesChanged"
  [productTypes]="globalData.filters.productTypes"
  [brands]="globalData.filters.brands">
</dps-datatable-sales-table>
<div class="error" *ngIf="globalData.globalError" [innerHtml]="globalData.globalError"></div>
<dps-loading-spinner class="spinner" *ngIf="globalData.loading"></dps-loading-spinner>

