import { environment } from './../../environments/environment';
import { GlobalData } from './../general/services/global-data.service';
import { GradesService } from './../general/services/grades.service';
import { FavoritesService } from './../general/services/favorites.service';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { _ } from 'underscore';
import { Router } from '@angular/router';
import { DpsFavorite } from '../general/models/dpsFavorite';

@Component({
  selector: 'dps-favorites',
  templateUrl: './favorites.component.html',
  styleUrls: ['./favorites.component.scss']
})
export class FavoritesComponent implements OnInit {
  editingIndex = -1;
  editingTitle = '';
  activeRemovePopup: DpsFavorite = null;
  iconUrlBase: string;
  contactUsUrl: string;

  constructor(
    public globalData: GlobalData,
    private _favoritesService: FavoritesService,
    private _gradesService: GradesService,
    private _router: Router,
    private _cdRef: ChangeDetectorRef
  ) {
    this.iconUrlBase = environment.iconUrlBase;
    this.contactUsUrl = environment.contactUsUrl;
  }

  ngOnInit() {
    if (!this.globalData.favorites) {
      this._favoritesService.loadFavorites();
    }
  }

  focusOnInput() {
    let input = document.querySelector('.favorite-edit-input') as HTMLInputElement;
    input.focus();
  }

  removeFromFavorites(favorite) {
    this.activeRemovePopup = null;
    this._favoritesService.removeFromFavorites(favorite);
  }

  editFavorite(favorite, index) {
    this.editingIndex = index;
    this.editingTitle = favorite.title;
  }

  saveEditFavorite(favorite, index) {
    favorite.title = this.editingTitle;
    this.editingIndex = -1;
    this._favoritesService.updateFavorite(favorite);
  }

  cancelEditFavorite(favorite, index) {
    this.editingIndex = -1;
  }

  goToFavorite(favorite: DpsFavorite) {
    this._favoritesService.setFavorite(favorite);
    if (favorite.path.indexOf('grade-detail') === -1) {
      this._gradesService.getData();
    }
    this._router.navigateByUrl(favorite.path);
  }

  getRegion(regionId) {
    let region = _.findWhere(this.globalData.filters.regions, { id: regionId });
    return region.name;
  }
}
