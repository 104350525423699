<p-dialog class="explore-data" [(visible)]="globalData.applicationState.showInitialFilterDialog" [modal]="true"
  [resizable]="false" [responsive]="true" [closable]="!!globalData.silo" [closeOnEscape]="!!globalData.silo"
  [appendTo]="'body'" [styleClass]="'dps-dialog dps-explore-dialog'" [draggable]="false"
  (onShow)="globalData.disableBodyScroll()" (onHide)="globalData.enableBodyScroll()">
  <p-header>
    <span class="product-selector-trademark" i18n="Explore product selector@@exploreProductSelector">Product
      selector</span>
    <ng-container i18n="Explore dialog title@@exploreTitle">Explore our products</ng-container>
    <a *ngIf="!!globalData.silo" class="ui-dialog-titlebar-close"></a>
  </p-header>
  <div class="dialog-form">
    <div class="region-selector">
      <label i18n="Explore dialog location label@@exploreLocation">Your preferred region</label>
      <ng-select [items]="globalData.exploreFilters.regions" [clearable]="false" bindLabel="name" bindValue="id"
        (change)="selectRegion($event); globalData.applicationState.regionsSidebarOpen = false;"
        [(ngModel)]="globalData.selectedFilters.regionId" [searchable]="false">
      </ng-select>
    </div>
    <div class="column">
      <div class="header">
        <span i18n="Search by keyword@@keywordSearch">Search by keyword</span>
      </div>
      <div class="fields">
        <div class="field search">
          <label i18n="Explore dialog seach label@@exploreSearch">
            Search
          </label>
          <p-autoComplete [appendTo]="'body'" [(ngModel)]="globalData.searchModel.searchTerm"
            [suggestions]="globalData.searchModel.autocompleteResults" (completeMethod)="autocomplete($event)" [delay]="300" styleClass="'explore-autocomplete'" (onSelect)="select($event)"
            (onFocus)="scrollDown()"
            i18n-placeholder="Brands, products group placeholder on the explore screen@@exploreBrandsProducts"
            placeholder="Brands, product group..."
            (keyup.enter)="search()">
            <ng-template let-item pTemplate="item">
              <span class="name" [innerHTML]="item.name"></span>
              <span class="type"
                i18n="Type of the exploreAutocompleteSuggestion@@exploreAutocompleteSuggestionType">{item.type, select,
                Product Type {Product Type}
                Brand {Brand}
                Application {Application}
                Grade {Grade}
                Business Unit {Business Unit}
                Market Segment {Application}
                Industry {Industry}}</span>
            </ng-template>
          </p-autoComplete>
          <a class="search-button" (click)="search()">
            <mat-icon svgIcon="search"></mat-icon>
          </a>
        </div>
      </div>
    </div>
    <div class="divide-keyword" i18n="Divider keyword on explore window@@exploreOr">
      or
    </div>
    <div class="column">
      <div class="header">
        <span i18n="Choose a filter@@chooseFilter">Choose a product filter</span>
      </div>
      <div class="fields">
        <div class="field">
          <label i18n="Explore dialog product group label@@exploreProductGroup">Product group</label>
          <ng-select [appendTo]="'body'" [searchable]="true" [items]="globalData.exploreFilters?.productTypes | sort: 'name'"
            i18n-placeholder="Explore product group placeholder@@exploreProductGroupPlaceholder" placeholder="Select"
            (change)="selectProductType($event)" [clearable]="false" bindLabel="name" bindValue="id">
          </ng-select>
        </div>
        <div class="field">
          <label i18n="Explore dialog industry label@@exploreIndustry">Industry</label>
          <ng-select [appendTo]="'body'" [searchable]="true" [items]="globalData.exploreFilters?.industries | sort: 'name'"
            i18n-placeholder="Explore industry placeholder@@exploreIndustryPlaceholder" placeholder="Select"
            (change)="selectIndustry($event)" [clearable]="false" bindLabel="name" bindValue="id">
          </ng-select>
        </div>
        <div class="field">
          <label i18n="Explore dialog brand label@@exploreBrand">Brand</label>
          <ng-select [appendTo]="'body'" [searchable]="true" [items]="globalData.exploreFilters?.brands | sort: 'name'"
            i18n-placeholder="Explore brand placeholder@@exploreBrandPlaceholder" placeholder="Select"
            (change)="selectBrand($event)" [clearable]="false" bindLabel="name" bindValue="id">
          </ng-select>
        </div>
      </div>
    </div>
    <!-- <div class="field inline">
      <label class="current-region-label" i18n="Explore dialog location label@@exploreLocation">Your preferred region</label>
      <div class="current-region">
        <ng-select
        [items]="globalData.exploreFilters.regions"
        [clearable]="false"
        bindLabel="name"
        bindValue="id"
        (change)="selectRegion(); globalData.applicationState.regionsSidebarOpen = false;"
        [(ngModel)]="globalData.selectedFilters.regionId"
        [searchable]="false">
        </ng-select>
      </div>
    </div> -->
    <!-- <div class="fields-wrapper">
      <div class="search-field">
        <div class="field-header">
          <span>Search by keyword</span>
        </div>
        <label i18n="Explore dialog seach label@@exploreSearch">Search</label>
        <div class="search-input-wrapper">
        <p-autoComplete
          [appendTo]="'body'"
          [(ngModel)]="globalData.searchModel.searchTerm"
          [suggestions]="globalData.searchModel.autocompleteResults"
          (completeMethod)="autocomplete($event)"
          (keyup.enter)="search($event)"
          [delay]="300"
          styleClass="'explore-autocomplete'"
          (onSelect)="select($event)"
          (onFocus)="scrollDown()"
          i18n-placeholder="Brands, products group placeholder on the explore screen@@exploreBrandsProducts"
          placeholder="Brands, product group...">
          <ng-template let-item pTemplate="item">
            <span class="name" [innerHTML]="item.name"></span>
            <span class="type" i18n="Type of the exploreAutocompleteSuggestion@@exploreAutocompleteSuggestionType">{item.type, select,
              Product Type {Product Type}
              Brand {Brand}
              Application {Application}
              Grade {Grade}
              Business Unit {Business Unit}
              Market Segment {Application}
              Industry {Industry}}</span>
          </ng-template>
        </p-autoComplete>
        <a class="search-button" (click)="search()">
          <mat-icon svgIcon="search"></mat-icon>
        </a>
        </div>
      </div>
      <div class="divider-keyword" i18n="Divider keyword on explore window@@exploreOr">or</div>
      <div class="field">
        <div class="field-header">
          <span>Choose a product filter</span>
        </div>
        <label i18n="Explore dialog product group label@@exploreProductGroup">Product group</label>
        <ng-select
          [appendTo]="'body'"
          [searchable]="true"
          [items]="globalData.exploreFilters?.productTypes"
          i18n-placeholder="Explore product group placeholder@@exploreProductGroupPlaceholder"
          placeholder="Select"
          (change)="selectProductType($event)"
          [clearable]="false"
          bindLabel="name"
          bindValue="id">
        </ng-select>
        <label i18n="Explore dialog industry label@@exploreIndustry">Industry</label>
        <ng-select
          [appendTo]="'body'"
          [searchable]="true"
          [items]="globalData.exploreFilters?.industries"
          i18n-placeholder="Explore product group placeholder@@exploreProductGroupPlaceholder"
          placeholder="Select"
          (change)="selectIndustry($event)"
          [clearable]="false"
          bindLabel="name"
          bindValue="id">
        </ng-select>
        <label i18n="Explore dialog brand label@@exploreBrand">Brand</label>
        <ng-select
          [appendTo]="'body'"
          [searchable]="true"
          [items]="globalData.exploreFilters?.brands"
          i18n-placeholder="Explore product group placeholder@@exploreProductGroupPlaceholder"
          placeholder="Select"
          (change)="selectBrand($event)"
          [clearable]="false"
          bindLabel="name"
          bindValue="id">
        </ng-select>
      </div>
      <div class="divider"></div>
    </div> -->

  </div>
</p-dialog>