<div
  class="content-container datatable-container"
  [ngClass]="{ 'sidebar-open': globalData.applicationState.datatableSidebarOpen && !globalData.applicationState.showInitialFilterDialog }">
  <div class="sidebar">
    <div class="sidebar-inner">
      <dps-datatable-filters></dps-datatable-filters>
    </div>
  </div>
  <div class="content">
    <div class="table-wrapper">
      <router-outlet></router-outlet>
    </div>
    <div class="tabs">
      <div class="tab tab-scroll" [ngClass]="{ disabled: !globalData.applicationState.enableScrollButtons }">
        <a class="button left" (click)="scrollLeft()">
            <mat-icon svgIcon="tab-arrow-left"></mat-icon>
        </a>
        <a class="button right" (click)="scrollRight()">
            <mat-icon svgIcon="tab-arrow-right"></mat-icon>
        </a>
      </div>
      <div class="tab-container">
        <a class="tab"
          [routerLink]="['/datatable']"
          [ngClass]="{active: router.url === '/datatable' || router.url === '/datatable/landing' }"
          (click)="globalData.applicationState.applicationActive = false; logTabClick('properties');">
          <ng-container
            *ngIf="globalData.applicationState.propertyType.id === 'GP'"
            i18n="Title of product datasheet tab@@tabProductProperties">Product data</ng-container>
          <ng-container
            *ngIf="globalData.applicationState.propertyType.id === 'SP'"
            i18n="Title of sales specifications tab@@tabSalesSpecs">Sales specifications</ng-container>
        </a>
        <a class="tab"
          [routerLink]="['/datatable/testmethods']"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{exact: true}"
          (click)="globalData.applicationState.applicationActive = false; logTabClick('testmethods');"
          i18n="Title of test methods tab@@tabTestMethods">Test methods</a>
        <a class="tab"
          *ngIf="globalData.data && globalData.data.applications && globalData.data.applications.length > 0"
          [routerLink]="['/datatable/applications']"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{exact: true}"
          (click)="globalData.applicationState.applicationActive = true; logTabClick('applications');"
          i18n="Title of applications tab@@tabApplications">Applications</a>
      </div>
    </div>
  </div>
</div>
