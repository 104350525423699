import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouteReuseStrategy, DetachedRouteHandle } from '@angular/router';

export class DefaultRouteReuseStrategy implements RouteReuseStrategy {
  shouldDetach(route: ActivatedRouteSnapshot): boolean { return false; }
  store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle): void {}
  shouldAttach(route: ActivatedRouteSnapshot): boolean { return false; }
  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle|null { return null; }
  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
      return future.routeConfig === curr.routeConfig;
  }
}

@Injectable()
export class DpsRouteReuseStrategy extends DefaultRouteReuseStrategy {
  handlers: {[key: string]: DetachedRouteHandle} = {};
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return route.data.reuse;
  }
  store (route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle) {
    if (route.data.reuse) {
      this.handlers[route.routeConfig.component.name] = detachedTree;
    }
  }
  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return !!this.handlers[route.routeConfig.component.name];
  }
  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle|null {
    let result = route.routeConfig.component ?
      (this.handlers.hasOwnProperty(route.routeConfig.component.name) ?
        this.handlers[route.routeConfig.component.name] : null) : null;
    return result;
  }
}
