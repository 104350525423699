import { RoutingStateService } from './general/services/routing-state.service';
import { AnalyticsService } from './general/services/analytics.service';
import { GlobalData } from './general/services/global-data.service';
import { LocationService } from './general/services/location.service';
import { environment } from './../environments/environment';
import { Component, Input, ElementRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material';

@Component({
  selector: 'dps-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  location: string;

  constructor(
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    public locationService: LocationService,
    public globalData: GlobalData,
    private elementRef: ElementRef,
    public analyticsService: AnalyticsService,
    private _routingStateService: RoutingStateService
  ) {
    this._routingStateService.loadRouting();

    let outputUrl = environment.iconUrlBase ? environment.iconUrlBase : 'dist';
    iconRegistry.addSvgIcon('polygon', sanitizer.bypassSecurityTrustResourceUrl(outputUrl + 'assets/icons/polygon.svg'));
    iconRegistry.addSvgIcon('polygon-outline', sanitizer.bypassSecurityTrustResourceUrl(outputUrl + 'assets/icons/polygon-outline.svg'));
    iconRegistry.addSvgIcon('polygon-toggler', sanitizer.bypassSecurityTrustResourceUrl(outputUrl + 'assets/icons/polygon-toggler.svg'));
    iconRegistry.addSvgIcon('polygon-menu', sanitizer.bypassSecurityTrustResourceUrl(outputUrl + 'assets/icons/polygon-menu.svg'));
    iconRegistry.addSvgIcon('polygon-filter', sanitizer.bypassSecurityTrustResourceUrl(outputUrl + 'assets/icons/polygon-filter.svg'));
    iconRegistry.addSvgIcon('polygon-fav', sanitizer.bypassSecurityTrustResourceUrl(outputUrl + 'assets/icons/polygon-fav.svg'));
    iconRegistry.addSvgIcon('xy', sanitizer.bypassSecurityTrustResourceUrl(outputUrl + 'assets/icons/x-y-icon.svg'));
    iconRegistry.addSvgIcon('x-y-icon-fav', sanitizer.bypassSecurityTrustResourceUrl(outputUrl + 'assets/icons/x-y-icon-fav.svg'));
    iconRegistry.addSvgIcon('table', sanitizer.bypassSecurityTrustResourceUrl(outputUrl + 'assets/icons/table.svg'));
    iconRegistry.addSvgIcon('search-list', sanitizer.bypassSecurityTrustResourceUrl(outputUrl + 'assets/icons/search-list.svg'));
    iconRegistry.addSvgIcon('star', sanitizer.bypassSecurityTrustResourceUrl(outputUrl + 'assets/icons/star.svg'));
    iconRegistry.addSvgIcon('star-outline', sanitizer.bypassSecurityTrustResourceUrl(outputUrl + 'assets/icons/star-outline.svg'));
    iconRegistry.addSvgIcon('menu', sanitizer.bypassSecurityTrustResourceUrl(outputUrl + 'assets/icons/menu.svg'));
    iconRegistry.addSvgIcon('filter', sanitizer.bypassSecurityTrustResourceUrl(outputUrl + 'assets/icons/filter.svg'));
    iconRegistry.addSvgIcon('share', sanitizer.bypassSecurityTrustResourceUrl(outputUrl + 'assets/icons/share.svg'));
    iconRegistry.addSvgIcon('export', sanitizer.bypassSecurityTrustResourceUrl(outputUrl + 'assets/icons/download.svg'));
    iconRegistry.addSvgIcon('arrow-right', sanitizer.bypassSecurityTrustResourceUrl(outputUrl + 'assets/icons/arrow-right.svg'));
    iconRegistry.addSvgIcon('tab-arrow-left', sanitizer.bypassSecurityTrustResourceUrl(outputUrl + 'assets/icons/tab-arrow-left.svg'));
    iconRegistry.addSvgIcon('tab-arrow-right', sanitizer.bypassSecurityTrustResourceUrl(outputUrl + 'assets/icons/tab-arrow-right.svg'));
    iconRegistry.addSvgIcon('arrow-left-right', sanitizer.bypassSecurityTrustResourceUrl(outputUrl + 'assets/icons/arrow-left-right.svg'));
    iconRegistry.addSvgIcon('long-arrow-right', sanitizer.bypassSecurityTrustResourceUrl(outputUrl + 'assets/icons/long-arrow-right.svg'));
    iconRegistry.addSvgIcon('arrow', sanitizer.bypassSecurityTrustResourceUrl(outputUrl + 'assets/icons/arrow.svg'));
    iconRegistry.addSvgIcon('switch', sanitizer.bypassSecurityTrustResourceUrl(outputUrl + 'assets/icons/switch.svg'));
    iconRegistry.addSvgIcon('graph-filter', sanitizer.bypassSecurityTrustResourceUrl(outputUrl + 'assets/icons/edit-graph-filter.svg'));
    iconRegistry.addSvgIcon('drag', sanitizer.bypassSecurityTrustResourceUrl(outputUrl + 'assets/icons/drag.svg'));
    iconRegistry.addSvgIcon('globe', sanitizer.bypassSecurityTrustResourceUrl(outputUrl + 'assets/icons/regions-icon.svg'));
    iconRegistry.addSvgIcon('close', sanitizer.bypassSecurityTrustResourceUrl(outputUrl + 'assets/icons/close.svg'));
    iconRegistry.addSvgIcon('check', sanitizer.bypassSecurityTrustResourceUrl(outputUrl + 'assets/icons/check.svg'));
    iconRegistry.addSvgIcon('edit', sanitizer.bypassSecurityTrustResourceUrl(outputUrl + 'assets/icons/edit.svg'));
    iconRegistry.addSvgIcon('back', sanitizer.bypassSecurityTrustResourceUrl(outputUrl + 'assets/icons/back.svg'));
    iconRegistry.addSvgIcon('actions-menu', sanitizer.bypassSecurityTrustResourceUrl(outputUrl + 'assets/icons/actions-menu.svg'));
    iconRegistry.addSvgIcon('asc', sanitizer.bypassSecurityTrustResourceUrl(outputUrl + 'assets/icons/arrow-ascending.svg'));
    iconRegistry.addSvgIcon('desc', sanitizer.bypassSecurityTrustResourceUrl(outputUrl + 'assets/icons/arrow-descending.svg'));
    iconRegistry.addSvgIcon('dot', sanitizer.bypassSecurityTrustResourceUrl(outputUrl + 'assets/icons/dot.svg'));
    iconRegistry.addSvgIcon('scroll', sanitizer.bypassSecurityTrustResourceUrl(outputUrl + 'assets/icons/scroll.svg'));
    iconRegistry.addSvgIcon('search', sanitizer.bypassSecurityTrustResourceUrl(outputUrl + 'assets/icons/search.svg'));
    iconRegistry.addSvgIcon('document', sanitizer.bypassSecurityTrustResourceUrl(outputUrl + 'assets/icons/document.svg'));
    iconRegistry.addSvgIcon('table-pp', sanitizer.bypassSecurityTrustResourceUrl(outputUrl + 'assets/icons/table-pp.svg'));
    iconRegistry.addSvgIcon('table-ss', sanitizer.bypassSecurityTrustResourceUrl(outputUrl + 'assets/icons/table-ss.svg'));
    iconRegistry.addSvgIcon('x-y-toggler', sanitizer.bypassSecurityTrustResourceUrl(outputUrl + 'assets/icons/x-y-toggler.svg'));
    iconRegistry.addSvgIcon('settings', sanitizer.bypassSecurityTrustResourceUrl(outputUrl + 'assets/icons/settings.svg'));
    iconRegistry.addSvgIcon('help', sanitizer.bypassSecurityTrustResourceUrl(outputUrl + 'assets/icons/help.svg'));
    iconRegistry.addSvgIcon('cross-link', sanitizer.bypassSecurityTrustResourceUrl(outputUrl + 'assets/icons/cross-link.svg'));
    iconRegistry.addSvgIcon('expand', sanitizer.bypassSecurityTrustResourceUrl(outputUrl + 'assets/icons/expand.svg'));
    iconRegistry.addSvgIcon('arrow-link', sanitizer.bypassSecurityTrustResourceUrl(outputUrl + 'assets/icons/arrow-link.svg'));
    iconRegistry.addSvgIcon('select-arrows', sanitizer.bypassSecurityTrustResourceUrl(outputUrl + 'assets/icons/select-arrows.svg'));
    iconRegistry.addSvgIcon('email', sanitizer.bypassSecurityTrustResourceUrl(outputUrl + 'assets/icons/email.svg'));
    iconRegistry.addSvgIcon('linkedin', sanitizer.bypassSecurityTrustResourceUrl(outputUrl + 'assets/icons/linkedin-icon.svg'));
    iconRegistry.addSvgIcon('social-x', sanitizer.bypassSecurityTrustResourceUrl(outputUrl + 'assets/icons/social-x-icon.svg'));
    iconRegistry.addSvgIcon('wechat', sanitizer.bypassSecurityTrustResourceUrl(outputUrl + 'assets/icons/wechat-icon.svg'));
    iconRegistry.addSvgIcon('sample', sanitizer.bypassSecurityTrustResourceUrl(outputUrl + 'assets/icons/sample.svg'));
    iconRegistry.addSvgIcon('sample-desktop', sanitizer.bypassSecurityTrustResourceUrl(outputUrl + 'assets/icons/sample-desktop.svg'));
    iconRegistry.addSvgIcon('fav-xy', sanitizer.bypassSecurityTrustResourceUrl(outputUrl + 'assets/icons/fav-xy.svg'));
    iconRegistry.addSvgIcon('fav-polygon', sanitizer.bypassSecurityTrustResourceUrl(outputUrl + 'assets/icons/fav-polygon.svg'));
    iconRegistry.addSvgIcon('fav-list-ss', sanitizer.bypassSecurityTrustResourceUrl(outputUrl + 'assets/icons/fav-list-ss.svg'));
    iconRegistry.addSvgIcon('fav-list-pp', sanitizer.bypassSecurityTrustResourceUrl(outputUrl + 'assets/icons/fav-list-pp.svg'));
    iconRegistry.addSvgIcon('fav-search', sanitizer.bypassSecurityTrustResourceUrl(outputUrl + 'assets/icons/fav-search.svg'));
    iconRegistry.addSvgIcon('fav-document', sanitizer.bypassSecurityTrustResourceUrl(outputUrl + 'assets/icons/fav-document.svg'));
    iconRegistry.addSvgIcon('play', sanitizer.bypassSecurityTrustResourceUrl(outputUrl + 'assets/icons/play.svg'));
    iconRegistry.addSvgIcon('feedback', sanitizer.bypassSecurityTrustResourceUrl(outputUrl + 'assets/icons/feedback.svg'));
    iconRegistry.addSvgIcon('start-over', sanitizer.bypassSecurityTrustResourceUrl(outputUrl + 'assets/icons/start-over.svg'));
    iconRegistry.addSvgIcon('visible', sanitizer.bypassSecurityTrustResourceUrl(outputUrl + 'assets/icons/visible.svg'));
    iconRegistry.addSvgIcon('invisible', sanitizer.bypassSecurityTrustResourceUrl(outputUrl + 'assets/icons/invisible.svg'));
    iconRegistry.addSvgIcon('add', sanitizer.bypassSecurityTrustResourceUrl(outputUrl + 'assets/icons/add.svg'));

    this.location = this.elementRef.nativeElement.getAttribute('location');
    let lang = this.elementRef.nativeElement.getAttribute('language');
    this.globalData.lang = lang ? lang : 'en';
    this.locationService.setRegion(this.location);
  }
}
