import { DpsGradePropertiesData } from './../../general/models/data/dpsGradePropertiesData';
import { Pipe, PipeTransform } from '@angular/core';
import { _ } from 'underscore';

@Pipe({
  name: 'plotChartPipe'
})
export class PlotChartPipe implements PipeTransform {
  transform(data: DpsGradePropertiesData, selectedProperties: any): any {
    if (!data || !selectedProperties || selectedProperties.length < 2) {
      return null;
    }
    let propertyX = selectedProperties[0];
    let propertyY = selectedProperties[1];
    let gradeProperties = data.gradeGlobalProperties;
    let grades = data.grades;
    let properties = data.globalProperties;


    // Variables
    let gradesObject = [];
    let propertiesObject = [];
    let chartGrades = [];

    // Create properties array.
    _.forEach(properties, function (p) {
      propertiesObject[p.id] = p;
    });

    // Create grades array.
    _.forEach(grades, function (g) {
      gradesObject[g.substance] = g;
    });

    let minValueX;
    let maxValueX;
    let minValueY;
    let maxValueY;

    _.forEach(gradeProperties, function (g, key) {
      if (g.hasOwnProperty(propertyX.id) && g.hasOwnProperty(propertyY.id)) {
        let xValue = parseFloat(g[propertyX.id].sortValue);
        let yValue = parseFloat(g[propertyY.id].sortValue);
        let xDisplayText = g[propertyX.id].displayText;
        let yDisplayText = g[propertyY.id].displayText;

        if (xValue || yValue) {
          minValueX = minValueX ? Math.min(minValueX, xValue) : xValue;
          maxValueX = maxValueX ? Math.max(maxValueX, xValue) : xValue;
          minValueY = minValueY ? Math.min(minValueY, yValue) : yValue;
          maxValueY = maxValueY ? Math.max(maxValueY, yValue) : yValue;
          chartGrades.push({
            substance: key.toString(),
            name: gradesObject[key].tradeName,
            propertyValueX: xValue,
            propertyValueY: yValue,
            propertyDisplayTextX: xDisplayText,
            propertyDisplayTextY: yDisplayText
          });
        }
      }
    });
    let result = {
      properties: {
        propertyX: {
          name: propertyX.name,
          unitOfMeasure: propertyX.unitOfMeasure,
          scaleMin: minValueX === maxValueX ? (minValueX - Math.abs(minValueX * 0.1)) : (minValueX - ((maxValueX - minValueX) / 10)),
          scaleMax: minValueX === maxValueX ? (maxValueX + Math.abs(maxValueX * 0.1)) : (maxValueX + ((maxValueX - minValueX) / 10))
        },
        propertyY: {
          name: propertyY.name,
          unitOfMeasure: propertyY.unitOfMeasure,
          scaleMin: minValueY === maxValueY ? (minValueY - Math.abs(minValueY * 0.1)) : (minValueY - ((maxValueY - minValueY) / 10)),
          scaleMax: minValueY === maxValueY ? (maxValueY + Math.abs(maxValueY * 0.1)) : (maxValueY + ((maxValueY - minValueY) / 10))
        }
      },
      grades: chartGrades
    };

    return result;
  }
}
