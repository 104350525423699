<main class="dps-application" [ngClass]="{ 'full-screen': globalData.applicationState.expandApplication }" [ngStyle]="{
    'height': (globalData.applicationState.expandApplication ? containerHeight : '100vh'),
    'min-height': (globalData.applicationState.expandApplication ? containerHeight : '100vh')
  }" (click)="mainClick($event)">
  <header class="header">
    <div class="left">
      <a class="navigation-link" (click)="toggleNavBar($event)" [ngClass]="{ active: navigationFull }">
        <mat-icon class="menu-icon" svgIcon="menu"></mat-icon>
      </a>
      <a class="back-link" [ngClass]="{ 'nav-hidden': router.url.indexOf('landing') !== -1 }" (click)="goBack()">
        <mat-icon class="back-icon" svgIcon="back"></mat-icon>
      </a>
      <router-outlet name="sidebar-toggle"></router-outlet>
      <div class="title">
        <ng-container *ngIf="router.url === '/search'" i18n="Title of the search section@@searchTitle">Search
        </ng-container>
        <ng-container *ngIf="router.url === '/favorites'" i18n="Title of the favorites section@@favoritesTitle">
          Favorites</ng-container>
        <ng-container *ngIf="globalData.title && router.url !== '/search' && router.url !== '/favorites'">
          {{ globalData.title }}
        </ng-container>
        <ng-container *ngIf="!globalData.title && router.url !== '/search' && router.url !== '/favorites'"
          i18n="The default title of the Dps when no silo is selected@@mainTitle">ExxonMobil™ Digital Product Selector
        </ng-container>
      </div>
    </div>
    <div class="right">

      <a class="expand-link" *ngIf="router.url.indexOf('grade-detail') === -1" (click)="toggleFullScreen()"
        [ngClass]="{ 'active': globalData.applicationState.expandApplication }">
        <mat-icon svgIcon="expand"></mat-icon>
      </a>
      <a class="back-link" *ngIf="router.url.indexOf('grade-detail') !== -1"
        (click)="router.url.indexOf('grade-detail') !== -1 ? goBack() : null">
        <mat-icon class="close-icon" svgIcon="close"></mat-icon>
      </a>
      <a class="export-link"
        *ngIf="router.url !== '/favorites' && router.url !== '/search' && (router.url.indexOf('grade-detail') === -1 || globalData.gradeDetail?.url)"
        (click)="doExport()">
        <mat-icon svgIcon="export"></mat-icon>
      </a>
      <a class="favorites-link" *ngIf="router.url !== '/favorites' && router.url !== '/search'"
        [ngClass]="{ 'active': globalData.currentFavorite }"
        (click)="globalData.currentFavorite ? removeFromFavorites(globalData.currentFavorite) : addToFavorites()">
        <mat-icon svgIcon="star-outline"></mat-icon>
      </a>
      <div class="share-wrapper">
        <a class="share-link" *ngIf="router.url !== '/favorites' && router.url !== '/search'"
          (click)="openShareDialog()">
          <mat-icon svgIcon="share"></mat-icon>
        </a>
        <dps-share *ngIf="globalData.applicationState.showShareDialog"></dps-share>
        <div (click)="globalData.applicationState.showShareDialog = false;"
          *ngIf="globalData.applicationState.showShareDialog" class="share-dialog-background"></div>
      </div>
      <router-outlet name="navbar-right"></router-outlet>
      <a class="actions-menu-link" (click)="globalData.applicationState.mobileActionsMenu = !globalData.applicationState.mobileActionsMenu;
        navigationFull = false;
        globalData.applicationState.showShareDialog = false;
        globalData.applicationState.radarChartSidebarOpen = false;
        globalData.applicationState.plotChartSidebarOpen = false;
        globalData.applicationState.datatableSidebarOpen = false;"
        [ngClass]="{ 'active': globalData.applicationState.mobileActionsMenu }">
        <mat-icon svgIcon="actions-menu"></mat-icon>
      </a>



    </div>
  </header>
  <div class="navigation-backdrop" (click)="closeNavBar()" [ngClass]="{ active: navigationFull }">

  </div>
  <div #navigation class="navigation" (mouseenter)="enterNavBar()" (mouseleave)="leaveNavBar()"
    [ngClass]="{ hovering: navigationFull}">
    <a class="datatable-link" (click)="globalData.applicationState.regionsSidebarOpen = false;
        navigationFull = false;
        globalData.applicationState.helpSidebarOpen = false;
        this.globalData.applicationState.mobileActionsMenu = false;
        this.globalData.applicationState.showShareDialog = false;" [routerLink]="['/datatable']"
      routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: false}">
      <mat-icon svgIcon="table"></mat-icon>
      <span i18n="Title of datatable section in the navigation sidebar@@navProducts">Products</span>
    </a>
    <a (click)="globalData.applicationState.regionsSidebarOpen = false;
        navigationFull = false;
        globalData.applicationState.helpSidebarOpen = false;
        onPlotChartClicked()" class="plot-chart-link" [routerLink]="['/plotchart']" routerLinkActive="active-link"
      [routerLinkActiveOptions]="{exact: false}"
      [ngClass]="{'active': (globalData.selectedProperties.length === 2 && globalData.applicationState.propertyType.id === 'GP') || (globalData.selectedSalesProperties.length === 2 && globalData.applicationState.propertyType.id === 'SP')}">
      <mat-icon svgIcon="xy"></mat-icon>
      <span i18n="Title of xy comparison section in the navigation sidebar@@navXY">X/Y comparison</span>
    </a>
    <a (click)="globalData.applicationState.regionsSidebarOpen = false;
        navigationFull = false;
        globalData.applicationState.helpSidebarOpen = false;
        this.globalData.applicationState.mobileActionsMenu = false;
        this.globalData.applicationState.showShareDialog = false;" class="radar-chart-link"
      [routerLink]="['/radarchart']" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: false}"
      [ngClass]="{ 'active': globalData.selectedGrades.length > 1 }">
      <mat-icon svgIcon="polygon-outline"></mat-icon>
      <span i18n="Title of spider chart section in the navigation sidebar@@navSpider">Spider comparison</span>
    </a>
    <a class="favorites-link" (click)="globalData.applicationState.regionsSidebarOpen = false;
        navigationFull = false;
        globalData.applicationState.helpSidebarOpen = false;
        this.globalData.applicationState.mobileActionsMenu = false;
        this.globalData.applicationState.showShareDialog = false;"
      [ngClass]="{ 'active-icon': globalData.favorites && globalData.favorites.length > 0 }"
      [routerLink]="['/favorites']" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: false}">
      <mat-icon svgIcon="star-outline"></mat-icon>
      <span i18n="Title of favorites section in the navigation sidebar@@navFavorites">Favorites</span>
    </a>
    <a class="search-link" (click)="globalData.applicationState.regionsSidebarOpen = false;
        navigationFull = false;
        globalData.applicationState.helpSidebarOpen = false;
        this.globalData.applicationState.mobileActionsMenu = false;
        this.globalData.applicationState.showShareDialog = false;" [routerLink]="['/search']"
      routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: false}">
      <mat-icon svgIcon="search-list"></mat-icon>
      <span i18n="Title of search section in the navigation sidebar@@navSearch">Search</span>
    </a>
    <a class="start-over-link" (click)="startOver();
        globalData.applicationState.regionsSidebarOpen = false;
        navigationFull = false;
        globalData.applicationState.helpSidebarOpen = false;
        this.globalData.applicationState.mobileActionsMenu = false;
        this.globalData.applicationState.showShareDialog = false;">
      <mat-icon svgIcon="start-over"></mat-icon>
      <span i18n="Title of start-over section in the navigation sidebar@@navStartOver">Start over</span>
    </a>
    <a class="regions-link" (click)="globalData.applicationState.regionsSidebarOpen = !globalData.applicationState.regionsSidebarOpen;
        navigationFull = false;
        globalData.applicationState.helpSidebarOpen = false;
        globalData.applicationState.datatableSidebarOpen = false;
        globalData.applicationState.plotChartSidebarOpen = false;
        globalData.applicationState.radarChartSidebarOpen = false;
        this.globalData.applicationState.mobileActionsMenu = false;
        this.globalData.applicationState.showShareDialog = false;"
      [ngClass]="{ 'active-link': globalData.applicationState.regionsSidebarOpen }">
      <mat-icon svgIcon="settings"></mat-icon>
      <span i18n="Title of region section in the navigation sidebar@@navRegion">Regions & settings</span>
    </a>
    <a class="help-link" (click)="globalData.applicationState.helpSidebarOpen = !globalData.applicationState.helpSidebarOpen;
        navigationFull = false;
        globalData.applicationState.regionsSidebarOpen = false;
        globalData.applicationState.datatableSidebarOpen = false;
        globalData.applicationState.plotChartSidebarOpen = false;
        globalData.applicationState.radarChartSidebarOpen = false;
        this.globalData.applicationState.mobileActionsMenu = false;
        this.globalData.applicationState.showShareDialog = false;
        onHelpButtonClicked();" [ngClass]="{ 'active-link': globalData.applicationState.helpSidebarOpen }">
      <mat-icon svgIcon="help"></mat-icon>
      <span i18n="Title of help section in the navigation sidebar@@navHelp">Help</span>
    </a>

    <div class="bottom">
      <!-- <a class="regions-link" (click)="globalData.applicationState.regionsSidebarOpen = !globalData.applicationState.regionsSidebarOpen;
          navigationFull = false;
          globalData.applicationState.helpSidebarOpen = false;
          globalData.applicationState.datatableSidebarOpen = false;
          globalData.applicationState.plotChartSidebarOpen = false;
          globalData.applicationState.radarChartSidebarOpen = false;
          this.globalData.applicationState.mobileActionsMenu = false;
          this.globalData.applicationState.showShareDialog = false;"
        [ngClass]="{ 'active-link': globalData.applicationState.regionsSidebarOpen }">
        <mat-icon svgIcon="settings"></mat-icon>
        <span i18n="Title of region section in the navigation sidebar@@navRegion">Regions & settings</span>
      </a>
      <a class="help-link" (click)="globalData.applicationState.helpSidebarOpen = !globalData.applicationState.helpSidebarOpen;
          navigationFull = false;
          globalData.applicationState.regionsSidebarOpen = false;
          globalData.applicationState.datatableSidebarOpen = false;
          globalData.applicationState.plotChartSidebarOpen = false;
          globalData.applicationState.radarChartSidebarOpen = false;
          this.globalData.applicationState.mobileActionsMenu = false;
          this.globalData.applicationState.showShareDialog = false;
          onHelpButtonClicked();" [ngClass]="{ 'active-link': globalData.applicationState.helpSidebarOpen }">
        <mat-icon svgIcon="help"></mat-icon>
        <span i18n="Title of help section in the navigation sidebar@@navHelp">Help</span>
      </a> -->
    </div>
  </div>
  <!-- <a class="feedback-button">
    <div class="feedback-icon-wrapper">
      <mat-icon svgIcon="feedback"></mat-icon>
    </div>
  </a> -->
  <router-outlet></router-outlet>
  <!-- <div (click)="globalData.applicationState.regionsSidebarOpen = false;" *ngIf="globalData.applicationState.regionsSidebarOpen" class="region-panel-background"></div>
  <div (click)="globalData.applicationState.helpSidebarOpen = false;" *ngIf="globalData.applicationState.helpSidebarOpen" class="help-panel-background"></div> -->
  <div class="help-sidebar" [ngClass]="{ open: globalData.applicationState.helpSidebarOpen }">

    <div class="tile">
      <a (click)="globalData.applicationState.showGuidedTourDialog = true; logHelpAnalytics('guidedtour');">
        <div class="tile-wrapper">
          <div class="image-placeholder">
            <img width="100%" [src]="iconUrlBase + 'assets/images/showMeHow.jpg'">
          </div>
          <div class="text-placeholder">
            <span i18n="Guided tour placeholder@@guidedTourPlaceholder">Show me how</span>
          </div>
        </div>
      </a>
    </div>

    <dps-brightcove-lightbox class="lightbox" (click)="logHelpAnalytics('promotionalvideo')"
      [image]="iconUrlBase + 'assets/images/aboutTheApplication.jpg'"
      [videoUrl]="globalData.videoLang === 'zh' ? 'https://www.exxonmobilchemical.com.cn/dmm3bwsv3/assetstream.aspx?assetid=103022&mediaformatid=50039&destinationid=10016' : (globalData.videoLang === 'es' ? 'https://www.exxonmobilchemical.com.cn/dmm3bwsv3/assetstream.aspx?assetid=103023&mediaformatid=50039&destinationid=10016' : 'https://www.exxonmobilchemical.com.cn/dmm3bwsv3/assetstream.aspx?assetid=103021&mediaformatid=50039&destinationid=10016')"
      i18n-placeholder="About the application placeholder@@aboutTheApplicationPlaceholder"
      placeholder="About the application">
    </dps-brightcove-lightbox>

    <div class="tile">
      <a (click)="logHelpAnalytics('contactus')"
        href="{{ contactUsUrl }}?regionId={{ globalData.selectedFilters?.regionId }}" target="_blank">
        <div class="tile-wrapper">
          <div class="image-placeholder">
            <img width="100%" [src]="iconUrlBase + 'assets/images/contactUs.jpg'">
          </div>
          <div class="text-placeholder">
            <span i18n="Contact us placeholder@@contactUsPlaceholder">Contact us</span>
          </div>
        </div>
      </a>
    </div>

  </div>
  <div class="regions-sidebar" [ngClass]="{ open: globalData.applicationState.regionsSidebarOpen }">
    <div class="region-switch">
      <label i18n="Region switch label in settings@@settingsRegion">Region</label>
      <ng-select [items]="globalData.filters.regions" [clearable]="false" bindLabel="name" bindValue="id"
        (change)="switchRegion(); globalData.applicationState.regionsSidebarOpen = false;"
        [(ngModel)]="globalData.selectedFilters.regionId" [searchable]="false">
      </ng-select>
    </div>
    <div class="properties-switch" *ngIf="globalData.hasOtherPropertyType">
      <label i18n="Property type switch label in settings@@settingsPropertyType">Which data set would you like to
        use?</label>
      <ng-select [items]="[{ id: 'GP', name: 'Product datasheet' }, { id: 'SP', name: 'Sales specifications' }]"
        [compareWith]="findById" [clearable]="false" [searchable]="false"
        (change)="switchPropertyType(); globalData.applicationState.regionsSidebarOpen = false;"
        [(ngModel)]="globalData.applicationState.propertyType">
        <ng-template ng-label-tmp let-item="item">
          <ng-container *ngIf="item.id === 'GP'" i18n="Property type switch product data value@@tabProductProperties">
            Product data</ng-container>
          <ng-container *ngIf="item.id === 'SP'" i18n="Property type switch sales spec value@@tabSalesSpecs">Sales
            specifications</ng-container>
        </ng-template>
        <ng-template ng-option-tmp let-item="item" let-index="index">
          <ng-container *ngIf="item.id === 'GP'" i18n="Property type switch product data value@@tabProductProperties">
            Product data</ng-container>
          <ng-container *ngIf="item.id === 'SP'" i18n="Property type switch sales spec value@@tabSalesSpecs">Sales
            specifications</ng-container>
        </ng-template>
      </ng-select>
    </div>
    <div class="unit-system-switch">
      <label i18n="Unit system switch label in settings@@settingsUnitSystem">Unit system</label>
      <div class="button-wrapper">
        <a class="left" (click)="switchUnitSystem('English'); globalData.applicationState.regionsSidebarOpen = false;"
          [ngClass]="{'active': globalData.unitSystem === 'English'}">
          <span i18n="Unit system switch Imperial value@@settingsUnitSystemImperial2">imprial</span>
        </a>
        <a class="right" (click)="switchUnitSystem('SI'); globalData.applicationState.regionsSidebarOpen = false;"
          [ngClass]="{'active': globalData.unitSystem === 'SI'}">
          <span i18n="Unit system switch Metric value@@settingsUnitSystemMetric2">metric</span>
        </a>
      </div>
    </div>
  </div>
  <dps-explore-data
    *ngIf="globalData.applicationState.showInitialFilterDialog && !globalData.applicationState.showGuidedTourDialog">
  </dps-explore-data>
  <dps-export *ngIf="globalData.applicationState.showExportDialog"></dps-export>
  <!-- <dps-silo-dialog *ngIf="globalData.applicationState.showSiloDialog"></dps-silo-dialog> -->
  <dps-share-dialog *ngIf="globalData.applicationState.showShareDialog"></dps-share-dialog>
  <dps-qr-share-dialog *ngIf="globalData.applicationState.showQrShareDialog"></dps-qr-share-dialog>
  <dps-succes-dialog *ngIf="globalData.applicationState.showSuccesDialog"></dps-succes-dialog>
  <dps-fail-dialog *ngIf="globalData.applicationState.showFailDialog"></dps-fail-dialog>
  <dps-property-type-selection *ngIf="globalData.applicationState.showPropertyTypeSelection">
  </dps-property-type-selection>
  <dps-property-type-switch *ngIf="globalData.applicationState.showPropertyTypeSwitch"></dps-property-type-switch>

  <dps-guided-tour *ngIf="globalData.applicationState.showGuidedTourDialog"></dps-guided-tour>

  <div class="mobile-actions-menu-background" (click)="globalData.applicationState.mobileActionsMenu = false;"
    *ngIf="globalData.applicationState.mobileActionsMenu"></div>
  <div class="mobile-actions-menu"
    [ngClass]="{ 'mobile-actions-menu-show': globalData.applicationState.mobileActionsMenu }">
    <router-outlet name="navbar-right-mobile"></router-outlet>
    <a class="share-link" *ngIf="router.url !== '/favorites' && router.url !== '/search'" (click)="openShareDialog()">
      <mat-icon svgIcon="share"></mat-icon>
      <span i18n="Title of share section in the actions navgiation sidebar@@actionsNavgiationShare">Share</span>
    </a>
    <a class="export-link"
      *ngIf="router.url !== '/favorites' && router.url !== '/search' && (router.url.indexOf('grade-detail') === -1 || globalData.gradeDetail?.url)"
      (click)="doExport()">
      <mat-icon svgIcon="export"></mat-icon>
      <span i18n="Title of export section in the actions navgiation sidebar@@actionsNavgiationExport">Export to
        PDF</span>
    </a>
    <a class="favorites-link" *ngIf="router.url !== '/favorites' && router.url !== '/search'"
      [ngClass]="{ 'active': globalData.currentFavorite }"
      (click)="globalData.currentFavorite ? removeFromFavorites(globalData.currentFavorite) : addToFavorites()">
      <mat-icon svgIcon="star-outline"></mat-icon>
      <span i18n="Title of favorites section in the actions navgiation sidebar@@actionsNavgiationFavorites">Add to
        Favorites</span>
    </a>

    <a class="expand-link" (click)="toggleFullScreen()"
      [ngClass]="{ 'active': globalData.applicationState.expandApplication }">
      <mat-icon svgIcon="expand"></mat-icon>
      <span *ngIf="!globalData.applicationState.expandApplication"
        i18n="Title of enter full screen mode in the actions navgiation sidebar@@actionsNavgiationEnterFullScreen">Full
        screen</span>
      <span *ngIf="globalData.applicationState.expandApplication"
        i18n="Title of leave full screen mode in the actions navgiation sidebar@@actionsNavgiationLeaveFullScreen">Exit
        full screen</span>
    </a>
  </div>

</main>