import { DpsGrade } from './../general/models/data/dpsGrade';
import { FilterSalesPropertiesPipe } from './../general/pipes/filter/filter-sales-properties.pipe';
import { FilterPropertiesPipe } from './../general/pipes/filter/filter-properties.pipe';
import { GlobalData } from './../general/services/global-data.service';
import { Component, OnInit } from '@angular/core';
import { _ } from 'underscore';
import { GradesService } from './../general/services/grades.service';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';


@Component({
  selector: 'dps-radar-chart',
  templateUrl: './radar-chart.component.html',
  styleUrls: ['./radar-chart.component.scss']
})
export class RadarChartComponent implements OnInit {
  requestASampleUrl: string;
  iconUrlBase: string;
  contactUsUrl: string;

  constructor(public globalData: GlobalData, public gradesService: GradesService, private _router: Router) {
    this.requestASampleUrl = environment.requestASampleUrl;
    this.iconUrlBase = environment.iconUrlBase;
    this.contactUsUrl = environment.contactUsUrl;
  }

  ngOnInit() {
    if (!this.globalData.data &&
      !this.globalData.loading &&
      !this.globalData.applicationState.showInitialFilterDialog &&
      !this.globalData.applicationState.showPropertyTypeSelection) {
      if ((window as any).silo) {
        this.gradesService.getData(() => {
          this.globalData.setDefaultSelectedRadarProperties();
        });
      } else {
        this.globalData.applicationState.showInitialFilterDialog = true;
      }
    }
    if (this.globalData.data) {
      this.globalData.setDefaultSelectedRadarProperties();
    }
  }

  showDetail(substance, name) {
    let gradeSubstance = parseInt(substance, null);
    this._router.navigateByUrl('/grade-detail/' + gradeSubstance);
  }

  closeSidebar() {
    this.globalData.applicationState.radarChartSidebarOpen = false;
  }

  isTouchDevice() {
    return 'ontouchstart' in document.documentElement;
  }

  showTooltip(message) {
    if (this.isTouchDevice()) {
      return null;
    } else {
      return message;
    }
  }

}
