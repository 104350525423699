import { RoutingStateService } from './../general/services/routing-state.service';
import { GradesService } from './../general/services/grades.service';
import { Component, OnInit } from '@angular/core';
import { GlobalData } from './../general/services/global-data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { _ } from 'underscore';
import { DataPropertyType } from '../general/enums/data-property-type';

@Component({
  selector: 'dps-grade-detail',
  templateUrl: './grade-detail.component.html',
  styleUrls: ['./grade-detail.component.scss']
})
export class GradeDetailComponent implements OnInit {
  pdfSrc = '/dist/assets/docs/document.pdf';
  zoom: number;
  fitToPage: boolean;
  autoResize: boolean;
  originalSize: boolean;
  gradeDetailId: number;
  gradeDetailName: string;
  previousRoute: string;
  public dataTypes;

  constructor(
    public globalData: GlobalData,
    public activatedRoute: ActivatedRoute,
    public router: Router,
    private _gradesService: GradesService,
    private _routingStateService: RoutingStateService
  ) {
    this.zoom = 1;
    this.fitToPage = true;
    this.autoResize = true;
    this.originalSize = true;
    this.dataTypes = DataPropertyType;
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.initializeData(params);
      setTimeout(() => {
        this.getGradeDetail();
      });
    });
  }

  private initializeData(params) {
    this.gradeDetailId = params.id;
  }

  private getGradeDetail() {
    this._gradesService.getGradeDetail(this.gradeDetailId, () => {

      this.globalData.gradeDetail.previousUrl = this._routingStateService.getPreviousUrl();
      this.globalData.title = this.globalData.gradeDetail.name;
      this.gradeDetailName = this.globalData.gradeDetail.name;

      if (this.shouldShowDetail()) {
        this.router.navigate(['grade-detail', this.gradeDetailId, 'related', 1]);
      }
    });
  }

  private shouldShowDetail() {
    return (this.globalData.gradeDetail && !this.globalData.gradeDetail.url && this.globalData.gradeDetail.tabs);
  }

  adjustZoom(step) {
    this.zoom += step;
  }

  ngOnDestroy() {
    this.globalData.gradeDetail = null;
  }
}
