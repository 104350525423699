import { GlobalData } from './../../general/services/global-data.service';
import { Component, OnInit } from '@angular/core';
import * as qrcode from 'qrcode-generator';

@Component({
  selector: 'dps-qr-share-dialog',
  templateUrl: './qr-share-dialog.component.html',
  styleUrls: ['./qr-share-dialog.component.scss']
})
export class QrShareDialogComponent implements OnInit {
  currentUrl: string;
  constructor(public globalData: GlobalData) { }

  ngOnInit() {
    this.currentUrl = window.location.href.substring(0, window.location.href.indexOf('#'));
  }

  ngAfterViewInit() {
    let shareUrl = this.currentUrl + '#/link/' + this.globalData.getShareLink();
    let qr = qrcode(0, 'L');
    qr.addData(shareUrl);
    qr.make();
    document.getElementById('qr').innerHTML = qr.createSvgTag({
      margin: 0
    });
  }
}
