import { FavoritesService } from './../../general/services/favorites.service';
import { GradesService } from './../../general/services/grades.service';
import { GlobalData } from './../../general/services/global-data.service';
import { Component, OnInit } from '@angular/core';
import { AnalyticsService } from 'src/app/general/services/analytics.service';
import { AnalyticsEvent } from 'src/app/general/models/analytics/analytics-event';
import { AnalyticsEventsService } from 'src/app/general/services/analytics-events.service';
import { EventTypes } from 'src/app/general/enums/analytics/event-types';
import { AnalyticsConstants } from 'src/app/general/constants/analytics';
import { CustomData } from 'src/app/general/models/analytics/custom-data';
import { PageUrlData } from 'src/app/general/models/analytics/page-url-data';
import { PageData } from 'src/app/general/models/analytics/page-data';
import { PageType } from 'src/app/general/enums/analytics/page-type';

@Component({
  selector: 'dps-property-type-selection',
  templateUrl: './property-type-selection.component.html',
  styleUrls: ['./property-type-selection.component.scss']
})
export class PropertyTypeSelectionComponent implements OnInit {

  propertyTypeInfo = false;

  constructor(public globalData: GlobalData, private _gradesService: GradesService, private _favoritesService: FavoritesService, private analyticsService: AnalyticsService, private analyticsEventsService: AnalyticsEventsService) { }

  ngOnInit() {
  }

  loadProperties(type) {
    this.globalData.applicationState.showPropertyTypeSelection = false;
    switch (type) {
      case 'GP':
        this.globalData.applicationState.propertyType = { id: 'GP', name: 'Product datasheet' };
        this.analyticsService.sendEvent(this.analyticsEventsService.getEvent(EventTypes.DATA_SET_SELECTION, 'Product Data'), new CustomData({ prod_selector_data_type: 'Product Data' }), new PageUrlData('/datatable/landing'), new PageData(PageType.Selector));
        break;
      case 'SP':
        this.globalData.applicationState.propertyType = { id: 'SP', name: 'Sales specifications' };
        this.analyticsService.sendEvent(this.analyticsEventsService.getEvent(EventTypes.DATA_SET_SELECTION, 'Sales Data'), new CustomData({ prod_selector_data_type: 'Sales Data' }), new PageUrlData('/datatable/landing'), new PageData(PageType.Selector));

        break;
    }
    if (!this.globalData.data) {
      this._favoritesService.updateCurrentFavorite();
      this._gradesService.getData();
    }
  }
}
