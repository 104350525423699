import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { ExportComponent } from './export/export.component';
import { ExploreDataComponent } from './explore-data/explore-data.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import { SiloDialogComponent } from './silo-dialog/silo-dialog.component';
import { ShareDialogComponent } from './share-dialog/share-dialog.component';
import { SuccesDialogComponent } from './succes-dialog/succes-dialog.component';
import { FailDialogComponent } from './fail-dialog/fail-dialog.component';
import { PropertyTypeSelectionComponent } from './property-type-selection/property-type-selection.component';
import { ShareComponent } from './../share/share.component';
import { RouterModule } from '@angular/router';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { GuidedTourComponent } from './guided-tour/guided-tour.component';
import { PropertyTypeSwitchComponent } from './property-type-switch/property-type-switch.component';
import { QrShareDialogComponent } from './qr-share-dialog/qr-share-dialog.component';
import { PipesModule } from '../general/pipes/pipes.module'

@NgModule({
  imports: [
    CommonModule,
    DialogModule,
    NgSelectModule,
    FormsModule,
    MatIconModule,
    RouterModule,
    AutoCompleteModule,
    PipesModule
  ],
  declarations: [
    ExploreDataComponent,
    ExportComponent,
    SiloDialogComponent,
    PropertyTypeSelectionComponent,
    ShareDialogComponent,
    ShareComponent,
    SuccesDialogComponent,
    FailDialogComponent,
    GuidedTourComponent,
    PropertyTypeSwitchComponent,
    QrShareDialogComponent
  ],
  exports: [
    ExploreDataComponent,
    ExportComponent,
    SiloDialogComponent,
    PropertyTypeSelectionComponent,
    ShareDialogComponent,
    ShareComponent,
    SuccesDialogComponent,
    FailDialogComponent,
    GuidedTourComponent,
    PropertyTypeSwitchComponent,
    QrShareDialogComponent
  ]
})
export class DialogsModule { }
