<div *ngIf="globalData.data" class="plotchart-filter">
  <div class="grade-count">
    <span class="large" *ngIf="globalData.applicationState.propertyType.id === 'GP'"
      [innerHTML]="globalData.data && globalData.data.filteredGrades ? (globalData.data.filteredGrades | filterPlotChartGrades : globalData.selectedProperties : globalData.data.gradeGlobalProperties : globalData.propertiesChanged).length : ''"></span>
    <span class="large" *ngIf="globalData.applicationState.propertyType.id === 'SP'"
      [innerHTML]="globalData.data && globalData.data.filteredSalesGrades ? (globalData.data.filteredSalesGrades | filterPlotChartGrades : globalData.selectedSalesProperties : globalData.data.gradeSalesProperties : globalData.propertiesChanged).length : ''"></span>
      <ng-container *ngIf="globalData.applicationState.propertyType.id === 'GP'">
          <span i18n="Applicable grades in the filters sidebar on datatable and plot/spider chart@@applicableGradesPlural"
            >{globalData.data.filteredGrades.length, plural, =0 {applicable grades} =1 {applicable grade} other {applicable grades}}</span>
        </ng-container>
        <ng-container *ngIf="globalData.applicationState.propertyType.id === 'SP'">
          <span i18n="Applicable grades in the filters sidebar on datatable and plot/spider chart@@applicableGradesPlural"
            >{globalData.data.filteredSalesGrades.length, plural, =0 {applicable grades} =1 {applicable grade} other {applicable grades}}</span>
        </ng-container>
  </div>
  <div class="plotchart-filter-body">
    <div class="switch-axes-wrapper">
        <div class="switch-axes-text">
          <a (click)="switchAxes()" i18n="Plot chart switch axes label@@plotChartSwitchAxes">Switch axes</a>
        </div>
      </div>
    <div class="x-section">
      <h1 i18n="Plot chart X label@@plotChartX">X</h1>
      <ng-select *ngIf="globalData.applicationState.propertyType.id === 'GP'"
        [items]="(globalData.data.globalProperties | filterProperties: globalData.selectedFilters.propertyFilters) | plotChartMatchingProperties: globalData.selectedProperties[1]: globalData.data.grades: globalData.data.gradeGlobalProperties: 0: globalData.selectedProperties[0]"
        [clearable]="false"
        bindLabel="name"
        (change)="propertyChanged($event)"
        [(ngModel)]="globalData.selectedProperties[0]">
        <ng-template ng-option-tmp let-item="item">
          <span [ngClass]="{ faded: !item.hasFirstPlotMatch }" title="{{ item.name }}">
            {{item.name}} {{item.unitOfMeasure}}
          </span>
        </ng-template>
      </ng-select>
      <ng-select *ngIf="globalData.applicationState.propertyType.id === 'SP'"
        [items]="(globalData.data.salesProperties | filterSalesProperties: globalData.selectedFilters.salesPropertyFilters) | plotChartMatchingSalesProperties: globalData.selectedSalesProperties[1]: globalData.data.grades: globalData.data.gradeSalesProperties: 0: globalData.selectedSalesProperties[0]"
        [clearable]="false"
        bindLabel="name"
        (change)="propertyChanged($event)"
        [(ngModel)]="globalData.selectedSalesProperties[0]">
        <ng-template ng-option-tmp let-item="item">
          <span [ngClass]="{ faded: !item.hasFirstPlotMatch }" title="{{ item.name }}">
            {{item.name}} {{item.unitOfMeasure}}
          </span>
        </ng-template>
      </ng-select>
    </div>
    <div class="y-section">
      <h1 i18n="Plot chart Y label@@plotChartY">Y</h1>
      <ng-select *ngIf="globalData.applicationState.propertyType.id === 'GP'"
        [items]="(globalData.data.globalProperties | filterProperties: globalData.selectedFilters.propertyFilters) | plotChartMatchingProperties: globalData.selectedProperties[0]: globalData.data.grades: globalData.data.gradeGlobalProperties: 1: globalData.selectedProperties[1]"
        [clearable]="false"
        bindLabel="name"
        (change)="propertyChanged($event)"
        [(ngModel)]="globalData.selectedProperties[1]">
        <ng-template ng-option-tmp let-item="item">
          <span [ngClass]="{ faded: !item.hasSecondPlotMatch }" title="{{ item.name }}">
            {{item.name}} {{item.unitOfMeasure}}
          </span>
        </ng-template>
      </ng-select>
      <ng-select *ngIf="globalData.applicationState.propertyType.id === 'SP'"
        [items]="(globalData.data.salesProperties | filterSalesProperties: globalData.selectedFilters.salesPropertyFilters) | plotChartMatchingSalesProperties: globalData.selectedSalesProperties[0]: globalData.data.grades: globalData.data.gradeSalesProperties: 1: globalData.selectedSalesProperties[1]"
        [clearable]="false"
        bindLabel="name"
        (change)="propertyChanged($event)"
        [(ngModel)]="globalData.selectedSalesProperties[1]">
        <ng-template ng-option-tmp let-item="item">
          <span [ngClass]="{ faded: !item.hasSecondPlotMatch }" title="{{ item.name }}">
            {{item.name}} {{item.unitOfMeasure}}
          </span>
        </ng-template>
      </ng-select>
    </div>
  </div>
</div>
