import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { DpsSearchResult } from './../models/data/dpsSearchResult';
import { DpsFilters } from './../models/dpsFilters';
import { GlobalData } from './global-data.service';
import { Router } from '@angular/router';
import { DpsFavorite } from '../models/dpsFavorite';
import { ServicesModule } from './services.module';
import { Injectable } from '@angular/core';
import { _ } from 'underscore';

@Injectable({
  providedIn: ServicesModule
})
export class FavoritesService {
  private _apiUrl: string;
  private _setId: string;

  constructor(public globalData: GlobalData, private _router: Router, private _http: HttpClient) {
    this._apiUrl = environment.apiUrlExxon;
  }

  getFavorites() {
    this._setId = localStorage.getItem('favoriteSetId');

    if (this._setId) {
      let url = this._apiUrl + 'favorite?favoriteSetId=' + this._setId;

      return this._http.get(url).subscribe((result: any) => {
        this._setId = result.favoriteSetId,
          this.globalData.favorites = _.map(result.item, (item) => {
            let favorite = JSON.parse(item.data) as DpsFavorite;
            favorite.id = item.id;
            favorite.title = item.name;
            return favorite;
          });
        this.updateCurrentFavorite();
      }, () => {
        this._setId = null;
        localStorage.removeItem('favoriteSetId');
        this.globalData.favorites = [];
      });
    } else {
      this.globalData.favorites = [];
    }
  }

  saveFavorite(favorite: DpsFavorite) {
    let self = this;
    let url = this._apiUrl + 'favorite/post';
    let params = {
      name: window.btoa(unescape(encodeURIComponent(favorite.title))),
      data: window.btoa(unescape(encodeURIComponent(JSON.stringify(favorite))))
    };

    if (this._setId) {
      (params as any).favoriteSetId = this._setId;
    }

    this.globalData.favorites.push(favorite);
    this.updateCurrentFavorite();
    return this._http.post(url, params).subscribe((result: any) => {
      if (!this._setId) {
        self.saveFavoriteSet(result.favoriteSetId);
      }
      self.getFavorites();
    });
  }

  saveFavoriteSet(id) {
    this._setId = id;
    localStorage.setItem('favoriteSetId', this._setId);
  }

  updateFavorite(favorite: DpsFavorite) {
    let self = this;
    let url = this._apiUrl + 'favorite/put';
    let params = {
      favoriteSetId: this._setId,
      favoriteItemId: favorite.id,
      name: window.btoa(unescape(encodeURIComponent(favorite.title))),
      data: window.btoa(unescape(encodeURIComponent(JSON.stringify(favorite))))
    };

    return this._http.post(url, params).subscribe((result) => {
      self.getFavorites();
    });
  }

  deleteFavorite(favorite: DpsFavorite) {
    let self = this;
    let url = this._apiUrl + 'favorite/delete';
    let params = {
      favoriteSetId: this._setId,
      favoriteItemId: favorite.id
    };
    return this._http.post(url, params).subscribe((result) => {
      if (this.globalData.favorites.length === 0) {
        this.saveFavoriteSet(null);
        this.updateCurrentFavorite();
      } else {
        self.getFavorites();
      }
    });
  }

  addToFavorites(path: string, type?: string): void {
    path = path.replace('/landing', '');
    let favorite = {
      breadCrumb: this._getFavoritesBreadcrumb(),
      title: this._getFavoritesBreadcrumb(),
      subtitle: this._getFavoritesSubtitle(),
      icon: this._getFavoriteIcon(path, this.globalData.applicationState.propertyType.id),
      path: path,
      type: type,
      selectedFilters: JSON.parse(JSON.stringify({
        regionId: this.globalData.selectedFilters.regionId,
        brandIds: this.globalData.selectedFilters.brandIds,
        productTypeIds: this.globalData.selectedFilters.productTypeIds,
        businessUnitIds: this.globalData.selectedFilters.businessUnitIds,
        industryIds: this.globalData.selectedFilters.industryIds,
        applicationIds: this.globalData.selectedFilters.applicationIds,
        marketSegmentIds: this.globalData.selectedFilters.marketSegmentIds,
        propertyFilters: _.filter(this.globalData.selectedFilters.propertyFilters, function (pf) {
          return pf.selected || pf.minimum || pf.maximum;
        }),
        salesPropertyFilters: _.filter(this.globalData.selectedFilters.salesPropertyFilters, function (pf) {
          return pf.selected ||
            (pf.salesRange.minimumRange && (pf.salesRange.minimumRange.minimum || pf.salesRange.minimumRange.maximum)) ||
            (pf.salesRange.maximumRange && (pf.salesRange.maximumRange.minimum || pf.salesRange.maximumRange.maximum)) ||
            (pf.salesRange.targetRange && (pf.salesRange.targetRange.minimum || pf.salesRange.targetRange.maximum));
        })
      })),
      unitSystem: this.globalData.unitSystem,
      sortProperty: JSON.parse(JSON.stringify(this.globalData.sortProperty)),
      sortOrder: this.globalData.sortOrder,
      sortSalesProperty: JSON.parse(JSON.stringify(this.globalData.sortSalesProperty)),
      sortSalesOrder: this.globalData.sortSalesOrder,
      sortApplication: JSON.parse(JSON.stringify(this.globalData.sortApplication)),
      sortApplicationOrder: this.globalData.sortApplicationOrder,
      selectedProperties: path === '/plotchart' ? JSON.parse(JSON.stringify(this.globalData.selectedProperties)) : [],
      selectedGrades: path === '/radarchart' ? JSON.parse(JSON.stringify(this.globalData.selectedGrades)) : [],
      selectedRadarProperties: path === '/radarchart' ? JSON.parse(JSON.stringify(this.globalData.selectedRadarProperties)) : [],
      selectedSalesProperties: path === '/plotchart' ? JSON.parse(JSON.stringify(this.globalData.selectedSalesProperties)) : [],
      selectedRadarSalesProperties: path === '/radarchart' ? JSON.parse(JSON.stringify(this.globalData.selectedRadarSalesProperties)) : [],
      applicationActive: this.globalData.applicationState.applicationActive,
      propertyType: JSON.parse(JSON.stringify(this.globalData.applicationState.propertyType)),
      excludes: this.globalData.silo ? JSON.parse(JSON.stringify(this.globalData.silo.excludes)) : []
    } as DpsFavorite;

    this.saveFavorite(favorite);
  }

  addSearchTermToFavorites(searchTerm) {
    let favorite = {
      title: searchTerm,
      type: 'searchTerm',
      searchTerm: searchTerm,
      path: '/search',
      icon: this._getFavoriteIcon('/search')
    } as DpsFavorite;
    this.saveFavorite(favorite);
  }

  addSearchResultToFavorites(searchResult: DpsSearchResult) {
    let selectedFilters = searchResult.getFilters(this.globalData.selectedFilters.regionId);
    let path = searchResult.getPath();
    let favorite = {
      breadCrumb: searchResult.name,
      title: searchResult.name,
      icon: this._getFavoriteIcon(path, this.globalData.applicationState.propertyType.id),
      path: path,
      type: 'searchResult',
      searchType: searchResult.type,
      searchResultId: searchResult.id,
      selectedFilters: selectedFilters,
      unitSystem: this.globalData.unitSystem,
      sortProperty: null,
      sortOrder: null,
      sortSalesProperty: null,
      sortSalesOrder: null,
      sortApplication: null,
      sortApplicationOrder: null,
      selectedProperties: [],
      selectedGrades: [],
      selectedRadarProperties: [],
      selectedSalesProperties: [],
      selectedRadarSalesProperties: [],
      applicationActive: false,
      propertyType: JSON.parse(JSON.stringify(this.globalData.applicationState.propertyType))
    } as DpsFavorite;

    this.saveFavorite(favorite);
  }

  removeFromFavorites(favorite: DpsFavorite): void {
    this.globalData.favorites.splice(this.globalData.favorites.indexOf(favorite), 1);
    this.deleteFavorite(favorite);
  }

  loadFavorites(): void {
    this.getFavorites();
  }

  updateCurrentFavorite(): void {
    let self = this;
    this.globalData.currentFavorite = _.find(this.globalData.favorites, (f: DpsFavorite) => {
      // check path
      let isSamePath = f.path === self._router.url.replace('/landing', '');
      if (!isSamePath) {
        return false;
      }

      if (f.type === 'searchTerm') {
        return f.searchTerm === self.globalData.searchModel.searchTerm;
      } else {
        // check filters
        if (
          f.selectedFilters.regionId !== this.globalData.selectedFilters.regionId ||
          !(Object as any).compare(f.selectedFilters.brandIds, this.globalData.selectedFilters.brandIds) ||
          !(Object as any).compare(f.selectedFilters.productTypeIds, this.globalData.selectedFilters.productTypeIds) ||
          !(Object as any).compare(f.selectedFilters.businessUnitIds, this.globalData.selectedFilters.businessUnitIds) ||
          !(Object as any).compare(f.selectedFilters.industryIds, this.globalData.selectedFilters.industryIds) ||
          !(Object as any).compare(f.selectedFilters.applicationIds, this.globalData.selectedFilters.applicationIds) ||
          !(Object as any).compare(f.selectedFilters.marketSegmentIds, this.globalData.selectedFilters.marketSegmentIds)
        ) {
          return false;
        }

        let gpf = _.map(_.filter(this.globalData.selectedFilters.propertyFilters, function (pf) {
          return pf.selected || pf.minimum || pf.maximum;
        }), (pf) => {
          return {
            selected: pf.selected,
            minimum: pf.minimum,
            maximum: pf.maximum
          };
        });

        let fgpf = _.map(f.selectedFilters.propertyFilters, (pf) => {
          return {
            selected: pf.selected,
            minimum: pf.minimum,
            maximum: pf.maximum
          };
        });

        let spf = _.map(_.filter(this.globalData.selectedFilters.salesPropertyFilters, function (pf) {
          return pf.selected ||
            (pf.salesRange.minimumRange && (pf.salesRange.minimumRange.minimum || pf.salesRange.minimumRange.maximum)) ||
            (pf.salesRange.maximumRange && (pf.salesRange.maximumRange.minimum || pf.salesRange.maximumRange.maximum)) ||
            (pf.salesRange.targetRange && (pf.salesRange.targetRange.minimum || pf.salesRange.targetRange.maximum));
        }), (pf) => {
          return {
            selected: pf.selected,
            minimumRange: pf.salesRange && pf.salesRange.minimumRange ? pf.salesRange.minimumRange : undefined,
            maximumRange: pf.salesRange && pf.salesRange.maximumRange ? pf.salesRange.maximumRange : undefined,
            targetRange: pf.targetRange && pf.salesRange.targetRange ? pf.salesRange.targetRange : undefined
          };
        });

        let fspf = _.map(f.selectedFilters.salesPropertyFilters, (pf) => {
          return {
            selected: pf.selected,
            minimumRange: pf.salesRange && pf.salesRange.minimumRange ? pf.salesRange.minimumRange : undefined,
            maximumRange: pf.salesRange && pf.salesRange.maximumRange ? pf.salesRange.maximumRange : undefined,
            targetRange: pf.targetRange && pf.salesRange.targetRange ? pf.salesRange.targetRange : undefined
          };
        });

        // check property filters
        if (
          !(Object as any).compare(fgpf, gpf) ||
          !(Object as any).compare(fspf, spf)
        ) {
          return false;
        }

        if (f.unitSystem && f.unitSystem !== this.globalData.unitSystem) {
          return false;
        }

        // let hasSameFilters = (Object as any).compare(self.globalData.selectedFilters, f.selectedFilters);
        // if (!hasSameFilters) {
        //   return false;
        // }

        // check properties based on view
        // GP
        if (f.propertyType.id === 'GP') {
          if (self.globalData.applicationState.propertyType.id !== 'GP') {
            return false;
          }

          // check sort
          let hasSameSort = (Object as any).compare(self.globalData.sortProperty, f.sortProperty)
            && f.sortOrder === self.globalData.sortOrder;
          if (!hasSameSort) {
            return false;
          }

          // check chart selection
          let hasSameSelection = true;
          if (f.path === '/plotchart') {
            hasSameSelection = (Object as any).compare(this.globalData.selectedProperties, f.selectedProperties);
          } else if (f.path === '/radarchart') {
            hasSameSelection = (Object as any).compare(this.globalData.selectedGrades, f.selectedGrades)
              && (Object as any).compare(this.globalData.selectedRadarProperties, f.selectedRadarProperties);
          }
          if (!hasSameSelection) {
            return false;
          }

        } else if (f.propertyType.id === 'SP') {
          if (self.globalData.applicationState.propertyType.id !== 'SP') {
            return false;
          }

          // check sort
          let hasSameSort = (Object as any).compare(self.globalData.sortSalesProperty, f.sortSalesProperty)
            && f.sortSalesOrder === self.globalData.sortSalesOrder;
          if (!hasSameSort) {
            return false;
          }

          // check chart selection
          let hasSameSelection = true;
          if (f.path === '/plotchart') {
            hasSameSelection = (Object as any).compare(this.globalData.selectedSalesProperties, f.selectedSalesProperties);
          } else if (f.path === '/radarchart') {
            hasSameSelection = (Object as any).compare(this.globalData.selectedGrades, f.selectedGrades)
              && (Object as any).compare(this.globalData.selectedRadarSalesProperties, f.selectedRadarSalesProperties);
          }
          if (!hasSameSelection) {
            return false;
          }
        }

        if (f.applicationActive && f.path === '/datatable/applications') {
          if (!self.globalData.applicationState.applicationActive) {
            return false;
          }

          // check sort
          let hasSameSort = (Object as any).compare(self.globalData.sortApplication, f.sortApplication)
            && f.sortApplicationOrder === self.globalData.sortApplicationOrder;
          if (!hasSameSort) {
            return false;
          }
        }
      }
      return true;
    });
  }

  setFavorite(favorite) {
    favorite = JSON.parse(JSON.stringify(favorite));
    if (favorite.type === 'searchTerm') {
      this.globalData.searchModel.searchTerm = favorite.searchTerm;
    } else {
      this.globalData.selectedFilters = new DpsFilters();
      this.globalData.selectedFilters = _.extend(this.globalData.selectedFilters, JSON.parse(JSON.stringify(favorite.selectedFilters)));
      this.globalData.selectedFilters.propertyFilters = _.reduce(this.globalData.selectedFilters.propertyFilters, (memo, pf) => {
        memo[pf.id] = pf;
        return memo;
      }, {});
      this.globalData.selectedFilters.salesPropertyFilters = _.reduce(this.globalData.selectedFilters.salesPropertyFilters, (memo, pf) => {
        memo[pf.id] = pf;
        return memo;
      }, {});
      this.globalData.unitSystem = JSON.parse(JSON.stringify(favorite.unitSystem));
      this.globalData.sortProperty = JSON.parse(JSON.stringify(favorite.sortProperty));
      this.globalData.sortOrder = JSON.parse(JSON.stringify(favorite.sortOrder));
      this.globalData.sortSalesOrder = JSON.parse(JSON.stringify(favorite.sortSalesOrder));
      this.globalData.sortSalesProperty = JSON.parse(JSON.stringify(favorite.sortSalesProperty));
      this.globalData.sortApplication = JSON.parse(JSON.stringify(favorite.sortApplication));
      this.globalData.sortApplicationOrder = JSON.parse(JSON.stringify(favorite.sortApplicationOrder));
      this.globalData.selectedGrades = JSON.parse(JSON.stringify(favorite.selectedGrades));
      this.globalData.selectedProperties = JSON.parse(JSON.stringify(favorite.selectedProperties));
      this.globalData.selectedRadarProperties = JSON.parse(JSON.stringify(favorite.selectedRadarProperties));
      this.globalData.selectedSalesProperties = JSON.parse(JSON.stringify(favorite.selectedSalesProperties));
      this.globalData.selectedRadarSalesProperties = JSON.parse(JSON.stringify(favorite.selectedRadarSalesProperties));
      this.globalData.applicationState.applicationActive = JSON.parse(JSON.stringify(favorite.applicationActive));
      this.globalData.applicationState.propertyType = JSON.parse(JSON.stringify(favorite.propertyType));

      let excludes = ['businessUnit', 'productType'];

      if (favorite.path && favorite.path.indexOf('/grade-detail') === -1) {
        let siloFilters = new DpsFilters();
        siloFilters.updateFromFilters(this.globalData.selectedFilters);

        this.globalData.silo = {
          filters: siloFilters,
          excludes: favorite.excludes ? favorite.excludes : excludes
        };
      }
    }
  }

  private _getFavoritesBreadcrumb(): string {
    let breadCrumb = [];
    if (this._router.url.indexOf('/grade-detail') > -1) {
      breadCrumb = [this.globalData.title];
    } else {
      breadCrumb = breadCrumb.concat(_.pluck(_.filter(this.globalData.filters.businessUnits, (v) => {
        return _.indexOf(this.globalData.selectedFilters.businessUnitIds, v.id) > -1;
      }), 'name'));
      breadCrumb = breadCrumb.concat(_.pluck(_.filter(this.globalData.filters.productTypes, (v) => {
        return _.indexOf(this.globalData.selectedFilters.productTypeIds, v.id) > -1;
      }), 'name'));
      breadCrumb = breadCrumb.concat(_.pluck(_.filter(this.globalData.filters.applications, (v) => {
        return _.indexOf(this.globalData.selectedFilters.applicationIds, v.id) > -1;
      }), 'name'));
      breadCrumb = breadCrumb.concat(_.pluck(_.filter(this.globalData.filters.brands, (v) => {
        return _.indexOf(this.globalData.selectedFilters.brandIds, v.id) > -1;
      }), 'name'));
      breadCrumb = breadCrumb.concat(_.pluck(_.filter(this.globalData.filters.industries, (v) => {
        return _.indexOf(this.globalData.selectedFilters.industryIds, v.id) > -1;
      }), 'name'));
      breadCrumb = breadCrumb.concat(_.pluck(_.filter(this.globalData.filters.marketSegments, (v) => {
        return _.indexOf(this.globalData.selectedFilters.marketSegmentIds, v.id) > -1;
      }), 'name'));
    }

    return breadCrumb.join(' > ');
  }

  private _getFavoritesSubtitle() {
    let result = null;
    if (this._router.url === '/plotchart') {
      if (this.globalData.applicationState.propertyType.id === 'GP') {
        result = _.pluck(this.globalData.selectedProperties, 'name').join(', ');
      } else if (this.globalData.applicationState.propertyType.id === 'SP') {
        result = _.pluck(this.globalData.selectedSalesProperties, 'name').join(', ');
      }
    } else if (this._router.url === '/radarchart') {
      result = this.globalData.selectedGrades.length + ' products';
    }
    return result;
  }

  private _getFavoriteIcon(path: string, type?: string): string {
    let result: string;
    switch (true) {
      case path.indexOf('/datatable') > -1:
        result = type === 'GP' ? 'fav-list-pp' : 'fav-list-ss';
        break;
      case path === '/plotchart':
        result = 'fav-xy';
        break;
      case path === '/radarchart':
        result = 'fav-polygon';
        break;
      case path === '/search':
        result = 'fav-search';
        break;
      case path.indexOf('/grade-detail') > -1:
        result = 'fav-document';
        break;
      default:
        result = '';
    }
    return result;
  }
}
