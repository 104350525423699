import { AnalyticsService } from './../../../general/services/analytics.service';
import { environment } from './../../../../environments/environment';
import { GradesService } from './../../../general/services/grades.service';
import { DpsFilterIdName } from './../../../general/models/filter/dpsFilterIdName';
import { GlobalData } from '../../../general/services/global-data.service';
import { FavoritesService } from '../../../general/services/favorites.service';
import { DpsFilterProperty } from '../../../general/models/filter/dpsFilterProperty';
import { DpsGrade } from '../../../general/models/data/dpsGrade';
import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { _ } from 'underscore';
import { EventTypes } from 'src/app/general/enums/analytics/event-types';
import { AnalyticsEventsService } from 'src/app/general/services/analytics-events.service';

@Component({
  selector: 'dps-datatable-applications-table',
  templateUrl: './datatable-applications-table.component.html',
  styleUrls: ['./datatable-applications-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatatableApplicationsTableComponent implements OnInit {
  @Input() grades: DpsGrade[];
  @Input() applications: DpsFilterIdName[];
  @Input() brands: DpsFilterIdName[];
  @Input() gradeApplications: any;
  @Input() selectedGrades: DpsGrade[];
  @Input() gradesChanged: any;
  @Input() productTypes: DpsFilterIdName[];
  @Input() sortApplication: DpsFilterIdName;
  @Input() sortOrder: string;

  requestASampleUrl: string;

  constructor(public globalData: GlobalData,
    private _gradesService: GradesService,
    private _favoritesService: FavoritesService,
    private _analyticsService: AnalyticsService,
    private _analyticsEventsService: AnalyticsEventsService) {
    this.requestASampleUrl = environment.requestASampleUrl;
  }

  ngOnInit() {
  }

  isTouchDevice() {
    return 'ontouchstart' in document.documentElement;
  }

  toggleSort(application: DpsFilterIdName) {
    if (this.globalData.sortApplication === application) {
      if (this.globalData.sortApplicationOrder === 'desc') {
        this.globalData.sortApplication = null;
        this.globalData.sortApplicationOrder = null;
      } else {
        this.globalData.sortApplicationOrder = 'desc';
      }
    } else {
      this.globalData.sortApplication = application;
      this.globalData.sortApplicationOrder = 'asc';
    }
    this._favoritesService.updateCurrentFavorite();
    this._analyticsService.sendEvent(this._analyticsEventsService.getEvent(EventTypes.DATATABLE_SORT_APPLICATION), application.name);

  }

  toggleGradeSelection(grade: DpsGrade) {
    let gradeIndex = _.indexOf(this.selectedGrades, grade);
    if (gradeIndex > -1) {
      grade.color = null;
      this.selectedGrades.splice(gradeIndex, 1);
    } else {
      if (this.selectedGrades.length > 4) {
        this.selectedGrades.splice(4, 1);
      }
      grade.color = this.globalData.getFirstAvailableGradeColor();
      this.selectedGrades.unshift(grade);
      this._analyticsService.sendEvent(this._analyticsEventsService.getEvent(EventTypes.DATATABLE_ADD_TO_SPIDER), grade.tradeName);
    }
    this.globalData.gradesChanged = {};
    this._favoritesService.updateCurrentFavorite();
  }

  onScroll() {
    this.globalData.limit += 15;
  }

  trackByIndex(index) {
    return index;
  }

  restoreDefaults() {
    let loadData = this.globalData.restoreDefaults();
    this._favoritesService.updateCurrentFavorite();
    if (loadData) {
      this._gradesService.getData();
    }
  }
}
