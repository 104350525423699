import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { GlobalData } from './general/services/global-data.service';
import { Injectable } from '@angular/core';
import { _ } from 'underscore';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AppQueryParamAuthGuardService {

  constructor(public globalData: GlobalData, private _router: Router, private location: Location) {

  }

  getInitialSilo() {
    let silo = {
      filters: {
        regionId: 0,
        businessUnitIds: [],
        brandIds: [],
        industryIds: [],
        marketSegmentIds: [],
        productTypeIds: [],
        globalPropertyFilters: {
        },
        salesPropertyFilters: {
        }
      },
      excludes: []
    };

    let windowSilo = window['silo'];
    if (windowSilo) {
      if (typeof (windowSilo) == "string") {
        windowSilo = JSON.parse(windowSilo);
      }

      silo.filters.regionId = windowSilo.filters.regionId;
    }

    return silo;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (route.queryParams && Object.keys(route.queryParams).length) {
      let gradeId = this.getParameterByName('gradeId');
      let excludes = this.getParameterByName('excludes');

      let fitlerParameters = {
        businessUnitIds: this.getParameterByName('businessUnitIds'),
        brandIds: this.getParameterByName('brandIds'),
        industryIds: this.getParameterByName('industryIds'),
        marketSegmentIds: this.getParameterByName('applicationIds'),
        productTypeIds: this.getParameterByName('categoryIds')
      }

      if (gradeId) {
        this._router.navigate(['/grade-detail', gradeId], { replaceUrl: true })
      }

      this.setupSilo(excludes, fitlerParameters);

    } else {
      this.setSearchTerm();

    }

    return true;
  }

  setupSilo(excludes, fitlerParameters) {
    let silo = this.getInitialSilo();
    if (!this.anyParameters(fitlerParameters)) {
      return true;
    }

    (window as any).silo = this.fillInSilo(silo, excludes, fitlerParameters);
  }

  setSearchTerm() {
    let search = this.getParameterByName('q');
    if (search) {
      this.globalData.searchModel.searchTerm = search;
    }
  }

  anyParameters(fitlerParameters) {
    return (fitlerParameters.businessUnitIds || fitlerParameters.brandIds || fitlerParameters.industryIds || fitlerParameters.marketSegmentIds || fitlerParameters.productTypeIds);
  }

  setValue(silo, key, value) {
    silo.filters[key] = +value ? +value : silo.filters[key];
  }

  setValues(silo, key, values) {
    silo.filters[key] = [...silo.filters[key], ...values]
  }

  initializeSiloFilter(silo, parameter, key, exclude) {
    if (parameter) {
      this.setValues(silo, key, _.map(parameter.split(','), (i) => +i))
      this.addExclude(silo, exclude);
    }
  }

  fillInSiloExcludes(silo, excludes) {
    if (excludes) {
      silo.excludes = [...silo.excludes, ...excludes.split(',')];
    }

    this.addExclude(silo, 'businessUnit');
    this.addExclude(silo, 'productType');
  }

  fillInSilo(silo, excludes, fitlerParameters) {
    this.fillInSiloExcludes(silo, excludes);
    this.initializeSiloFilter(silo, fitlerParameters.businessUnitIds, 'businessUnitIds', 'businessUnit');
    this.initializeSiloFilter(silo, fitlerParameters.brandIds, 'brandIds', 'brand');
    this.initializeSiloFilter(silo, fitlerParameters.industryIds, 'industryIds', 'industry');
    this.initializeSiloFilter(silo, fitlerParameters.marketSegmentIds, 'marketSegmentIds', 'marketSegment');
    this.initializeSiloFilter(silo, fitlerParameters.productTypeIds, 'productTypeIds', 'productType');

    return JSON.stringify(silo);
  }

  addExclude(silo, exclude) {
    if (silo.excludes.findIndex((el) => el == exclude) == -1) {
      silo.excludes.push(exclude);
    }
  }

  getParameterByName(name, url?) {
    if (!url) {
      url = window.location.href;
    }
    name = name.replace(/[\[\]]/g, '\\$&');
    let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) {
      return null;
    }
    if (!results[2]) {
      return '';
    }
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }
}
