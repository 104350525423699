import { GlobalData } from './../../general/services/global-data.service';
import {
  Directive,
  ElementRef,
  Renderer,
  HostListener,
  Inject,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { DOCUMENT } from '@angular/platform-browser';
import * as d3 from 'd3';
import * as axis from 'd3-axis';
import { _ } from 'underscore';
import { SelectorFlags } from '@angular/core/src/render3/interfaces/projection';
import { Router } from '@angular/router';
import { TooltipComponent } from '@angular/material';

@Directive({
  selector: '[dpsPlotChart]'
})
export class PlotChartDirective {
  private _data: any;
  element: any;
  lastTransform: any;

  @Output() resultsAvailable = new EventEmitter();

  @Input()
  options: any;
  // {
  //   verticalOffset: 400,
  //   circleRadius: 15,
  //   export: true
  // }

  @Input()
  set data(value: any) {
    this._data = value;
    if (value) {
      this.draw(this.element, this._data);
    }
  }

  get data(): any {
    return this._data;
  }

  constructor(private el: ElementRef, public globalData: GlobalData, public router: Router) {
    this.element = el.nativeElement;
  }

  draw(id, data): void {
    let self = this;
    // Config.
    let width = (this.options && this.options.width) ? this.options.width : this.element.clientWidth;
    let height = (this.options && this.options.height) ? this.options.height : (this.element.clientHeight - (this.options ? this.options.verticalOffset : 100));
    let gridlineStrokeWidth = 1;
    let circleRadius = (this.options ? this.options.circleRadius : 5);

    // Set the ranges.
    let x = d3.scaleLinear().range([0, width]);
    let y = d3.scaleLinear().range([height, 0]);
    let hoveringTooltip;
    let hoveringDot;
    let transformY = (this.options ? (this.options.verticalOffset / 2) : 50);

    let plotChartNoResults = d3.select('.plot-chart-wrapper .no-results')
      .attr('class', 'hide no-results');

    if (!data.grades || data.grades.length === 0) {
      plotChartNoResults
        .attr('class', 'no-results');
    } else {
      this.resultsAvailable.emit();
    }

    // Remove the previous SVG.
    d3
      .select(id)
      .selectAll('*')
      .remove();

    let zoom = d3.zoom()
      .scaleExtent([1, 5])
      .on('zoom', zoomed)
      .on('end', function () {
        d3.select(id).select('svg').attr('class', '');
      });


    // Define the SVG
    let svg = d3
      .select(id)
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .attr('style', 'top: ' + transformY + 'px; overflow: hidden;')
      .attr('viewbox', '0 0 ' + width + ' ' + (height))
      .on('click', clicked)
      .on('mousedown', function () {
        let svgContainer = d3.select(this);
        if (self.lastTransform && self.lastTransform.k > 1) {
          svgContainer.attr('class', 'dragging');
        }
      })
      .call(zoom)
      .append('g');

    this.lastTransform = {
      k: 1,
      x: 0,
      y: 0
    };

    // only used for panning
    function zoomed() {
      let newZoom = d3.event.transform.k;
      if (newZoom > self.lastTransform.k) {
        svg.attr('transform', d3.event.transform);
      } else if (newZoom < self.lastTransform.k) {
        let zoomFactor = ((newZoom - 1) / (self.lastTransform.k - 1));
        d3.event.transform.x = self.lastTransform.x * zoomFactor;
        d3.event.transform.y = self.lastTransform.y * zoomFactor;
        svg.attr('transform', d3.event.transform);
      } else if (d3.event.type === 'zoom' && newZoom === 1) {
        d3.event.transform.x = self.lastTransform.x;
        d3.event.transform.y = self.lastTransform.y;
        svg.attr('transform', d3.event.transform);
      } else {
        svg.attr('transform', d3.event.transform);
      }
      self.lastTransform = {
        k: newZoom,
        x: d3.event.transform.x,
        y: d3.event.transform.y
      };

      if (data.grades && data.grades.length > 0) {
        let currentMinX = x.domain()[0] + (-self.lastTransform.x / (width * self.lastTransform.k)) * (x.domain()[1] - x.domain()[0]);
        let currentMaxX = x.domain()[0] + ((-self.lastTransform.x + width) / (width * self.lastTransform.k)) * (x.domain()[1] - x.domain()[0]);
        if (Math.abs(currentMaxX - currentMinX) < 1) {
          currentMinX = sigFigs(currentMinX, 3);
          currentMaxX = sigFigs(currentMaxX, 3);
        } else {
          currentMinX = (Math.round(currentMinX * 100) / 100);
          currentMaxX = (Math.round(currentMaxX * 100) / 100);
        }
        d3.select('.x-range').html('min ' + currentMinX + ' - max ' + currentMaxX);

        let currentMinY = y.domain()[0] + (y.domain()[1] - y.domain()[0]) * (1 - ((-self.lastTransform.y + height) / (height * self.lastTransform.k)));
        let currentMaxY = y.domain()[0] + (y.domain()[1] - y.domain()[0]) * (1 - ((-self.lastTransform.y) / (height * self.lastTransform.k)));
        if (Math.abs(currentMaxY - currentMinY) < 1) {
          currentMinY = sigFigs(currentMinY, 3);
          currentMaxY = sigFigs(currentMaxY, 3);
        } else {
          currentMinY = (Math.round(currentMinY * 100) / 100);
          currentMaxY = (Math.round(currentMaxY * 100) / 100);
        }
        d3.select('.y-range').html('min ' + currentMinY + ' - max ' + currentMaxY);
      }

      svg.selectAll('circle')
        .attr('r', circleRadius / newZoom)
        .attr('stroke-width', circleRadius * 2 / newZoom);

      svg.selectAll('.gridline')
        .attr('stroke-width', gridlineStrokeWidth / newZoom);

      d3.select('.plot-chart').selectAll('.hover-box').remove();
    }

    // used for click zoom in/out
    function clicked(e) {
      // let event = d3.event;
      // let cx = event.offsetX;
      // let cy = event.offsetY;
      // let k = self.lastTransform.k;
      // let dk = 0.148698354997035;
      // let dx = self.lastTransform.x;
      // let dy = self.lastTransform.y;

      // let newK = Math.min(Math.max((k + (k * dk)), 1), 5);
      // let xOnTotal = cx - dx;
      // let yOnTotal = cy - dy;
      // // let newDx = (dx - (xOnTotal * (newK - k))) / k;
      // // let newDy = (dy - (yOnTotal * (newK - k))) / k;
      // // let zoomFactor = (k - 1) / (newK - 1);
      // let newDx = (dx - (xOnTotal * dk)) / k;
      // let newDy = (dy - (yOnTotal * dk)) / k;

      // svg.attr('transform', 'scale(' + newK + ') translate(' + newDx + ',' + newDy + ')');
      // self.lastTransform = {
      //   x: newDx,
      //   y: newDy,
      //   k: newK
      // };


      // svg.selectAll('circle')
      //   .attr('r', circleRadius / newK)
      //   .attr('stroke-width', circleRadius * 2 / newK);
    }

    // Define the tooltip.
    let tooltip = d3.select('.plot-chart')
      .append('div')
      .style('position', 'absolute')
      .on('mouseenter', function () {
        hoveringTooltip = true;
      })
      .on('mouseleave', function () {
        hoveringTooltip = false;
        tooltip.select('.plot-tooltip').attr('class', 'plot-tooltip removed');
        let hoverBox = d3.select('.plot-chart').selectAll('.hover-box');
        hoverBox.attr('class', 'hover-box removed');
        setTimeout(() => {
          if (!hoveringTooltip && !hoveringDot) {
            hoverBox.remove();
          }
        }, 200);
      });

    // Scale the range of the data.
    x.domain([
      data.properties.propertyX.scaleMin,
      data.properties.propertyX.scaleMax
    ]);
    y.domain([
      data.properties.propertyY.scaleMin,
      data.properties.propertyY.scaleMax
    ]);

    let activeDot;
    let hoverBreakpoint = 1024;

    svg
      .append('rect')
      .attr('x', 0)
      .attr('width', width)
      .attr('y', 0)
      .attr('height', height)
      .attr('class', 'dot-remover')
      .on('click', function () {
        if (window.innerWidth <= hoverBreakpoint) {
          activeDot = null;
          let hoverBox = d3.select('.plot-chart').selectAll('.hover-box');
          hoverBox.attr('class', 'hover-box removed');
          setTimeout(() => {
            hoverBox.remove();
          }, 200);
          tooltip.select('.plot-tooltip').attr('class', 'plot-tooltip removed');
        }
      });

    if (!this.options || !this.options.export) {
      svg
        .selectAll('dot')
        .data(data.grades)
        .enter()
        .append('circle')
        .attr('r', circleRadius)
        .attr('cx', function (d) {
          return x(d.propertyValueX);
        })
        .attr('cy', function (d) {
          return y(d.propertyValueY);
        })
        .style('fill', 'transparent')
        .attr('class', 'dot-button')
        .on('click', function (d) {
          if (window.innerWidth <= hoverBreakpoint) {
            if (activeDot !== d) {
              setTooltip(d);
              activeDot = d;
            } else {
              let hoverBox = d3.select('.plot-chart').selectAll('.hover-box');
              hoverBox.attr('class', 'hover-box removed');
              setTimeout(() => {
                hoverBox.remove();
              }, 200);
              tooltip.select('.plot-tooltip').attr('class', 'plot-tooltip removed');
              activeDot = null;
            }
          }
        });

      // add a grid to the table (10 lines)
      let numberOfLines = 10;
      let lineGap = width / numberOfLines;
      let linesGroup = svg.append('g');
      for (let i = -numberOfLines; i < numberOfLines * 2; i++) {
        linesGroup.append('line')
          .attr('x1', (i + 1) * lineGap)
          .attr('x2', (i + 1) * lineGap)
          .attr('y1', -height)
          .attr('y2', height * 2)
          .attr('class', 'gridline')
          .attr('stroke-width', 1)
          .attr('stroke', 'rgba(255, 255, 255, 0.2)');

        linesGroup.append('line')
          .attr('y1', -(i + 1) * lineGap + height)
          .attr('y2', -(i + 1) * lineGap + height)
          .attr('x1', -width)
          .attr('x2', width * 2)
          .attr('class', 'gridline')
          .attr('stroke-width', 1)
          .attr('stroke', 'rgba(255, 255, 255, 0.2)');
      }
    }

    // add the dots with tooltips
    svg
      .selectAll('dot')
      .data(data.grades)
      .enter()
      .append('circle')
      .attr('r', circleRadius)
      .attr('cx', function (d) {
        return x(d.propertyValueX);
      })
      .attr('cy', function (d) {
        return y(d.propertyValueY);
      })
      .attr('stroke', 'transparent')
      .attr('stroke-width', circleRadius * 2)
      .attr('stroke-opacity', 0)
      .style('fill', '#f2ac33')
      .on('mouseenter', function (d) {
        if (window.innerWidth > hoverBreakpoint) {
          hoveringDot = true;
          setTooltip(d);
        }
      })
      .on('click', function (d) {
        if (window.innerWidth <= hoverBreakpoint) {
          if (activeDot !== d) {
            setTooltip(d);
            activeDot = d;
          } else {
            let hoverBox = d3.select('.plot-chart').selectAll('.hover-box');
            hoverBox.attr('class', 'hover-box removed');
            setTimeout(() => {
              hoverBox.remove();
            }, 200);
            tooltip.select('.plot-tooltip').attr('class', 'plot-tooltip removed');
            activeDot = null;
          }
        }
      })
      .on('mouseleave', function () {
        if (window.innerWidth > hoverBreakpoint) {
          hoveringDot = false;
          setTimeout(function () {
            if (!hoveringTooltip && !hoveringDot) {
              let hoverBox = d3.select('.plot-chart').selectAll('.hover-box');
              hoverBox.attr('class', 'hover-box removed');
              setTimeout(() => {
                if (!hoveringTooltip && !hoveringDot) {
                  hoverBox.remove();
                }
              }, 200);
              tooltip.select('.plot-tooltip').attr('class', 'plot-tooltip removed');
            }
          }, 1);
        }
      });

    function setTooltip(d) {
      let xPosition = ((d.propertyValueX - data.properties.propertyX.scaleMin) /
        (data.properties.propertyX.scaleMax - data.properties.propertyX.scaleMin) * width);
      let yPosition = (height - ((d.propertyValueY - data.properties.propertyY.scaleMin) /
        (data.properties.propertyY.scaleMax - data.properties.propertyY.scaleMin) * height));
      let bottomYPosition = (height - yPosition) + 80;
      let activeGrade = _.findWhere(self.globalData.selectedGrades, { substance: d.substance });

      // adjust to zoom
      xPosition = xPosition * self.lastTransform.k + self.lastTransform.x;
      yPosition = yPosition * self.lastTransform.k + self.lastTransform.y + transformY;

      let hoverBox;

      if (d3.selectAll('.hover-box').size() === 0) {
        hoverBox = d3.select('.plot-chart').append('div').attr('class', 'hover-box');
      } else {
        hoverBox = d3.select('.hover-box');
      }

      hoverBox
        .style('left', '0px')
        .style('top', yPosition + 'px')
        .style('width', xPosition + 'px')
        .style('height', height - yPosition + transformY + 'px')
        .html(`
            <div class="value-x-line">
            </div>
            <div class="value-y-line">
            </div>
            <div class="value-x">
              ${d.propertyDisplayTextX + (data.properties.propertyX.unitOfMeasure ? (' ' + data.properties.propertyX.unitOfMeasure) : '')}
            </div>
            <div class="value-y">
              ${d.propertyDisplayTextY + (data.properties.propertyY.unitOfMeasure ? (' ' + data.properties.propertyY.unitOfMeasure) : '')}
            </div>
          `);

      setTimeout(() => {
        hoverBox.attr('class', 'hover-box added');
      }, 1);

      if ((document.querySelector('.value-x').getBoundingClientRect().top - yPosition) < 300) {
        tooltip
          .html(
            `<div class="plot-tooltip">
              <div class="tooltip-arrow reversed">
              </div>
              <div class="inner">
                <div class="name" data-gradesubstance="` + d.substance + `">` + d.name + `</div>
                  <div class="selector` + (activeGrade ? ' active' : '') + `" data-gradesubstance="` + d.substance + `">
                    <svg width="20" height="22" x="0px" y="0px"
                    viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve">
                      <title>polygon</title>
                      <polygon class="polygon" points="1.2,6 12,0 22.8,6 22.8,18 12,24 1.2,18 "/>
                      <g>
                        <path class="plus" d="M13.3,10.7h3.5v2.7h-3.5v3.5h-2.7v-3.5H7.2v-2.7h3.5V7.2h2.7C13.3,7.2,13.3,10.7,13.3,10.7z"/>
                        <path class="min" d="M16.8,10.7v2.7l-9.7,0v-2.7H16.8z"/>
                      </g>
                    </svg>
                </div>
              </div>
            </div>`
          )
          .style('left', xPosition + 'px')
          .style('top', 'auto')
          .style('bottom', bottomYPosition + 'px')
          .select('.selector')
          .on('click', function () {
            let selector = this;
            let gradeSubstance = parseInt(selector.getAttribute('data-gradesubstance'), null);
            activeGrade = _.findWhere(self.globalData.selectedGrades, { substance: d.substance });
            if (activeGrade) {
              let gradeIndex = _.indexOf(self.globalData.selectedGrades, activeGrade);
              let activeIndex = selector.className.indexOf('active');
              selector.className = cut(selector.className, activeIndex, activeIndex + 6);
              activeGrade.color = null;
              self.globalData.selectedGrades.splice(gradeIndex, 1);
            } else {
              selector.className += ' active';
              let grade = _.findWhere(self.globalData.data.grades, { substance: gradeSubstance.toString() });
              if (self.globalData.selectedGrades.length > 4) {
                self.globalData.selectedGrades.splice(0, 1);
              }
              activeGrade = grade;
              activeGrade.color = self.globalData.getFirstAvailableGradeColor();
              self.globalData.selectedGrades.unshift(grade);
            }
            self.globalData.gradesChanged = {};
            function cut(str, cutStart, cutEnd) {
              return str.substr(0, cutStart) + str.substr(cutEnd + 1);
            }
          });


      } else {
        tooltip
          .html(
            `<div class="plot-tooltip">
              <div class="tooltip-arrow">
              </div>
              <div class="inner">
                <div class="name" data-gradesubstance="` + d.substance + `">` + d.name + `</div>
                <div class="selector` + (activeGrade ? ' active' : '') + `" data-gradesubstance="` + d.substance + `">
                  <svg width="20" height="22" x="0px" y="0px"
                  viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve">
                    <title>polygon</title>
                    <polygon class="polygon" points="1.2,6 12,0 22.8,6 22.8,18 12,24 1.2,18 "/>
                    <g>
                      <path class="plus" d="M13.3,10.7h3.5v2.7h-3.5v3.5h-2.7v-3.5H7.2v-2.7h3.5V7.2h2.7C13.3,7.2,13.3,10.7,13.3,10.7z"/>
                      <path class="min" d="M16.8,10.7v2.7l-9.7,0v-2.7H16.8z"/>
                    </g>
                  </svg>
                </div>
              </div>
            </div>`
          );

        tooltip.style('left', xPosition + 'px')
          .style('top', yPosition + 10 + 'px')
          .select('.selector')
          .on('click', function () {
            let selector = this;
            let gradeSubstance = parseInt(selector.getAttribute('data-gradesubstance'), null);
            activeGrade = _.findWhere(self.globalData.selectedGrades, { substance: d.substance });
            if (activeGrade) {
              let gradeIndex = _.indexOf(self.globalData.selectedGrades, activeGrade);
              let activeIndex = selector.className.indexOf('active');
              selector.className = cut(selector.className, activeIndex, activeIndex + 6);
              activeGrade.color = null;
              self.globalData.selectedGrades.splice(gradeIndex, 1);
            } else {
              selector.className += ' active';
              let grade = _.findWhere(self.globalData.data.grades, { substance: gradeSubstance.toString() });
              if (self.globalData.selectedGrades.length > 4) {
                self.globalData.selectedGrades.splice(0, 1);
              }
              activeGrade = grade;
              activeGrade.color = self.globalData.getFirstAvailableGradeColor();
              self.globalData.selectedGrades.unshift(grade);
            }
            self.globalData.gradesChanged = {};

            function cut(str, cutStart, cutEnd) {
              return str.substr(0, cutStart) + str.substr(cutEnd + 1);
            }
          });
      }

      let plotChart = document.querySelector('.plot-chart') as HTMLElement;
      if (self.globalData.applicationState.plotChartSidebarOpen) {
        if (window.innerWidth < hoverBreakpoint) {
          if ((document.querySelector('.value-x').getBoundingClientRect().left - 280) < 100) {
            document.querySelector('.plot-tooltip').className += ' left';
          } else {
            document.querySelector('.plot-tooltip').className += ' right';
          }
        } else {
          if ((document.querySelector('.value-x').getBoundingClientRect().left - 480) < 100) {
            document.querySelector('.plot-tooltip').className += ' left';
          } else {
            document.querySelector('.plot-tooltip').className += ' right';
          }
        }
      } else {
        if (document.querySelector('.value-x').getBoundingClientRect().left < 100) {
          document.querySelector('.plot-tooltip').className += ' left';
        } else {
          document.querySelector('.plot-tooltip').className += ' right';
        }
      }



      tooltip.select('.name')
        .on('click', function () {
          let selector = this;
          let gradeSubstance = parseInt(selector.getAttribute('data-gradesubstance'), null);
          let grade = _.findWhere(self.globalData.data.grades, { substance: gradeSubstance.toString() });
          self.router.navigateByUrl('/grade-detail/' + gradeSubstance);
        });
    }

    // add the X Axis
    // let xAxisHidden = svg
    //   .append('g')
    //   .attr('opacity', 0)
    //   .attr('transform', 'translate(' + (0 + transformX) + ',' + (height + transformY) + ')')
    //   .call(axis.axisBottom(x).tickSize(0).ticks(0));
    // xAxis.selectAll('.tick').remove();
    let xAxis = d3.select('.plot-chart')
      .append('div')
      .attr('class', 'x-axis');

    // add the Y Axis
    // let yAxisHidden = svg.append('g')
    //   .attr('opacity', 0)
    //   .attr('transform', 'translate(' + transformX + ',' + transformY + ')')
    //   .call(axis.axisLeft(y).tickSize(0).ticks(0));
    // yAxis.selectAll('.tick').remove();
    let yAxis = d3.select('.plot-chart')
      .append('div')
      .attr('class', 'y-axis');

    // text label for the x axis
    // svg
    //   .append('text')
    //   .attr('dy', height + 20 + transformY)
    //   .attr('dx', width - 150 + transformX)
    //   .attr('width', 150)
    //   .style('font-size', '11px')
    //   .style('font-family', 'EMprint')
    //   .style('font-weight', '700')
    //   .style('opacity', '0.8')
    //   .style('fill', '#FFF')
    //   .attr('text-anchor', 'right')
    //   .text(data.properties.propertyX.name);

    // text label for the x axis
    // svg
    //   .append('text')
    //   .attr('dy', height + 40 + transformY)
    //   .attr('dx', width - 150 + transformX)
    //   .attr('width', 150)
    //   .style('font-size', '11px')
    //   .style('font-family', 'EMprint')
    //   .style('opacity', '0.8')
    //   .style('fill', '#FFF')
    //   .attr('text-anchor', 'right')
    //   .text(data.properties.propertyX.unitOfMeasure);
    let xRangeMin;
    let xRangeMax;

    if (Math.abs(data.properties.propertyX.scaleMin - data.properties.propertyX.scaleMax) < 1) {
      xRangeMin = sigFigs(data.properties.propertyX.scaleMin, 3);
      xRangeMax = sigFigs(data.properties.propertyX.scaleMax, 3);
    } else {
      xRangeMin = (Math.round(data.properties.propertyX.scaleMin * 100) / 100);
      xRangeMax = (Math.round(data.properties.propertyX.scaleMax * 100) / 100);
    }

    let html = `<div class="name">${data.properties.propertyX.name}</div>
    <div class="unit-of-measure">${ data.properties.propertyX.unitOfMeasure ? data.properties.propertyX.unitOfMeasure : ''}</div>`;
    if (data.grades && data.grades.length > 0) {
      html += `<div class="x-range">min ${xRangeMin} - max ${xRangeMax}</div>`;
    }

    d3.select('.plot-chart')
      .append('div')
      .attr('class', 'x-axis-label')
      .html(html);

    // text label for the y axis
    // svg
    //   .append('text')
    //   .attr('dy', '40px')
    //   .attr('dx', '0px')
    //   .style('font-size', '11px')
    //   .style('font-family', 'EMprint')
    //   .style('font-weight', '700')
    //   .style('opacity', '0.8')
    //   .style('fill', '#FFF')
    //   .text(data.properties.propertyY.name);

    // // text label for the x axis
    // svg
    //   .append('text')
    //   .attr('dy', '20px')
    //   .attr('dx', '0px')
    //   .style('font-size', '11px')
    //   .style('font-family', 'EMprint')
    //   .style('opacity', '0.8')
    //   .style('fill', '#FFF')
    //   .text(data.properties.propertyY.unitOfMeasure);

    let yRangeMin;
    let yRangeMax;

    if (Math.abs(data.properties.propertyY.scaleMin - data.properties.propertyY.scaleMax) < 1) {
      yRangeMin = sigFigs(data.properties.propertyY.scaleMin, 3);
      yRangeMax = sigFigs(data.properties.propertyY.scaleMax, 3);
    } else {
      yRangeMin = (Math.round(data.properties.propertyY.scaleMin * 100) / 100);
      yRangeMax = (Math.round(data.properties.propertyY.scaleMax * 100) / 100);
    }

    let html2 = `<div class="name">${data.properties.propertyY.name}</div>
    <div class="unit-of-measure">${ data.properties.propertyY.unitOfMeasure ? data.properties.propertyY.unitOfMeasure : ''}</div>`;
    if (data.grades && data.grades.length > 0) {
      html2 += `<div class="y-range">min ${yRangeMin} - max ${yRangeMax}</div>`;
    }

    d3.select('.plot-chart')
      .append('div')
      .attr('class', 'y-axis-label')
      .html(html2);

    let chartContainer = document.getElementsByClassName('chart-container');
    chartContainer = Array.prototype.slice.call(chartContainer);
    _.forEach(chartContainer, function (element) {
      element.style.opacity = '1';
    });

    function sigFigs(n, sig) {
      let mult = Math.pow(10, sig - Math.floor(Math.log(n) / Math.LN10) - 1);
      return Math.round(n * mult) / mult;
    }
  }

  ngOnInit(): void {
    let self = this;

    window.addEventListener('resize', resizeThrottler, false);
    let resizeTimeout;
    function resizeThrottler() {
      let chartContainer = document.getElementsByClassName('chart-container');
      chartContainer = Array.prototype.slice.call(chartContainer);
      _.forEach(chartContainer, function (element) {
        element.style.opacity = '0';
      });
      if (!resizeTimeout) {
        resizeTimeout = setTimeout(function () {
          resizeTimeout = null;
          actualResizeHandler();
        }, 1000);
      }
    }

    function actualResizeHandler() {
      self.draw(self.element, self._data);
    }
  }
}
