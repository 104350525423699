import { GlobalData } from './../../general/services/global-data.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dps-property-type-switch',
  templateUrl: './property-type-switch.component.html',
  styleUrls: ['./property-type-switch.component.scss']
})
export class PropertyTypeSwitchComponent implements OnInit {
  propertyTypeInfo = false;

  constructor(public globalData: GlobalData) { }

  ngOnInit() {
  }

}
