import { Component, OnInit, Input } from '@angular/core';
import { DpsFilterProperty } from '../../general/models/filter/dpsFilterProperty';
import { GlobalData } from '../../general/services/global-data.service';

@Component({
  selector: 'dps-datatable-test-methods',
  templateUrl: './datatable-test-methods.component.html',
  styleUrls: ['./datatable-test-methods.component.scss']
})
export class DatatableTestMethodsComponent implements OnInit {
  constructor(public globalData: GlobalData) { 
    
  }
  ngOnInit() {
  }

}
