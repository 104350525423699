import { DpsGrade } from './../../general/models/data/dpsGrade';
import { DpsFilterProperty } from './../../general/models/filter/dpsFilterProperty';
import { Pipe, PipeTransform } from '@angular/core';
import { _ } from 'underscore';

@Pipe({
  name: 'plotChartMatchingProperties'
})
export class PlotChartMatchingPropertiesPipe implements PipeTransform {

  transform(properties: DpsFilterProperty[], selectedProperty: DpsFilterProperty, grades: DpsGrade[], gradeProperties: any, index: number): DpsFilterProperty[] {
    if (selectedProperty) {
      properties = _.without(properties, selectedProperty);
    }
    let result = _.map(_.filter(properties, (p: DpsFilterProperty) => {
      return !p.textValues || p.textValues.length === 0;
    }), (p: DpsFilterProperty) => {
      let hasMatch = false;
      if (!selectedProperty) {
        hasMatch = true;
      } else {
        _.every(grades, (g: DpsGrade) => {
          if (gradeProperties.hasOwnProperty(g.substance)) {
            if (gradeProperties[g.substance].hasOwnProperty(p.id) && gradeProperties[g.substance].hasOwnProperty(selectedProperty.id)) {
              hasMatch = true;
              return false;
            }
          }
          return true;
        });
      }
      if (index === 0) {
        p.hasFirstPlotMatch = hasMatch;
      } else {
        p.hasSecondPlotMatch = hasMatch;
      }
      return p;
    });
    return result;
  }

}
