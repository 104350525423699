import { RoutingStateService } from './../../general/services/routing-state.service';
import { AnalyticsService } from './../../general/services/analytics.service';
import { environment } from './../../../environments/environment';
import { DpsGrade } from '../../general/models/data/dpsGrade';
import { GlobalData } from '../../general/services/global-data.service';
import { Component, OnInit } from '@angular/core';
import { ParamMap, ActivatedRoute } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { _ } from 'underscore';
import { debug } from 'util';
import { AnalyticsEventsService } from 'src/app/general/services/analytics-events.service';
import { EventTypes } from 'src/app/general/enums/analytics/event-types';
import { CustomData } from 'src/app/general/models/analytics/custom-data';
import { PageUrlData } from 'src/app/general/models/analytics/page-url-data';
import { PageData } from 'src/app/general/models/analytics/page-data';
import { PageType } from 'src/app/general/enums/analytics/page-type';

@Component({
  selector: 'dps-grade-detail-actions',
  templateUrl: './grade-detail-actions.component.html',
  styleUrls: ['./grade-detail-actions.component.scss']
})
export class GradeDetailActionsComponent implements OnInit {
  gradeId: number;
  gradeName: string;
  requestASampleUrl: string;
  previousRoute: string;

  constructor(
    public globalData: GlobalData,
    private _route: ActivatedRoute,
    private _analyticsService: AnalyticsService,
    private _analyticsEventsService: AnalyticsEventsService,
    private _routingStateService: RoutingStateService
  ) {
    this.requestASampleUrl = environment.requestASampleUrl;
  }

  ngOnInit() {
    this._route.params.subscribe((params) => {
      this.gradeId = +params['id'];
    });
    this.previousRoute = this._routingStateService.getPreviousUrl();
  }

  getActiveGrade() {
    return _.findWhere(this.globalData.selectedGrades, { substance: '' + this.gradeId });
  }

  toggleGrade() {
    let grade = this.getActiveGrade();
    if (grade) {
      grade.color = null;
      this.globalData.selectedGrades.splice(this.globalData.selectedGrades.indexOf(grade));
    } else {
      let newGrade = _.findWhere(this.globalData.data.grades, { substance: '' + this.gradeId });
      newGrade.color = this.globalData.getFirstAvailableGradeColor();
      if (this.globalData.selectedGrades.length > 4) {
        this.globalData.selectedGrades.splice(0, 1);
      }
      this.globalData.selectedGrades.unshift(newGrade);

      // analytics
      this._analyticsService.sendEvent(this._analyticsEventsService.getEvent(EventTypes.GRADE_DETAIL_ADD_TO_SPIDER), newGrade.tradeName);
    }
  }

  // analytics
  logRequestASample() {
    this.gradeName = this.globalData.gradeDetail.name;
    this._analyticsService.sendEvent(this._analyticsEventsService.getEvent(EventTypes.GRADE_DETAIL_REQUEST_A_SAMPLE, this.gradeName), new CustomData({ prod_selector_sample_prod_name: this.gradeName }), new PageUrlData('/grade-detail/' + this.gradeId), new PageData(PageType.Detail));
  }
}
