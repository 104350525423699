export class DpsApplicationState {
  datatableSidebarOpen: boolean;
  plotChartSidebarOpen: boolean;
  radarChartSidebarOpen: boolean;
  gradesSelectorBarOpen: boolean;
  showInitialFilterDialog: boolean;
  showExportDialog: boolean;
  showSiloDialog: boolean;
  showGuidedTourDialog: boolean;
  showQrShareDialog: boolean;
  showShareDialog: boolean;
  showPropertyTypeSelection: boolean;
  showPropertyTypeSwitch: boolean;
  enableScrollButtons: boolean;
  regionsSidebarOpen: boolean;
  propertyType: any;
  applicationActive: boolean;
  testMethodActive: boolean;
  mobileActionsMenu: boolean;
  navigationFull: boolean;
  helpSidebarOpen: boolean;
  expandApplication: boolean;
  showSuccesDialog: boolean;
  showFailDialog: boolean;

  constructor() {
    this.datatableSidebarOpen = window.screen && window.screen.width > 1024;
    this.plotChartSidebarOpen = window.screen && window.screen.width > 1024;
    this.radarChartSidebarOpen = window.screen && window.screen.width > 1024;
    this.regionsSidebarOpen = false;
    this.gradesSelectorBarOpen = false;
    this.showInitialFilterDialog = false;
    this.showPropertyTypeSelection = false;
    this.showExportDialog = false;
    this.showGuidedTourDialog = !localStorage.getItem('hideGuidedTourDialog');
    this.showPropertyTypeSwitch = false;
    this.enableScrollButtons = false;
    this.showSiloDialog = false;
    this.showShareDialog = false;
    this.showQrShareDialog = false;
    this.applicationActive = false;
    this.mobileActionsMenu = false;
    this.navigationFull = false;
    this.helpSidebarOpen = false;
    this.expandApplication = false;
    this.showSuccesDialog = false;
    this.showFailDialog = false;
    // this.propertyType = { id: 'GP', name: 'Product Datasheet' };
    this.propertyType = {};
  }
}
