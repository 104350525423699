import { DpsFilters } from './../general/models/dpsFilters';
import { AnalyticsService } from './../general/services/analytics.service';
import { GradesService } from './../general/services/grades.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DpsSearchResult } from './../general/models/data/dpsSearchResult';
import { FavoritesService } from './../general/services/favorites.service';
import { GlobalData } from './../general/services/global-data.service';
import { SearchService } from './../general/services/search.service';
import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { _ } from 'underscore';
import { AutoComplete } from 'primeng/autocomplete';
import { AnalyticsEventsService } from '../general/services/analytics-events.service';
import { EventTypes } from '../general/enums/analytics/event-types';

@Component({
  selector: 'dps-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, AfterViewInit {
  searchTerm: string;

  @ViewChild('searchAutocomplete') searchAutocomplete: AutoComplete;

  constructor(
    public globalData: GlobalData,
    public searchService: SearchService,
    private _gradesService: GradesService,
    private _favoritesService: FavoritesService,
    private _analyticsService: AnalyticsService,
    private _analyticsEventsService: AnalyticsEventsService,
    private _router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    if (this.globalData.searchModel && this.globalData.searchModel.searchTerm) {
      this.search();
    }

    this.activatedRoute.queryParams.subscribe(params => {
      if (params.q) {
        this.searchTerm = params.q;
        this.globalData.searchModel.searchTerm = params.q;

        if(this.searchService.stripUnwantedCharacters(params.q).length > 0) {
          this.search();
        }
      }
    });
  }

  ngAfterViewInit() {
    let input = (this.searchAutocomplete as AutoComplete).inputEL.nativeElement;
    setTimeout(function () {
      input.focus();
    });
  }

  search(event?) {
    if (this.searchService.stripUnwantedCharacters(this.globalData.searchModel.searchTerm)) {
      this.searchTerm = this.globalData.searchModel.searchTerm;
      this.searchService.getResults(true);
    }
  }

  autocomplete(event?) {
    this.searchService.getAutocompleteResults();
  }

  select(item: DpsSearchResult, autocomplete?: boolean) {
    if (item.type !== 'Grade') {
      this.globalData.selectedFilters = item.getFilters(this.globalData.selectedFilters.regionId);
      this.globalData.sortProperty = null;
      this.globalData.sortOrder = null;
      this.globalData.sortSalesOrder = null;
      this.globalData.sortSalesProperty = null;
      this.globalData.sortApplication = null;
      this.globalData.sortApplicationOrder = null;
      this.globalData.selectedGrades = [];
      this.globalData.selectedProperties = [];
      this.globalData.selectedRadarProperties = [];
      this.globalData.selectedSalesProperties = [];
      this.globalData.selectedRadarSalesProperties = [];
      this.globalData.applicationState.applicationActive = false;
      this.globalData.applicationState.propertyType = {};

      let excludes = ['businessUnit', 'productType'];
      switch (item.type) {
        case 'Brand':
          excludes.push('brand');
          break;
        case 'Application':
          excludes.push('application');
          break;
        case 'Market Segment':
          excludes.push('marketSegment');
          break;
        case 'Industry':
          excludes.push('industry');
          break;
      }

      let siloFilters = new DpsFilters();
      siloFilters.updateFromFilters(this.globalData.selectedFilters);

      this.globalData.silo = {
        filters: siloFilters,
        excludes: excludes
      };

      // check for multiplePropertyTypes
      this._gradesService.getData(() => {
        this.globalData.silo.hasMultiplePropertyTypes = this.globalData.hasOtherPropertyType;
        if (this.globalData.silo.hasMultiplePropertyTypes) {
          // open properties dialog
          this.globalData.data = null;
          this.globalData.applicationState.showPropertyTypeSelection = true;
        }
      });
    }

    // analytics
    this._analyticsService.sendEvent(this._analyticsEventsService.getEvent(EventTypes.SEARCH_CLICK), item.name + ' (' + item.type + ')');
    if (autocomplete) {
      this.globalData.searchModel.searchTerm = '';
    }
    this._router.navigateByUrl(item.getPath());
  }

  loadMore(event?) {
    this.searchService.getResults();
  }

  updateCurrentFavorite() {
    this._favoritesService.updateCurrentFavorite();
  }

  toggleFavoriteTerm() {
    if (this.globalData.currentFavorite) {
      this._favoritesService.removeFromFavorites(this.globalData.currentFavorite);
    } else {
      this._favoritesService.addSearchTermToFavorites(this.globalData.searchModel.searchTerm);
    }
  }

  toggleFavoriteItem(item) {
    let favorite = this.getFavoriteItem(item);
    if (favorite) {
      this._favoritesService.removeFromFavorites(favorite);
    } else {
      this._favoritesService.addSearchResultToFavorites(item);
    }
  }

  getFavoriteItem(item) {
    return _.findWhere(this.globalData.favorites, { searchResultId: item.id, searchType: item.type });
  }
}
