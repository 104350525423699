import { AnalyticsService } from './analytics.service';
import { FilterSalesPropertiesPipe } from './../pipes/filter/filter-sales-properties.pipe';
import { FilterPropertiesPipe } from './../pipes/filter/filter-properties.pipe';
import { DpsSalesProperty } from '../models/data/dpsSalesProperty';
import { DpsFilterIdName } from '../models/filter/dpsFilterIdName';
import { DpsFilterProperty } from '../models/filter/dpsFilterProperty';
import { Injectable } from '@angular/core';
import { DpsFilters } from '../models/dpsFilters';
import { DpsFavorite } from '../models/dpsFavorite';
import { DpsGradePropertiesData } from '../models/data/dpsGradePropertiesData';
import { DpsApplicationState } from '../models/dpsApplicationState';
import { DpsFilterData } from '../models/filter/dpsFilterData';
import { DpsGrade } from '../models/data/dpsGrade';
import { _ } from 'underscore';
import { Router, RoutesRecognized } from '@angular/router';
import { DpsSearchModel } from '../models/dpsSearchModel';
import { DpsShareModel } from '../models/dpsShareModel';
import { AnalyticsEventsService } from './analytics-events.service';
import { EventTypes } from '../enums/analytics/event-types';
import { DataPropertyType } from '../enums/data-property-type';
import { LOCALE_ID, Inject } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalData {
  lang: string;
  videoLang: string;
  unitSystem: string;
  loading: boolean;
  favorites: DpsFavorite[];
  currentFavorite: DpsFavorite;
  applicationState: DpsApplicationState;
  limit: number;
  silo: {
    excludes: string[],
    filters: DpsFilters,
    hasMultiplePropertyTypes?: boolean
  };
  title: string;
  colors = ['#939598', '#f2ac33', '#0c479d', '#f05822', '#00a14d'];
  initialized = false;
  globalError: string;
  hasOtherPropertyType: boolean;

  // partly common (between global properties and sales specs)
  filters: DpsFilterData;
  selectedFilters: DpsFilters;
  exploreFilters: DpsFilterData;
  data: DpsGradePropertiesData;
  propertiesChanged: any;
  gradesChanged: any;
  favoritesChanged: any;
  filtersChanged: any;
  selectedGrades: DpsGrade[];

  sortApplication: DpsFilterIdName;
  sortApplicationOrder: string;

  // global properties
  selectedProperties: DpsFilterProperty[];
  selectedRadarProperties: DpsFilterProperty[];
  sortProperty: DpsFilterProperty;
  sortOrder: string;

  // sales properties
  selectedSalesProperties: DpsSalesProperty[];
  selectedRadarSalesProperties: DpsSalesProperty[];
  sortSalesProperty: DpsSalesProperty;
  sortSalesOrder: string;

  // search
  searchModel: DpsSearchModel;

  // gradeDetail
  gradeDetail: any;

  constructor(private router: Router, private _analyticsService: AnalyticsService, private _analyticsEventsService: AnalyticsEventsService, @Inject(LOCALE_ID) public locale: string) {
    this.lang = 'en';
    this.videoLang = locale.substring(0,2);
    this.unitSystem = 'English';
    this.loading = false;
    this.favorites = [];
    this.applicationState = new DpsApplicationState();
    this.propertiesChanged = {};
    this.gradesChanged = {};
    this.favoritesChanged = {};
    this.filtersChanged = {};
    this.limit = 15;
    // this.silo = {
    //   filters: new DpsFilters(),
    //   excludes: []
    // };

    this.filters = new DpsFilterData();
    this.exploreFilters = new DpsFilterData();
    this.selectedFilters = new DpsFilters();

    this.selectedGrades = [];
    this.selectedProperties = [];
    this.selectedRadarProperties = [];
    this.sortProperty = null;
    this.sortOrder = null;

    this.selectedSalesProperties = [];
    this.selectedRadarSalesProperties = [];
    this.sortSalesProperty = null;
    this.sortSalesOrder = null;

    this.sortApplication = null;
    this.sortApplicationOrder = null;

    this.searchModel = new DpsSearchModel('');
    this.gradeDetail = null;

    this.title = null;

    this.router.events.subscribe(event => {
      if (event instanceof RoutesRecognized) {
        this.updateTitle(event.state);
      }
    });
  }

  getFiltersForAnalytics(): string {
    return this.selectedFilters.getAnalyticsValue(this.exploreFilters);
  }

  getSelectedRadarProperties() {
    switch (this.applicationState.propertyType.id) {
      case DataPropertyType.Product:
        return this.selectedRadarProperties;
      case DataPropertyType.Sales:
        return this.selectedRadarSalesProperties;
    }
  }

  getSelectedProperties() {
    switch (this.applicationState.propertyType.id) {
      case DataPropertyType.Product:
        return this.selectedProperties;
      case DataPropertyType.Sales:
        return this.selectedSalesProperties;
    }
  }

  getPlotChartValues() {
    let properties = this.getSelectedProperties();
    let x = properties[0].name;
    let y = properties[1].name;

    return { x, y };
  }

  disableBodyScroll() {
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    document.getElementsByTagName('html')[0].style.overflow = 'hidden';
  }

  enableBodyScroll() {
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    document.getElementsByTagName('html')[0].style.overflow = 'auto';
  }

  updateTitle(route?) {
    if (route && route.url && route.url.indexOf('grade-detail') > -1) {
      return;
    }

    // check for siloTitle
    if ((window as any).siloTitle) {
      this.title = (window as any).siloTitle;
      return;
    }

    // set global title based on filter
    let title = '';

    if (this.selectedFilters.businessUnitIds.length > 0) {
      let bu = _.findWhere(this.filters.businessUnits, { id: this.selectedFilters.businessUnitIds[0] });
      let name = bu ? bu.name : '';
      title = name;
    }

    if (this.selectedFilters.productTypeIds.length > 0) {
      let pt = _.findWhere(this.filters.productTypes, { id: this.selectedFilters.productTypeIds[0] });
      let name = pt ? pt.name : '';
      title = title ? title + ' > ' + name : name;
    }

    this.title = title;
  }

  restoreDefaults(keepRegion?) {
    let regionId = this.selectedFilters.regionId;
    this.selectedFilters = new DpsFilters();
    if (this.silo) {
      let siloFilters = JSON.parse(JSON.stringify(this.silo.filters));
      this.selectedFilters.updateFromFilters(siloFilters);
    }

    if (keepRegion || !this.selectedFilters.regionId) {
      this.selectedFilters.regionId = regionId;
    }
    this.sortProperty = null;
    this.sortOrder = null;
    this.sortSalesProperty = null;
    this.sortSalesOrder = null;

    this.selectedGrades = [];
    this.selectedProperties = [];
    this.selectedRadarProperties = [];

    this.selectedSalesProperties = [];
    this.selectedRadarSalesProperties = [];

    this.sortApplication = null;
    this.sortApplicationOrder = null;

    if (!this.silo) {
      this.applicationState.showInitialFilterDialog = true;
      return false;
    } else {
      return true;
    }
  }

  hasNoPlotChartResults() {
    if (this.data) {
      if (this.applicationState.propertyType.id === 'GP' && this.selectedProperties.length === 2) {
        let commonGrades = _.filter(_.values(this.data.gradeGlobalProperties), (g) => {
          return g.hasOwnProperty(this.selectedProperties[0].id) && g.hasOwnProperty(this.selectedProperties[1].id);
        });
        return commonGrades.length === 0;
      } else if (this.applicationState.propertyType.id === 'SP' && this.selectedSalesProperties.length === 2) {
        let commonGrades = _.filter(_.values(this.data.gradeSalesProperties), (g) => {
          return g.hasOwnProperty(this.selectedSalesProperties[0].id) && g.hasOwnProperty(this.selectedSalesProperties[1].id);
        });
        return commonGrades.length === 0;
      }
    }
  }

  showRestoreDefaults() {
    if (this.silo) {

      let propertyIsSelected = this.applicationState.propertyType.id === 'GP' ? this.selectedProperties.length > 0 : this.selectedSalesProperties.length > 0;

      if (propertyIsSelected) {
        return true;
      }

      if (this.selectedGrades.length > 0) {
        return true;
      }

      let isEqual = (Object as any).compare(this.selectedFilters.regionId, this.silo.filters.regionId) &&
        (Object as any).compare(this.selectedFilters.brandIds, this.silo.filters.brandIds) &&
        (Object as any).compare(this.selectedFilters.productTypeIds, this.silo.filters.productTypeIds) &&
        (Object as any).compare(this.selectedFilters.businessUnitIds, this.silo.filters.businessUnitIds) &&
        (Object as any).compare(this.selectedFilters.industryIds, this.silo.filters.industryIds) &&
        (Object as any).compare(this.selectedFilters.applicationIds, this.silo.filters.applicationIds) &&
        (Object as any).compare(this.selectedFilters.marketSegmentIds, this.silo.filters.marketSegmentIds);

      if (!isEqual) {
        return true;
      }

      let filteredProps = _.filter(this.selectedFilters.propertyFilters, function (pf) {
        return pf.selected || pf.minimum || pf.maximum || (pf.textValues && pf.textValues.length > 0);
      });
      let filteredSalesProps = _.filter(this.selectedFilters.salesPropertyFilters, function (pf) {
        return pf.selected ||
          (pf && pf.salesRange && pf.salesRange.minimumRange && (pf.salesRange.minimumRange.minimum || pf.salesRange.minimumRange.maximum)) ||
          (pf && pf.salesRange && pf.salesRange.maximumRange && (pf.salesRange.maximumRange.minimum || pf.salesRange.maximumRange.maximum)) ||
          (pf && pf.salesRange && pf.salesRange.targetRange && (pf.salesRange.targetRange.minimum || pf.salesRange.targetRange.maximum)) ||
          (pf && pf.salesRange && pf.textValues && pf.textValues.length > 0);
      });

      isEqual = (Object as any).compare(filteredProps, _.values(this.silo.filters.propertyFilters))
        && (Object as any).compare(filteredSalesProps, _.values(this.silo.filters.salesPropertyFilters));

      const propsStartupSelectedCheck = filteredProps.length === _.values(this.selectedFilters.propertyFilters).length && filteredSalesProps.length === _.values(this.selectedFilters.salesPropertyFilters).length

      return (!isEqual && !propsStartupSelectedCheck) || this.sortProperty || this.sortSalesProperty;
    } else {
      return true;
    }
  }

  getFirstAvailableGradeColor() {
    let availableColors = _.difference(this.colors, _.pluck(this.selectedGrades, 'color'));
    return availableColors.length > 0 ? availableColors[0] : 'white';
  }

  getSelectedPropertiesAnalyticsValue() {
    return this.applicationState.propertyType.id === 'GP' ?
      (this.selectedProperties && this.selectedProperties.length === 2 ?
        (this.selectedProperties[0].name + ' / ' + this.selectedProperties[1].name) : '') :
      (this.selectedSalesProperties && this.selectedSalesProperties.length === 2 ?
        (this.selectedSalesProperties[0].name + ' / ' + this.selectedSalesProperties[1].name) : '');
  }

  getSelectedGradesAnalyticsValue() {
    return this.selectedGrades ? _.pluck(this.selectedGrades, 'tradeName').join(' / ') : '';
  }

  setDefaultSelectedRadarProperties() {
    if (
      this.applicationState.propertyType.id === 'GP' &&
      (!this.selectedRadarProperties || this.selectedRadarProperties.length < 3)
    ) {
      let self = this;
      this.selectedRadarProperties = _.filter(new FilterPropertiesPipe().transform(this.data.globalProperties,
        this.selectedFilters.propertyFilters), function (p) {
          let hasMatch = false;
          if (!p.textValues || p.textValues.length === 0) {
            _.each(self.selectedGrades, function (g: DpsGrade) {
              if (self.data.gradeGlobalProperties.hasOwnProperty(g.substance)) {
                if (self.data.gradeGlobalProperties[g.substance].hasOwnProperty(p.id)) {
                  hasMatch = true;
                }
              }
            });
          }
          return hasMatch;
        }).slice(0, 6);
    } else if (
      this.applicationState.propertyType.id === 'SP' &&
      (!this.selectedRadarSalesProperties || this.selectedRadarSalesProperties.length < 3)
    ) {
      let self = this;
      this.selectedRadarSalesProperties = _.filter(new FilterSalesPropertiesPipe().transform(this.data.salesProperties,
        this.selectedFilters.salesPropertyFilters), function (p) {
          let hasMatch = false;
          if (!p.textValues || p.textValues.length === 0) {
            _.each(self.selectedGrades, function (g: DpsGrade) {
              if (self.data.gradeSalesProperties.hasOwnProperty(g.substance)) {
                if (self.data.gradeSalesProperties[g.substance].hasOwnProperty(p.id)) {
                  hasMatch = !!self.data.gradeSalesProperties[g.substance][p.id][p.type + 'DisplayText'];
                }
              }
            });
          }
          return hasMatch;
        }).slice(0, 6);
    }
  }

  requestASample(grade) {
    this._analyticsService.sendEvent(this._analyticsEventsService.getEvent(EventTypes.GLOBAL_REQUEST_A_SAMPLE), grade.tradeName);
  }

  getShareLink() {
    let result: DpsShareModel = new DpsShareModel();

    result.p = this.router.url;
    // filters
    result.f = this.selectedFilters.getShareString();

    // propertyType
    result.pt = this.applicationState.propertyType.id;

    // selections
    result.se = {};
    if (this.selectedGrades && this.selectedGrades.length > 0) {
      result.se.g = _.map(this.selectedGrades, (g) => {
        return {
          s: g.substance,
          c: g.color
        };
      });
    }
    if (result.pt === 'GP') {
      if (this.selectedProperties && this.selectedProperties.length > 0) {
        result.se.p = _.pluck(this.selectedProperties, 'id');
      }
      if (this.selectedRadarProperties && this.selectedRadarProperties.length > 0) {
        result.se.rp = _.pluck(this.selectedRadarProperties, 'id');
      }
    } else {
      if (this.selectedSalesProperties && this.selectedSalesProperties.length > 0) {
        result.se.p = _.pluck(this.selectedSalesProperties, 'id');
      }
      if (this.selectedRadarSalesProperties && this.selectedRadarSalesProperties.length > 0) {
        result.se.rp = _.pluck(this.selectedRadarSalesProperties, 'id');
      }
    }
    if (_.isEmpty(result.se)) {
      delete result.se;
    }

    // unitSystem
    result.us = this.unitSystem;

    // sortings
    result.so = {};

    if (this.sortProperty) {
      result.so.sp = this.sortProperty.id;
      result.so.so = this.sortOrder;
    }
    if (this.sortSalesProperty) {
      result.so.sp = this.sortSalesProperty.id;
      result.so.so = this.sortSalesOrder;
    }
    if (this.sortApplication) {
      result.so.sa = this.sortApplication.id;
      result.so.sao = this.sortApplicationOrder;
    }

    if (_.isEmpty(result.so)) {
      delete result.so;
    }

    // silo
    if (this.silo) {
      result.si = {
        e: this.silo.excludes,
        f: this.silo.filters.getShareString(),
        h: this.silo.hasMultiplePropertyTypes
      };
    }

    return result.encode();
  }
}
