import { GlobalData } from '../../general/services/global-data.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dps-datatable-sidebar-toggle',
  templateUrl: './datatable-sidebar-toggle.component.html',
  styleUrls: ['./datatable-sidebar-toggle.component.scss']
})
export class DatatableSidebarToggleComponent implements OnInit {
  constructor(public globalData: GlobalData) {
  }

  ngOnInit() {
  }

  toggleDatableSidebar() {
    this.globalData.applicationState.datatableSidebarOpen = !this.globalData.applicationState.datatableSidebarOpen;
    this.globalData.applicationState.regionsSidebarOpen = false;
    this.globalData.applicationState.helpSidebarOpen = false;
    this.globalData.applicationState.mobileActionsMenu = false;
  }
}
