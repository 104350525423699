<p-dialog
  class="fail-dialog"
  [(visible)]="globalData.applicationState.showFailDialog"
  [dismissableMask]="true"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [responsive]="true"
  [appendTo]="'body'"
  [styleClass]="'dps-dialog dps-fail-dialog'">
  <p-header>
    <ng-container i18n="Fail dialog title@@failTitle">Something went wrong.</ng-container>
  </p-header>
  <div class="fail-message" >
    <ng-container i18n="Fail dialog message@@failMessage">Please try again or contact us if the problem persists.</ng-container>
  </div>
  <a class="cta-button"
    (click)="retry()">
    <ng-container i18n="Fail retry button@@failRetryButton">Try again</ng-container>
    <mat-icon svgIcon="long-arrow-right"></mat-icon>
  </a>
</p-dialog>
