// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrlExxon: (window as any).dpsConfig && (window as any).dpsConfig.apiUrlExxon ? (window as any).dpsConfig.apiUrlExxon : 'https://www.exxonmobilchemical.com/en/chemicals/webapi/dps/v1/',
  requestASampleUrl: (window as any).dpsConfig && (window as any).dpsConfig.requestASampleUrl ?
    (window as any).dpsConfig.requestASampleUrl : 'https://www.exxonmobilchemical.com/en/productselector/request-a-sample',
  contactUsUrl: (window as any).dpsConfig && (window as any).dpsConfig.contactUsUrl ?
    (window as any).dpsConfig.contactUsUrl : 'https://www.exxonmobilchemical.com/en/resources/contact-us',
  exportUrl: (window as any).dpsConfig && (window as any).dpsConfig.exportUrl ? (window as any).dpsConfig.exportUrl : 'http://demo.alligence.com/exxondpsnext/',
  iconUrlBase: (window as any).dpsConfig && (window as any).dpsConfig.iconUrlBase ? (window as any).dpsConfig.iconUrlBase : '/dist/',
  analyticsTrackingId:  'UA-125969377-1'
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
