import { PipesModule } from './../general/pipes/pipes.module';
import { MatIconModule } from '@angular/material/icon';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GradeDetailComponent } from './grade-detail.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { GradeDetailActionsComponent } from './grade-detail-actions/grade-detail-actions.component';
import { RouterModule } from '@angular/router';
import { GradeDetailProductDatasheetComponent } from './grade-detail-product-datasheet/grade-detail-product-datasheet.component';
import { GradeDetailRelatedComponent } from './grade-detail-related/grade-detail-related.component';

@NgModule({
  imports: [
    CommonModule,
    PdfViewerModule,
    MatIconModule,
    RouterModule,
    PipesModule
  ],
  declarations: [
    GradeDetailComponent,
    GradeDetailActionsComponent,
    GradeDetailProductDatasheetComponent,
    GradeDetailRelatedComponent
  ]
})
export class GradeDetailModule { }
