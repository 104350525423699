import { GlobalData } from './general/services/global-data.service';
import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AppAuthGuardService implements CanActivate {
  defaultRoute: string;

  constructor(public globalData: GlobalData, private _router: Router) {
    this.defaultRoute = '/datatable';
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let path = state.url;
    let canActivate = true;

    if (!this.globalData.initialized && (!path || (path.indexOf('/search') === -1 && path.indexOf('/grade-detail') === -1))) {
      this._router.navigateByUrl('/datatable/landing');
      canActivate = false;
    }

    return canActivate;
  }
}
