import { DpsGrade } from './../../general/models/data/dpsGrade';
import { DpsSalesProperty } from './../../general/models/data/dpsSalesProperty';
import { Pipe, PipeTransform } from '@angular/core';
import { _ } from 'underscore';

@Pipe({
  name: 'radarChartMatchingSalesProperties'
})
export class RadarChartMatchingSalesPropertiesPipe implements PipeTransform {

  transform(globalProperties: DpsSalesProperty[], grades: DpsGrade[], gradeGlobalProperties: any): any[] {
    let result = [];
    _.each(globalProperties, (p) => {
      if (!p.textValues || p.textValues.length === 0) {
        let hasRadarMatch = false;
        _.every(grades, function(g: DpsGrade) {
          if (gradeGlobalProperties.hasOwnProperty(g.substance)) {
            if (gradeGlobalProperties[g.substance].hasOwnProperty(p.id)) {
              hasRadarMatch = !!gradeGlobalProperties[g.substance][p.id][p.type + 'DisplayText'];
              if (hasRadarMatch) {
                return false;
              }
            }
          }
          return true;
        });
        p.hasRadarMatch = hasRadarMatch;
        result.push(p);
      }
    });
    return result;
  }

}
