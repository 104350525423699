import { PipesModule } from './../general/pipes/pipes.module';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { LoadingSpinnerComponent } from './../general/ui/loading-spinner/loading-spinner.component';
import { PlotChartFiltersComponent } from './plot-chart-filters/plot-chart-filters.component';
import { PlotChartDirective } from './directives/plot-chart.directive';
import { PlotChartSidebarToggleComponent } from './plot-chart-sidebar-toggle/plot-chart-sidebar-toggle.component';
import { PlotChartPipe } from './pipes/plotChartPipe.pipe';
import { PlotChartComponent } from './plot-chart.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { UiModule } from '../general/ui/ui.module';
import { PlotChartSalesPipePipe } from './pipes/plot-chart-sales-pipe.pipe';
import { PlotChartMatchingPropertiesPipe } from './pipes/plot-chart-matching-properties.pipe';
import { PlotChartMatchingSalesPropertiesPipe } from './pipes/plot-chart-matching-sales-properties.pipe';
import { TooltipModule } from 'primeng/tooltip';

@NgModule({
  imports: [
    CommonModule,
    NgSelectModule,
    UiModule,
    MatIconModule,
    NgSelectModule,
    FormsModule,
    PipesModule,
    TooltipModule
  ],
  declarations: [
    PlotChartComponent,
    PlotChartPipe,
    PlotChartSidebarToggleComponent,
    PlotChartDirective,
    PlotChartFiltersComponent,
    PlotChartSalesPipePipe,
    PlotChartMatchingPropertiesPipe,
    PlotChartMatchingSalesPropertiesPipe
  ],
  exports: [
    PlotChartPipe,
    PlotChartSalesPipePipe,
    PlotChartDirective
  ]
})
export class PlotChartModule { }
