import { GlobalData } from './../../general/services/global-data.service';
import { DpsGrade } from './../../general/models/data/dpsGrade';
import { Directive, ElementRef, ViewChildren, OnDestroy, NgZone, Input } from '@angular/core';
import { _ } from 'underscore';

@Directive({
  selector: '[dpsStickyTable]'
})
export class StickyTableDirective implements OnDestroy {

  private _raf =
    window.requestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    window.msRequestAnimationFrame ||
    window.oRequestAnimationFrame;
  private _cancelRaf =
    window.cancelAnimationFrame ||
    window.webkitCancelAnimationFrame ||
    window.mozCancelAnimationFrame ||
    window.msCancelAnimationFrame ||
    window.oCancelAnimationFrame;
  private _currentRaf;
  private _initialized = false;

  private _rows: any;
  @Input()
  set rows(value: any) {
    this._rows = value;
    if (this._initialized) {
      setTimeout(() => {
        this.setScrollBarWidth();
        // this.setTableDimensions();
      });
    }
  }
  get rows(): any {
    return this._rows;
  }

  private _columns: any;
  @Input()
  set columns(value: any) {
    this._columns = value;
    if (this._initialized) {
      setTimeout(() => {
        this.setScrollBarWidth();
        this.setTableDimensions();
      });
    }
  }
  get columns(): any {
    return this._columns;
  }

  table: any;
  body: any;
  headerWrapper: any;
  header: any;
  verticalScrollWrapper: any;
  headerOffset: number;

  constructor(private el: ElementRef, private _ngZone: NgZone, public globalData: GlobalData) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this._cancelRaf(this._currentRaf);
  }

  ngAfterViewInit() {
    this._initialized = true;
    this.table = this.el.nativeElement;
    this.body = this.table.querySelector('.table-body');
    this.headerWrapper = this.table.querySelector('.table-header');
    this.header = this.table.querySelector('.table-header-center');
    this.verticalScrollWrapper = this.table.querySelector('.table-vertical-scroll-wrapper');
    // let stickLeft = table.querySelector('.table-stick-left');
    // let stickRight = table.querySelector('.table-stick-right');
    let self = this;

    // body.addEventListener('wheel', onScroll);
    // body.addEventListener('scroll', onScroll);

    window.addEventListener('resize', () => {
      self.setScrollBarWidth();
      self.setTableDimensions(true);
    });
    self.setTableDimensions();
    setTimeout(() => {
      self.setScrollBarWidth();
    });


    if (this._raf) {
      this._ngZone.runOutsideAngular(() => {
        loop();
      });
    } else {
      this.body.addEventListener('wheel', onScroll);
      this.body.addEventListener('scroll', onScroll);
      this.verticalScrollWrapper.addEventListener('wheel', onScrollVerticalWrapper);
      this.verticalScrollWrapper.addEventListener('scroll', onScrollVerticalWrapper);
      this.header.addEventListener('wheel', onScrollHorizontalWrapper);
      this.header.addEventListener('scroll', onScrollHorizontalWrapper);
    }

    let lastScrollTop = this.body.scrollTop;
    let lastScrollLeft = this.body.scrollLeft;
    let lastScrollTopVertical = this.verticalScrollWrapper.scrollTop;
    let lastScrollLeftHorizontal = this.header.scrollLeft;
    let scrollingVertical = false;
    let scrollingHorizontal = false;
    let scrollingBody = false;

    function loop() {
      let scrollTop = self.body.scrollTop;
      let scrollLeft = self.body.scrollLeft;
      let scrollTopVertical = self.verticalScrollWrapper.scrollTop;
      let scrollLeftHorizontal = self.header.scrollLeft;

      // if (lastScrollTop === scrollTop &&
      //   lastScrollLeft === scrollLeft &&
      //   lastScrollTopVertical === scrollTopVertical) {
      if (lastScrollTop === scrollTop &&
        lastScrollLeft === scrollLeft &&
        lastScrollTopVertical === scrollTopVertical &&
        lastScrollLeftHorizontal === scrollLeftHorizontal) {
          scrollingBody = false;
          scrollingVertical = false;
          scrollingHorizontal = false;
          self._cancelRaf(self._currentRaf);
          self._currentRaf = self._raf(loop);
          return;
      } else {
        if (lastScrollTop !== scrollTop && !scrollingVertical) {
          lastScrollTop = scrollTop;
          scrollingBody = true;
          onScrollBody();
        }
        // if (lastScrollLeft !== scrollLeft) {
        if (lastScrollLeft !== scrollLeft && !scrollingHorizontal) {
          lastScrollLeft = scrollLeft;
          scrollingBody = true;
          onScrollLeft();
        }
        if (lastScrollTopVertical !== scrollTopVertical && !scrollingBody) {
          lastScrollTopVertical = scrollTopVertical;
          scrollingVertical = true;
          onScrollVerticalWrapper();
        }
        if (lastScrollLeftHorizontal !== scrollLeftHorizontal && !scrollingBody) {
          lastScrollLeftHorizontal = scrollLeftHorizontal;
          scrollingHorizontal = true;
          onScrollHorizontalWrapper();
        }

        // update the values anyway after either body or vertical scroll
        lastScrollTop = scrollTop;
        lastScrollLeft = scrollLeft;
        lastScrollTopVertical = scrollTopVertical;
        lastScrollLeftHorizontal = scrollLeftHorizontal;

        self._cancelRaf(self._currentRaf);
        self._currentRaf = self._raf(loop);
      }
    }

    function onScroll() {
      self.verticalScrollWrapper.scrollTop = self.body.scrollTop;
      self.header.scrollLeft = self.body.scrollLeft;
    }

    function onScrollBody() {
      self.verticalScrollWrapper.scrollTop = self.body.scrollTop;
    }

    function onScrollLeft() {
      self.header.scrollLeft = self.body.scrollLeft;
    }

    function onScrollVerticalWrapper() {
      self.body.scrollTop = self.verticalScrollWrapper.scrollTop;
    }

    function onScrollHorizontalWrapper() {
      self.body.scrollLeft = self.header.scrollLeft;
    }
  }

  private setTableDimensions(resize?: boolean) {
    let maxElementHeight = 0;
    let nameArray = [].slice.call(this.table.querySelectorAll('a.name'));
    let nameWrapperArray = [].slice.call(this.table.querySelectorAll('.name-wrapper'));

    if (!this.headerOffset) {
      this.headerOffset = this.headerWrapper.offsetHeight - (nameWrapperArray.length > 0 ? nameWrapperArray[0].offsetHeight : 0) + 15;
    }

    if (resize) {
      this.headerOffset = this.headerWrapper.offsetHeight - (nameWrapperArray.length > 0 ? nameWrapperArray[0].offsetHeight : 0);
    }
    nameArray.forEach(function(i) {
      maxElementHeight = (maxElementHeight < i.offsetHeight) ? i.offsetHeight : maxElementHeight;
    });

    nameWrapperArray.forEach(function(i) {
      i.style.height = maxElementHeight + 'px';
    });

    let headerHeight = this.headerOffset + maxElementHeight;
    this.headerWrapper.style.height = headerHeight + 'px' ;
    this.body.style.marginTop = headerHeight + 'px';
    this.body.style.height = this.table.offsetHeight - headerHeight + 'px';
    this.verticalScrollWrapper.style.top = headerHeight + 'px';
  }

  private getScrollbarWidth() {
    let outer = document.createElement('div');
    outer.style.visibility = 'hidden';
    outer.style.width = '100px';
    outer.style.msOverflowStyle = 'scrollbar';
    document.body.appendChild(outer);
    let widthNoScroll = outer.offsetWidth;
    outer.style.overflow = 'scroll';
    // add innerdiv
    let inner = document.createElement('div');
    inner.style.width = '100%';
    outer.appendChild(inner);
    let widthWithScroll = inner.offsetWidth;
    // remove divs
    outer.parentNode.removeChild(outer);
    return widthNoScroll - widthWithScroll;
  }

  private setScrollBarWidth() {
    let scrollbarWidth = this.getScrollbarWidth();
    if (this.body.clientWidth !== this.body.scrollWidth) {
      this.verticalScrollWrapper.style.bottom = scrollbarWidth + 'px';
      this.globalData.applicationState.enableScrollButtons = true;
    } else {
      this.verticalScrollWrapper.style.bottom = 0;
      this.globalData.applicationState.enableScrollButtons = false;
    }
    if (this.body.clientHeight !== this.body.scrollHeight) {
      // this.verticalScrollWrapper.style.right = scrollbarWidth + 'px';
      this.headerWrapper.style.right = scrollbarWidth + 'px';
    } else {
      // this.verticalScrollWrapper.style.right = 0;
      this.headerWrapper.style.right = 0;
    }
  }
}
