import { GlobalData } from './../../services/global-data.service';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';

@Component({
  selector: 'dps-brightcove-lightbox',
  templateUrl: './brightcove-lightbox.component.html',
  styleUrls: ['./brightcove-lightbox.component.scss']
})

export class BrightcoveLightboxComponent implements OnInit, OnDestroy {

  // @Input() url: string;
  @Input() image: string;
  @Input() placeholder: string;

  @Input() accountId: string;
  @Input() playerId: string;
  @Input() videoId: string;
  @Input() videoUrl: string;

  brightCoveScript: HTMLScriptElement;

  isBrightCoveVideo: boolean = false;

  constructor(public globalData: GlobalData) { }

  ngOnInit() {
    if(!this.videoUrl){ 
      this.isBrightCoveVideo = true;
    
      this.accountId = this.accountId ? this.accountId : '5425749949001';
      this.playerId = this.playerId ? this.playerId : 'HkZVQaqgZ';
    }
  }

  ngOnDestroy() {
    if (this.brightCoveScript) {
      this.brightCoveScript.remove();
    }
  }

  openLightbox() {

    let body: HTMLElement = document.querySelector('body');

    let lightBoxWrapper = document.createElement('div');
    lightBoxWrapper.setAttribute('class', 'lightbox-wrapper');

    let lightBoxBackground = document.createElement('div');
    lightBoxBackground.setAttribute('class', 'lightbox-background');
    lightBoxBackground.addEventListener('click', function() {
      let lightboxWrapper: HTMLElement = document.querySelector('.lightbox-wrapper');
      lightboxWrapper.parentNode.removeChild(lightboxWrapper);
      this.parentNode.removeChild(this);
    });

    let lightBoxContent = document.createElement('div');
    lightBoxContent.setAttribute('class', 'lightbox-content');

    let playerHTML = '';

    if(this.isBrightCoveVideo) {
      playerHTML =
        '<video id="aboutTheApplicationVideo" data-video-id="' +
        this.videoId +
        '" data-account="' +
        this.accountId +
        '" data-player="' +
        this.playerId +
        '" data-embed="default" class="video-js"></video>';
    } else {
      playerHTML =
        '<video id="aboutTheApplicationVideo" data-embed="default" class="video-js" autoplay><source src="' + this.videoUrl + '" type="video/mp4"></video>';
    }

    lightBoxContent.innerHTML = playerHTML;
    lightBoxWrapper.appendChild(lightBoxBackground);
    lightBoxWrapper.appendChild(lightBoxContent);
    body.appendChild(lightBoxWrapper);

    
    if(this.isBrightCoveVideo) {
      this.brightCoveScript = document.createElement('script');
      this.brightCoveScript.src =
        'https://players.brightcove.net/' +
        this.accountId +
        '/' +
        this.playerId +
        '_default/index.min.js';
      document.body.appendChild(this.brightCoveScript);
      this.brightCoveScript.onload = this.onBrightCoveLoaded;
    } else {
      this.onVideoLoaded();
    }
  }

  onBrightCoveLoaded() {
    const player = (window as any).bc('aboutTheApplicationVideo');
    player.on('loadedmetadata', () => {
      player.muted(false);
      player.play(true);
      
      this.onVideoLoaded()
    });
  }

  onVideoLoaded() {      
    let lightBoxContentClose = document.createElement('div');
    lightBoxContentClose.classList.add('lightbox-close');
    lightBoxContentClose.addEventListener('click', function() {
      let lightboxWrapper: HTMLElement = document.querySelector('.lightbox-wrapper');
      lightboxWrapper.parentNode.removeChild(lightboxWrapper);
      this.parentNode.removeChild(this);
    });
    document.querySelector('.lightbox-content').appendChild(lightBoxContentClose);

  }
}
