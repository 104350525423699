<div class="datatable-filters">
  <div class=grade-count-wrapper>
    <div class="togglers">
      <div class="current-region">
        <label i18n="Region switch label value@@settingRegionLabel">Region</label>
        <ng-select [items]="globalData.filters.regions" [clearable]="false" bindLabel="name" bindValue="id" (change)="switchRegion(); globalData.applicationState.regionsSidebarOpen = false;"
          [(ngModel)]="globalData.selectedFilters.regionId" [searchable]="false">
        </ng-select>
      </div>
      <div class="current-unit-system">
        <label i18n="Unit system switch label value@@settingUnitSystemLabel">Unit system</label>
        <ng-select [clearable]="false" bindLabel="name" bindValue="id"
          [(ngModel)]="globalData.unitSystem" (change)="switchUnitSystem(); globalData.applicationState.regionsSidebarOpen = false;" [searchable]="false">
          <ng-option [value]="'English'" i18n="Unit system switch Imperial value@@settingsUnitSystemImperial2">imperial</ng-option>
          <ng-option [value]="'SI'" i18n="Unit system switch Metric value@@settingsUnitSystemMetric2">metric</ng-option>
        </ng-select>
      </div>
      <div class="current-datatable-type" *ngIf="globalData.silo?.hasMultiplePropertyTypes && !globalData.applicationState.showPropertyTypeSelection">
        <label i18n="Datatable type switch label value@@settingDatatableTypeLabel">Data set</label>
        <ng-select [clearable]="false" bindLabel="id" bindValue="id"
          [(ngModel)]="globalData.applicationState.propertyType.id" (change)="switchDatatableType($event); globalData.applicationState.regionsSidebarOpen = false;" [searchable]="false">
          <ng-option [value]="'GP'" i18n="Datatable type switch Product data value@@settingDatatableTypeProductData">Product data</ng-option>
          <ng-option [value]="'SP'" i18n="Datatable type switch Sales specification value@@settingDatatableTypeSalesSpecification">Sales specification</ng-option>
        </ng-select>
      </div>
    </div>
    <div class="grade-count">
      <span class="large" *ngIf="globalData.applicationState.applicationActive"
        [innerHTML]="globalData.data && globalData.data.grades ? globalData.data.grades.length : ''"></span>
      <span class="large" *ngIf="!globalData.applicationState.applicationActive && globalData.applicationState.propertyType.id === 'GP'"
        [innerHTML]="globalData.data && globalData.data.filteredGrades ? globalData.data.filteredGrades.length : ''"></span>
      <span class="large" *ngIf="!globalData.applicationState.applicationActive && globalData.applicationState.propertyType.id === 'SP'"
        [innerHTML]="globalData.data && globalData.data.filteredSalesGrades ? globalData.data.filteredSalesGrades.length : ''"></span>
      <ng-container *ngIf="globalData.data">
        <ng-container *ngIf="globalData.applicationState.applicationActive">
          <span i18n="Applicable grades in the filters sidebar on datatable and plot/spider chart@@applicableGradesPlural"
            >{globalData.data.grades.length, plural, =0 {applicable grades} =1 {applicable grade} other {applicable grades}}</span>
        </ng-container>
        <ng-container *ngIf="!globalData.applicationState.applicationActive && globalData.applicationState.propertyType.id === 'GP'">
          <span i18n="Applicable grades in the filters sidebar on datatable and plot/spider chart@@applicableGradesPlural"
            >{globalData.data.filteredGrades.length, plural, =0 {applicable grades} =1 {applicable grade} other {applicable grades}}</span>
        </ng-container>
        <ng-container *ngIf="!globalData.applicationState.applicationActive && globalData.applicationState.propertyType.id === 'SP'">
          <span i18n="Applicable grades in the filters sidebar on datatable and plot/spider chart@@applicableGradesPlural"
            >{globalData.data.filteredSalesGrades.length, plural, =0 {applicable grades} =1 {applicable grade} other {applicable grades}}</span>
        </ng-container>
      </ng-container>
      <a class="clear-all"
        (click)="clearAllFilters()"
        *ngIf="globalData.showRestoreDefaults()"
        i18n="Clear all label in the datatable filters sidebar@@clearAllFilters">clear all</a>
    </div>
    <div [ngClass]="{'hide-filters': (globalData.filters | siloFilter: globalData.selectedFilters: globalData.silo: globalData.filtersChanged).length === 0 }" class="active-filters">
      <div class="active-filter" *ngFor="let activeFilter of globalData.filters | siloFilter: globalData.selectedFilters: globalData.silo: globalData.filtersChanged; trackBy: trackByName">
        <span class="name">{{ activeFilter.name }}</span>
        <mat-icon svgIcon="close" (click)="toggleFilter(activeFilter.id, globalData.selectedFilters[activeFilter.type + 'Ids'])"></mat-icon>
      </div>
    </div>
  </div>
  <div *ngIf="globalData.filters" class="filters">
    <div class="filter-row"
      *ngIf="!globalData.silo ||
        (globalData.filters.businessUnits.length > 1 && globalData.silo?.excludes.indexOf('businessUnit') === -1) ||
        (globalData.filters.productTypes.length > 1 && globalData.silo?.excludes.indexOf('productType') === -1) ||
        (globalData.filters.industries.length > 1 && globalData.silo?.excludes.indexOf('industry') === -1) ||
        (globalData.filters.marketSegments.length > 1 && globalData.silo?.excludes.indexOf('marketSegment') === -1) ||
        (globalData.filters.brands.length > 1 && globalData.silo?.excludes.indexOf('brand') === -1)">
      <div class="filter-title"><span class="underline" i18n="Filter by label in the datatable filters sidebar@@filterBy">Filter by:</span></div>
    </div>

    <div class="filter-row"
      [ngClass]="{ open: businessUnitsOpen }"
      (click)="businessUnitsOpen = !businessUnitsOpen"
      *ngIf="globalData.filters.businessUnits.length > 1 && (!globalData.silo || globalData.silo?.excludes.indexOf('businessUnit') === -1)">
      <div class="filter-title light" i18n="Business unit grouping label in the datatable filters sidebar@@productGroup">Product group</div>
      <a id="open-business-units">
        <mat-icon svgIcon="arrow-right"></mat-icon>
      </a>
    </div>
    <div *ngIf="businessUnitsOpen && globalData.filters.businessUnits.length > 0 && (!globalData.silo || globalData.silo?.excludes.indexOf('businessUnit') === -1)" class="filter-select">
      <span [ngClass]="{ active: globalData.filters.businessUnits.length !== globalData.selectedFilters.businessUnitIds.length }"
        (click)="toggleAllFilter(globalData.filters.businessUnits, globalData.selectedFilters.businessUnitIds)"
        class="select-all-text"
        i18n="Select all in filters sidebar@@selectAll">Select all</span>
      <span [ngClass]="{ active: this.globalData.selectedFilters.businessUnitIds.length != 0 }"
        (click)="(this.globalData.selectedFilters.businessUnitIds = []) && this.getData();"
        class="deselect-all-text"
        i18n="Deselect all in filters sidebar@@deSelectAll">Deselect all</span>

      <label class="dps-checkbox indent" *ngFor="let businessUnit of globalData.filters.businessUnits; trackBy: trackById">
        <input
          [checked]="globalData.selectedFilters.businessUnitIds.indexOf(businessUnit.id) > -1"
          (change)="toggleFilter(businessUnit.id, globalData.selectedFilters.businessUnitIds)"
          type="checkbox"
          name="businessUnits"
          [value]="businessUnit.id">
        <span></span>
        <span class="checkbox-text">{{ businessUnit.name }}</span>
      </label>
    </div>

    <div class="filter-row"
      [ngClass]="{ open: productTypesOpen }"
      (click)="productTypesOpen = !productTypesOpen"
      *ngIf="globalData.filters.productTypes.length > 1 && (!globalData.silo || globalData.silo?.excludes.indexOf('productType') === -1)">
      <div class="filter-title light" i18n="Product group grouping label in the datatable filters sidebar@@productCategory">Product category</div>
      <a id="open-productTypes">
        <mat-icon svgIcon="arrow-right"></mat-icon>
      </a>
    </div>
    <div *ngIf="productTypesOpen && globalData.filters.productTypes.length > 0 && (!globalData.silo || globalData.silo?.excludes.indexOf('productType') === -1)"
      class="filter-select">

      <span [ngClass]="{ active: globalData.filters.productTypes.length != globalData.selectedFilters.productTypeIds.length }"
        (click)="toggleAllFilter(globalData.filters.productTypes, globalData.selectedFilters.productTypeIds)"
        class="select-all-text"
        i18n="Select all in filters sidebar@@selectAll">Select all</span>
      <span [ngClass]="{ active: this.globalData.selectedFilters.productTypeIds.length === 0 }"
      (click)="(this.globalData.selectedFilters.productTypeIds = []) && this.getData();"
      class="deselect-all-text"
      i18n="Deselect all in filters sidebar@@deSelectAll">Deselect all</span>

      <label class="dps-checkbox indent" *ngFor="let productType of globalData.filters.productTypes; trackBy: trackById">
        <input
          [checked]="globalData.selectedFilters.productTypeIds.indexOf(productType.id) > -1"
          (change)="toggleFilter(productType.id, globalData.selectedFilters.productTypeIds)"
          type="checkbox"
          name="productTypes"
          [value]="productType.id">
        <span></span>
        <span class="checkbox-text">{{ productType.name }}</span>
      </label>
    </div>

    <div class="filter-row"
      [ngClass]="{ open: industriesOpen }"
      (click)="industriesOpen = !industriesOpen"
      *ngIf="globalData.filters.industries.length > 1 && (!globalData.silo || globalData.silo?.excludes.indexOf('industry') === -1)">
      <div class="filter-title light" i18n="Industries grouping label in the datatable filters sidebar@@industry">Industry</div>
      <a id="open-industries">
        <mat-icon svgIcon="arrow-right"></mat-icon>
      </a>
    </div>
    <div *ngIf="industriesOpen && globalData.filters.industries.length > 0 && (!globalData.silo || globalData.silo?.excludes.indexOf('industry') === -1)" class="filter-select">

      <span [ngClass]="{ active: globalData.filters.industries.length !== globalData.selectedFilters.industryIds.length }"
        (click)="toggleAllFilter(globalData.filters.industries, globalData.selectedFilters.industryIds)"
        class="select-all-text"
        i18n="Select all in filters sidebar@@selectAll">Select all</span>
      <span [ngClass]="{ active: this.globalData.selectedFilters.industryIds.length != 0 }"
        (click)="(this.globalData.selectedFilters.industryIds = []) && this.getData();"
        class="deselect-all-text"
        i18n="Deselect all in filters sidebar@@deSelectAll">Deselect all</span>

      <label class="dps-checkbox indent" *ngFor="let industry of globalData.filters.industries; trackBy: trackById">
        <input
          [checked]="globalData.selectedFilters.industryIds.indexOf(industry.id) > -1"
          (change)="toggleFilter(industry.id, globalData.selectedFilters.industryIds)"
          type="checkbox"
          name="industries"
          [value]="industry.id">
        <span></span>
        <span class="checkbox-text">{{ industry.name }}</span>
      </label>
    </div>

    <div class="filter-row" [ngClass]="{ open: marketSegmentsOpen }" (click)="marketSegmentsOpen = !marketSegmentsOpen"
      *ngIf="globalData.filters.marketSegments.length > 1 && (!globalData.silo || globalData.silo?.excludes.indexOf('marketSegment') === -1)">
      <div class="filter-title light" i18n="Market segments grouping label in the datatable filters sidebar@@marketSegment">Application</div>
      <a id="open-marketsegments">
        <mat-icon svgIcon="arrow-right"></mat-icon>
      </a>
    </div>
    <div *ngIf="marketSegmentsOpen && globalData.filters.marketSegments.length > 0 && (!globalData.silo || globalData.silo?.excludes.indexOf('marketSegment') === -1)" class="filter-select">

      <span [ngClass]="{ active: globalData.filters.marketSegments.length !== globalData.selectedFilters.marketSegmentIds.length }"
        (click)="toggleAllFilter(globalData.filters.marketSegments, globalData.selectedFilters.marketSegmentIds)"
        class="select-all-text"
        i18n="Select all in filters sidebar@@selectAll">Select all</span>
      <span [ngClass]="{ active: this.globalData.selectedFilters.marketSegmentIds.length != 0 }"
        (click)="(this.globalData.selectedFilters.marketSegmentIds = []) && this.getData();"
        class="deselect-all-text"
        i18n="Deselect all in filters sidebar@@deSelectAll">Deselect all</span>

      <label class="dps-checkbox indent" *ngFor="let marketSegment of globalData.filters.marketSegments; trackBy: trackById">
        <input
          [checked]="globalData.selectedFilters.marketSegmentIds.indexOf(marketSegment.id) > -1"
          (change)="toggleFilter(marketSegment.id, globalData.selectedFilters.marketSegmentIds)"
          type="checkbox"
          name="marketSegments"
          [value]="marketSegment.id">
        <span></span>
        <span class="checkbox-text">{{ marketSegment.name }}</span>
      </label>
    </div>
<!--
    <div class="filter-row" [ngClass]="{ open: applicationsOpen }" (click)="applicationsOpen = !applicationsOpen" *ngIf="globalData.filters.applications.length > 1 && globalData.silo.excludes.indexOf('application') === -1">
      <div class="filter-title light" i18n="Applications grouping label in the datatable filters sidebar@@applications">
        Applications
      </div>
      <a id="open-applications">
        <mat-icon svgIcon="arrow-right"></mat-icon>
      </a>
    </div>

    <div *ngIf="applicationsOpen && globalData.filters.applications.length > 0" class="filter-select">

      <span [ngClass]="{ active: globalData.filters.applications.length !== globalData.selectedFilters.applicationIds.length }"
        (click)="toggleAllFilter(globalData.filters.applications, globalData.selectedFilters.applicationIds)"
        class="select-all-text"
        i18n="Select all in filters sidebar@@selectAll">Select all</span>
      <span [ngClass]="{ active: this.globalData.selectedFilters.applicationIds.length != 0 }"
        (click)="(this.globalData.selectedFilters.applicationIds = []) && this.getData();"
        class="deselect-all-text"
        i18n="Deselect all in filters sidebar@@deSelectAll">Deselect all</span>

      <label class="dps-checkbox indent" *ngFor="let application of globalData.filters.applications; trackBy: trackById">
        <input
          [checked]="globalData.selectedFilters.applicationIds.indexOf(application.id) > -1"
          (change)="toggleFilter(application.id, globalData.selectedFilters.applicationIds)"
          type="checkbox"
          name="applications"
          [value]="application.id">
        <span></span>
        <span class="checkbox-text">{{ application.name }}</span>
      </label>
    </div> -->

    <div class="filter-row" [ngClass]="{ open: brandsOpen }" (click)="brandsOpen = !brandsOpen"
      *ngIf="globalData.filters.brands.length > 1 && (!globalData.silo || globalData.silo?.excludes.indexOf('brand') === -1)">
      <div class="filter-title light" i18n="Brands grouping label in the datatable filters sidebar@@brands">Brand</div>
      <a id="open-brands">
        <mat-icon svgIcon="arrow-right"></mat-icon>
      </a>
    </div>
    <div *ngIf="brandsOpen && globalData.filters.brands.length > 1 && (!globalData.silo || globalData.silo?.excludes.indexOf('brand') === -1)" class="filter-select">
      <span [ngClass]="{ active: globalData.filters.brands.length !== globalData.selectedFilters.brandIds.length }"
        (click)="toggleAllFilter(globalData.filters.brands, globalData.selectedFilters.brandIds)"
        class="select-all-text"
        i18n="Select all in filters sidebar@@selectAll">Select all</span>
      <span [ngClass]="{ active: this.globalData.selectedFilters.brandIds.length != 0 }"
        (click)="(this.globalData.selectedFilters.brandIds = []) && this.getData();"
        class="deselect-all-text"
        i18n="Deselect all in filters sidebar@@deSelectAll">Deselect all</span>
      <label class="dps-checkbox indent" *ngFor="let brand of globalData.filters.brands; trackBy: trackById">
        <input
          [checked]="globalData.selectedFilters.brandIds.indexOf(brand.id) > -1"
          (change)="toggleFilter(brand.id, globalData.selectedFilters.brandIds)"
          type="checkbox"
          name="brands"
          [value]="brand.id">
        <span></span>
        <span class="checkbox-text">{{ brand.name }}</span>
      </label>
    </div>

    <div class="refine-section">
      <div class="filter-row"
        *ngIf="
          (globalData.applicationState.propertyType.id === 'GP' && globalData.filters.globalProperties && globalData.filters.globalProperties.length > 0) ||
          (globalData.applicationState.propertyType.id === 'SP' && globalData.filters.salesProperties && globalData.filters.salesProperties.length > 0)
        ">
        <div class="filter-title"><span class="underline" i18n="Regine properties label in the datatable filters sidebar@@refineProperties">Refine properties:</span></div>
      </div>
      <ng-container *ngIf="
        (globalData.applicationState.propertyType.id === 'GP' && globalData.filters.globalProperties && globalData.filters.globalProperties.length > 0)">
        <ng-container *ngFor="let propertyGroup of globalData.filters.globalProperties; trackBy: trackByPropertyGroupKey">
          <div class="filter-row" [ngClass]="{ open: openProperties.indexOf(propertyGroup.key) > -1 }" (click)="togglePropertyGroup(propertyGroup.key)">
            <div class="filter-title light">
              {{ propertyGroup.key }}
            </div>
            <a id="open-properties">
              <mat-icon svgIcon="arrow-right"></mat-icon>
            </a>
          </div>
          <div *ngIf="openProperties.indexOf(propertyGroup.key) > -1" class="filter-select">
          <div class="filter-actions">
            <div class="edit-range" (click)="toggleEditRanges(propertyGroup.key)">
                <span *ngIf="editRangeProperties.indexOf(propertyGroup.key) > -1; else editRanges" i18n="Hide edit range in filters sidebar@@hideEditRange">Hide edit range</span>
                <ng-template #editRanges>
                  <span i18n="Edit range in filters sidebar@@editRange">Edit range</span>
                </ng-template>
            </div>
            <div class="reset-range" (click)="resetRanges(globalData.selectedFilters.propertyFilters, propertyGroup.key, false)" *ngIf="editRangeProperties.indexOf(propertyGroup.key) > -1">
              <span i18n="Reset ranges in filters sidebar@@editRangeReset">reset</span>
            </div>
            <div class="edit-visibility" (click)="selectAllPropertiesToggle(globalData.selectedFilters.propertyFilters, propertyGroup.key)" [ngClass]="{ active: hasAllOptionsSelected(globalData.selectedFilters.propertyFilters, propertyGroup.key)}">
              <span i18n="Select all in filters sidebar@@showAll" *ngIf="hasAllOptionsSelected(globalData.selectedFilters.propertyFilters, propertyGroup.key) === false; else hideAll">Show all</span>
              <ng-template #hideAll>
                <span i18n="Select all in filters sidebar@@hideAll">Hide all</span>
              </ng-template>
            </div>
          </div>
            <div class="select-option select-icon" *ngFor="let property of propertyGroup.value; trackBy: trackById">
              <label class="dps-checkbox">
                <span class="checkbox-text">{{ property.name }}
                  <span class="checkbox-unit-of-measure" *ngIf="property.unitOfMeasure != '-'">
                      {{ property.unitOfMeasure }}
                  </span>
                </span>
                <span class="checkbox-add" [ngClass]="{ active: globalData.selectedFilters.propertyFilters[property.id]?.selected }" (click)="toggleProperty(globalData.selectedFilters.propertyFilters, property)">
                  <mat-icon class="checkbox-add__icon" svgIcon="add"></mat-icon>
                </span>
              </label>
              <div class="values" *ngIf="editRangeProperties.indexOf(propertyGroup.key) > -1">
                <dps-datatable-range-editor
                  *ngIf="property.minimum || property.maximum"
                  [propertyId]="property.id"
                  [range]="property"
                  [type]="'range'"
                  [unitOfMeasure]="property.unitOfMeasure"
                  [selectedRange]="globalData.selectedFilters.propertyFilters[property.id]"
                  [customError]="customError"
                  (rangeChanged)="getData(); logPropertyRange(property);">
                </dps-datatable-range-editor>
                <div class="text" *ngIf="property.textValues">
                  <label class="value dps-checkbox" *ngFor="let textValue of property.textValues; index as i; trackBy: trackByTextValue">
                    <input type='checkbox'
                      [checked]="globalData.selectedFilters.propertyFilters[property.id] && globalData.selectedFilters.propertyFilters[property.id].textValues.indexOf(textValue) > -1"
                      (change)="toggleFilter(textValue, globalData.selectedFilters.propertyFilters[property.id].textValues)"
                    />
                    <span></span>
                    <span class="checkbox-text">{{ textValue }}</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="
        (globalData.applicationState.propertyType.id === 'SP' && globalData.filters.salesProperties && globalData.filters.salesProperties.length > 0)">
        <ng-container *ngFor="let propertyGroup of globalData.filters.salesProperties; trackBy: trackByPropertyGroupKey">
          <div class="filter-row" (click)="togglePropertyGroup(propertyGroup.key)" [ngClass]="{ open: openProperties.indexOf(propertyGroup.key) > -1 }">
            <div class="filter-title light">
              {{ propertyGroup.key }}
            </div>
            <a id="open-properties">
              <mat-icon svgIcon="arrow-right"></mat-icon>
            </a>
          </div>
          <div *ngIf="openProperties.indexOf(propertyGroup.key) > -1" class="filter-select">
            <div class="filter-actions">
              <div class="edit-range more-spacing" (click)="toggleEditRanges(propertyGroup.key)">
                <span *ngIf="editRangeProperties.indexOf(propertyGroup.key) > -1; else editRanges" i18n="Hide edit range in filters sidebar@@hideEditRange">Hide edit range</span>
                <ng-template #editRanges>
                  <span i18n="Edit range in filters sidebar@@editRange">Edit range</span>
                </ng-template>
              </div>
              <div class="reset-range" (click)="resetRanges(globalData.selectedFilters.salesPropertyFilters, propertyGroup.key, true)" *ngIf="editRangeProperties.indexOf(propertyGroup.key) > -1">
                <span>reset</span>
              </div>
              <div class="edit-visibility" (click)="selectAllPropertiesToggle(globalData.selectedFilters.salesPropertyFilters, propertyGroup.key)" [ngClass]="{ active: hasAllOptionsSelected(globalData.selectedFilters.salesPropertyFilters, propertyGroup.key)}">
                <span i18n="Select all in filters sidebar@@showAll" *ngIf="hasAllOptionsSelected(globalData.selectedFilters.salesPropertyFilters, propertyGroup.key, propertyGroup.value.length) === false; else hideAll">Show all</span>
                <ng-template #hideAll>
                  <span i18n="Select all in filters sidebar@@hideAll">Hide all</span>
                </ng-template>
              </div>
            </div>
            <div class="select-option select-icon" *ngFor="let property of propertyGroup.value; trackBy: trackById">
              <label class="dps-checkbox">
                <span class="checkbox-text">{{ property.name }}</span>
                <span class="checkbox-unit-of-measure" *ngIf="property.unitOfMeasure != '-'">
                    {{ property.unitOfMeasure }}
                </span>
                <span class="checkbox-add" [ngClass]="{ active: globalData.selectedFilters.salesPropertyFilters[property.id]?.selected }" (click)="toggleProperty(globalData.selectedFilters.salesPropertyFilters, property)">
                  <mat-icon class="checkbox-add__icon" svgIcon="add"></mat-icon>
                </span>
              </label>
              <div class="values" *ngIf="editRangeProperties.indexOf(propertyGroup.key) > -1">

                <dps-datatable-range-editor
                  *ngIf="property.salesRange && property.salesRange.targetRange"
                  [propertyId]="property.id"
                  [range]="property.salesRange.targetRange"
                  [type]="'target'"
                  [unitOfMeasure]="property.unitOfMeasure"
                  [selectedRange]="globalData.selectedFilters.salesPropertyFilters[property.id]?.salesRange.targetRange"
                  [customError]="customError"
                  (rangeChanged)="getData(); logPropertyRange(property);">
                </dps-datatable-range-editor>

                <dps-datatable-range-editor
                  *ngIf="property.salesRange && property.salesRange.minimumRange"
                  [propertyId]="property.id"
                  [range]="property.salesRange.minimumRange"
                  [type]="'min'"
                  [unitOfMeasure]="property.unitOfMeasure"
                  [selectedRange]="globalData.selectedFilters.salesPropertyFilters[property.id]?.salesRange.minimumRange"
                  [customError]="customError"
                  (rangeChanged)="getData(); logPropertyRange(property);">
                </dps-datatable-range-editor>

                <dps-datatable-range-editor
                  *ngIf="property.salesRange && property.salesRange.maximumRange"
                  [propertyId]="property.id"
                  [range]="property.salesRange.maximumRange"
                  [type]="'max'"
                  [unitOfMeasure]="property.unitOfMeasure"
                  [selectedRange]="globalData.selectedFilters.salesPropertyFilters[property.id]?.salesRange.maximumRange"
                  [customError]="customError"
                  (rangeChanged)="getData(); logPropertyRange(property);">
                </dps-datatable-range-editor>

                <div class="text" *ngIf="property.textValues">
                  <label class="value dps-checkbox" *ngFor="let textValue of property.textValues; index as i; trackBy: trackByTextValue">
                    <input type='checkbox'
                      [checked]="globalData.selectedFilters.salesPropertyFilters[property.id] && globalData.selectedFilters.salesPropertyFilters[property.id].textValues.indexOf(textValue) > -1"
                      (change)="toggleFilter(textValue, globalData.selectedFilters.salesPropertyFilters[property.id]?.textValues)"/>
                    <span></span>
                    <span class="checkbox-text">{{ textValue }}</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
