import { DpsFilterIdName } from './dpsFilterIdName';
import { DpsFilterProperty } from './dpsFilterProperty';
import { DpsFilterSalesProperty } from './dpsFilterSalesProperty';

export class DpsFilterData {
    brands: DpsFilterIdName[];
    businessUnits: DpsFilterIdName[];
    regions: DpsFilterIdName[];
    applications: DpsFilterIdName[];
    industries: DpsFilterIdName[];
    productTypes: DpsFilterIdName[];
    globalProperties: any;
    salesProperties: any;
    marketSegments: DpsFilterIdName[];

    constructor() {
      this.regions = [];
      this.businessUnits = [];
      this.productTypes = [];
      this.industries = [];
      this.applications = [];
      this.brands = [];
      this.globalProperties = {};
      this.salesProperties = {};
      this.marketSegments = [];
    }

}
