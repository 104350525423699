import { DpsFilterIdName } from './../../models/filter/dpsFilterIdName';
import { DpsGrade } from './../../models/data/dpsGrade';
import { Pipe, PipeTransform } from '@angular/core';
import { _ } from 'underscore';

@Pipe({
  name: 'sortApplicationGrades'
})
export class SortApplicationGradesPipe implements PipeTransform {

  transform(grades: DpsGrade[], application: DpsFilterIdName, sortOrder: string, gradeApplicationsObject: any, productTypes: DpsFilterIdName[], brands: DpsFilterIdName[]): any[] {

    if (!grades || !gradeApplicationsObject) {
      return [];
    }

    // Check if is not null
    if (!application) {
      if (productTypes) {
        let result = [];
        let gradesByProductType = _.groupBy(_.sortBy(grades, 'productTypeId'), 'productTypeId');

        _.each(gradesByProductType, (value, key) => {
          let productType = _.findWhere(productTypes, { id: +key });
          result.push({
            type: 'productType',
            name: productType ? productType.name : null,
            crossLink: productType ? productType.crossLink : null
          });
          let gradesByIdesFamily = _.groupBy(_.sortBy(value, 'brandId'), 'brandId');
          _.each(gradesByIdesFamily, (v, k) => {
            let brand = _.findWhere(brands, { id: +k });
            result.push({
              type: 'idesFamily',
              name: brand ? brand.name : null,
              crossLink: brand ? brand.crossLink : null
            });
            result = result.concat(v);
          });
        });
        return result;
      } else {
        return [];
      }
    }

    let sortCopy = [].concat(grades);
    return sortCopy.sort((a: DpsGrade, b: DpsGrade) => {
      let gradeApplicationsA;
      let gradeApplicationsB;
      let gradeA = gradeApplicationsObject[a.substance];
      gradeApplicationsA = gradeA ? _.indexOf(gradeA, application.id) > -1 : false;
      let gradeB = gradeApplicationsObject[b.substance];
      gradeApplicationsB = gradeB ? gradeB[application.id] : null;
      let order = sortOrder === 'asc' ? 1 : -1;

      if (gradeApplicationsA && gradeApplicationsB) {
        return 0;
      } else {
        return gradeApplicationsA ? order : order * -1;
      }
    });
  }

}
