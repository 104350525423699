export class CustomData {
    constructor(public data = {}) {
    }

    getFirst() {
        for (var prop in this.data) {
            return this.data[prop];
        }

        return {};
    }

    addProperty(name: string, value: any) {
        this.data[name] = value;
    }
}