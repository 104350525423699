import { DpsGradePropertiesData } from './../../general/models/data/dpsGradePropertiesData';
import { Pipe, PipeTransform } from '@angular/core';
import { _ } from 'underscore';

@Pipe({
  name: 'radarChartSalesPipe'
})
export class RadarChartSalesPipePipe implements PipeTransform {

  transform(data: DpsGradePropertiesData, selectedProperties: any[], selectedGrades: any[]): any {
    let gradeProperties = data.gradeSalesProperties;
    let properties = data.salesProperties;

    if (!data || !selectedProperties || selectedProperties.length === 0 || !selectedGrades || selectedGrades.length === 0) {
      return null;
    }

    let propertiesObject = [];
    _.each(properties, function(p) {
      propertiesObject[p.id] = p;
    });

    let result = {
      data: [],
      axis: [],
      colors: _.pluck(selectedGrades, 'color')
    };

    _.each(selectedProperties, function(property, pIndex) {
      let propertyMin = _.min(_.map(selectedGrades, function(g) {
        return gradeProperties.hasOwnProperty(g.substance) &&
          gradeProperties[g.substance].hasOwnProperty(property.id) &&
          gradeProperties[g.substance][property.id][property.type + 'SortValue'] ? parseFloat(gradeProperties[g.substance][property.id][property.type + 'SortValue']) : null;
      }));
      let propertyMax = _.max(_.map(selectedGrades, function(g) {
        return gradeProperties.hasOwnProperty(g.substance) &&
          gradeProperties[g.substance].hasOwnProperty(property.id) &&
          gradeProperties[g.substance][property.id][property.type + 'SortValue'] ? parseFloat(gradeProperties[g.substance][property.id][property.type + 'SortValue']) : null;
      }));

      _.each(selectedGrades, function(grade, gIndex) {
        let valueObject = {
          gradeId: grade.substance,
          gradeName: grade.tradeName,
          propertyId: property.id,
          propertyType: property.type,
          name: property.name,
          value: gradeProperties.hasOwnProperty(grade.substance) &&
            gradeProperties[grade.substance].hasOwnProperty(property.id) &&
            gradeProperties[grade.substance][property.id][property.type + 'SortValue'] ? parseFloat(gradeProperties[grade.substance][property.id][property.type + 'SortValue']) : null,
          displayText: gradeProperties.hasOwnProperty(grade.substance) &&
            gradeProperties[grade.substance].hasOwnProperty(property.id) &&
            gradeProperties[grade.substance][property.id][property.type + 'DisplayText'] ? parseFloat(gradeProperties[grade.substance][property.id][property.type + 'DisplayText']) : null,
          minValue: propertyMin === propertyMax ? (propertyMin - Math.abs(propertyMin * 0.1)) : propertyMin - (propertyMax - propertyMin) / 10,
          maxValue: propertyMin === propertyMax ? (propertyMin + Math.abs(propertyMin * 0.1)) : propertyMax + (propertyMax - propertyMin) / 10,
          unitOfMeasure: property.unitOfMeasure ? property.unitOfMeasure : ''
        };

        if (result.data.length <= gIndex) {
          result.data.push([valueObject]);
        } else {
          result.data[gIndex].push(valueObject);
        }
      });

      result.axis.push({
        id: property.id,
        name: property.name,
        unitOfMeasure: property.unitOfMeasure ? property.unitOfMeasure : '',
        minValue: propertyMin === propertyMax ? (propertyMin - Math.abs(propertyMin * 0.1)) : propertyMin - (propertyMax - propertyMin) / 10,
        maxValue: propertyMin === propertyMax ? (propertyMin + Math.abs(propertyMin * 0.1)) : propertyMax + (propertyMax - propertyMin) / 10,
        propertyType: property.type
      });
    });

    return result;
  }
}
