import { DpsFilterSalesProperty } from './../../models/filter/dpsFilterSalesProperty';
import { Pipe, PipeTransform } from '@angular/core';
import { _ } from 'underscore';

@Pipe({
  name: 'filterSalesProperties'
})
export class FilterSalesPropertiesPipe implements PipeTransform {

  transform(properties: DpsFilterSalesProperty[], propertyFilters: any): any {
    let selectedFilters = _.where(_.values(propertyFilters), { selected: true });
    if (selectedFilters.length > 0) {
      return _.filter(properties, (p) => {
        return propertyFilters.hasOwnProperty(p.id) && propertyFilters[p.id].selected;
      });
    } else {
      return properties;
    }
  }

}
