export class DpsExportState {
  private _exportAllGradeData: boolean;
  set exportAllGradeData(value: boolean) {
    this._exportAllGradeData = value;
    if (value) {
      this._exportGradeProperties = true;
      this._exportGradeApplications = true;
    } else if (!value
      && this.exportGradeApplications
      && this.exportGradeProperties
    ) {
      this._exportGradeProperties = false;
      this._exportGradeApplications = false;
    }
  }
  get exportAllGradeData(): boolean {
    return this._exportAllGradeData;
  }

  private _exportGradeProperties: boolean;
  set exportGradeProperties(value: boolean) {
    this._exportGradeProperties = value;
    if (!value) {
      this._exportAllGradeData = false;
    } else if (this.exportGradeApplications) {
      this._exportAllGradeData = true;
    }
  }
  get exportGradeProperties(): boolean {
    return this._exportGradeProperties;
  }
  private _exportGradeApplications: boolean;
  set exportGradeApplications(value: boolean) {
    this._exportGradeApplications = value;
    if (!value) {
      this._exportAllGradeData = false;
    } else if (this.exportGradeProperties) {
      this._exportAllGradeData = true;
    }
  }
  get exportGradeApplications(): boolean {
    return this._exportGradeApplications;
  }

  private _exportGradeResources: boolean;
  set exportGradeResources(value: boolean) {
    this._exportGradeResources = value;
  }
  get exportGradeResources(): boolean {
    return this._exportGradeResources;
  }

  public exportXYDisabled: boolean;
  public exportXY: boolean;
  public exportSpiderDisabled: boolean;
  public exportSpider: boolean;
  public exportTermsAndConditions: boolean;
  public exportPrivacyStatement: boolean;
  public exportNewsletterSubscription: boolean;
  public exportEmail: string;

  constructor() {
    this.exportAllGradeData = true;
    this.exportXY = true;
    this.exportSpider = true;
    this.exportGradeResources = true;
    this.exportTermsAndConditions = true;
    this.exportPrivacyStatement = true;
    this.exportNewsletterSubscription = false;
  }

  selectAll(): void {
    this.exportAllGradeData = true;
    this.exportGradeResources = true;
    this.exportXY = !this.exportXYDisabled;
    this.exportSpider = !this.exportSpiderDisabled;
  }

  deselectAll(): void {
    this.exportAllGradeData = false;
    this.exportGradeProperties = false;
    this.exportGradeApplications = false;
    this.exportXY = false;
    this.exportSpider = false;
    this.exportGradeResources = false;
  }
}
