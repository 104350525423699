import { AnalyticsService } from './../general/services/analytics.service';
import { Router } from '@angular/router';
import { GradesService } from './../general/services/grades.service';
import { GlobalData } from '../general/services/global-data.service';
import { FavoritesService } from '../general/services/favorites.service';
import { Component, OnInit } from '@angular/core';
import easyScroll from 'easy-scroll';
import { _ } from 'underscore';
import { AnalyticsEventsService } from '../general/services/analytics-events.service';
import { EventTypes } from '../general/enums/analytics/event-types';

@Component({
  selector: 'dps-datatable',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.scss'],
})
export class DatatableComponent implements OnInit {
  scrollAmount: number;

  constructor(public router: Router,
    public globalData: GlobalData,
    public gradesService: GradesService,
    private _analyticsService: AnalyticsService,
    private _analyticsEventsService: AnalyticsEventsService) { }

  ngOnInit() {
    this.globalData.initialized = true;
    if (!this.globalData.data &&
      !this.globalData.loading &&
      !this.globalData.applicationState.showInitialFilterDialog &&
      !this.globalData.applicationState.showPropertyTypeSelection) {
      if ((window as any).silo) {
        this.gradesService.getData();
      } else {
        this.globalData.applicationState.showInitialFilterDialog = true;
      }
    }
  }

  scrollRight() {
    let tableBody = document.querySelector('.datatable-container .table .table-body');
    if (window.innerWidth >= 768) {
      this.scrollAmount = window.innerWidth * 2 / 3;
    } else {
      this.scrollAmount = 145;
    }
    easyScroll({
      scrollableDomEle: tableBody,
      direction: 'right',
      duration: 200,
      easingPreset: 'easeInQuad',
      scrollAmount: this.scrollAmount
    });
  }

  scrollLeft() {
    let tableBody = document.querySelector('.datatable-container .table .table-body');
    if (window.innerWidth >= 768) {
      this.scrollAmount = window.innerWidth * 2 / 3;
    } else {
      this.scrollAmount = 145;
    }
    easyScroll({
      scrollableDomEle: tableBody,
      direction: 'right',
      duration: 200,
      easingPreset: 'easeInQuad',
      scrollAmount: -this.scrollAmount
    });
  }

  closeSidebar() {
    this.globalData.applicationState.datatableSidebarOpen = false;
  }

  logTabClick(tab) {
    let currentTab = this.router.url === '/datatable/testmethods' ? 'Test methods' : (this.router.url === '/datatable/applications' ? 'Applications' : 'Properties');
    switch (tab) {
      case 'properties':
        this._analyticsService.sendEvent(this._analyticsEventsService.getEvent(EventTypes.DATATABLE_PROPERTIES_TAB), currentTab + ' table');
        break;
      case 'testmethods':
        this._analyticsService.sendEvent(this._analyticsEventsService.getEvent(EventTypes.DATATABLE_TESTMETHODS_TAB), currentTab + ' table');
        break;
      case 'applications':
        this._analyticsService.sendEvent(this._analyticsEventsService.getEvent(EventTypes.DATATABLE_APPLICATIONS_TAB), currentTab + ' table');
        break;
    }
  }
}
