import { DpsGrade } from './../../general/models/data/dpsGrade';
import { DpsSalesProperty } from './../../general/models/data/dpsSalesProperty';
import { Pipe, PipeTransform } from '@angular/core';
import { _ } from 'underscore';

@Pipe({
  name: 'plotChartMatchingSalesProperties'
})
export class PlotChartMatchingSalesPropertiesPipe implements PipeTransform {

  transform(properties: DpsSalesProperty[], selectedProperty: DpsSalesProperty, grades: DpsGrade[], gradeProperties: any, index: number): DpsSalesProperty[] {
    if (selectedProperty) {
      properties = _.without(properties, selectedProperty);
    }
    let result = _.map(_.filter(properties, (p: DpsSalesProperty) => {
      return !p.textValues || p.textValues.length === 0;
    }), (p: DpsSalesProperty) => {
      let hasMatch = false;
      if (!selectedProperty) {
        hasMatch = true;
      } else {
        _.every(grades, (g: DpsGrade) => {
          if (gradeProperties.hasOwnProperty(g.substance)) {
            if (gradeProperties[g.substance].hasOwnProperty(p.id) && gradeProperties[g.substance].hasOwnProperty(selectedProperty.id)) {
              if (gradeProperties[g.substance][p.id].hasOwnProperty(p.type + 'DisplayText') && gradeProperties[g.substance][selectedProperty.id].hasOwnProperty(p.type + 'DisplayText')) {
                hasMatch = true;
                return false;
              }
            }
          }
          return true;
        });
      }
      if (index === 0) {
        p.hasFirstPlotMatch = hasMatch;
      } else {
        p.hasSecondPlotMatch = hasMatch;
      }
      return p;
    });
    return result;
  }

}
