import { AnalyticsService } from './../../general/services/analytics.service';
import { ExportHtmlComponent } from './../../export-html/export-html.component';
import { ExportService } from '../../general/services/export.service';
import { GlobalData } from '../../general/services/global-data.service';
import { Component, OnInit, ComponentFactoryResolver, ViewContainerRef } from '@angular/core';
import { AnalyticsEventsService } from 'src/app/general/services/analytics-events.service';
import { EventTypes } from 'src/app/general/enums/analytics/event-types';
import { CustomData } from 'src/app/general/models/analytics/custom-data';
import { AnalyticsConstants } from 'src/app/general/constants/analytics';
import { PageUrlData } from 'src/app/general/models/analytics/page-url-data';
import { PageData } from 'src/app/general/models/analytics/page-data';
import { PageType } from 'src/app/general/enums/analytics/page-type';

@Component({
  selector: 'dps-export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.scss']
})
export class ExportComponent implements OnInit {
  submitted = false;
  validForm = false;

  constructor(public globalData: GlobalData, public exportService: ExportService, private _componentFactoryResolver: ComponentFactoryResolver,
    private _viewContainerRef: ViewContainerRef, private _analyticsService: AnalyticsService, private _analyticsEventsService: AnalyticsEventsService
  ) { }

  ngOnInit() {
    this.exportService.exportState.exportXYDisabled = this.shouldDisableXYExport();
    this.exportService.exportState.exportSpiderDisabled = this.shouldDisableRadarExport();

    this.exportService.exportState.exportXY = !this.exportService.exportState.exportXYDisabled;
    this.exportService.exportState.exportSpider = !this.exportService.exportState.exportSpiderDisabled;
  }

  shouldDisableXYExport() {
    return this.globalData.getSelectedProperties().length != 2;
  }

  shouldDisableRadarExport() {
    return this.globalData.getSelectedRadarProperties().length < 3 || this.globalData.selectedGrades.length < 2;
  }

  export(form) {
    this.submitted = true;
    this.validForm = form.valid;
    if (form.valid) {
      let componentFactory = this._componentFactoryResolver.resolveComponentFactory(ExportHtmlComponent);
      let componentRef = this._viewContainerRef.createComponent(componentFactory);
      setTimeout(() => {
        let html = (componentRef.hostView as any).rootNodes[0].innerHTML;
        this.exportService.export(html);
        componentRef.destroy();
      }, 500);
      this.sendGlobalExportGaToAnalytics();
      this._analyticsService.sendEvent(this._analyticsEventsService.getEvent(EventTypes.GLOBAL_EXPORT), this.globalData.title);
    }
  }

  private sendGlobalExportGaToAnalytics() {
    let filters = this.globalData.getFiltersForAnalytics();
    let customData = new CustomData();
    let event = this._analyticsEventsService.getEvent(EventTypes.GLOBAL_EXPORT_GA, filters);
    customData.addProperty(AnalyticsConstants.GLOBAL_EXPORT_GA.Fields.FilterList, filters);
    this._analyticsService.sendEvent(event, customData, new PageUrlData('/datatable/landing'), new PageData(PageType.Selector));
  }
}
