<dps-datatable-applications-table
  *ngIf="globalData.data && globalData.data.gradeApplications && !globalData.globalError"
  [grades]="globalData.data.grades"
  [applications]="globalData.data.applications | filterApplications: globalData.selectedFilters.marketSegmentIds"
  [gradeApplications]="globalData.data.gradeApplications"
  [selectedGrades]="globalData.selectedGrades"
  [gradesChanged]="globalData.gradesChanged"
  [productTypes]="globalData.filters.productTypes"
  [brands]="globalData.filters.brands"
  [sortApplication]="globalData.sortApplication"
  [sortOrder]="globalData.sortApplicationOrder">
</dps-datatable-applications-table>
<div class="error" *ngIf="globalData.globalError" [innerHtml]="globalData.globalError"></div>
<dps-loading-spinner class="spinner" *ngIf="!globalData.data || !globalData.data.gradeApplications || globalData.loading"></dps-loading-spinner>

