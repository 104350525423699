<div class="content">
  <ng-container
    *ngIf="tabId && globalData.gradeDetail && globalData.gradeDetail.tabs && globalData.gradeDetail.tabs.length > 0">
    <ng-container *ngFor="let relatedItem of globalData.gradeDetail.tabs[tabId - 1].featuredResources">
      <a class="related-item" target="_blank" href="{{relatedItem.url}}">
        <img [src]="relatedItem.img" alt="">
        <div class="info">
          <h3 [innerHTML]="relatedItem.title"></h3>
          <p [innerHTML]="relatedItem.description"></p>
          <div class="read-more">
            <span [innerHTML]="relatedItem.label"></span>
            <mat-icon svgIcon="arrow-link"></mat-icon>
          </div>
        </div>
      </a>
    </ng-container>
  </ng-container>
</div>