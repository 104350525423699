import { GradesService } from '../../general/services/grades.service';
import { FavoritesService } from '../../general/services/favorites.service';
import { GlobalData } from '../../general/services/global-data.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dps-datatable-applications',
  templateUrl: './datatable-applications.component.html',
  styleUrls: ['./datatable-applications.component.scss']
})
export class DatatableApplicationsComponent implements OnInit {

  constructor(public globalData: GlobalData, private _favoritesService: FavoritesService, private _gradesService: GradesService) { }

  ngOnInit() {
    if (!this.globalData.data || !this.globalData.data.gradeApplications) {
      this._gradesService.getData();
    }
  }

}
