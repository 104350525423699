import { DpsFilters } from './../../general/models/dpsFilters';
import { AnalyticsService } from './../../general/services/analytics.service';
import { FavoritesService } from './../../general/services/favorites.service';
import { DpsFilterIdName } from '../../general/models/filter/dpsFilterIdName';
import { LocationService } from '../../general/services/location.service';
import { DpsSearchResult } from './../../general/models/data/dpsSearchResult';
import { GlobalData } from '../../general/services/global-data.service';
import { GradesService } from '../../general/services/grades.service';
import { SearchService } from './../../general/services/search.service';
import { Component, OnInit, Input } from '@angular/core';
import { _ } from 'underscore';
import { Router } from '@angular/router';
import { AnalyticsEventsService } from 'src/app/general/services/analytics-events.service';
import { EventTypes } from 'src/app/general/enums/analytics/event-types';
import { AnalyticsEvent } from 'src/app/general/models/analytics/analytics-event';
import { CustomData } from 'src/app/general/models/analytics/custom-data';
import { PageData } from 'src/app/general/models/analytics/page-data';
import { PageUrlData } from 'src/app/general/models/analytics/page-url-data';
import { PageType } from 'src/app/general/enums/analytics/page-type';

@Component({
  selector: 'dps-explore-data',
  templateUrl: './explore-data.component.html',
  styleUrls: ['./explore-data.component.scss']
})
export class ExploreDataComponent implements OnInit {
  businessUnit: number;
  productType: number;
  loading = true;
  searchTerm: string;



  @Input() appendTo: any;

  constructor(
    public globalData: GlobalData,
    private _searchService: SearchService,
    private _gradesService: GradesService,
    private _favoritesService: FavoritesService,
    private _analyticsService: AnalyticsService,
    private _analyticsEventsService: AnalyticsEventsService,
    private _router: Router
  ) { }

  ngOnInit() {
    let self = this;
    if (this.globalData.applicationState.showInitialFilterDialog) {
      this._gradesService.getInitialFilters(function () {
        self.loading = false;
        self.globalData.selectedFilters.regionId =
          self.globalData.selectedFilters.regionId ? self.globalData.selectedFilters.regionId : self.globalData.filters.regions[0].id;
        self._gradesService.getInitialFilters();
      });
    }
    this._analyticsService.sendPageView(new PageData(PageType.Selector), new PageUrlData('/explore'));
  }

  explore() {
    if (this.globalData.selectedFilters.productTypeIds.length > 0 || this.globalData.selectedFilters.industryIds.length > 0 || this.globalData.selectedFilters.brandIds.length > 0) {
      this.globalData.sortProperty = null;
      this.globalData.sortOrder = null;
      this.globalData.sortSalesOrder = null;
      this.globalData.sortSalesProperty = null;
      this.globalData.sortApplication = null;
      this.globalData.sortApplicationOrder = null;
      this.globalData.selectedGrades = [];
      this.globalData.selectedProperties = [];
      this.globalData.selectedRadarProperties = [];
      this.globalData.selectedSalesProperties = [];
      this.globalData.selectedRadarSalesProperties = [];
      this.globalData.applicationState.applicationActive = false;
      this.globalData.applicationState.propertyType = {};
      this.globalData.applicationState.showInitialFilterDialog = false;

      let siloFilters = new DpsFilters();
      siloFilters.updateFromFilters(this.globalData.selectedFilters);

      this.globalData.silo = {
        filters: siloFilters,
        excludes: ['businessUnit', 'productType']
      };

      this.globalData.updateTitle();
      this._gradesService.getData(() => {
        this.globalData.silo.hasMultiplePropertyTypes = this.globalData.hasOtherPropertyType;
        if (this.globalData.silo.hasMultiplePropertyTypes) {
          // open properties dialog
          this.globalData.data = null;
          this.globalData.applicationState.showPropertyTypeSelection = true;
        }
      });
      this._favoritesService.updateCurrentFavorite();
      this._router.navigateByUrl('/datatable/landing');
      this.globalData.applicationState.regionsSidebarOpen = false;
      this.globalData.applicationState.datatableSidebarOpen = window.screen && window.screen.width > 1024;
      this._analyticsService.sendEvent(this._analyticsEventsService.getEvent(EventTypes.EXPLORE_CLICK), null);
    }
  }

  scrollDown() {
    let dialog = document.querySelector('.dps-explore-dialog');
    dialog.scrollTop = dialog.scrollHeight;
  }

  selectBusinessUnit() {
    this.globalData.selectedFilters.businessUnitIds = [this.businessUnit];
    let self = this;
    this._gradesService.getInitialFilters(function () {
      self.globalData.selectedFilters.productTypeIds =
        _.intersection(_.pluck(self.globalData.filters.productTypes, 'id'), self.globalData.selectedFilters.productTypeIds);
      if (self.globalData.selectedFilters.productTypeIds.length === 0) {
        self.productType = null;
        self._gradesService.getInitialFilters();
      }
    });
  }

  clearSelectedFilters() {
    this.globalData.selectedFilters.businessUnitIds = [];
    this.globalData.selectedFilters.productTypeIds = [];
    this.globalData.selectedFilters.brandIds = [];
    this.globalData.selectedFilters.applicationIds = [];
    this.globalData.selectedFilters.industryIds = [];
    this.globalData.selectedFilters.marketSegmentIds = [];
    this.globalData.selectedFilters.propertyFilters = [];
    this.globalData.selectedFilters.salesPropertyFilters = [];
  }

  selectProductType(productType) {
    if (productType) {
      this._analyticsService.sendEvent(this._analyticsEventsService.getEvent(EventTypes.EXPLORE_FILTER_CHOICE, 'Product Category>' + productType.name), new CustomData({ prod_selector_splsh_filter_name: "Product Category", prod_selector_splsh_filter_choice: productType.name }), new PageUrlData('/datatable/landing'), new PageData(PageType.Selector));
      this.clearSelectedFilters();
      this.globalData.selectedFilters.productTypeIds = [productType.id];
      this.globalData.selectedFilters.brandIds = [];
      this.globalData.selectedFilters.industryIds = [];
      this.explore();
    }
  }

  selectIndustry(industry) {
    if (industry) {
      this._analyticsService.sendEvent(this._analyticsEventsService.getEvent(EventTypes.EXPLORE_FILTER_CHOICE, 'Industry>' + industry.name), new CustomData({ prod_selector_splsh_filter_name: "Industry", prod_selector_splsh_filter_choice: industry.name }), new PageUrlData('/datatable/landing'), new PageData(PageType.Selector));
      this.clearSelectedFilters();
      this.globalData.selectedFilters.industryIds = [industry.id];
      this.globalData.selectedFilters.brandIds = [];
      this.globalData.selectedFilters.productTypeIds = [];
      this.explore();
    }
  }

  selectBrand(brand) {
    if (brand) {
      this._analyticsService.sendEvent(this._analyticsEventsService.getEvent(EventTypes.EXPLORE_FILTER_CHOICE, 'Brand>' + brand.name), new CustomData({ prod_selector_splsh_filter_name: "Brand", prod_selector_splsh_filter_choice: brand.name }), new PageUrlData('/datatable/landing'), new PageData(PageType.Selector));
      this.clearSelectedFilters();
      this.globalData.selectedFilters.brandIds = [brand.id];
      this.globalData.selectedFilters.industryIds = [];
      this.globalData.selectedFilters.productTypeIds = [];
      this.explore();
    }
  }

  selectRegion(region) {
    this._analyticsService.sendEvent(this._analyticsEventsService.getEvent(EventTypes.EXPLORE_REGION, region.name), new CustomData({ prod_selector_splsh_region_select: region.name }), new PageUrlData('/datatable/landing'), new PageData(PageType.Selector));
    let self = this;
    this._gradesService.getInitialFilters(function () {
      self.globalData.selectedFilters.productTypeIds =
        _.intersection(_.pluck(self.globalData.filters.productTypes, 'id'), self.globalData.selectedFilters.productTypeIds);
      if (self.globalData.selectedFilters.productTypeIds.length === 0) {
        self.productType = null;
      }
    });
  }

  search(event?) {
    if (this._searchService.stripUnwantedCharacters(this.globalData.searchModel.searchTerm)) {
      this.globalData.applicationState.showInitialFilterDialog = false;
      this.searchTerm = this.globalData.searchModel.searchTerm;
      this._router.navigateByUrl('/search?q=' + encodeURIComponent(this.globalData.searchModel.searchTerm));
    }
  }

  autocomplete(event?) {
    this._searchService.getAutocompleteResults();
  }

  select(item: DpsSearchResult) {
    if (item.type !== 'Grade') {
      this.globalData.selectedFilters = item.getFilters(this.globalData.selectedFilters.regionId);
      this.globalData.sortProperty = null;
      this.globalData.sortOrder = null;
      this.globalData.sortSalesOrder = null;
      this.globalData.sortSalesProperty = null;
      this.globalData.sortApplication = null;
      this.globalData.sortApplicationOrder = null;
      this.globalData.selectedGrades = [];
      this.globalData.selectedProperties = [];
      this.globalData.selectedRadarProperties = [];
      this.globalData.selectedSalesProperties = [];
      this.globalData.selectedRadarSalesProperties = [];
      this.globalData.applicationState.applicationActive = false;
      this.globalData.applicationState.propertyType = {};

      let excludes = ['businessUnit', 'productType'];
      switch (item.type) {
        case 'Brand':
          excludes.push('brand');
          break;
        case 'Application':
          excludes.push('application');
          break;
        case 'Market Segment':
          excludes.push('marketSegment');
          break;
        case 'Industry':
          excludes.push('industry');
          break;
      }

      let siloFilters = new DpsFilters();
      siloFilters.updateFromFilters(this.globalData.selectedFilters);

      this.globalData.silo = {
        filters: siloFilters,
        excludes: excludes
      };

      // check for multiplePropertyTypes
      this._gradesService.getData(() => {
        this.globalData.silo.hasMultiplePropertyTypes = this.globalData.hasOtherPropertyType;
        if (this.globalData.silo.hasMultiplePropertyTypes) {
          // open properties dialog
          this.globalData.data = null;
          this.globalData.applicationState.showPropertyTypeSelection = true;
        }
      });
    }
    this.globalData.searchModel.searchTerm = '';
    this.globalData.applicationState.showInitialFilterDialog = false;
    this._router.navigateByUrl(item.getPath());
  }
}
