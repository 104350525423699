import { AnalyticsService } from './../general/services/analytics.service';
import { GlobalData } from '../general/services/global-data.service';
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { _ } from 'underscore';
import { AnalyticsEventsService } from '../general/services/analytics-events.service';
import { EventTypes } from '../general/enums/analytics/event-types';

@Component({
  selector: 'dps-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss']
})
export class ShareComponent implements OnInit {
  currentUrl: string;

  constructor(public globalData: GlobalData, private _http: HttpClient, private _analyticsService: AnalyticsService, private _analyticsEventsService: AnalyticsEventsService) {
  }

  ngOnInit() {
    this.currentUrl = window.location.href.substring(0, window.location.href.indexOf('#'));
  }

  closeShareDialog() {
    this.globalData.applicationState.showShareDialog = false;
  }

  shareOnLinkedIn() {
    let shareUrl = this.getShareLink();
    let url = 'https://www.linkedin.com/shareArticle';
    url += '?url=' + shareUrl;
    url += '&mini=true';
    url += '&title=something';
    url += '&source=' + shareUrl;
    window.open(url, '_blank');
    this._analyticsService.sendEvent(this._analyticsEventsService.getEvent(EventTypes.SHARE_LINKEDIN), this.currentUrl);
  }

  shareOnSocialX() {
    let shareUrl = this.getShareLink();
    let url = 'https://twitter.com/intent/tweet';
    url += '?text=' + (this.globalData.lang === 'en' ?
      encodeURI('The Digital Product Selector | Put our products to the test and find the grades that fit. @XOM_Chemical') :
      encodeURI('数字产品选择器 | 可以测试我们的产品并找到合适的牌号。 @XOM_Chemical'));
    url += '&related=@XOM_Chemical';
    url += '&url=' + shareUrl;
    window.open(url, '_blank');
    this._analyticsService.sendEvent(this._analyticsEventsService.getEvent(EventTypes.SHARE_SOCIAL_X), this.currentUrl);
  }

  shareOnWeChat() {
    this._analyticsService.sendEvent(this._analyticsEventsService.getEvent(EventTypes.SHARE_WECHAT), this.currentUrl);
    this.globalData.applicationState.showShareDialog = false;
    this.globalData.applicationState.showQrShareDialog = true;
  }

  shareByEmail() {
    this._analyticsService.sendEvent(this._analyticsEventsService.getEvent(EventTypes.SHARE_EMAIL), this.currentUrl);
  }

  getShareLink() {
    const copyToClipboard = str => {
      const el = document.createElement('textarea');
      el.value = str;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
    };
    let result = this.currentUrl + '#/link/' + this.globalData.getShareLink();
    // copyToClipboard(result);
    return encodeURIComponent(result);
  }
}
