import { DpsFilters } from './../general/models/dpsFilters';
import { environment } from 'src/environments/environment';
import { AnalyticsService } from './../general/services/analytics.service';
import { Location } from '@angular/common';
import { FavoritesService } from './../general/services/favorites.service';
import { GradesService } from './../general/services/grades.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { GlobalData } from './../general/services/global-data.service';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { _ } from 'underscore';
import { AnalyticsEventsService } from '../general/services/analytics-events.service';
import { PageUrlData } from '../general/models/analytics/page-url-data';
import { PageData } from '../general/models/analytics/page-data';
import { PageType } from '../general/enums/analytics/page-type';
import { EventTypes } from '../general/enums/analytics/event-types';
import { CustomData } from '../general/models/analytics/custom-data';
import { AnalyticsConstants } from '../general/constants/analytics';

@Component({
  selector: 'dps-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  navigationFull: boolean;
  tabletBreakpoint = 1024;
  showBackButton = false;
  initialRouteId: number;
  containerHeight = '';
  iconUrlBase: string;
  contactUsUrl: string;
  noTabs: boolean;
  param: any;

  @ViewChild('navigation') navigation: ElementRef;

  constructor(
    public globalData: GlobalData,
    public router: Router,
    public gradesService: GradesService,
    private _location: Location,
    private _favoritesService: FavoritesService,
    private _analyticsService: AnalyticsService,
    private _analyticsEventsService: AnalyticsEventsService,
    private activatedRoute: ActivatedRoute
  ) {
    this.iconUrlBase = environment.iconUrlBase;
    this.contactUsUrl = environment.contactUsUrl;
    let self = this;
    window.addEventListener('resize', function () {
      if (self.globalData.applicationState.expandApplication) {
        // fix to recalculate height of viewport
        self.containerHeight = '100vh';
        setTimeout(function () {
          self.containerHeight = document.documentElement.clientHeight + 'px';
        }, 100);
      }
    });
  }

  ngOnInit() {
    let self = this;
    this.navigationFull = false;
    this._favoritesService.loadFavorites();
    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        this._favoritesService.updateCurrentFavorite();
        if (self.globalData.initialized && !self.showBackButton) {
          self.showBackButton = true;
          self.initialRouteId = e.id;
        }
      }
    });
    this.listenForSilo();
    if ((window as any).siloTitle) {
      this.globalData.title = (window as any).siloTitle;
    }

    this.checkSilo();

    this.adjustNavigation();
    window.addEventListener('resize', function () {
      self.adjustNavigation();
    });
  }

  adjustNavigation() {
    let self = this;
    let navItems = window.innerWidth > this.tabletBreakpoint ? self.navigation.nativeElement.querySelectorAll('a').length : (self.navigation.nativeElement.querySelectorAll('a').length + 1);
    let navHeight = self.navigation.nativeElement.clientHeight;
    let navItemHeight = window.innerWidth > this.tabletBreakpoint ? 80 : 40;
    let navItemsHeight = navItems * navItemHeight;
    let bottomNavItems = self.navigation.nativeElement.querySelector('.bottom');
    if (navHeight < navItemsHeight) {
      bottomNavItems.style.position = 'static';
    } else {
      bottomNavItems.style.position = 'absolute';
    }
  }

  checkSilo() {
    if (window['silo'] && !this.globalData.silo) {
      // remove the initialFilterDialog
      this.globalData.applicationState.showInitialFilterDialog = false;
      if ((window as any).hasOwnProperty('filters') && (window as any).filters.hasOwnProperty('regionId')) {
        delete (window as any).silo.filters.regionId;
      }
      // if the silo has no region, use  the injected region
      let silo = JSON.parse((window as any).silo);
      if (silo.filters && !silo.filters.regionId && this.globalData.selectedFilters && this.globalData.selectedFilters.regionId) {
        silo.filters.regionId = this.globalData.selectedFilters.regionId;
      }
      // if still no region show initialFilterDialog again
      if (!silo.filters.regionId) {
        this.globalData.applicationState.showInitialFilterDialog = true;
      } else {
        // else extend the filters
        this.globalData.silo = silo;
        _.extend(this.globalData.selectedFilters, this.globalData.silo.filters);
        this.globalData.silo.filters = _.extend(new DpsFilters(), JSON.parse(JSON.stringify(this.globalData.selectedFilters)));
        this.gradesService.getData(() => {
          if (this.globalData.data.grades && this.globalData.data.grades.length > 0) {
            this.globalData.silo.hasMultiplePropertyTypes = this.globalData.hasOtherPropertyType;
            if (this.globalData.silo.hasMultiplePropertyTypes) {
              // open properties dialog
              this.globalData.data = null;
              this.globalData.applicationState.showPropertyTypeSelection = true;
            }
          } else {
            this.globalData.data = null;
            this.globalData.applicationState.showInitialFilterDialog = true;
          }
        });
        this.router.navigate(['/datatable/landing'], { replaceUrl: true });
      }
    } else if (this.router.url.indexOf('/search') > -1) {
      this.globalData.applicationState.showInitialFilterDialog = false;
    }
  }

  addToFavorites() {
    this._favoritesService.addToFavorites(this.router.url);

    // analytics
    if (this.router.url.indexOf('grade-detail') > -1) {
      this._analyticsService.sendEvent(this._analyticsEventsService.getEvent(EventTypes.GRADE_DETAIL_FAVORITE, this.globalData.gradeDetail.name), new CustomData({ 'prod_selector_fave_prod': this.globalData.gradeDetail.name }), new PageUrlData('/grade-detail/' + this.globalData.gradeDetail.id), new PageData(PageType.Detail))
    } else if (this.router.url === '/plotchart') {
      this._analyticsService.sendEvent(this._analyticsEventsService.getEvent(EventTypes.XY_CHART_FAVORITE), this.globalData.getSelectedPropertiesAnalyticsValue());
    } else if (this.router.url === '/radarchart') {
      this._analyticsService.sendEvent(this._analyticsEventsService.getEvent(EventTypes.SPIDER_CHART_FAVORITE), this.globalData.getSelectedGradesAnalyticsValue());
    } else if (this.router.url.indexOf('/datatable') > -1) {
      this._analyticsService.sendEvent(this._analyticsEventsService.getEvent(EventTypes.DATATABLE_FAVORITE), this.globalData.title)
    }
  }

  removeFromFavorites(favorite) {
    this._favoritesService.removeFromFavorites(favorite);
  }

  closeNavBar() {
    this.navigationFull = false;
  }

  toggleNavBar(event) {
    event.stopPropagation();
    this.navigationFull = !this.navigationFull;
    if (this.navigationFull) {
      this.globalData.applicationState.mobileActionsMenu = false;
      this.globalData.applicationState.showShareDialog = false;
    }
  }

  enterNavBar() {
    if (window.innerWidth > this.tabletBreakpoint) {
      this.navigationFull = true;
    }
  }

  leaveNavBar() {
    if (window.innerWidth > this.tabletBreakpoint) {
      this.navigationFull = false;
    }
  }

  toggleFullScreen() {
    let body = document.querySelector('body');
    let html = document.querySelector('html');
    if (this.globalData.applicationState.expandApplication) {
      this.globalData.applicationState.expandApplication = false;
      body.style.overflow = 'auto';
      html.style.overflow = 'auto';
    } else {
      this.globalData.applicationState.expandApplication = true;
      body.style.overflow = 'hidden';
      html.style.overflow = 'hidden';
      console.log(document.documentElement.clientHeight);
      this.containerHeight = document.documentElement.clientHeight + 'px';
    }
    
    // fix scroll issue when going into fullscreen on mobile (can't see last item)
    if(this.router.url.indexOf('datatable') > -1) {
      this.gradesService.getData();
    }
  }

  openShareDialog() {
    this.globalData.applicationState.showShareDialog = true;

    // analytics
    if (this.router.url.indexOf('grade-detail') > -1) {
      this._analyticsService.sendEvent(this._analyticsEventsService.getEvent(EventTypes.GRADE_DETAIL_SHARE), this.globalData.gradeDetail.name);
    } else if (this.router.url === '/plotchart') {
      this._analyticsService.sendEvent(this._analyticsEventsService.getEvent(EventTypes.XY_CHART_SHARE), this.globalData.getSelectedPropertiesAnalyticsValue());
    } else if (this.router.url === '/radarchart') {
      this._analyticsService.sendEvent(this._analyticsEventsService.getEvent(EventTypes.SPIDER_CHART_SHARE), this.globalData.getSelectedGradesAnalyticsValue());
    } else if (this.router.url.indexOf('/datatable') > -1) {
      this._analyticsService.sendEvent(this._analyticsEventsService.getEvent(EventTypes.DATATABLE_SHARE), this.globalData.title, new PageUrlData('/datatable/landing'), new PageData(PageType.Selector));
    }
  }

  listenForSilo() {
    let ctrlDown = false,
      altDown = false,
      ctrlKey = 17,
      cmdKey = 91,
      altKey = 16,
      sKey = 83;

    document.addEventListener('keydown', (e) => {
      if (e.keyCode === ctrlKey || e.keyCode === cmdKey) {
        ctrlDown = true;
      }
      if (e.keyCode === altKey) {
        altDown = true;
      }
      if (e.keyCode === sKey && ctrlDown && altDown) {
        this.globalData.applicationState.showSiloDialog = true;
      }
    });

    document.addEventListener('keyup', (e) => {
      if (e.keyCode === ctrlKey || e.keyCode === cmdKey) {
        ctrlDown = false;
      }
      if (e.keyCode === altKey) {
        altDown = false;
      }
    });
  }

  switchPropertyType() {
    this.globalData.applicationState.applicationActive = false;
    this.globalData.restoreDefaults(true);
    this.gradesService.getData();
    this._favoritesService.updateCurrentFavorite();

    if (this.router.url.indexOf('/datatable') === -1) {
      this.router.navigateByUrl('/datatable');
    }
  }

  switchRegion() {
    this.globalData.restoreDefaults(true);
    this.gradesService.getData();
    this._favoritesService.updateCurrentFavorite();

    if (this.router.url.indexOf('/datatable') === -1) {
      this.router.navigateByUrl('/datatable');
    }
  }

  switchUnitSystem(unitSystem) {
    this.globalData.unitSystem = unitSystem;
    this.globalData.restoreDefaults(true);
    this.gradesService.getData();
    this._favoritesService.updateCurrentFavorite();

    if (this.router.url.indexOf('/datatable') === -1) {
      this.router.navigateByUrl('/datatable');
    }
  }

  findById(a, b) {
    return a.id === b.id;
  }

  goBack() {
    if (this.globalData.gradeDetail) {
      this.router.navigateByUrl(this.globalData.gradeDetail.previousUrl);
    } else {
      this._location.back();
    }
  }

  doExport() {
    if (this.router.url.indexOf('/grade-detail') === -1) {
      this.globalData.applicationState.showExportDialog = true;
      // analytics
      if (this.router.url === '/plotchart') {
        this._analyticsService.sendEvent(this._analyticsEventsService.getEvent(EventTypes.XY_CHART_DOWNLOAD), this.globalData.getSelectedPropertiesAnalyticsValue());
      } else if (this.router.url === '/radarchart') {
        this._analyticsService.sendEvent(this._analyticsEventsService.getEvent(EventTypes.SPIDER_CHART_DOWNLOAD), this.globalData.getSelectedGradesAnalyticsValue());
      } else if (this.router.url.indexOf('/datatable') > -1) {
        this._analyticsService.sendEvent(this._analyticsEventsService.getEvent(EventTypes.DATATABLE_DOWNLOAD), this.globalData.title);
      }
    } else {
      this.downloadPdf();
    }
  }

  downloadPdf() {
    if (this.globalData.gradeDetail && this.globalData.gradeDetail.url) {
      let a = document.createElement('a');
      a.href = this.globalData.gradeDetail.url;
      a.download = this.globalData.gradeDetail.url.split('/').pop();
      a.click();

      this.sendGradeDownloadToAnalytics();
      this._analyticsService.sendEvent(this._analyticsEventsService.getEvent(EventTypes.GRADE_DETAIL_DOWNLOAD), this.globalData.gradeDetail.name);
    }
  }

  private sendGradeDownloadToAnalytics() {
    let filters = this.globalData.getFiltersForAnalytics();
    let customData = new CustomData();
    let grade = this.globalData.gradeDetail;
    let event = this._analyticsEventsService.getEvent(EventTypes.GRADE_DETAIL_DOWNLOAD_GA, grade.name);
    customData.addProperty(AnalyticsConstants.GRADE_DETAIL_DOWNLOAD_GA.Fields.FilterList, filters);
    customData.addProperty(AnalyticsConstants.GRADE_DETAIL_DOWNLOAD_GA.Fields.DocumentTitle, grade.name);
    customData.addProperty(AnalyticsConstants.GRADE_DETAIL_DOWNLOAD_GA.Fields.DocumentType, 'pdf');
    this._analyticsService.sendEvent(event, customData, new PageUrlData('/grade-detail/' + grade.id), new PageData(PageType.Detail));
  }

  logHelpAnalytics(type) {
    let event;
    switch (type) {
      case 'guidedtour':
        event = this._analyticsEventsService.getEvent(EventTypes.HELP_GUIDED_TOUR);
        break;
      case 'promotionalvideo':
        event = this._analyticsEventsService.getEvent(EventTypes.HELP_PROMOTIONAL_VIDEO);
        break;
      case 'contactus':
        event = this._analyticsEventsService.getEvent(EventTypes.HELP_CONTACT_US);
        break;
    }
    this._analyticsService.sendEvent(event, null);
  }

  startOver() {
    this.globalData.applicationState.showInitialFilterDialog = true;
  }

  getEventPath(event) {
    if (event.path) {
      return event.path;
    }
    let path = [];
    let target = event.target;
    while (target.parentNode) {
      path.push(target);
      target = target.parentNode;
    }
    path.push(document, window);
    return path;
  }

  mainClick(event) {
    let path = this.getEventPath(event);
    if (this.globalData.applicationState.regionsSidebarOpen
      && path.indexOf(document.querySelector('.regions-sidebar')) === -1
      && path.indexOf(document.querySelector('.regions-link')) === -1) {
      this.globalData.applicationState.regionsSidebarOpen = false;
    } else if (this.globalData.applicationState.helpSidebarOpen
      && path.indexOf(document.querySelector('.help-sidebar')) === -1
      && path.indexOf(document.querySelector('.help-link')) === -1) {
      this.globalData.applicationState.helpSidebarOpen = false;
    }
  }

  onHelpButtonClicked() {
    this._analyticsService.sendEvent(this._analyticsEventsService.getEvent(EventTypes.HELP_BUTTON_SELECTED), null, new PageUrlData('/datatable/landing'), new PageData(PageType.Selector))
  }

  onPlotChartClicked() {
    this._analyticsService.sendEvent(this._analyticsEventsService.getEvent(EventTypes.XY_CHART_SELECTED), new CustomData(), new PageUrlData('/plotchart'), new PageData(PageType.Selector))
  }
}
