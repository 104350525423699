import { DpsFilters } from './dpsFilters';
import { _ } from 'underscore';

export class DpsShareModel {
  // path
  p: string;
  // filters
  f: DpsShareFilter;
  // property type
  pt: string;
  // selections
  se?: {
    g?: {
      s: string,
      c: string
    }[],
    p?: string[],
    rp?: string[]
  };
  // unit system
  us: string;
  // sorting
  so?: {
    // sortProperty
    sp?: string,
    // sortOrder
    so?: string,
    // sortApplication
    sa?: string,
    // sortApplicationOrder
    sao?: string
  };
  // silo
  si: {
    // exclude
    e: string[],
    // filters
    f: DpsShareFilter;
    // hasMultiplePropertyTypes
    h: boolean
  };

  constructor(encodedString?: string) {
    if (encodedString) {
      _.extend(this, this.decode(encodedString));
      let filter = this.f;
      this.f = new DpsShareFilter();
      _.extend(this.f, filter);
      if (this.si) {
        let siloFilter = this.si.f;
        this.si.f = new DpsShareFilter();
        _.extend(this.si.f, siloFilter);
      }
    }
  }

  decode(encodedUrl: string) {
    return JSON.parse(decodeURIComponent(window.atob(decodeURIComponent(encodedUrl))));
  }

  encode() {
    return encodeURIComponent(window.btoa(encodeURIComponent(JSON.stringify(this))));
  }
}

export class DpsShareFilter {
  // region
  r: number;
  // businessunits
  bu: number[];
  // industries
  i: number[];
  // applications
  a: number[];
  // product types
  pt: number[];
  // brands
  b: number[];
  // market segments
  m: string[];
  // global properties
  gp: {
    id: string,
    s?: boolean,
    min?: number,
    max?: number,
    t?: string[]
  }[];
  // sales properties
  sp: {
    id: string,
    s?: boolean,
    t?: string[],
    minR?: {
      min?: number,
      max?: number
    },
    maxR?: {
      min?: number,
      max?: number
    },
    tarR?: {
      min?: number,
      max?: number
    }
  }[];

  assignFilter(name, value) {
    if (this.filterApplicable(value)) {
      this[name] = value;
    }

  }

  private filterApplicable(value) {
    if (value) {
      if (value.hasOwnProperty('length')) {
        return value.length > 0;
      }

      return true;
    }

    return false;
  }

  toDpsFilters() {
    let result = new DpsFilters();
    result.regionId = this.r;
    result.businessUnitIds = this.bu ? this.bu : [];
    result.industryIds = this.i ? this.i : [];
    result.applicationIds = this.a ? this.a : [];
    result.productTypeIds = this.pt ? this.pt : [];
    result.brandIds = this.b ? this.b : [];
    result.marketSegmentIds = this.m ? this.m : [];
    let propertyFiltersArray = _.map(this.gp, (p) => {
      return {
        id: p.id,
        selected: p.s,
        minimum: p.min,
        maximum: p.max,
        textValues: p.t
      };
    });
    result.propertyFilters = _.reduce(propertyFiltersArray, (memo, item) => {
      memo[item.id] = item;
      return memo;
    }, {});
    let salesPropertyFiltersArray = _.map(this.sp, (p) => {
      return {
        id: p.id,
        selected: p.s,
        textValues: p.t,
        salesRange: {
          minimumRange: (p.minR ? {
            minimum: p.minR.min,
            maximum: p.minR.max
          } : {}),
          maximumRange: (p.maxR ? {
            minimum: p.maxR.min,
            maximum: p.maxR.max
          } : {}),
          targetRange: (p.tarR ? {
            minimum: p.tarR.min,
            maximum: p.tarR.max
          } : {}),
        }
      };
    });
    result.salesPropertyFilters = _.reduce(salesPropertyFiltersArray, (memo, item) => {
      memo[item.id] = item;
      return memo;
    }, {});
    return result;
  }
}
