import { LinkComponent } from './link/link.component';
import { LinkModule } from './link/link.module';
import { AppQueryParamAuthGuardService } from './app-query-param-auth-guard.service';
import { FormsModule } from '@angular/forms';
import { DatatableApplicationsComponent } from './datatable/datatable-applications/datatable-applications.component';
import { GradeDetailModule } from './grade-detail/grade-detail.module';
import { GradeDetailComponent } from './grade-detail/grade-detail.component';
import { GradeDetailProductDatasheetComponent } from './grade-detail/grade-detail-product-datasheet/grade-detail-product-datasheet.component';
import { GradeDetailRelatedComponent } from './grade-detail/grade-detail-related/grade-detail-related.component';
import { DpsRouteReuseStrategy } from './app-route-reuse-strategy';
import { ServicesModule } from './general/services/services.module';
import { DialogsModule } from './dialogs/dialogs.module';
import { PipesModule } from './general/pipes/pipes.module';
import { SearchModule } from './search/search.module';
import { FavoritesModule } from './favorites/favorites.module';
import { RadarChartModule } from './radar-chart/radar-chart.module';
import { PlotChartModule } from './plot-chart/plot-chart.module';
import { DatatableModule } from './datatable/datatable.module';
import { MatIconModule } from '@angular/material/icon';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { NgModule } from '@angular/core';
import { FavoritesComponent } from './favorites/favorites.component';
import { RadarChartSidebarToggleComponent } from './radar-chart/radar-chart-sidebar-toggle/radar-chart-sidebar-toggle.component';
import { RadarChartComponent } from './radar-chart/radar-chart.component';
import { PlotChartSidebarToggleComponent } from './plot-chart/plot-chart-sidebar-toggle/plot-chart-sidebar-toggle.component';
import { PlotChartComponent } from './plot-chart/plot-chart.component';
import { AppAuthGuardService } from './app-auth-guard.service';
import { SearchComponent } from './search/search.component';
import { DatatableSidebarToggleComponent } from './datatable/datatable-sidebar-toggle/datatable-sidebar-toggle.component';
import { DatatableComponent } from './datatable/datatable.component';
import { Routes, RouterModule, RouteReuseStrategy } from '@angular/router';
import { UiModule } from './general/ui/ui.module';
import { GradeDetailActionsComponent } from './grade-detail/grade-detail-actions/grade-detail-actions.component';
import { DatatablePropertiesComponent } from './datatable/datatable-properties/datatable-properties.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { DatatableTestMethodsComponent } from './datatable/datatable-test-methods/datatable-test-methods.component';
import { HomeComponent } from './home/home.component';
import { ExportHtmlComponent } from './export-html/export-html.component';

const routes: Routes = [
  // { path: '**',   redirectTo: '/datatable' },
  {
    path: '',
    component: HomeComponent,
    canActivate: [AppQueryParamAuthGuardService],
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: '',
        redirectTo: 'datatable/landing',
        pathMatch: 'full',
      },
      {
        path: 'datatable',
        children: [
          {
            path: '',
            component: DatatableComponent,
            children: [
              {
                path: 'landing',
                component: DatatablePropertiesComponent
              },
              {
                path: '',
                component: DatatablePropertiesComponent
              },
              {
                path: 'applications',
                component: DatatableApplicationsComponent
              },
              {
                path: 'testmethods',
                component: DatatableTestMethodsComponent
              }
            ],
          },
          { path: '', component: DatatableSidebarToggleComponent, outlet: 'sidebar-toggle' }
        ]
      },
      {
        path: 'grade-detail/:id',
        canActivate: [AppAuthGuardService],
        children: [
          {
            path: '',
            component: GradeDetailComponent,
            children: [
              {
                path: '',
                component: GradeDetailProductDatasheetComponent
              },
              {
                path: 'related/:tabId',
                component: GradeDetailRelatedComponent
              }
            ],
          },
          { path: '', component: GradeDetailActionsComponent, outlet: 'navbar-right' },
          { path: '', component: GradeDetailActionsComponent, outlet: 'navbar-right-mobile' },
        ]
      },
      {
        path: 'industry',
        component: DatatablePropertiesComponent
      },
      {
        path: 'search',
        canActivate: [AppAuthGuardService],
        component: SearchComponent,
      },
      {
        path: 'plotchart',
        canActivate: [AppAuthGuardService],
        children: [
          { path: '', component: PlotChartComponent },
          { path: '', component: PlotChartSidebarToggleComponent, outlet: 'sidebar-toggle' }
        ]
      },
      {
        path: 'radarchart',
        canActivate: [AppAuthGuardService],
        children: [
          { path: '', component: RadarChartComponent },
          { path: '', component: RadarChartSidebarToggleComponent, outlet: 'sidebar-toggle' }
        ]
      },
      {
        path: 'favorites',
        canActivate: [AppAuthGuardService],
        component: FavoritesComponent,
      }
    ]
  },
  {
    path: 'index',
    redirectTo: ''
  },
  {
    path: 'export',
    component: ExportHtmlComponent
  },
  {
    path: 'link/:link',
    component: LinkComponent,
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: true, onSameUrlNavigation: 'reload' }),
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    DatatableModule,
    PlotChartModule,
    RadarChartModule,
    FavoritesModule,
    SearchModule,
    LinkModule,
    UiModule,
    DialogsModule,
    MatIconModule,
    ServicesModule,
    GradeDetailModule,
    NgSelectModule,
    FormsModule,
    PipesModule
  ],
  declarations: [
    AppComponent,
    HomeComponent,
    ExportHtmlComponent
  ],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: DpsRouteReuseStrategy
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
