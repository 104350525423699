export class AnalyticsSpecification {
    Action?: string;
    Event?: string;
    Goal?: string = '';
    Fields?: any;
}

export class AnalyticsConstants {

    //#region GA + SITECORE

    //Product selector, adding product to favorites
    public static GRADE_DETAIL_FAVORITE: AnalyticsSpecification = {
        Action: 'Product Selector Added to Favorites',
        Event: 'gaCustomEvents_prod_selector_fave',
        Goal: '{8F93044D-8711-4834-B7BD-B3E646690264}'
    }

    //User selects the type of data they would like displayed in the product selector
    public static DATA_SET_SELECTION: AnalyticsSpecification = {
        Action: 'Product Selector Chose Splashscreen Specification Type',
        Event: 'gaCustomEvents_prod_selector_data_type'
    }

    //User shares the product selector 
    public static DATATABLE_SHARE: AnalyticsSpecification = {
        Action: 'Product Selector Clicked Social Share Button',
        Event: 'gaCustomEvents_prod_selector_share',
        Goal: '{9D788204-2E7D-4891-A8B0-48DFA5CE4320}'
    }

    //User selects the "Help" button in the left-nav of the product selector
    public static HELP_BUTTON_SELECTED: AnalyticsSpecification = {
        Action: 'Product Selector Clicked Help Button',
        Event: 'gaCustomEvents_prod_selector_help_request',
    }

    public static GRADE_DETAIL_REQUEST_A_SAMPLE: AnalyticsSpecification = {
        Action: 'Product Selector Sample Requested',
        Event: 'gaCustomEvents_prod_selector_sample_request',
        Goal: '{35D8C974-2497-4780-8DAD-E4ACBD517F13}'
    }

    public static EXPLORE_FILTER_CHOICE: AnalyticsSpecification = {
        Action: 'Product Selector Chose Splashscreen Filter',
        Event: 'gaCustomEvents_prod_selector_splash'
    }

    public static EXPLORE_REGION: AnalyticsSpecification = {
        Action: 'Product Selector Chose Splashscreen Region',
        Event: 'gaCustomEvents_prod_selector_splash'
    }

    public static XY_CHART_SELECTED: AnalyticsSpecification = {
        Action: 'Product Selector Chose X/Y Comparison Tool',
        Event: 'gaCustomEvents_prod_selector_xy'
    }

    public static XY_CHART_XY_SELECTED: AnalyticsSpecification = {
        Action: 'Product Selector Chose Properties in X/Y Product Selector',
        Event: 'gaCustomEvents_prod_selector_xy'
    }

    public static GRADE_DETAIL_DOWNLOAD_GA: AnalyticsSpecification = {
        // Action: 'Product Selector Clicked Submit Single Product PDF',
        Action: 'Document Downloaded',
        Event: 'gaCustomEvents_prod_selector_pdf_export',
        Fields: {
            FilterList: 'prod_selector_pdf_export_filter_list',
            DocumentTitle: 'document_title',
            DocumentType: 'document_type'
        }
    }

    public static GLOBAL_EXPORT_GA: AnalyticsSpecification = {
        Action: 'Product Selector Exported Single Product PDF',
        Event: 'gaCustomEvents_prod_selector_pdf_export',
        Fields: {
            FilterList: 'prod_selector_pdf_export_filter_list',
            DocumentTitle: 'document_title',
            DocumentType: 'document_type'
        }
    }

    //#endregion

    //#region SITE CORE

    public static EXPLORE_CLICK: AnalyticsSpecification = {
        Goal: '{14AC93EB-6FC2-4BD1-B9DA-D9A7E16B717B}'
    }

    public static DATATABLE_SORT_PROPERTY: AnalyticsSpecification = {
        Goal: '{CF8759CA-6ABF-413F-8A16-10F3D7B75567}'
    }

    public static DATATABLE_ADD_TO_XY: AnalyticsSpecification = {
        Goal: '{F81D319A-3A71-48B8-8A81-DF92FDCBAC34}'
    }

    public static DATATABLE_ADD_TO_SPIDER: AnalyticsSpecification = {
        Goal: '{65272FF9-51B9-4E4A-96B8-7915CE4ACB6E}'
    }

    public static DATATABLE_SORT_APPLICATION: AnalyticsSpecification = {
        Goal: '{45A163D8-C23F-49CF-8AE5-85DC0DEDDE43}'
    }

    public static DATATABLE_APPLICATIONS_TAB: AnalyticsSpecification = {
        Goal: '{7D647786-3170-4625-9BC9-8837B8072FCD}'
    }

    public static DATATABLE_PROPERTIES_TAB: AnalyticsSpecification = {
        Goal: '{E7DE4067-64B3-4373-A486-C45B0CB2DF69}'
    }

    public static DATATABLE_TESTMETHODS_TAB: AnalyticsSpecification = {
        Goal: '{A73DDF00-066A-4FB2-A4AD-B71386BB312C}'
    }

    public static DATATABLE_FAVORITE: AnalyticsSpecification = {
        Goal: '{E01C6929-AF5E-40BA-95F5-DCFC48257753}'
    }

    public static DATATABLE_DOWNLOAD: AnalyticsSpecification = {
        Goal: '{824BEEAF-55E6-425A-AD1F-E7AB88B4FB01}'
    }

    public static FILTER_REGION: AnalyticsSpecification = {
        Goal: '{03409200-9AA9-4316-ABE4-CA9AE4F6124A}'
    }

    public static FILTER_UNIT_SYSTEM: AnalyticsSpecification = {
        Goal: '{7FCE60D9-FE20-4F3D-AEAD-DA3BFFEF80E7}'
    }

    public static FILTER_BUSINESS_UNIT: AnalyticsSpecification = {
        Goal: '{6E8EB402-C6B1-476B-9FF3-C0EAB4EE88F7}'
    }

    public static FILTER_PRODUCT_TYPE: AnalyticsSpecification = {
        Goal: '{CCD05D6E-8FBB-43E5-A9CF-1B15D32956C7}'
    }

    public static FILTER_APPLICATION: AnalyticsSpecification = {
        Goal: '{C83DA394-ABD5-41B0-9EAC-9B6188C3D16A}'
    }

    public static FILTER_BRAND: AnalyticsSpecification = {
        Goal: '{7182A8AE-3F24-45C5-8582-71AB17D71D2F}'
    }

    public static FILTER_INDUSTRY: AnalyticsSpecification = {
        Goal: '{D7C623E7-CB49-4DD9-A917-A563DB29B11A}'
    }

    public static FILTER_PROPERTY: AnalyticsSpecification = {
        Goal: '{30F0BE4C-EC67-4B0D-B77C-97FD92509FE4}'
    }

    public static FILTER_REFINED_PROPERTY: AnalyticsSpecification = {
        Goal: '{A32B8F4A-A32C-42CB-9F8D-6C362B3DE412}'
    }

    public static GRADE_DETAIL_ADD_TO_SPIDER: AnalyticsSpecification = {
        Goal: '{C96EDE59-F4F2-430B-8F8D-8D185A1ACB2B}'
    }

    public static GRADE_DETAIL_SHARE: AnalyticsSpecification = {
        Goal: '{39EF1313-D549-4024-BC68-8869B74AA49C}'
    }

    public static GRADE_DETAIL_DOWNLOAD: AnalyticsSpecification = {
        Goal: '{14BC805F-A7E4-421E-BDC1-B255C591BC2E}'
    }

    public static XY_CHART_ADD_PROPERTY: AnalyticsSpecification = {
        Goal: '{77DFC97B-1F98-424E-B787-622511A60D35}'
    }

    public static XY_CHART_SWITCH_AXES: AnalyticsSpecification = {
        Goal: '{C2DA09FB-6FF5-428C-962C-68D0089C3F6A}'
    }

    public static XY_CHART_DOWNLOAD: AnalyticsSpecification = {
        Goal: '{386B62D4-7A1D-4761-89DE-6A56EACD5C71}'
    }

    public static XY_CHART_SHARE: AnalyticsSpecification = {
        Goal: '{C5B10073-579D-4BD2-9AE6-3EED85FFFF2B}'
    }

    public static XY_CHART_FAVORITE: AnalyticsSpecification = {
        Goal: '{35ADBAE1-2B74-41FF-8725-56EEF15C5916}'
    }

    public static SPIDER_CHART_ADD_GRADE: AnalyticsSpecification = {
        Goal: '{A3642151-6612-440B-A175-C1EF28C17F01}'
    }

    public static SPIDER_CHART_DOWNLOAD: AnalyticsSpecification = {
        Goal: '{E11FBA73-C959-4C75-84E6-B16B737EEBB0}'
    }

    public static SPIDER_CHART_SHARE: AnalyticsSpecification = {
        Goal: '{B90C4FBE-3A02-4F6B-8636-4F54CCAD7DF4}'
    }

    public static SPIDER_CHART_FAVORITE: AnalyticsSpecification = {
        Goal: '{C8663DC0-1B6E-4A71-9BF3-3C654D6B314A}'
    }

    public static SEARCH_SUCCESS: AnalyticsSpecification = {
        Goal: '{2A70D0D5-60CB-457D-AD2E-B8D666FBA325}'
    }

    public static SEARCH_FAIL: AnalyticsSpecification = {
        Goal: '{4E415C1E-3709-4F1D-811F-1E3D6DBF5A78}'
    }

    public static SEARCH_CLICK: AnalyticsSpecification = {
        Goal: '{ED33CFB0-AC71-43AE-AE7B-98EB09A3E1A2}'
    }

    public static SHARE_LINKEDIN: AnalyticsSpecification = {
        Goal: '{6E57BECE-D874-469F-9C38-34500A95156B}'
    }

    public static SHARE_SOCIAL_X: AnalyticsSpecification = {
        Goal: '{5B282AD3-A04C-4A0B-9CB8-063F40963C27}'
    }

    public static SHARE_EMAIL: AnalyticsSpecification = {
        Goal: '{8547CC4C-E9C7-4A41-AF70-A02BFA5B5880}'
    }

    public static SHARE_WECHAT: AnalyticsSpecification = {
        Goal: '{D900D69F-3236-472C-A225-7C93088172A5}'
    }

    public static GLOBAL_REQUEST_A_SAMPLE: AnalyticsSpecification = {
        Goal: '{D4828D9A-CACD-4F45-9B83-EDE1C96E683B}'
    }

    public static GLOBAL_EXPORT: AnalyticsSpecification = {
        Goal: '{E8CF9135-377D-4DE1-9E67-35A55685197E}'
    }

    public static HELP_GUIDED_TOUR: AnalyticsSpecification = {
        Goal: '{C47825C4-A6E3-43A6-9847-B75FE712F286}'
    }

    public static HELP_PROMOTIONAL_VIDEO: AnalyticsSpecification = {
        Goal: '{DD138ABE-804B-4E8F-ABDB-C2D4581F01AB}'
    }

    public static HELP_CONTACT_US: AnalyticsSpecification = {
        Goal: '{BCB6D89F-DA8D-416E-8071-705C43DF76E8}'
    }

    //#endregion

    //#region HTML tags
    public static MetaDefaults = {
        page_bu: 'chemical'
    };
    public static BusinessUnitTag = "page_bu";
    public static DataLayerTag = "dataLayer";
    public static GTag = "gtag";
    public static MetaNameProperty = 'name=';
    //#endregion

    //#region Default constants
    public static GAPageView = 'gaCustomEvents_prod_selector_virtual_pageview';
    public static GTriggerEvent = 'event';
    public static GTriggerConfig = 'config';
    public static DefaultPageTitle = "Digital Product Selector";
    //#endregion
}