<div class="share-content">
  <h2>Share</h2>
  <button>
    <mat-icon (click)="closeShareDialog()" svgIcon="close"></mat-icon>
  </button>
  <div class="linkedin">
    <a (click)="shareOnLinkedIn()">
      <mat-icon svgIcon="linkedin"></mat-icon>
      <span i18n="Title of linkedin icon in the mobile menu actions sidebar@@iconLinkedin">Linkedin</span>
    </a>
  </div>
  <div class="social-x">
    <a (click)="shareOnSocialX()">
      <mat-icon svgIcon="social-x"></mat-icon>
      <span i18n="Title of social x icon in the mobile menu actions sidebar@@iconSocialX">X</span>
    </a>
  </div>
  <div class="email">
    <a i18n-href="Subject and body of the share email@@shareEmailSubjectBody"
      href="mailto:?subject=Interesting information&body=I thought you might find this information interesting: {{ getShareLink() }}" (click)="shareByEmail()">
      <mat-icon svgIcon="email"></mat-icon>
      <span i18n="Title of email icon in the mobile menu actions sidebar@@iconEmail">Email</span>
    </a>
  </div>
  <div class="we-chat" *ngIf="globalData.lang === 'zh'">
    <a (click)="shareOnWeChat()">
      <mat-icon svgIcon="wechat"></mat-icon>
      <span i18n="Title of wechat icon in the mobile menu actions sidebar@@iconWechat">WeChat</span>
    </a>
  </div>
  <!-- <button (click)="getShareLink()">SHARE</button> -->
</div>
