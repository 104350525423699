import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrightcoveLightboxComponent } from './brightcove-lightbox/brightcove-lightbox.component';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule
  ],
  declarations: [
    LoadingSpinnerComponent,
    BrightcoveLightboxComponent
  ],
  exports: [
    LoadingSpinnerComponent,
    BrightcoveLightboxComponent
  ]
})
export class UiModule { }
