import { DpsGrade } from './../../models/data/dpsGrade';
import { Pipe, PipeTransform } from '@angular/core';
import { _ } from 'underscore';

@Pipe({
  name: 'filterPlotChartGrades'
})
export class FilterPlotChartGradesPipe implements PipeTransform {
  transform(grades: DpsGrade[], selectedProperties: any[], gradeProperties: any): DpsGrade[] {
    if (!selectedProperties || selectedProperties.length < 2) {
      return [];
    }

    let result = _.filter(grades, (g) => {
      return gradeProperties.hasOwnProperty(g.substance)
        && gradeProperties[g.substance].hasOwnProperty(selectedProperties[0].id)
        && gradeProperties[g.substance].hasOwnProperty(selectedProperties[1].id);
    });
    return result;
  }
}
