import { Pipe, PipeTransform } from '@angular/core';
import { _ } from 'underscore';

@Pipe({
  name: 'filterOut'
})
export class FilterOutPipe implements PipeTransform {

  transform(value: any, filter: any): any {
    let result = _.difference(value, filter);
    return result;
  }
}
