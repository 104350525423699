<div class="table" dpsStickyTable infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="25"
  infiniteScrollContainer=".table-body" (scrolled)="onScroll($event)" *ngIf="gradeProperties" [rows]="grades"
  [columns]="properties" horizontal="true">
  <ng-template #noValue>
    <div i18n="Datatable no value@@datatableNoValue">N/A</div>
  </ng-template>
  <div class="table-header">
    <div class=table-header-left>
      <a class="restore-default-link" (click)="restoreDefaults()" *ngIf="globalData.showRestoreDefaults()"
        i18n="Clear all label in the datatable filters sidebar@@clearAllFilters">clear all</a>
      <div *ngIf="globalData.hasNoPlotChartResults()" class="no-results"
        i18n="No selection results label in datatable header left@@selectionHasNoResults">This selection has no results.
      </div>
    </div>
    <div class=table-header-center>
      <ng-container *ngFor="let property of properties; trackBy: trackByIndex;">
        <div class="table-header-cell" [ngClass]="{ sorted: sortProperty === property }">
          <div *ngIf="property">
            <div class="name-wrapper">
              <a class="name" (click)="toggleSort(property)" [title]="property.name">
                {{property.name}}
                <div class="sort">
                  <mat-icon *ngIf="sortProperty == property" [svgIcon]=sortOrder></mat-icon>
                  <mat-icon *ngIf="sortProperty !== property" class="ascending" svgIcon="asc"></mat-icon>
                  <mat-icon *ngIf="sortProperty !== property" class="descending" svgIcon="desc"></mat-icon>
                </div>
              </a>
            </div>
            <div class="unit-of-measure">{{property.unitOfMeasure}}</div>
            <label
              *ngIf="(!property.textValues || property.textValues.length === 0) && selectedProperties && selectedProperties.indexOf(property) === -1"
              i18n-pTooltip="Datatable add to xy chart tooltip@@datatableAddToXYChart" pTooltip="Add to X/Y comparison"
              showDelay="300" [tooltipDisabled]="isTouchDevice()" tooltipPosition="bottom" class="icon-checkbox">
              <input type="checkbox" [checked]="selectedProperties && selectedProperties.indexOf(property) > -1"
                (change)="togglePropertySelection(property)">
              <mat-icon class="empty" svgIcon="x-y-toggler"></mat-icon>
            </label>
            <label
              *ngIf="(!property.textValues || property.textValues.length === 0) && selectedProperties && selectedProperties.indexOf(property) > -1"
              i18n-pTooltip="Datatable remove from xy chart tooltip@@datatableRemoveFromXYChart"
              pTooltip="Remove from X/Y comparison" showDelay="300" [tooltipDisabled]="isTouchDevice()"
              tooltipPosition="bottom" class="icon-checkbox"
              [ngClass]="{ activate: globalData.selectedProperties.length === 2 && selectedProperties.indexOf(property) === 1 && propertiesActivated}">
              <input type="checkbox" [checked]="true" (change)="togglePropertySelection(property)">
              <mat-icon class="added" svgIcon="x-y-toggler"></mat-icon>
            </label>
          </div>
        </div>
      </ng-container>
    </div>
    <div class=table-header-right>
      <a class="mobile-grades-bar-toggler"
        (click)="globalData.applicationState.gradesSelectorBarOpen = !globalData.applicationState.gradesSelectorBarOpen"
        [ngClass]="{ open: globalData.applicationState.gradesSelectorBarOpen, active: selectedGrades.length > 0 }">
        <mat-icon svgIcon="polygon-menu"></mat-icon>
      </a>
    </div>
  </div>
  <div class="table-body" #tableBody>
    <virtual-scroller #scroll
      [items]="grades | sortSalesGrades: sortProperty: sortOrder: gradeProperties: productTypes: brands"
      [parentScroll]="tableBody" (vsUpdate)="viewPortItems = $event">
      <div class="table-row" *ngFor="let grade of scroll.viewPortItems; let i = index; trackBy: trackByIndex;"
        [ngClass]="{
          odd: (i + scroll.viewPortInfo.startIndex)%2 !== 0,
          even: (i + scroll.viewPortInfo.startIndex)%2 === 0,
          'product-type': grade.type === 'productType',
          'trade-name': grade.type === 'idesFamily' }">
        <div class="table-cell sample-cell"
          [ngClass]="{ 'hide': grade.type === 'productType' || grade.type === 'idesFamily' }">
          <a target="_blank" (click)="globalData.requestASample(grade)"
            [href]="requestASampleUrl + '?dpsid=' + grade.substance" class="sample" pTooltip="Request a sample"
            showDelay="300" i18n-pTooltip="Datatable request a sample icon@@datatableRequestASample"
            [tooltipDisabled]="isTouchDevice()" tooltipPosition="right" *ngIf="!grade.type">
            <mat-icon svgIcon="sample"></mat-icon>
          </a>
        </div>
        <div class="table-cell"
          *ngFor="let property of properties | slice: 0: this.globalData.limit; trackBy: trackByIndex;"
          [ngClass]="{ sorted: sortProperty === property }">
          <ng-container *ngIf="!grade.type">
            <div *ngIf="(gradeProperties.hasOwnProperty(grade.substance) &&
              gradeProperties[grade.substance].hasOwnProperty(property.id)) &&
              gradeProperties[grade.substance][property.id][property.type + 'DisplayText']; else noValue"
              [title]="gradeProperties[grade.substance][property.id][property.type + 'DisplayText']">
              {{ gradeProperties[grade.substance][property.id][property.type + 'DisplayText'] }}
            </div>
          </ng-container>
        </div>
      </div>
    </virtual-scroller>
  </div>
  <div class="table-vertical-scroll-wrapper">
    <div class="table-stick-left">
      <div class="table-stick-left-inner">
        <div class="table-cell"
          *ngFor="let grade of grades | sortSalesGrades: sortProperty: sortOrder: gradeProperties: productTypes: brands; let i = index; trackBy: trackByIndex"
          [ngClass]="{ odd: i%2 !== 0, even: i%2 === 0, 'product-type': grade.type === 'productType', 'trade-name': grade.type === 'idesFamily' }">
          <a target="_blank" (click)="globalData.requestASample(grade)"
            [href]="requestASampleUrl + '?dpsid=' + grade.substance" pTooltip="Request a sample" showDelay="300"
            i18n-pTooltip="Datatable request a sample icon@@datatableRequestASample" [tooltipDisabled]="isTouchDevice()"
            tooltipPosition="right" class="sample" *ngIf="!grade.type">
            <mat-icon svgIcon="sample-desktop"></mat-icon>
          </a>
          <a [routerLink]="!grade.type ? ['/grade-detail', grade.substance] : null">
            <span *ngIf="!grade.type" [innerHTML]="grade.tradeName"></span>
            <span *ngIf="grade.type === 'idesFamily' || grade.type === 'productType'" [innerHTML]="grade.name"></span>
          </a>
          <a class="cross-link" *ngIf="grade.crossLink" [href]="grade.crossLink">
            <mat-icon svgIcon="cross-link"></mat-icon>
          </a>
        </div>
      </div>
    </div>
    <div class="table-stick-right" [ngClass]="{ open: globalData.applicationState.gradesSelectorBarOpen}">
      <div class="table-cell"
        *ngFor="let grade of grades | sortSalesGrades: sortProperty: sortOrder: gradeProperties: productTypes: brands; let i = index; trackBy: trackByIndex">
        <label *ngIf="!grade.type" class="icon-checkbox">
          <input type="checkbox" [checked]="selectedGrades.indexOf(grade) > -1" (change)="toggleGradeSelection(grade)">
          <mat-icon *ngIf="selectedGrades.indexOf(grade) === -1"
            i18n-pTooltip="Datatable add to spider chart tooltip@@datatableAddToSpiderChart"
            pTooltip="Add to spider chart" showDelay="300" [tooltipDisabled]="isTouchDevice()" tooltipPosition="left"
            class="empty" svgIcon="polygon-toggler"></mat-icon>
          <mat-icon *ngIf="selectedGrades.indexOf(grade) > -1"
            i18n-pTooltip="Datatable remove from spider chart tooltip@@datatableRemoveFromSpiderChart"
            pTooltip="Remove from spider chart" showDelay="300" [tooltipDisabled]="isTouchDevice()"
            tooltipPosition="left" class="added" svgIcon="polygon-toggler"></mat-icon>
        </label>
      </div>
    </div>
  </div>
</div>