import { DpsFilters } from './../dpsFilters';
export class DpsSearchResult {
  id: any;
  name: string;
  type: string;
  numberOfGrades: number;

  constructor(result: any) {
    this.id = isNaN(result.id) ? result.id : parseInt(result.id);
    this.name = result.name;
    this.type = result.type;
    this.numberOfGrades = result.numberOfGrades;
  }

  getPath(): string {
    let path: string;
    switch (this.type) {
      case 'Application':
        path = '/datatable';
        break;
      case 'Market Segment':
        path = '/datatable';
        break;
      case 'Industry':
        path = '/datatable';
        break;
      case 'Business Unit':
        path = '/datatable';
        break;
      case 'Product Type':
        path = '/datatable';
        break;
      case 'Brand':
        path = '/datatable';
        break;
      case 'Grade':
        path = encodeURI('/grade-detail/' + this.id).replace('(', '%28').replace(')', '%29');
        break;
    }
    return path;
  }

  getFilters(regionId: number): DpsFilters {
    let filters = new DpsFilters(regionId);
    switch (this.type) {
      case 'Application':
        filters.applicationIds.push(this.id);
        break;
      case 'Market Segment':
        filters.marketSegmentIds.push(this.id);
        break;
      case 'Industry':
        filters.industryIds.push(this.id);
        break;
      case 'Business Unit':
        filters.businessUnitIds.push(this.id);
        break;
      case 'Product Type':
        filters.productTypeIds.push(this.id);
        break;
      case 'Brand':
        filters.brandIds.push(this.id);
        break;
      case 'Grade':
        break;
    }
    return filters;
  }
}
