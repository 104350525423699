import {
  Directive,
  ElementRef,
} from '@angular/core';
import { _ } from 'underscore';

@Directive({
  selector: '[dpsDotToCommaConverter]'
})

export class DotToCommaConverterDirective {

  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    let self = this;
    this.el.nativeElement.addEventListener('keyup', function() {
        self.el.nativeElement.value = self.el.nativeElement.value.split(',').join('.');
    });
  }

}
