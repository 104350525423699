import { GradesService } from './grades.service';
import { FavoritesService } from './favorites.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
  ],
  exports: [
  ]
})
export class ServicesModule { }
