<p-dialog
  class="property-type-selection"
  [(visible)]="globalData.applicationState.showPropertyTypeSelection"
  [modal]="true"
  [resizable]="false"
  [responsive]="true"
  [closeOnEscape]="false"
  [draggable]="false"
  [appendTo]="'body'"
  [styleClass]="'dps-dialog dps-property-selection-dialog'">
  <p-header>
    <ng-container i18n="Property type switch dialog header@@propertySwitchHeader">Which data-set would you like to use?</ng-container>
  </p-header>
  <div class="actions">
    <a class="cta-button"
      i18n="Property type switch physical properties button label@@propertySwitchPhysicalProperties"
      (click)="loadProperties('GP')">Physical properties</a>
    <a class="cta-button"
      i18n="Property type switch sales specifications button label@@propertySwitchSalesSpecifications"
      (click)="loadProperties('SP')">Sales specifications</a>
  </div>
  <div class="info" i18n="Property type switch info text@@propertySwitchInfo">You can change settings at any time.</div>
  <a *ngIf="!propertyTypeInfo" (click)="propertyTypeInfo = true" class="help" i18n="Property type switch help link text@@propertySwitchHelp">What is the difference between the data-sets?</a>
  <div class="info-message" *ngIf="propertyTypeInfo" 
    i18n="Property type switch help explanation text@@propertySwitchHelpExplanation"
    >Product data contains typical property data.<br>Sales specifications are the specifications our products meet.</div>
</p-dialog>
