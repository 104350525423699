import { DpsFilterSalesProperty } from './../general/models/filter/dpsFilterSalesProperty';
import { DpsFilterProperty } from './../general/models/filter/dpsFilterProperty';
import { environment } from './../../environments/environment';
import { FilterPlotChartGradesPipe } from './../general/pipes/filter/filter-plot-chart-grades.pipe';
import { ExportService } from './../general/services/export.service';
import { SortApplicationGradesPipe } from './../general/pipes/sort/sort-application-grades.pipe';
import { FilterApplicationsPipe } from './../general/pipes/filter/filter-applications.pipe';
import { DpsFilterIdName } from './../general/models/filter/dpsFilterIdName';
import { FilterSalesPropertiesPipe } from './../general/pipes/filter/filter-sales-properties.pipe';
import { FilterPropertiesPipe } from './../general/pipes/filter/filter-properties.pipe';
import { SortSalesGradesPipe } from './../general/pipes/sort/sort-sales-grades.pipe';
import { DpsGrade } from './../general/models/data/dpsGrade';
import { SortGradesPipe } from './../general/pipes/sort/sortGrades.pipe';
import { GradesService } from './../general/services/grades.service';
import { FavoritesService } from './../general/services/favorites.service';
import { GlobalData } from './../general/services/global-data.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { _ } from 'underscore';

@Component({
  selector: 'dps-export-html',
  templateUrl: './export-html.component.html',
  styleUrls: ['./export-html.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ExportHtmlComponent implements OnInit {
  url: string;
  filterProperties: any[];
  rangeProperties: any[];
  gradeGroups: DpsGrade[][];
  gradeApplicationGroups: DpsGrade[][];
  gradeXYGroups: DpsGrade[][];
  propertyGroups: any[][];
  testMethodPropertyGroups: any[][];
  applicationGroups: DpsFilterIdName[][];
  today: number = Date.now();
  landscapeColumns = 8;
  landscapeRows = 10;
  portraitColumns = 5;
  portraitRows = 16;
  viewModeProperties: string;
  viewModeApplications: string;

  constructor(
    public globalData: GlobalData,
    public gradesService: GradesService,
    public exportService: ExportService,
    private _favoritesService: FavoritesService,
  ) {
    this.url = environment.exportUrl;
  }

  ngOnInit() {
    let self = this;
    if (!this.globalData.data) {
      // if ((window as any).siloTitle) {
      //   this.globalData.title = (window as any).siloTitle;
      // }
      // if ((window as any).silo) {
      //   this.globalData.applicationState.showInitialFilterDialog = false;
      //   _.extend(this.globalData.silo, JSON.parse((window as any).silo));
      //   _.extend(this.globalData.selectedFilters, this.globalData.silo.filters);
      //   this.globalData.silo.filters = JSON.parse(JSON.stringify(this.globalData.selectedFilters));

      //   if (this.globalData.silo.hasMultiplePropertyTypes) {
      //     this.globalData.applicationState.showPropertyTypeSelection = true;
      //   } else {
      //     this.gradesService.getData(() => {
      //       this.prepareDatatable();
      //       this.prepareXYChart();
      //       this.prepareRadarChart();
      //     });
      //   }
      // }
    } else {
      this.prepareDatatable();
      this.prepareXYChart();
      this.prepareRadarChart();
    }
  }

  prepareDatatable() {
    if (this.globalData.applicationState.propertyType.id === 'GP') {
      let grades = new SortGradesPipe().transform(
        this.globalData.data.filteredGrades,
        this.globalData.sortProperty,
        this.globalData.sortOrder,
        this.globalData.data.gradeGlobalProperties,
        this.globalData.filters.productTypes,
        this.globalData.filters.brands
      );
      let properties = new FilterPropertiesPipe().transform(
        this.globalData.data.globalProperties,
        this.globalData.selectedFilters.propertyFilters
      );

      // determine landscape or portrait
      this.viewModeProperties = this.getViewMode(properties.length, grades.length);

      this.gradeGroups = _.chunk(grades, (this.viewModeProperties === 'landscape' ? this.landscapeRows : this.portraitRows));
      this.propertyGroups = _.chunk(properties, (this.viewModeProperties === 'landscape' ? this.landscapeColumns : this.portraitColumns));

      this.testMethodPropertyGroups = _.chunk(new FilterPropertiesPipe().transform(this.globalData.data.globalProperties, this.globalData.selectedFilters.propertyFilters), this.portraitRows);
      this.filterProperties = _.where(_.values(this.globalData.selectedFilters.propertyFilters), { selected: true});
      this.rangeProperties = _.filter(_.values(this.globalData.selectedFilters.propertyFilters), (pf: DpsFilterProperty) => {
        return pf.maximum || pf.minimum || (pf.textValues && pf.textValues.length > 0);
      });
    } else {
      // determine landscape or portrait
      let salesGrades = new SortSalesGradesPipe().transform(
        this.globalData.data.filteredSalesGrades,
        this.globalData.sortSalesProperty,
        this.globalData.sortSalesOrder,
        this.globalData.data.gradeGlobalProperties,
        this.globalData.filters.productTypes,
        this.globalData.filters.brands
      );

      let salesProperties = new FilterSalesPropertiesPipe().transform(
        this.globalData.data.salesProperties,
        this.globalData.selectedFilters.salesPropertyFilters
      );

      this.viewModeProperties = this.getViewMode(salesProperties.length, salesGrades.length);

      this.gradeGroups = _.chunk(salesGrades, (this.viewModeProperties === 'landscape' ? this.landscapeRows : this.portraitRows));
      this.propertyGroups = _.chunk(salesProperties, (this.viewModeProperties === 'landscape' ? this.landscapeColumns : this.portraitColumns));

      this.testMethodPropertyGroups = _.chunk(new FilterSalesPropertiesPipe().transform(this.globalData.data.salesProperties, this.globalData.selectedFilters.salesPropertyFilters), this.portraitRows);
      this.filterProperties = _.where(_.values(this.globalData.selectedFilters.salesPropertyFilters), { selected: true});
      this.rangeProperties = _.filter(_.values(this.globalData.selectedFilters.salesPropertyFilters), (pf: DpsFilterSalesProperty) => {
        return pf.salesRange &&
        ((pf.salesRange.minimumRange && (pf.salesRange.minimumRange.minimum || pf.salesRange.minimumRange.maximum)) ||
        (pf.salesRange.maximumRange && (pf.salesRange.maximumRange.minimum || pf.salesRange.maximumRange.maximum)) ||
        (pf.salesRange.targetRange && (pf.salesRange.targetRange.minimum || pf.salesRange.targetRange.maximum)) ||
        (pf.textValues && pf.textValues.length > 0));
      });
    }

    // determine landscape or portrait
    let gradeApplications = new SortApplicationGradesPipe().transform(
      this.globalData.applicationState.propertyType.id === 'GP' ? this.globalData.data.filteredGrades : this.globalData.data.filteredSalesGrades,
      this.globalData.sortApplication,
      this.globalData.sortApplicationOrder,
      this.globalData.data.gradeApplications,
      this.globalData.filters.productTypes,
      this.globalData.filters.brands
    );

    let applications = new FilterApplicationsPipe().transform(
      this.globalData.data.applications,
      this.globalData.selectedFilters.marketSegmentIds
    );

    // determine landscape or portrait
    this.viewModeApplications = this.getViewMode(applications.length, gradeApplications.length);

    this.gradeApplicationGroups = _.chunk(gradeApplications, this.viewModeApplications === 'landscape' ? this.landscapeRows : this.portraitRows);
    this.applicationGroups = _.chunk(applications, this.viewModeApplications === 'landscape' ? this.landscapeColumns : this.portraitColumns);
  }

  prepareXYChart() {
    // if (this.globalData.applicationState.propertyType.id === 'GP' && this.globalData.selectedProperties.length < 2) {
    //   this.globalData.selectedProperties = _.first(this.globalData.data.globalProperties, 2);
    // }
    // if (this.globalData.applicationState.propertyType.id === 'SP' && this.globalData.selectedRadarProperties.length < 2) {
    //   this.globalData.selectedRadarProperties = _.first(this.globalData.data.salesProperties, 2);
    // }

    if (this.globalData.applicationState.propertyType.id === 'GP') {
      this.gradeXYGroups = _.chunk(new FilterPlotChartGradesPipe().transform(
        this.globalData.data.filteredGrades,
        this.globalData.selectedProperties,
        this.globalData.data.gradeGlobalProperties
      ), 16);
    } else {
      this.gradeXYGroups = _.chunk(new FilterPlotChartGradesPipe().transform(
        this.globalData.data.filteredSalesGrades,
        this.globalData.selectedSalesProperties,
        this.globalData.data.gradeSalesProperties
      ), 16);
    }
  }

  prepareRadarChart() {
    // let self = this;
    // if (this.globalData.selectedGrades.length < 2) {
    //   this.globalData.selectedGrades = _.first(
    //     this.globalData.applicationState.propertyType.id === 'GP' ?
    //       this.globalData.data.filteredGrades :
    //       this.globalData.data.filteredSalesGrades,
    //     5);
    //   _.each(this.globalData.selectedGrades, (g) => {
    //     g.color = self.globalData.getFirstAvailableGradeColor();
    //   });
    // }

    this.globalData.setDefaultSelectedRadarProperties();
  }

  private getViewMode(totalColumns, totalRows) {
    let columnPagesPortrait = Math.floor(totalColumns / this.portraitColumns) + ( totalColumns % this.portraitColumns > 0 ? 1 : 0);
    let rowPagesPortrait = Math.floor(totalRows / this.portraitRows) + ( totalRows % this.portraitRows > 0 ? 1 : 0);
    let columnPagesLandscape = Math.floor(totalColumns / this.landscapeColumns) + ( totalColumns % this.landscapeColumns > 0 ? 1 : 0);
    let rowPagesLandscape = Math.floor(totalRows / this.landscapeRows) + ( totalRows % this.landscapeRows > 0 ? 1 : 0);

    return (columnPagesLandscape * rowPagesLandscape < columnPagesPortrait * rowPagesPortrait) ? 'landscape' : 'portrait';
  }
}
