<div class="range">
  <label class="label-header" *ngIf="type === 'target'" i18n="Target range in filters sidebar edit range@@targetRange">target</label>
  <label class="label-header" *ngIf="type === 'min'" i18n="Min range in filters sidebar edit range@@minRange">min</label>
  <label class="label-header" *ngIf="type === 'max'" i18n="Max range in filters sidebar edit range@@maxRange">max</label>

  <div class="value" *ngIf="type === 'target' || type === 'range'">
    <label i18n="min input label in filters sidebar edit range@@minInput">min</label>
    <input type="text"
      dpsDotToCommaConverter
      [(ngModel)]="minimum"
      name="min" #min="ngModel"
      [min]="range.minimum"
      [max]="selectedRange?.maximum ? selectedRange?.maximum : range.maximum"
      placeholder="{{ range.minimum }}"
      (keyup)="setBlurTimer($event)"
      (keyup.enter)="$event.target.blur();"
      (keyup.tab)="$event.target.blur();"
      (blur)="applyFilters(min)">
  </div>

  <div class="value" *ngIf="type === 'target' || type === 'range'">
    <label i18n="max input label in filters sidebar edit range@@maxInput">max</label>
    <input type="text"
      dpsDotToCommaConverter
      [(ngModel)]="maximum"
      name="max" #max="ngModel"
      [min]="selectedRange?.minimum ? selectedRange?.minimum : range.minimum"
      [max]="range.maximum"
      placeholder="{{ range.maximum }}"
      (keyup)="setBlurTimer($event)"
      (keyup.enter)="$event.target.blur();"
      (keyup.tab)="$event.target.blur();"
      (blur)="applyFilters(max)">
  </div>

  <div class="value" *ngIf="type === 'min'">
    <label i18n="min input label in filters sidebar edit range@@minInputGreaterThan">&gt;</label>
    <input type="text"
      dpsDotToCommaConverter
      [(ngModel)]="minimum"
      name="min" #min="ngModel"
      [min]="range.minimum"
      [max]="selectedRange?.maximum ? selectedRange?.maximum : range.maximum"
      placeholder="{{ range.minimum }}"
      (keyup)="setBlurTimer($event)"
      (keyup.enter)="$event.target.blur();"
      (keyup.tab)="$event.target.blur();"
      (blur)="applyFilters(min)">
  </div>

  <div class="value" *ngIf="type === 'max'">
    <label i18n="max input label in filters sidebar edit range@@maxInputLessThan">&lt;</label>
    <input type="text"
      dpsDotToCommaConverter
      [(ngModel)]="maximum"
      name="max" #max="ngModel"
      [min]="selectedRange?.minimum ? selectedRange?.minimum : range.minimum"
      [max]="range.maximum"
      placeholder="{{ range.maximum }}"
      (keyup)="setBlurTimer($event)"
      (keyup.enter)="$event.target.blur();"
      (keyup.tab)="$event.target.blur();"
      (blur)="applyFilters(max)">
  </div>

  <div class="value" *ngIf="unitOfMeasure">
    <label>{{ unitOfMeasure }}</label>
  </div>
  <div class="error-message">
    <p *ngIf="customError.id === propertyId + type + 'min'" i18n="Range editor minimum value is@@rangeMinValueError">
        The minimum value has to be ≥ {(range.minimum), select, other {{{(range.minimum)}}}}, and ≤ {(selectedRange?.maximum ? selectedRange?.maximum : range.maximum), select, other {{{(selectedRange?.maximum ? selectedRange?.maximum : range.maximum)}}}}.
    </p>
    <p *ngIf="customError.id === propertyId + type + 'max'" i18n="Range editor maximum value is@@rangeMaxValueError">
        The maximum value has to be ≥ {(selectedRange?.minimum ? selectedRange?.minimum : range.minimum), select, other {{{(selectedRange?.minimum ? selectedRange?.minimum : range.minimum)}}}}, and ≤ {(range.maximum), select, other {{{(range.maximum)}}}}.
    </p>
  </div>
</div>
