import { FilterPropertiesPipe } from './../pipes/filter/filter-properties.pipe';
import { KeyValuesPipe } from '../pipes/transform/key-values.pipe';
import { FilterSalesPropertiesPipe } from '../pipes/filter/filter-sales-properties.pipe';
import { environment } from '../../../environments/environment';
import { ServicesModule } from './services.module';
import { GlobalData } from './global-data.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { DpsGradePropertiesData } from '../models/data/dpsGradePropertiesData';
import { Subscription, forkJoin } from 'rxjs';
import { Injectable } from '@angular/core';
import { _ } from 'underscore';
import { Router } from '@angular/router';
import { DataPropertyType } from '../enums/data-property-type';
import DataPropertyMapping from '../constants/data-property-mapping';

@Injectable({
  providedIn: ServicesModule
})
export class GradesService {
  private _apiUrl: string;
  private _getDataSubscription: Subscription;

  private sheetsUrl: string;
  private tabsUrl: string;
  private exploreUrl: string;

  constructor(public globalData: GlobalData, private _http: HttpClient, private _router: Router) {
    this.initializeUrls();
  }

  private initializeUrls() {
    this._apiUrl = environment.apiUrlExxon;
    this.sheetsUrl = this._apiUrl + 'data/sheets';
    this.tabsUrl = this._apiUrl + 'data/tabs';
    this.exploreUrl = this._apiUrl + 'data/explore';
  }

  getInitialFilters(callback?): Subscription {
    let params = {
      language: this.globalData.lang,
      regionIds: this.globalData.selectedFilters.regionId ? [this.globalData.selectedFilters.regionId] : []
    };

    return this._http.post(this.exploreUrl, params).subscribe((result) => {
      if (!this.globalData.filters) {
        _.extend(this.globalData.filters, result);
      }
      _.extend(this.globalData.exploreFilters, result);
      if (callback) {
        callback();
      }
    });
  }

  private mapGradeDataType(id: string) {
    if (id && id.length >= 2) {
      let firstTwoDigits = id.slice(0, 2);
      return DataPropertyMapping[firstTwoDigits];
    }

    return DataPropertyType.Product;
  }

  //type
  getGradeDetail(id: number, callback?: any): void {
    this.globalData.gradeDetail = { id, name: '', dataType: this.mapGradeDataType(id.toString()) };

    let params = { language: this.globalData.lang }

    let sheetsParams = Object.assign(params, { ids: [id] });
    let tabParams = Object.assign(params, { id });

    forkJoin(this._http.post(this.sheetsUrl, sheetsParams), this._http.post(this.tabsUrl, tabParams)).subscribe((results) => {
      let sheetsResult = results[0];
      let tabsResult = results[1];

      this.mapSheets(sheetsResult);
      this.mapTabs(tabsResult);
      this.globalData.gradeDetail.loaded = true;
      if (callback) callback();
    });
  }

  private mapSheetData(data) {
    data.name = data.substanceName;
    delete data.substanceName;

    return data;
  }

  private mapTabs(tabs) {
    _.extend(this.globalData.gradeDetail, (tabs && !_.isEmpty(tabs)) ? { tabs: tabs } : {});
  }

  private mapSheets(sheets) {
    let additionalData = sheets.length > 0 ? this.mapSheetData(sheets[0]) : {};
    _.extend(this.globalData.gradeDetail, additionalData);
  }

  getData(callback?): Subscription {
    if (this._getDataSubscription && this.globalData.loading) {
      this._getDataSubscription.unsubscribe();
    }

    this.globalData.loading = true;
    const url = this._apiUrl + 'data/producttable';

    // view = view ? view : (this.globalData.applicationState.applicationActive ? 'A' : this.globalData.applicationState.propertyType.id);

    let params = {
      language: this.globalData.lang,
      unitSystem: this.globalData.unitSystem,
      regionIds: this.globalData.selectedFilters.regionId ? [this.globalData.selectedFilters.regionId] : [],
      businessUnitIds: this.globalData.selectedFilters.businessUnitIds,
      productTypeIds: this.globalData.selectedFilters.productTypeIds,
      industryIds: this.globalData.selectedFilters.industryIds,
      applicationIds: this.globalData.selectedFilters.applicationIds,
      brandIds: this.globalData.selectedFilters.brandIds,
      marketSegmentIds: this.globalData.selectedFilters.marketSegmentIds,
      globalPropertyFilters: this.globalData.applicationState.propertyType.id === 'GP' ? _.map(_.filter(_.values(this.globalData.selectedFilters.propertyFilters), function (gpf) {
        console.log(gpf.selected);
        return (gpf.minimum || gpf.maximum || (gpf.textValues && gpf.textValues.length > 0)) && gpf.selected;
      }), (pf) => {
        pf = JSON.parse(JSON.stringify(pf));
        if (pf) {
          delete pf.unitOfMeasure;
          delete pf.name;
          delete pf.selected;
          delete pf.type;
          pf.id = btoa(unescape(encodeURIComponent(pf.id)));
        }
        return pf;
      }) : undefined,
      salesPropertyFilters: this.globalData.applicationState.propertyType.id === 'SP' ? _.filter(_.map(_.values(this.globalData.selectedFilters.salesPropertyFilters), function (spf) {
        spf = JSON.parse(JSON.stringify(spf));
        let minDel = false;
        let maxDel = false;
        let tarDel = false;

        if (spf && spf.selected) {
          delete spf.unitOfMeasure;
          delete spf.name;
          delete spf.selected;
          delete spf.type;
          spf.id = btoa(unescape(encodeURIComponent(spf.id)));

          if (!spf.salesRange.minimumRange.minimum && !spf.salesRange.minimumRange.maximum) {
            minDel = true;
            delete spf.salesRange.minimumRange;
          }
          if (!spf.salesRange.targetRange.minimum && !spf.salesRange.targetRange.maximum) {
            tarDel = true;
            delete spf.salesRange.targetRange;
          }
          if (!spf.salesRange.maximumRange.minimum && !spf.salesRange.maximumRange.maximum) {
            maxDel = true;
            delete spf.salesRange.maximumRange;
          }

          if (!spf.textValues || spf.textValues.length === 0) {
            delete spf.textValues;
          }

          if (minDel && maxDel && tarDel) {
            delete spf.salesRange;
          }

          return spf;
        }

        return false;
      }), (spf) => {
        return typeof spf.salesRange !== 'undefined' || typeof spf.textValues !== 'undefined';
      }) : undefined,
    };

    // if (!params.globalPropertyFilters && params.globalPropertyFilters.length === 0) {
    //   delete params.globalPropertyFilters;
    // }

    // if (params.salesPropertyFilters && params.salesPropertyFilters.length === 0) {
    //   delete params.salesPropertyFilters;
    // }

    if (params.regionIds.length < 1) {
      return;
    }

    this._getDataSubscription = this._http.post(url, params)
      .subscribe((result: any) => {
        this.globalData.globalError = null;
        this.globalData.data = this.globalData.data ? this.globalData.data : {} as DpsGradePropertiesData;
        // update the grades
        _.extend(this.globalData.data, {
          grades: result.grades ? result.grades : [],
        });

        // update hasOtherPropertyType
        this.globalData.hasOtherPropertyType = result.hasOtherPropertyType;

        // update filterd grades
        _.extend(this.globalData.data, {
          filteredGrades: _.where(this.globalData.data.grades, { showOnGlobalProperties: true }),
          filteredSalesGrades: _.where(this.globalData.data.grades, { showOnSalesProperties: true })
        });

        // update the corresponding property / application data
        _.extend(this.globalData.data, {
          globalProperties: result.globalProperties ? result.globalProperties : [],
          gradeGlobalProperties: result.gradeGlobalProperties ? result.gradeGlobalProperties : {}
        });
        _.extend(this.globalData.data, {
          applications: result.marketSegments ? result.marketSegments : [],
          gradeApplications: result.gradeMarketSegments ? result.gradeMarketSegments : {}
        });
        let salesProperties = [];
        _.each(result.salesProperties, (sp) => {
          let hasSalesRange = false;
          if (sp.salesRange) {
            if (sp.salesRange.minimumRange) {
              hasSalesRange = true;
              salesProperties.push({
                id: sp.id,
                name: sp.name + '(min)',
                unitOfMeasure: sp.unitOfMeasure,
                type: 'minimum',
                range: sp.salesRange.minimumRange,
                testMethods: sp.testMethods
              });
            }
            if (sp.salesRange.targetRange) {
              hasSalesRange = true;
              salesProperties.push({
                id: sp.id,
                name: sp.name + '(target)',
                unitOfMeasure: sp.unitOfMeasure,
                type: 'target',
                range: sp.salesRange.targetRange,
                testMethods: sp.testMethods
              });
            }
            if (sp.salesRange.maximumRange) {
              hasSalesRange = true;
              salesProperties.push({
                id: sp.id,
                name: sp.name + '(max)',
                unitOfMeasure: sp.unitOfMeasure,
                type: 'maximum',
                range: sp.salesRange.maximumRange,
                testMethods: sp.testMethods
              });
            }
          }
          // !hasSalesRange &&
          if (sp.textValues && sp.textValues.length > 0) {
            salesProperties.push({
              id: sp.id,
              name: sp.name,
              textValues: sp.textValues,
              unitOfMeasure: sp.unitOfMeasure,
              type: 'text',
              testMethods: sp.testMethods
            });
          }
        });
        _.extend(this.globalData.data, {
          salesProperties: salesProperties,
          gradeSalesProperties: result.gradeSalesProperties ? result.gradeSalesProperties : {}
        });

        // extend the filters
        _.extend(this.globalData.filters, {
          brands: result.brands ? result.brands : [],
          businessUnits: result.businessUnits ? result.businessUnits : [],
          applications: result.applications ? result.applications : [],
          productTypes: result.productTypes ? result.productTypes : [],
          industries: result.industries ? result.industries : [],
          marketSegments: result.marketSegments ? result.marketSegments : [],
          regions: result.regions ? result.regions : [],
        });
        _.extend(this.globalData.filters, {
          globalProperties: new KeyValuesPipe().transform(_.groupBy(result.globalProperties, 'type'))
        });
        _.extend(this.globalData.filters, {
          salesProperties: new KeyValuesPipe().transform(_.groupBy(result.salesProperties, 'type'))
        });
        // update selected filters
        this.updateSelectedFilters(result);
        this.globalData.filtersChanged = {};

        // TEMP fix rounding
        // this.globalData.data.gradeGlobalProperties = _.mapObject(this.globalData.data.gradeGlobalProperties, (g) => {
        //   return _.mapObject(g, (p) => {
        //     p.displayText = !isNaN(p.displayText) ? new DecimalPipe('EN-us').transform(p.displayText, '1.0-3') : p.displayText;
        //     return p;
        //   });
        // });
        this.globalData.data.gradeSalesProperties = _.mapObject(this.globalData.data.gradeSalesProperties, (g) => {
          return _.mapObject(g, (p) => {
            p.textSortValue = p.textValue;
            p.textDisplayText = p.textValue;
            return p;
          });
        });

        // update selectedProperties
        this.globalData.selectedProperties =
          _.filter(new FilterPropertiesPipe().transform(this.globalData.data.globalProperties, this.globalData.selectedFilters.propertyFilters), (p) => {
            return _.findWhere(this.globalData.selectedProperties, { id: p.id });
          });
        // update selectedRadarProperties
        this.globalData.selectedRadarProperties =
          _.filter(new FilterPropertiesPipe().transform(this.globalData.data.globalProperties, this.globalData.selectedFilters.propertyFilters), (p) => {
            return _.findWhere(this.globalData.selectedRadarProperties, { id: p.id });
          });
        // update selectedSalesProperties
        this.globalData.selectedSalesProperties =
          _.filter(new FilterSalesPropertiesPipe().transform(this.globalData.data.salesProperties, this.globalData.selectedFilters.salesPropertyFilters), (p) => {
            return _.findWhere(this.globalData.selectedSalesProperties, { id: p.id, type: p.type });
          });
        // update selectedSalesRadarProperties
        this.globalData.selectedRadarSalesProperties =
          _.filter(new FilterSalesPropertiesPipe().transform(this.globalData.data.salesProperties, this.globalData.selectedFilters.salesPropertyFilters), (p) => {
            return _.findWhere(this.globalData.selectedRadarSalesProperties, { id: p.id, type: p.type });
          });
        // update selectedGrades
        for (let i = this.globalData.selectedGrades.length - 1; i >= 0; i--) {
          let g = this.globalData.selectedGrades[i];
          if (!g.color) {
            g.color = this.globalData.getFirstAvailableGradeColor();
          }
          let newGrade = _.findWhere(this.globalData.data.grades, { substance: g.substance });
          if (!newGrade) {
            this.globalData.selectedGrades.splice(i, 1);
          } else {
            newGrade.color = g.color;
            this.globalData.selectedGrades.splice(i, 1, newGrade);
          }
        }

        // update sortProperties
        this.globalData.sortProperty = this.globalData.sortProperty ? _.findWhere(this.globalData.data.globalProperties, { id: this.globalData.sortProperty.id }) : null;
        this.globalData.sortSalesProperty = this.globalData.sortSalesProperty ?
          _.findWhere(this.globalData.data.salesProperties, { id: this.globalData.sortSalesProperty.id, type: this.globalData.sortSalesProperty.type }) : null;
        this.globalData.sortApplication = this.globalData.sortApplication ? _.findWhere(this.globalData.data.applications, { id: this.globalData.sortApplication.id }) : null;

        this.globalData.limit = 15;
        this.globalData.loading = false;

        this.globalData.updateTitle();

        // select correct initial view
        if (!this.globalData.applicationState.propertyType.id) {
          if (this.globalData.data.globalProperties.length > 0) {
            this.globalData.applicationState.propertyType = { id: 'GP', name: 'Product Datasheet' };
          } else if (this.globalData.data.salesProperties.length > 0) {
            this.globalData.applicationState.propertyType = { id: 'SP', name: 'Sales specifications' };
          }
        } else {
          // select correct view TODO show popup
          // this.globalData.applicationState.showPropertyTypeSwitch = true;
          if (this.globalData.applicationState.propertyType.id === 'GP' && this.globalData.data.globalProperties.length === 0 && this.globalData.hasOtherPropertyType) {
            this.globalData.applicationState.propertyType = { id: 'SP', name: 'Sales specifications' };
            this.globalData.applicationState.showPropertyTypeSwitch = true;
            this.getData(callback);
          } else if (this.globalData.applicationState.propertyType.id === 'SP' && this.globalData.data.salesProperties.length === 0 && this.globalData.hasOtherPropertyType) {
            this.globalData.applicationState.propertyType = { id: 'GP', name: 'Product Datasheet' };
            this.globalData.applicationState.showPropertyTypeSwitch = true;
            this.getData(callback);
          }
        }

        if (this.globalData.applicationState.applicationActive && this.globalData.data.applications.length === 0) {
          this.globalData.applicationState.applicationActive = false;
          this._router.navigateByUrl('');
        }

        if (callback) {
          callback();
        }
      }, (error) => {
        this.globalData.globalError = (error ? error.message : '') + '<br>' + (error && error.error ? error.error.Message : '');
        this.globalData.loading = false;
      });

    return this._getDataSubscription;
  }

  private updateSelectedFilters(result): void {
    this.globalData.selectedFilters.productTypeIds =
      _.intersection(_.pluck(this.globalData.filters.productTypes, 'id'), this.globalData.selectedFilters.productTypeIds);
    this.globalData.selectedFilters.applicationIds =
      _.intersection(_.pluck(this.globalData.filters.applications, 'id'), this.globalData.selectedFilters.applicationIds);
    this.globalData.selectedFilters.businessUnitIds =
      _.intersection(_.pluck(this.globalData.filters.businessUnits, 'id'), this.globalData.selectedFilters.businessUnitIds);
    this.globalData.selectedFilters.industryIds =
      _.intersection(_.pluck(this.globalData.filters.industries, 'id'), this.globalData.selectedFilters.industryIds);
    this.globalData.selectedFilters.marketSegmentIds =
      _.intersection(_.pluck(this.globalData.filters.marketSegments, 'id'), this.globalData.selectedFilters.marketSegmentIds);
    this.globalData.selectedFilters.brandIds =
      _.intersection(_.pluck(this.globalData.filters.brands, 'id'), this.globalData.selectedFilters.brandIds);

    const brandsIdFromSilo = (window as any).silo ? JSON.parse((window as any).silo).filters.brandIds : [];
    if(brandsIdFromSilo.length && this.globalData.applicationState.propertyType.id === 'SP') {
      this.globalData.selectedFilters.brandIds = _.uniq(this.globalData.selectedFilters.brandIds.concat(brandsIdFromSilo));
    }

    let self = this;

    // update selected properties
    let updatedPropertyFilters = _.map(result.globalProperties, function (property) {
      let oldSelection = self.globalData.selectedFilters.propertyFilters[property.id];
      let newSelection = {
        id: property.id,
        name: property.name,
        unitOfMeasure: property.unitOfMeasure,
        type: property.type,
        textValues: property.textValues && property.textValues.length >= 1 ? [] : undefined,
        selected: false
      };
      if (oldSelection) {
        _.extend(newSelection, {
          selected: oldSelection.selected,
          minimum: oldSelection.minimum,
          maximum: oldSelection.maximum,
          textValues: oldSelection.textValues
        });
      }
      return newSelection;
    });
    this.globalData.selectedFilters.propertyFilters = _.reduce(updatedPropertyFilters, (memo, item) => {
      memo[item.id] = item;

      if(!this.globalData.selectedFilters.propertyFilters[item.id]) {
        item.selected = true;
      }

      return memo;
    }, {});
    // _.pick(this.globalData.selectedFilters.propertyFilters, (value: DpsFilterProperty, key) => {
    //   return !!_.findWhere(result.globalProperties, { id: key });
    // });
    // update selected properties

    let updatedSalesPropertyFilters = _.map(result.salesProperties, function (p) {
      let oldSelection = self.globalData.selectedFilters.salesPropertyFilters[p.id];
      let newSelection = {
        id: p.id,
        name: p.name,
        unitOfMeasure: p.unitOfMeasure,
        type: p.type,
        selected: false,
        salesRange: {
          minimumRange: {},
          maximumRange: {},
          targetRange: {}
        },
        textValues: p.textValues && p.textValues.length >= 1 ? [] : undefined
      };
      if (oldSelection) {
        _.extend(newSelection, {
          selected: oldSelection.selected,
          salesRange: oldSelection.salesRange,
          textValues: oldSelection.textValues
        });
      }
      return newSelection;
    });
    this.globalData.selectedFilters.salesPropertyFilters = _.reduce(updatedSalesPropertyFilters, (memo, item) => {
      memo[item.id] = item;
      
      if(!this.globalData.selectedFilters.salesPropertyFilters[item.id]) {
        item.selected = true;
      }

      return memo;
    }, {});
  }

  private getParams(data: any): HttpParams {
    let result: HttpParams = new HttpParams();
    _.each(data, function (value, key) {
      if (value) {
        result = result.append(key, value.toString());
      }
    });
    result = result.append('lang', this.globalData.lang);
    return result;
  }
}
