import { DpsSearchResult } from './data/dpsSearchResult';
export class DpsSearchModel {
  skip: number;
  take: number;
  searchTerm: string;
  filterType: string;
  searchResults: DpsSearchResult[];
  autocompleteResults: DpsSearchResult[];
  hasMoreItems: boolean;
  loadingAutocomplete: boolean;
  loadingResults: boolean;

  constructor(searchTerm) {
    this.skip = 0;
    this.take = 50;
    this.searchTerm = searchTerm;
    this.searchResults = [];
    this.autocompleteResults = [];
    this.hasMoreItems = true;
    this.loadingAutocomplete = false;
    this.loadingResults = false;
  }
}
