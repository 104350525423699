import { DpsShareFilter } from './dpsShareModel';
import { _ } from 'underscore';
import { DpsFilterData } from './filter/dpsFilterData';

export class DpsFilters {
  regionId: number;
  brandIds: number[];
  productTypeIds: number[];
  businessUnitIds: number[];
  industryIds: number[];
  applicationIds: number[];
  propertyFilters: any;
  salesPropertyFilters: any;
  marketSegmentIds: string[];

  constructor(regionId?: number, businessUnitId?: number) {
    this.regionId = regionId;
    this.businessUnitIds = businessUnitId ? [businessUnitId] : [];
    this.industryIds = [];
    this.applicationIds = [];
    this.productTypeIds = [];
    this.brandIds = [];
    this.propertyFilters = {};
    this.salesPropertyFilters = {};
    this.marketSegmentIds = [];
  }

  updateFromFilters(filters) {
    this.regionId = filters.regionId;
    this.businessUnitIds = filters.businessUnitIds;
    this.productTypeIds = filters.productTypeIds;
    this.brandIds = filters.brandIds;
    this.applicationIds = filters.applicationIds;
    this.industryIds = filters.industryIds;
    this.marketSegmentIds = filters.marketSegmentIds;
    this.propertyFilters = filters.propertyFilters;
    this.salesPropertyFilters = filters.salesPropertyFilters;
  }

  getShareString() {
    let activeFilters: DpsShareFilter = this.assignFilters();

    let globalProperties = this.extractGlobalProperties();

    activeFilters.assignFilter('gp', globalProperties);

    let salesProperties = this.extractSalesProperties();

    activeFilters.assignFilter('sp', salesProperties);

    return activeFilters;
  }

  private extractGlobalProperties() {
    return _.map(_.filter(this.propertyFilters, (pf) => {
      return pf.selected || pf.minmium || pf.maximum || (pf.textValues && pf.textValues.length > 0);
    }), (pf) => {
      let result: any = {
        id: pf.id,
      };
      if (pf.selected) {
        result.s = true;
      }
      if (pf.minimum) {
        result.min = pf.minimum;
      }
      if (pf.maximum) {
        result.max = pf.maximum;
      }
      if (pf.textValues && pf.textValues.length > 0) {
        result.t = pf.textValues;
      }
      return result;
    });
  }

  private extractSalesProperties() {
    return _.map(_.filter(this.salesPropertyFilters, (pf) => {
      return pf.selected || (pf.textValues && pf.textValues.length > 0) ||
        (pf.salesRange && (!_.isEmpty(pf.salesRange.minimumRange) || !_.isEmpty(pf.salesRange.maximumRange) || !_.isEmpty(pf.salesRange.targetRange)));
    }), (pf) => {
      let result: any = {
        id: pf.id,
      };
      if (pf.selected) {
        result.s = true;
      }
      if (pf.salesRange) {
        if (!_.isEmpty(pf.salesRange.minimumRange)) {
          result.minR = {};
          if (pf.salesRange.minimumRange.minimum) {
            result.minR.min = pf.salesRange.minimumRange.minimum;
          }
          if (pf.salesRange.minimumRange.maximum) {
            result.minR.max = pf.salesRange.minimumRange.maximum;
          }
        }
        if (!_.isEmpty(pf.salesRange.maximumRange)) {
          result.maxR = {};
          if (pf.salesRange.maximumRange.minimum) {
            result.maxR.min = pf.salesRange.maximumRange.minimum;
          }
          if (pf.salesRange.maximumRange.maximum) {
            result.maxR.max = pf.salesRange.maximumRange.maximum;
          }
        }
        if (!_.isEmpty(pf.salesRange.targetRange)) {
          result.tarR = {};
          if (pf.salesRange.targetRange.minimum) {
            result.tarR.min = pf.salesRange.targetRange.minimum;
          }
          if (pf.salesRange.targetRange.maximum) {
            result.tarR.max = pf.salesRange.targetRange.maximum;
          }
        }
      }
      if (pf.textValues && pf.textValues.length > 0) {
        result.t = pf.textValues;
      }
      return result;
    });
  }

  private assignFilters(): DpsShareFilter {
    let activeFilters: DpsShareFilter = new DpsShareFilter();

    activeFilters.assignFilter('r', this.regionId);
    activeFilters.assignFilter('bu', this.businessUnitIds);
    activeFilters.assignFilter('i', this.industryIds);
    activeFilters.assignFilter('a', this.applicationIds);
    activeFilters.assignFilter('pt', this.productTypeIds);
    activeFilters.assignFilter('b', this.brandIds);
    activeFilters.assignFilter('m', this.marketSegmentIds);

    return activeFilters;
  }

  /**
   * Gets pipe concatenated values of main filters
   */
  getAnalyticsValue(data: DpsFilterData): string {
    let filters = "";

    filters += this.getAnalytycsValueFrom(this.industryIds, data.industries);
    filters += this.getAnalytycsValueFrom(this.marketSegmentIds, data.marketSegments);
    filters += this.getAnalytycsValueFrom(this.brandIds, data.brands);
    filters += this.getAnalytycsValueFrom(this.productTypeIds, data.productTypes);

    if (filters.length) filters = filters.substring(0, filters.length - 1);
    return filters;
  }

  private getAnalytycsValueFrom(array, dataArray): string {
    let filter = "";
    for (let i = 0; i < array.length; i++) {
      const id = array[i];
      filter += dataArray.find((industry) => industry.id == id).name;
      filter += "|";
    }

    return filter;
  }
}
