<p-dialog class="export-data" [(visible)]="globalData.applicationState.showExportDialog" [dismissableMask]="true" [modal]="true"
[draggable]="false" [resizable]="false" [responsive]="true"
[appendTo]="'body'"
[focusOnShow]="false"
[styleClass]="'dps-dialog dps-export-dialog'">
  <p-header>
    <ng-container i18n="Export dialog title label@@exportTitle">Export to PDF</ng-container>
  </p-header>
  <form #exportForm="ngForm" class="dialog-form" *ngIf="exportService.exportState" (submit)="export(exportForm)" novalidate [ngClass]="{ submitted: submitted }">
    <div class="grade-count">
      <span class="large"
        *ngIf="globalData.applicationState.propertyType.id ==='GP'"
        [innerHTML]="globalData.data && globalData.data.filteredGrades ? globalData.data.filteredGrades.length : ''"></span>
      <span class="large"
        *ngIf="globalData.applicationState.propertyType.id ==='SP'"
        [innerHTML]="globalData.data && globalData.data.filteredSalesGrades ? globalData.data.filteredSalesGrades.length : ''"></span>
      <span i18n="Applicable grades in the export dialog chart@@applicableGrades">applicable grades</span>
    </div>
    <div class="subtitle" i18n="Export dialog subtitle label@@exportSubtitle">Please select the items you would like to receive as PDF.</div>
    <div class="link-list">
      <div class="link" (click)="exportService.exportState.selectAll()" i18n="Select all in export dialog@@selectAll">Select all</div>
      <div class="link" (click)="exportService.exportState.deselectAll()" i18n="Deselect all in export dialog@@deSelectAll">Deselect all</div>
    </div>
    <div class="field">
      <label class="dps-checkbox">
        <input type="checkbox" name="exportAllGradeData" [(ngModel)]="exportService.exportState.exportAllGradeData">
        <span></span>
        <span class="checkbox-text" i18n="Export product datatables label@@exportDataTables">Data tables</span>
      </label>
      <label class="dps-checkbox indent">
        <input type="checkbox" name="exportGradeProperties" [(ngModel)]="exportService.exportState.exportGradeProperties">
        <span></span>
        <span class="checkbox-text">
          <ng-container *ngIf="globalData.applicationState.propertyType.id === 'GP'" i18n="Export product datasheet label@@exportProductData">Product data</ng-container>
          <ng-container *ngIf="globalData.applicationState.propertyType.id === 'SP'" i18n="Export sales specs label@@exportSalesSpecifications">Sales specifications</ng-container>
        </span>
      </label>
      <label class="dps-checkbox indent">
        <input type="checkbox" name="exportGradeApplications" [(ngModel)]="exportService.exportState.exportGradeApplications">
        <span></span>
        <span class="checkbox-text" i18n="Export applications label@@exportApplications">Applications</span>
      </label>
      <label class="dps-checkbox">
        <input type="checkbox" name="exportGradeResources" [(ngModel)]="exportService.exportState.exportGradeResources">
        <span></span>
        <span class="checkbox-text" i18n="Export grade resources label@@exportGradeResources">Grade resources</span>
      </label>
      <!-- <label class="dps-checkbox indent">
        <input type=checkbox [(ngModel)]="exportService.exportState.exportProductDataSheets">
        <span></span>
        <span class="checkbox-text" i18n="Export product data sheets label@@exportProductDataSheets">Product data sheets</span>
      </label>
      <label class="dps-checkbox indent">
        <input type=checkbox [(ngModel)]="exportService.exportState.exportSalesSpecSheets">
        <span></span>
        <span class="checkbox-text" i18n="Export sales spec sheets label@@exportSalesSpecificationSheets">Sales specification sheets</span>
      </label> -->
      <label class="dps-checkbox" [ngClass]="{
        disabled: exportService.exportState.exportXYDisabled
      }">
        <input type="checkbox" name="exportXY" [(ngModel)]="exportService.exportState.exportXY">
        <span></span>
        <span class="checkbox-text" i18n="Export X/Y comparison label@@exportXY">X/Y comparison</span>
      </label>
      <label class="dps-checkbox" [ngClass]="{
        disabled: exportService.exportState.exportSpiderDisabled
      }">
        <input type="checkbox" name="exportSpider" [(ngModel)]="exportService.exportState.exportSpider">
        <span></span>
        <span class="checkbox-text" i18n="Export spidar comparison label@@exportSpider">Spider comparison</span>
      </label>
    </div>
    <div class="line"></div>
    <div class="field-wrapper">
      <div class="half-field">
        <label class="label" i18n="Export email address label@@exportEmailLabel">Email</label>
        <label class="dps-textbox">
          <input type="email"
            required
            email
            name="email"
            [(ngModel)]="exportService.exportState.exportEmail"
            i18n-placeholder="Export email address placeholder@@exportEmailPlaceholder"
            placeholder="Email address" />
        </label>
      </div>
      <div class="half-field padding-top">
        <label class="dps-checkbox">
          <input type="checkbox"
            name="exportNewsletterSubscription"
            [(ngModel)]="exportService.exportState.exportNewsletterSubscription">
          <span></span>
          <span class="checkbox-text" i18n="Accept receiving news and updates label@@exportReceiveNewsAndUpdates">
            Yes! I would like ExxonMobil and its authorized distributors to send me news and updates about ExxonMobil products and services, promotions and events. I agree that my contact details are provided to ExxonMobil authorized distributors.
          </span>
          <br>
          <br>
          <span class="checkbox-text" i18n="Terms, conditions and privacy label@@exportTermsConditionsAndPrivacyPolicy">
            By clicking on Download you agree to <a href="https://corporate.exxonmobil.com/en/global-legal-pages/terms-and-conditions" target="_blank">terms & Conditions</a> and our
            <a href="https://corporate.exxonmobil.com/en/global-legal-pages/privacy-policy" target="_blank">privacy policy</a>.
          </span>
        </label>
      </div>
      <div class="field">
        <button type="submit" [ngClass]="{disabled: submitted && validForm || (
          !exportService.exportState.exportAllGradeData &&
          !exportService.exportState.exportGradeProperties &&
          !exportService.exportState.exportGradeApplications &&
          !exportService.exportState.exportGradeResources &&
          !exportService.exportState.exportXY &&
          !exportService.exportState.exportSpider
        )}" class="cta-button">
          <ng-container i18n="Export submit@@exportSubmit">Submit</ng-container>
          <mat-icon svgIcon="long-arrow-right"></mat-icon>
        </button>
        <!-- <a class="cta-button" i18n="Export submit@@exportSubmit" [routerLink]="['/export']">
          Export
          <mat-icon svgIcon="long-arrow-right"></mat-icon>
        </a> -->
      </div>
    </div>
  </form>
</p-dialog>
