import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'keyValues'
})
export class KeyValuesPipe implements PipeTransform {

  transform(value: any): any[] {
    return Object.keys(value).map((key) => {
      return {
        key: key,
        value: value[key]
      };
    });
  }

}
