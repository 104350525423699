<p-dialog
  class="succes-dialog"
  [(visible)]="globalData.applicationState.showSuccesDialog"
  [dismissableMask]="true"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [responsive]="true"
  [appendTo]="'body'"
  [styleClass]="'dps-dialog dps-success-dialog'">
  <p-header>
    <ng-container i18n="Succes dialog title@@succesTitle">Thank you!</ng-container>
  </p-header>
  <div>
    <ng-container i18n="Succes dialog message@@succesMessage">You can expect an e-mail from us shortly.</ng-container>
  </div>
</p-dialog>
