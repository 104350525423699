import { GlobalData } from './global-data.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { _ } from 'underscore';

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  private _countriesMap: any;

  constructor(public globalData: GlobalData, private _http: HttpClient) {
    this._countriesMap = {'AF': 5,
    'AX': 2,
    'AL': 2,
    'DZ': 7,
    'AS': 5,
    'AD': 2,
    'AO': 7,
    'AI': 8,
    'AQ': 1,
    'AG': 8,
    'AR': 8,
    'AM': 5,
    'AW': 8,
    'AU': 5,
    'AT': 2,
    'AZ': 5,
    'BS': 8,
    'BH': 5,
    'BD': 5,
    'BB': 8,
    'BY': 2,
    'BE': 2,
    'BZ': 8,
    'BJ': 7,
    'BM': 1,
    'BT': 5,
    'BO': 8,
    'BQ': 8,
    'BA': 2,
    'BW': 7,
    'BV': 8,
    'BR': 8,
    'IO': 7,
    'BN': 5,
    'BG': 2,
    'BF': 7,
    'BI': 7,
    'CV': 7,
    'KH': 5,
    'CM': 7,
    'CA': 1,
    'KY': 8,
    'CF': 7,
    'TD': 7,
    'CL': 8,
    'CN': 5,
    'CX': 5,
    'CC': 5,
    'CO': 8,
    'KM': 7,
    'CG': 7,
    'CD': 7,
    'CK': 5,
    'CR': 8,
    'CI': 7,
    'HR': 2,
    'CU': 8,
    'CW': 8,
    'CY': 5,
    'CZ': 2,
    'DK': 2,
    'DJ': 7,
    'DM': 8,
    'DO': 8,
    'EC': 8,
    'EG': 7,
    'SV': 8,
    'GQ': 7,
    'ER': 7,
    'EE': 2,
    'SZ': 7,
    'ET': 7,
    'FK': 8,
    'FO': 2,
    'FJ': 5,
    'FI': 2,
    'FR': 2,
    'GF': 8,
    'PF': 5,
    'TF': 7,
    'GA': 7,
    'GM': 7,
    'GE': 5,
    'DE': 2,
    'GH': 7,
    'GI': 2,
    'GR': 2,
    'GL': 1,
    'GD': 8,
    'GP': 8,
    'GU': 5,
    'GT': 8,
    'GG': 2,
    'GN': 7,
    'GW': 7,
    'GY': 8,
    'HT': 8,
    'HM': 5,
    'VA': 2,
    'HN': 8,
    'HK': 5,
    'HU': 2,
    'IS': 2,
    'IN': 5,
    'ID': 5,
    'IR': 5,
    'IQ': 5,
    'IE': 2,
    'IM': 2,
    'IL': 5,
    'IT': 2,
    'JM': 8,
    'JP': 5,
    'JE': 2,
    'JO': 5,
    'KZ': 5,
    'KE': 7,
    'KI': 5,
    'KP': 5,
    'KR': 5,
    'KW': 5,
    'KG': 5,
    'LA': 5,
    'LV': 2,
    'LB': 5,
    'LS': 7,
    'LR': 7,
    'LY': 7,
    'LI': 2,
    'LT': 2,
    'LU': 2,
    'MO': 5,
    'MK': 2,
    'MG': 7,
    'MW': 7,
    'MY': 5,
    'MV': 5,
    'ML': 7,
    'MT': 2,
    'MH': 5,
    'MQ': 8,
    'MR': 7,
    'MU': 7,
    'YT': 7,
    'MX': 8,
    'FM': 5,
    'MD': 2,
    'MC': 2,
    'MN': 5,
    'ME': 2,
    'MS': 8,
    'MA': 7,
    'MZ': 7,
    'MM': 5,
    'NA': 7,
    'NR': 5,
    'NP': 5,
    'NL': 2,
    'NC': 5,
    'NZ': 5,
    'NI': 8,
    'NE': 7,
    'NG': 7,
    'NU': 5,
    'NF': 5,
    'MP': 5,
    'NO': 2,
    'OM': 5,
    'PK': 5,
    'PW': 5,
    'PS': 5,
    'PA': 8,
    'PG': 5,
    'PY': 8,
    'PE': 8,
    'PH': 5,
    'PN': 5,
    'PL': 2,
    'PT': 2,
    'PR': 8,
    'QA': 5,
    'RE': 7,
    'RO': 2,
    'RU': 2,
    'RW': 7,
    'BL': 8,
    'SH': 7,
    'KN': 8,
    'LC': 8,
    'MF': 8,
    'PM': 1,
    'VC': 8,
    'WS': 5,
    'SM': 2,
    'ST': 7,
    'SA': 5,
    'SN': 7,
    'RS': 2,
    'SC': 7,
    'SL': 7,
    'SG': 5,
    'SX': 8,
    'SK': 2,
    'SI': 2,
    'SB': 5,
    'SO': 7,
    'ZA': 7,
    'GS': 8,
    'SS': 7,
    'ES': 2,
    'LK': 5,
    'SD': 7,
    'SR': 8,
    'SJ': 2,
    'SE': 2,
    'CH': 2,
    'SY': 5,
    'TW': 5,
    'TJ': 5,
    'TZ': 7,
    'TH': 5,
    'TL': 5,
    'TG': 7,
    'TK': 5,
    'TO': 5,
    'TT': 8,
    'TN': 7,
    'TR': 5,
    'TM': 5,
    'TC': 8,
    'TV': 5,
    'UG': 7,
    'UA': 2,
    'AE': 5,
    'GB': 2,
    'US': 1,
    'UM': 5,
    'UY': 8,
    'UZ': 5,
    'VU': 5,
    'VE': 8,
    'VN': 5,
    'VG': 8,
    'VI': 8,
    'WF': 7,
    'EH': 7,
    'YE': 5,
    'ZM': 7,
    'ZW': 7
    };
  }

  setRegion(location) {
    if (location) {
      let region = this._countriesMap[location] || 1;
      this.globalData.selectedFilters.regionId = region;
    } else {
      this.globalData.selectedFilters.regionId = 1;
    }
  }
}
