<p-dialog class="qr-share"
  [(visible)]="globalData.applicationState.showQrShareDialog"
  [dismissableMask]="true"
  [modal]="true"
  [showHeader]="false"
  [draggable]="false"
  [resizable]="false"
  [responsive]="true"
  [appendTo]="'body'"
  [closable]="true"
  [closeOnEscape]="true"
  [styleClass]="'q-share dps-dialog dps-qr-share-dialog'">
  <div id="qr"></div>
</p-dialog>
