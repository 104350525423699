import { Router, NavigationEnd } from '@angular/router';
import { Injectable } from '@angular/core';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RoutingStateService {
  private history = [];
  private historyExclude = ['gradeId', 'exclude', 'businessUnitIds', 'brandIds', 'industryIds', 'applicationIds', 'categoryIds'];

  constructor(
    private router: Router
  ) { }

  public loadRouting(): void {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(({ urlAfterRedirects }: NavigationEnd) => {
        let param = urlAfterRedirects.split('?');
        if (!this.historyExclude.some((v) => param[param.length - 1].includes(v))) {
          this.history = [...this.history, urlAfterRedirects];
        }
      });
  }

  public getHistory(): string[] {
    return this.history;
  }

  public getPreviousUrl(): string {
    return this.history[this.history.length - 2] || '/index';
  }
}
