import { PipesModule } from './../general/pipes/pipes.module';
import { MatIconModule } from '@angular/material/icon';
import { NgSelectModule } from '@ng-select/ng-select';
import { RadarChartFiltersComponent } from './radar-chart-filters/radar-chart-filters.component';
import { RadarChartDirective } from './directives/radar-chart.directive';
import { RadarChartPipe } from './pipes/radar-chart-pipe.pipe';
import { RadarChartSidebarToggleComponent } from './radar-chart-sidebar-toggle/radar-chart-sidebar-toggle.component';
import { RadarChartComponent } from './radar-chart.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragulaModule } from 'ng2-dragula';
import { UiModule } from '../general/ui/ui.module';
import { FormsModule } from '@angular/forms';
import { RadarChartSalesPipePipe } from './pipes/radar-chart-sales-pipe.pipe';
import { RadarChartMatchingPropertiesPipe } from './pipes/radar-chart-matching-properties.pipe';
import { RadarChartMatchingSalesPropertiesPipe } from './pipes/radar-chart-matching-sales-properties.pipe';
import { TooltipModule } from 'primeng/tooltip';

@NgModule({
  imports: [
    CommonModule,
    DragulaModule,
    NgSelectModule,
    UiModule,
    FormsModule,
    MatIconModule,
    PipesModule,
    TooltipModule
  ],
  declarations: [
    RadarChartComponent,
    RadarChartSidebarToggleComponent,
    RadarChartPipe,
    RadarChartDirective,
    RadarChartFiltersComponent,
    RadarChartSalesPipePipe,
    RadarChartMatchingPropertiesPipe,
    RadarChartMatchingSalesPropertiesPipe
  ],
  exports: [
    RadarChartDirective,
    RadarChartPipe,
    RadarChartSalesPipePipe
  ]
})
export class RadarChartModule { }
