<div class="content">
  <pdf-viewer
    *ngIf="globalData.gradeDetail && globalData.gradeDetail.url"
    [src]="globalData.gradeDetail.url"
    [render-text]="true"
    [zoom]="zoom"
    [fit-to-page]="fitToPage"
    [original-size]="originalSize"
    [autoresize]="autoResize"
    [original-size]="true"
    style="width: 100%; position: relative; display: block;">
  </pdf-viewer>
  <div class="no-resources" *ngIf="globalData.gradeDetail && globalData.gradeDetail.loaded && !globalData.gradeDetail.url && !globalData.gradeDetail.tabs"
    i18n="No resources for the pdf@@pdfNoResources">There are no resources available for this grade.</div>
</div>
<div class="actions" *ngIf="globalData.gradeDetail?.url">
  <div class="button-round zoom" (click)="adjustZoom(0.3)">+</div>
  <div class="button-round zoom min" (click)="adjustZoom(-0.3)">-</div>
  <!-- <div class="button-round fit-to-page"></div> -->
</div>
