import { GlobalData } from './../../general/services/global-data.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'dps-grade-detail-related',
  templateUrl: './grade-detail-related.component.html',
  styleUrls: ['./grade-detail-related.component.scss']
})
export class GradeDetailRelatedComponent implements OnInit {

  constructor(public globalData: GlobalData, public route: ActivatedRoute) { }
  tabId: number;
  relatedData = [];

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.tabId = params.tabId;
    });
  }
}
