import { NameByIdPipe } from './filter/nameById.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SortGradesPipe } from './sort/sortGrades.pipe';
import { ValuesPipe } from './transform/values.pipe';
import { FilterOutPipe } from './filter/filterOut.pipe';
import { ReversePipe } from './transform/reverse.pipe';
import { FilterPropertiesPipe } from './filter/filter-properties.pipe';
import { FilterSalesPropertiesPipe } from './filter/filter-sales-properties.pipe';
import { SortSalesGradesPipe } from './sort/sort-sales-grades.pipe';
import { SortApplicationGradesPipe } from './sort/sort-application-grades.pipe';
import { KeyValuesPipe } from './transform/key-values.pipe';
import { FilterApplicationsPipe } from './filter/filter-applications.pipe';
import { SiloFilterPipe } from './filter/silo-filter.pipe';
import { FilterPipe } from './filter/filter.pipe';
import { FilterPlotChartGradesPipe } from './filter/filter-plot-chart-grades.pipe';
import { EncodeURIPipe } from './transform/encode-uri.pipe';
import { SortPipe } from './sort/sort.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    ValuesPipe,
    FilterOutPipe,
    ReversePipe,
    SortGradesPipe,
    FilterPropertiesPipe,
    FilterSalesPropertiesPipe,
    SortSalesGradesPipe,
    SortApplicationGradesPipe,
    KeyValuesPipe,
    FilterApplicationsPipe,
    SiloFilterPipe,
    FilterPipe,
    FilterPlotChartGradesPipe,
    NameByIdPipe,
    EncodeURIPipe,
    SortPipe
  ],
  exports: [
    ValuesPipe,
    FilterOutPipe,
    ReversePipe,
    SortGradesPipe,
    FilterPropertiesPipe,
    FilterSalesPropertiesPipe,
    SortSalesGradesPipe,
    SortApplicationGradesPipe,
    KeyValuesPipe,
    FilterApplicationsPipe,
    SiloFilterPipe,
    FilterPipe,
    FilterPlotChartGradesPipe,
    NameByIdPipe,
    EncodeURIPipe,
    SortPipe
  ]
})
export class PipesModule { }
