import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FavoritesComponent } from './favorites.component';
import { PipesModule } from '../general/pipes/pipes.module';
import { UiModule } from '../general/ui/ui.module';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    FormsModule,
    PipesModule,
    UiModule
  ],
  declarations: [
    FavoritesComponent
  ]
})
export class FavoritesModule { }
