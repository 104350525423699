import { DpsFilterIdName } from './../filter/dpsFilterIdName';
import { DpsRange } from './../filter/dpsFilterSalesProperty';

export class DpsSalesProperty extends DpsFilterIdName {
    textValues: string[];
    unitOfMeasure: string;
    type: string;
    range: DpsRange;
    testMethods: string[];
    hasRadarMatch: boolean;
    hasFirstPlotMatch: boolean;
    hasSecondPlotMatch: boolean;

    constructor() {
      super();
    }
}
