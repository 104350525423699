import { AnalyticsService } from './../../general/services/analytics.service';
import { GlobalData } from './../../general/services/global-data.service';
import { FavoritesService } from './../../general/services/favorites.service';
import { Component, OnInit, ElementRef } from '@angular/core';
import { AnalyticsEventsService } from 'src/app/general/services/analytics-events.service';
import { EventTypes } from 'src/app/general/enums/analytics/event-types';

@Component({
  selector: 'dps-plot-chart-filters',
  templateUrl: './plot-chart-filters.component.html',
  styleUrls: ['./plot-chart-filters.component.scss']
})
export class PlotChartFiltersComponent implements OnInit {
  constructor(public globalData: GlobalData,
    private _favoritesService: FavoritesService,
    private _analyticsService: AnalyticsService,
    private _analyticsEventsService: AnalyticsEventsService,
    private hostElement: ElementRef) { }

  ngOnInit() {
  }

  checkId(a, b) {
    return a && b && a.id === b.id;
  }

  propertyChanged(property) {
    this.globalData.propertiesChanged = {};
    this._favoritesService.updateCurrentFavorite();
    let inputs = this.hostElement.nativeElement.getElementsByTagName('input');
    for (let i = 0; i < inputs.length; i++) {
      inputs[i].blur();
    }
    // analytics
    if (property) {
      this._analyticsService.sendEvent(this._analyticsEventsService.getEvent(EventTypes.XY_CHART_ADD_PROPERTY), property.name);
    }

  }

  switchAxes() {
    if (this.globalData.applicationState.propertyType.id === 'GP') {
      this.globalData.selectedProperties.unshift(this.globalData.selectedProperties.pop());
    } else {
      this.globalData.selectedSalesProperties.unshift(this.globalData.selectedSalesProperties.pop());
    }
    this.globalData.propertiesChanged = {};
    this._favoritesService.updateCurrentFavorite();
    // analytics
    this._analyticsService.sendEvent(this._analyticsEventsService.getEvent(EventTypes.XY_CHART_SWITCH_AXES), null);
  }
}
