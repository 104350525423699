import { DpsGrade } from './../../general/models/data/dpsGrade';
import { DpsFilterProperty } from './../../general/models/filter/dpsFilterProperty';
import { Pipe, PipeTransform } from '@angular/core';
import { _ } from 'underscore';

@Pipe({
  name: 'radarChartMatchingProperties'
})
export class RadarChartMatchingPropertiesPipe implements PipeTransform {

  transform(globalProperties: DpsFilterProperty[], grades: DpsGrade[], gradeGlobalProperties: any): any[] {
    let result = [];
    _.each(globalProperties, (p) => {
      if (!p.textValues || p.textValues.length === 0) {
        let hasRadarMatch = false;
        _.every(grades, function(g: DpsGrade) {
          if (gradeGlobalProperties.hasOwnProperty(g.substance)) {
            if (gradeGlobalProperties[g.substance].hasOwnProperty(p.id)) {
              hasRadarMatch = true;
              return false;
            }
          }
          return true;
        });
        p.hasRadarMatch = hasRadarMatch;
        result.push(p);
      }
    });
    return result;
  }
}
