import { DpsFilterIdName } from './dpsFilterIdName';
export class DpsFilterSalesProperty extends DpsFilterIdName {
    selected: boolean;
    textValues: string[];
    unitOfMeasure: string;
    salesRange: DpsSalesRange;
    testMethods: string[];

    constructor() {
      super();
    }
}

export class DpsSalesRange {
  minimumRange: DpsRange;
  maximumRange: DpsRange;
  targetRange: DpsRange;
}

export class DpsRange {
  minimum: number;
  maximum: number;
}
