<div class="content-container grade-detail-container">
  <router-outlet></router-outlet>
  <div class="tabs" *ngIf="globalData.gradeDetail?.url || globalData.gradeDetail?.tabs">
    <div class="tab-container">

      <a class="tab" (click)="globalData.applicationState.applicationActive = false;"
        *ngIf="(globalData.applicationState.propertyType.id === dataTypes.Product || globalData.gradeDetail.dataType == dataTypes.Product) && globalData.gradeDetail?.url"
        [routerLink]="['/grade-detail', gradeDetailId]" routerLinkActive="active"
        [routerLinkActiveOptions]="{exact: true}"
        i18n="Grade detail product data sheet tab@@gradeDetailProductDataSheet">
        Product data sheet
      </a>

      <a class="tab"
        *ngIf="(globalData.applicationState.propertyType.id === dataTypes.Sales || globalData.gradeDetail.dataType == dataTypes.Sales) && globalData.gradeDetail?.url"
        [routerLink]="['/grade-detail/', gradeDetailId]" routerLinkActive="active"
        [routerLinkActiveOptions]="{exact: true}" (click)="globalData.applicationState.applicationActive = false;"
        i18n="Grade detail sales spec sheet tab@@gradeDetailSalesSpecificationsSheet">
        Sales specifications sheet
      </a>

      <ng-container *ngIf="globalData.gradeDetail?.tabs">
        <a class="tab" *ngFor="let tab of globalData.gradeDetail.tabs; let i = index;"
          [routerLink]="['/grade-detail/', gradeDetailId, 'related', i + 1]" routerLinkActive="active"
          [routerLinkActiveOptions]="{exact: true}" (click)="globalData.applicationState.applicationActive = false;"
          [innerHTML]="tab.tabName">
        </a>
      </ng-container>

    </div>
  </div>
</div>