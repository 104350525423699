<div class="table">
  <table>
    <thead>
      <tr>
        <th class="property"><a class="name" i18n="Test methods table property header@@propertyHeader">Property</a></th>
        <th><a class="name" i18n="Test methods table test methods header@@testMethodsHeader">Test Methods</a></th>
      </tr>
    </thead>
    <tbody *ngIf="globalData.data && globalData.data.globalProperties && globalData.applicationState.propertyType.id === 'GP'">
      <tr *ngFor="let property of globalData.data.globalProperties | filterProperties:globalData.selectedFilters.propertyFilters:globalData.filtersChanged; let i = index"
        [ngClass]="{ odd: i%2 !== 0, even: i%2 === 0 }">
        <td class="property">
          <div class="name">{{property.name}}
            <span *ngIf="property.unitOfMeasure != '-'">{{property.unitOfMeasure}}</span>
          </div>
        </td>
        <td>
          <div class="name">
            <span *ngFor="let testMethod of property.testMethods; index as i;">{{testMethod + (i === property.testMethods.length - 1 ? '' : '; ')}}</span>
          </div>
        </td>
      </tr>
    </tbody>
    <tbody *ngIf="globalData.data && globalData.data.salesProperties && globalData.applicationState.propertyType.id === 'SP'">
      <tr *ngFor="let property of globalData.data.salesProperties | filterSalesProperties:globalData.selectedFilters.salesPropertyFilters; let i = index"
        [ngClass]="{ odd: i%2 !== 0, even: i%2 === 0 }">
        <td class="property">
          <div class="name">
            {{property.name}} <span *ngIf="property.unitOfMeasure != '-'">{{property.unitOfMeasure}}</span>
          </div>
        </td>
        <td>
          <div class="name">
            <span *ngFor="let testMethod of property.testMethods; index as i;">
              {{testMethod + (i === property.testMethods.length - 1 ? '' : '; ') }}
            </span>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
