<p-dialog class="guided-tour"
[(visible)]="globalData.applicationState.showGuidedTourDialog"
[dismissableMask]="true"
[modal]="true"
[draggable]="false"
[resizable]="false"
[responsive]="true"
[appendTo]="'body'"
[closable]="false"
[closeOnEscape]="false"
[styleClass]="'guided-tour dps-dialog dps-guided-tour-dialog' + (step === 0 || step === 9 ? ' gradient' : '')">

  <div class="intro" *ngIf="step === 0">
    <div class="close" (click)="finish()"></div>
    <div class="title" i18n="Title in the intro screen of the @@introTitleGuidedTour">The Digital Product Selector</div>
    <div class="title-large" i18n="Subtitle in the intro screen of the @@introSubtitleGuidedTour">find your fit.</div>
    <div class="content">
    </div>
    <a class="cta-button" (click)="step = step + 1;" i18n="Call to action button in the intro screen of the @@introCallToActionButtonGuidedTour">Show me how</a>
  </div>

  <div class="step animated fadeIn" *ngIf="step === 1">
    <div class="header">
      <div class="title" i18n="Title of step one of the @@stepOneTitleGuidedTour">Comprehensive tables</div>
      <div class="title-bold" i18n="Subtitle of step one of the @@stepOneSubtitleGuidedTour">Allow you to search for the right performance fit.</div>
    </div>
    <div class="content">
      <ng-container *ngIf="mobile">
          <video loop autoplay>
            <source [src]="'https://www.exxonmobilchemical.com.cn/dmm3bwsv3/assetstream.aspx?assetid=103193&mediaformatid=50039&destinationid=10016'" type="video/mp4">
          </video>
      </ng-container>
      <ng-container *ngIf="!mobile" >
          <video loop autoplay>
            <source [src]="'https://www.exxonmobilchemical.com.cn/dmm3bwsv3/assetstream.aspx?assetid=103012&mediaformatid=50039&destinationid=10016'" type="video/mp4">
          </video>
      </ng-container>
    </div>
    <div class="footer">
        <a class="cta-button" (click)="step = step + 1;" i18n="Next button in a step screen of the @@nextStepButtonGuidedTour">Next</a>
        <a class="skip-button" (click)="finish()" i18n="Skip button in a step screen of the @@skipStepButtonGuidedTour">Skip</a>
    </div>
  </div>

  <div class="step animated fadeIn" *ngIf="step === 2">
    <div class="header">
      <div class="title" i18n="Title of step two of the @@stepTwoTitleGuidedTour">Comprehensive tables</div>
      <div class="title-bold" i18n="Subtitle of step two of the @@stepTwoSubtitleGuidedTour">Customize and focus on what matters to you.</div>
    </div>
    <div class="content">
      <ng-container *ngIf="mobile">
        <video loop autoplay>
          <source [src]="'https://www.exxonmobilchemical.com.cn/dmm3bwsv3/assetstream.aspx?assetid=103195&mediaformatid=50039&destinationid=10016'" type="video/mp4">
        </video>
      </ng-container>
      <ng-container *ngIf="!mobile">
        <video loop autoplay>
          <source [src]="'https://www.exxonmobilchemical.com.cn/dmm3bwsv3/assetstream.aspx?assetid=103013&mediaformatid=50039&destinationid=10016'" type="video/mp4">
        </video>
      </ng-container>
    </div>
    <div class="footer">
        <a class="previous-button" (click)="step = step - 1;" i18n="Previous button in a step screen of the @@previousStepButtonGuidedTour">Previous</a>
        <a class="cta-button" (click)="step = step + 1;" i18n="Next button in a step screen of the @@nextStepButtonGuidedTour">Next</a>
        <a class="skip-button" (click)="finish()" i18n="Skip button in a step screen of the @@skipStepButtonGuidedTour">Skip</a>
    </div>
  </div>

  <div class="step animated fadeIn" *ngIf="step === 3">
    <div class="header">
      <div class="title" i18n="Title of step three of the @@stepThreeTitleGuidedTour">Comprehensive tables</div>
      <div class="title-bold" i18n="Subtitle of step three of the @@stepThreeSubtitleGuidedTour">Zero in on the properties you need.</div>
    </div>
    <div class="content">
      <ng-container *ngIf="mobile">
        <video loop autoplay>
          <source [src]="'https://www.exxonmobilchemical.com.cn/dmm3bwsv3/assetstream.aspx?assetid=103191&mediaformatid=50039&destinationid=10016'" type="video/mp4">
        </video>
      </ng-container>
      <ng-container *ngIf="!mobile">
        <video loop autoplay>
          <source [src]="'https://www.exxonmobilchemical.com.cn/dmm3bwsv3/assetstream.aspx?assetid=103016&mediaformatid=50039&destinationid=10016'" type="video/mp4">
        </video>
      </ng-container>
    </div>
    <div class="footer">
        <a class="previous-button" (click)="step = step - 1;" i18n="Previous button in a step screen of the @@previousStepButtonGuidedTour">Previous</a>
        <a class="cta-button" (click)="step = step + 1;" i18n="Next button in a step screen of the @@nextStepButtonGuidedTour">Next</a>
        <a class="skip-button" (click)="finish()" i18n="Skip button in a step screen of the @@skipStepButtonGuidedTour">Skip</a>
    </div>
  </div>

  <div class="step animated fadeIn" *ngIf="step === 4">
    <div class="header">
      <div class="title" i18n="Title of step four of the @@stepFourTitleGuidedTour">Comprehensive tables</div>
      <div class="title-bold" i18n="Subtitle of step four of the @@stepFourSubtitleGuidedTour">Everything you need to guide your decisions.</div>
    </div>
    <div class="content">
      <ng-container *ngIf="mobile">
        <video loop autoplay>
          <source [src]="'https://www.exxonmobilchemical.com.cn/dmm3bwsv3/assetstream.aspx?assetid=103192&mediaformatid=50039&destinationid=10016'" type="video/mp4">
        </video>
      </ng-container>
      <ng-container *ngIf="!mobile">
        <video loop autoplay>
          <source [src]="'https://www.exxonmobilchemical.com.cn/dmm3bwsv3/assetstream.aspx?assetid=103014&mediaformatid=50039&destinationid=10016'" type="video/mp4">
        </video>
      </ng-container>
    </div>
    <div class="footer">
        <a class="previous-button" (click)="step = step - 1;" i18n="Previous button in a step screen of the @@previousStepButtonGuidedTour">Previous</a>
        <a class="cta-button" (click)="step = step + 1;" i18n="Next button in a step screen of the @@nextStepButtonGuidedTour">Next</a>
        <a class="skip-button" (click)="finish()" i18n="Skip button in a step screen of the @@skipStepButtonGuidedTour">Skip</a>
    </div>
  </div>

  <div class="step animated fadeIn" *ngIf="step === 5">
    <div class="header">
      <div class="title" i18n="Title of step five of the @@stepFiveTitleGuidedTour">Put our products to the test</div>
      <div class="title-bold" i18n="Subtitle of step five of the @@stepFiveSubtitleGuidedTour">to find the grades that fit.</div>
    </div>
    <div class="content">
      <ng-container *ngIf="mobile">
        <video loop autoplay>
          <source [src]="'https://www.exxonmobilchemical.com.cn/dmm3bwsv3/assetstream.aspx?assetid=103197&mediaformatid=50039&destinationid=10016'" type="video/mp4">
        </video>
      </ng-container>
      <ng-container *ngIf="!mobile">
        <video loop autoplay>
          <source [src]="'https://www.exxonmobilchemical.com.cn/dmm3bwsv3/assetstream.aspx?assetid=103015&mediaformatid=50039&destinationid=10016'" type="video/mp4">
        </video>
      </ng-container>
    </div>
    <div class="footer">
        <a class="previous-button" (click)="step = step - 1;" i18n="Previous button in a step screen of the @@previousStepButtonGuidedTour">Previous</a>
        <a class="cta-button" (click)="step = step + 1;" i18n="Next button in a step screen of the @@nextStepButtonGuidedTour">Next</a>
        <a class="skip-button" (click)="finish()" i18n="Skip button in a step screen of the @@skipStepButtonGuidedTour">Skip</a>
    </div>
  </div>

  <div class="step animated fadeIn" *ngIf="step === 6">
    <div class="header">
      <div class="title" i18n="Title of step six of the @@stepSixTitleGuidedTour">Put our products to the test</div>
      <div class="title-bold" i18n="Subtitle of step six of the @@stepSixSubtitleGuidedTour">Plot performance across 2 dimensions.</div>
    </div>
    <div class="content">
      <ng-container *ngIf="mobile">
        <video loop autoplay>
          <source [src]="'https://www.exxonmobilchemical.com.cn/dmm3bwsv3/assetstream.aspx?assetid=103196&mediaformatid=50039&destinationid=10016'" type="video/mp4">
        </video>
      </ng-container>
      <ng-container *ngIf="!mobile">
        <video loop autoplay>
          <source [src]="'https://www.exxonmobilchemical.com.cn/dmm3bwsv3/assetstream.aspx?assetid=103018&mediaformatid=50039&destinationid=10016'" type="video/mp4">
        </video>
      </ng-container>
    </div>
    <div class="footer">
        <a class="previous-button" (click)="step = step - 1;" i18n="Previous button in a step screen of the @@previousStepButtonGuidedTour">Previous</a>
        <a class="cta-button" (click)="step = step + 1;" i18n="Next button in a step screen of the @@nextStepButtonGuidedTour">Next</a>
        <a class="skip-button" (click)="finish()" i18n="Skip button in a step screen of the @@skipStepButtonGuidedTour">Skip</a>
    </div>
  </div>

  <div class="step animated fadeIn" *ngIf="step === 7">
    <div class="header">
      <div class="title" i18n="Title of step seven of the @@stepSevenTitleGuidedTour">Put our products to the test</div>
      <div class="title-bold" i18n="Subtitle of step seven of the @@stepSevenSubtitleGuidedTour">Visualize data of up to 9 properties.</div>
    </div>
    <div class="content">
      <ng-container *ngIf="mobile">
        <video loop autoplay>
          <source [src]="'https://www.exxonmobilchemical.com.cn/dmm3bwsv3/assetstream.aspx?assetid=103194&mediaformatid=50039&destinationid=10016'" type="video/mp4">
        </video>
      </ng-container>
      <ng-container *ngIf="!mobile">
        <video loop autoplay>
          <source [src]="'https://www.exxonmobilchemical.com.cn/dmm3bwsv3/assetstream.aspx?assetid=103017&mediaformatid=50039&destinationid=10016'" type="video/mp4">
        </video>
      </ng-container>
    </div>
    <div class="footer">
        <a class="previous-button" (click)="step = step - 1;" i18n="Previous button in a step screen of the @@previousStepButtonGuidedTour">Previous</a>
        <a class="cta-button" (click)="step = step + 1;" i18n="Next button in a step screen of the @@nextStepButtonGuidedTour">Next</a>
        <a class="skip-button" (click)="finish()" i18n="Skip button in a step screen of the @@skipStepButtonGuidedTour">Skip</a>
    </div>
  </div>

  <div class="step animated fadeIn" *ngIf="step === 8">
    <div class="header">
      <div class="title" i18n="Title of step eight of the @@stepEightTitleGuidedTour">Put our products to the test</div>
      <div class="title-bold" i18n="Subtitle of step eight of the @@stepEightSubtitleGuidedTour">Manage a personal collection of favorites.</div>
    </div>
    <div class="content">
      <ng-container *ngIf="mobile">
        <video loop autoplay>
          <source [src]="'https://www.exxonmobilchemical.com.cn/dmm3bwsv3/assetstream.aspx?assetid=103198&mediaformatid=50039&destinationid=10016'" type="video/mp4">
        </video>
      </ng-container>
      <ng-container *ngIf="!mobile">
        <video loop autoplay>
          <source [src]="'https://www.exxonmobilchemical.com.cn/dmm3bwsv3/assetstream.aspx?assetid=103019&mediaformatid=50039&destinationid=10016'" type="video/mp4">
        </video>
      </ng-container>
    </div>
    <div class="footer">
        <a class="previous-button" (click)="step = step - 1;" i18n="Previous button in a step screen of the @@previousStepButtonGuidedTour">Previous</a>
        <a class="cta-button" (click)="step = step + 1;" i18n="Next button in a step screen of the @@nextStepButtonGuidedTour">Next</a>
        <a class="skip-button" (click)="finish()" i18n="Skip button in a step screen of the @@skipStepButtonGuidedTour">Skip</a>
    </div>
  </div>

  <div class="intro animated fadeIn" *ngIf="step === 9">
    <div class="close" (click)="finish()"></div>
    <div class="title" i18n="Title of the last step of the @@lastStepTitleGuidedTour">The Digital Product Selector</div>
    <div class="title-large" i18n="Subtitle of the last step of the @@lastStepSubtitleGuidedTour">find your fit.</div>
    <div class="content"></div>
    <a class="cta-button" (click)="finish()" i18n="Finish button in the last screen of the @@finishButtonGuidedTour">Get started</a>
  </div>

</p-dialog>
