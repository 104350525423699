import { PageType } from "../../enums/analytics/page-type";
import { AnalyticsConstants } from "../../constants/analytics";

export class PageData {
    page_type: PageType;
    page_title: string = AnalyticsConstants.DefaultPageTitle

    constructor(pageType, pageTitle?) {
        this.page_type = pageType;
        this.page_title = pageTitle ? pageTitle : this.page_title;
    }
}