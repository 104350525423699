import { GlobalData } from './../../general/services/global-data.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dps-radar-chart-sidebar-toggle',
  templateUrl: './radar-chart-sidebar-toggle.component.html',
  styleUrls: ['./radar-chart-sidebar-toggle.component.scss']
})
export class RadarChartSidebarToggleComponent implements OnInit {
  constructor(public globalData: GlobalData) { }

  ngOnInit() {
  }

  toggleRadarChartSidebar() {
    this.globalData.applicationState.radarChartSidebarOpen = !this.globalData.applicationState.radarChartSidebarOpen;
    this.globalData.applicationState.regionsSidebarOpen = false;
    this.globalData.applicationState.helpSidebarOpen = false;
    this.globalData.applicationState.mobileActionsMenu = false;
  }
}
