import { UiModule } from './../general/ui/ui.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatIconModule } from '@angular/material/icon';
import { SearchComponent } from './search.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AutoCompleteModule } from 'primeng/autocomplete';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatIconModule,
    NgSelectModule,
    AutoCompleteModule,
    InfiniteScrollModule,
    UiModule
  ],
  declarations: [
    SearchComponent
  ]
})
export class SearchModule { }
