import { AnalyticsEvent } from "../models/analytics/analytics-event";
import { AnalyticsConstants } from "../constants/analytics";
import { EventCategories } from "../enums/analytics/event-categories";
import { EventTypes } from "../enums/analytics/event-types";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})

export class AnalyticsEventsService {

    private events: AnalyticsEvent[] = [];

    constructor() {
        this.initializeEvents();
    }

    private initializeEvents() {
        //#region GA + SITECORE

        this.events[EventTypes.GRADE_DETAIL_FAVORITE] = new AnalyticsEvent(
            AnalyticsConstants.GRADE_DETAIL_FAVORITE.Goal,
            EventCategories.CustomerEnablementAndEducation,
            AnalyticsConstants.GRADE_DETAIL_FAVORITE.Action,
            AnalyticsConstants.GRADE_DETAIL_FAVORITE.Event, true);

        this.events[EventTypes.DATA_SET_SELECTION] = new AnalyticsEvent(
            AnalyticsConstants.DATA_SET_SELECTION.Goal,
            EventCategories.CustomerEnablementAndEducation,
            AnalyticsConstants.DATA_SET_SELECTION.Action,
            AnalyticsConstants.DATA_SET_SELECTION.Event, true, false);

        this.events[EventTypes.DATATABLE_SHARE] = new AnalyticsEvent(
            AnalyticsConstants.DATATABLE_SHARE.Goal,
            EventCategories.CustomerEnablementAndEducation,
            AnalyticsConstants.DATATABLE_SHARE.Action,
            AnalyticsConstants.DATATABLE_SHARE.Event, true
        )

        this.events[EventTypes.HELP_BUTTON_SELECTED] = new AnalyticsEvent(
            AnalyticsConstants.HELP_BUTTON_SELECTED.Goal,
            EventCategories.CustomerEnablementAndEducation,
            AnalyticsConstants.HELP_BUTTON_SELECTED.Action,
            AnalyticsConstants.HELP_BUTTON_SELECTED.Event, true, false
        )

        this.events[EventTypes.GRADE_DETAIL_REQUEST_A_SAMPLE] = new AnalyticsEvent(
            AnalyticsConstants.GRADE_DETAIL_REQUEST_A_SAMPLE.Goal,
            EventCategories.CustomerEnablementAndEducation,
            AnalyticsConstants.GRADE_DETAIL_REQUEST_A_SAMPLE.Action,
            AnalyticsConstants.GRADE_DETAIL_REQUEST_A_SAMPLE.Event, true
        )

        this.events[EventTypes.EXPLORE_FILTER_CHOICE] = new AnalyticsEvent(
            AnalyticsConstants.EXPLORE_FILTER_CHOICE.Goal,
            EventCategories.CustomerEnablementAndEducation,
            AnalyticsConstants.EXPLORE_FILTER_CHOICE.Action,
            AnalyticsConstants.EXPLORE_FILTER_CHOICE.Event, true, false
        )

        this.events[EventTypes.EXPLORE_REGION] = new AnalyticsEvent(
            AnalyticsConstants.EXPLORE_REGION.Goal,
            EventCategories.CustomerEnablementAndEducation,
            AnalyticsConstants.EXPLORE_REGION.Action,
            AnalyticsConstants.EXPLORE_REGION.Event, true, false
        )

        this.events[EventTypes.XY_CHART_SELECTED] = new AnalyticsEvent(
            AnalyticsConstants.XY_CHART_SELECTED.Goal,
            EventCategories.CustomerEnablementAndEducation,
            AnalyticsConstants.XY_CHART_SELECTED.Action,
            AnalyticsConstants.XY_CHART_SELECTED.Event, true, false
        )

        this.events[EventTypes.XY_CHART_XY_SELECTED] = new AnalyticsEvent(
            AnalyticsConstants.XY_CHART_XY_SELECTED.Goal,
            EventCategories.CustomerEnablementAndEducation,
            AnalyticsConstants.XY_CHART_XY_SELECTED.Action,
            AnalyticsConstants.XY_CHART_XY_SELECTED.Event, true, false
        )

        this.events[EventTypes.GRADE_DETAIL_DOWNLOAD_GA] = new AnalyticsEvent(
            AnalyticsConstants.GRADE_DETAIL_DOWNLOAD_GA.Goal,
            EventCategories.CustomerEnablementAndEducation,
            AnalyticsConstants.GRADE_DETAIL_DOWNLOAD_GA.Action,
            AnalyticsConstants.GRADE_DETAIL_DOWNLOAD_GA.Event, true, false
        )

        this.events[EventTypes.GLOBAL_EXPORT_GA] = new AnalyticsEvent(
            AnalyticsConstants.GLOBAL_EXPORT_GA.Goal,
            EventCategories.CustomerEnablementAndEducation,
            AnalyticsConstants.GLOBAL_EXPORT_GA.Action,
            AnalyticsConstants.GLOBAL_EXPORT_GA.Event, true, false
        )

        //#endregion
        //#region SITECORE ONLY

        this.events[EventTypes.EXPLORE_CLICK] = new AnalyticsEvent(
            AnalyticsConstants.EXPLORE_CLICK.Goal
        )

        this.events[EventTypes.DATATABLE_SORT_PROPERTY] = new AnalyticsEvent(
            AnalyticsConstants.DATATABLE_SORT_PROPERTY.Goal
        )

        this.events[EventTypes.DATATABLE_ADD_TO_XY] = new AnalyticsEvent(
            AnalyticsConstants.DATATABLE_ADD_TO_XY.Goal
        )

        this.events[EventTypes.DATATABLE_ADD_TO_SPIDER] = new AnalyticsEvent(
            AnalyticsConstants.DATATABLE_ADD_TO_SPIDER.Goal
        )

        this.events[EventTypes.DATATABLE_SORT_APPLICATION] = new AnalyticsEvent(
            AnalyticsConstants.DATATABLE_SORT_APPLICATION.Goal
        )

        this.events[EventTypes.DATATABLE_APPLICATIONS_TAB] = new AnalyticsEvent(
            AnalyticsConstants.DATATABLE_APPLICATIONS_TAB.Goal
        )

        this.events[EventTypes.DATATABLE_PROPERTIES_TAB] = new AnalyticsEvent(
            AnalyticsConstants.DATATABLE_PROPERTIES_TAB.Goal
        )

        this.events[EventTypes.DATATABLE_TESTMETHODS_TAB] = new AnalyticsEvent(
            AnalyticsConstants.DATATABLE_TESTMETHODS_TAB.Goal
        )

        this.events[EventTypes.DATATABLE_FAVORITE] = new AnalyticsEvent(
            AnalyticsConstants.DATATABLE_FAVORITE.Goal
        )

        this.events[EventTypes.DATATABLE_DOWNLOAD] = new AnalyticsEvent(
            AnalyticsConstants.DATATABLE_DOWNLOAD.Goal
        )

        this.events[EventTypes.FILTER_REGION] = new AnalyticsEvent(
            AnalyticsConstants.FILTER_REGION.Goal
        )

        this.events[EventTypes.FILTER_UNIT_SYSTEM] = new AnalyticsEvent(
            AnalyticsConstants.FILTER_UNIT_SYSTEM.Goal
        )

        this.events[EventTypes.FILTER_BUSINESS_UNIT] = new AnalyticsEvent(
            AnalyticsConstants.FILTER_BUSINESS_UNIT.Goal
        )

        this.events[EventTypes.FILTER_PRODUCT_TYPE] = new AnalyticsEvent(
            AnalyticsConstants.FILTER_PRODUCT_TYPE.Goal
        )

        this.events[EventTypes.FILTER_APPLICATION] = new AnalyticsEvent(
            AnalyticsConstants.FILTER_APPLICATION.Goal
        )

        this.events[EventTypes.FILTER_BRAND] = new AnalyticsEvent(
            AnalyticsConstants.FILTER_BRAND.Goal
        )

        this.events[EventTypes.FILTER_INDUSTRY] = new AnalyticsEvent(
            AnalyticsConstants.FILTER_INDUSTRY.Goal
        )

        this.events[EventTypes.FILTER_PROPERTY] = new AnalyticsEvent(
            AnalyticsConstants.FILTER_PROPERTY.Goal
        )

        this.events[EventTypes.FILTER_REFINED_PROPERTY] = new AnalyticsEvent(
            AnalyticsConstants.FILTER_REFINED_PROPERTY.Goal
        )

        this.events[EventTypes.GRADE_DETAIL_ADD_TO_SPIDER] = new AnalyticsEvent(
            AnalyticsConstants.GRADE_DETAIL_ADD_TO_SPIDER.Goal
        )

        this.events[EventTypes.GRADE_DETAIL_SHARE] = new AnalyticsEvent(
            AnalyticsConstants.GRADE_DETAIL_SHARE.Goal
        )

        this.events[EventTypes.GRADE_DETAIL_DOWNLOAD] = new AnalyticsEvent(
            AnalyticsConstants.GRADE_DETAIL_DOWNLOAD.Goal
        )

        this.events[EventTypes.XY_CHART_ADD_PROPERTY] = new AnalyticsEvent(
            AnalyticsConstants.XY_CHART_ADD_PROPERTY.Goal
        )

        this.events[EventTypes.XY_CHART_SWITCH_AXES] = new AnalyticsEvent(
            AnalyticsConstants.XY_CHART_SWITCH_AXES.Goal
        )

        this.events[EventTypes.XY_CHART_DOWNLOAD] = new AnalyticsEvent(
            AnalyticsConstants.XY_CHART_DOWNLOAD.Goal
        )

        this.events[EventTypes.XY_CHART_SHARE] = new AnalyticsEvent(
            AnalyticsConstants.XY_CHART_SHARE.Goal
        )

        this.events[EventTypes.XY_CHART_FAVORITE] = new AnalyticsEvent(
            AnalyticsConstants.XY_CHART_FAVORITE.Goal
        )

        this.events[EventTypes.SPIDER_CHART_ADD_GRADE] = new AnalyticsEvent(
            AnalyticsConstants.SPIDER_CHART_ADD_GRADE.Goal
        )

        this.events[EventTypes.SPIDER_CHART_DOWNLOAD] = new AnalyticsEvent(
            AnalyticsConstants.SPIDER_CHART_DOWNLOAD.Goal
        )

        this.events[EventTypes.SPIDER_CHART_SHARE] = new AnalyticsEvent(
            AnalyticsConstants.SPIDER_CHART_SHARE.Goal
        )

        this.events[EventTypes.SPIDER_CHART_FAVORITE] = new AnalyticsEvent(
            AnalyticsConstants.SPIDER_CHART_FAVORITE.Goal
        )

        this.events[EventTypes.SEARCH_SUCCESS] = new AnalyticsEvent(
            AnalyticsConstants.SEARCH_SUCCESS.Goal
        )

        this.events[EventTypes.SEARCH_FAIL] = new AnalyticsEvent(
            AnalyticsConstants.SEARCH_FAIL.Goal
        )

        this.events[EventTypes.SEARCH_CLICK] = new AnalyticsEvent(
            AnalyticsConstants.SEARCH_CLICK.Goal
        )

        this.events[EventTypes.SHARE_LINKEDIN] = new AnalyticsEvent(
            AnalyticsConstants.SHARE_LINKEDIN.Goal
        )

        this.events[EventTypes.SHARE_SOCIAL_X] = new AnalyticsEvent(
            AnalyticsConstants.SHARE_SOCIAL_X.Goal
        )

        this.events[EventTypes.SHARE_EMAIL] = new AnalyticsEvent(
            AnalyticsConstants.SHARE_EMAIL.Goal
        )

        this.events[EventTypes.SHARE_WECHAT] = new AnalyticsEvent(
            AnalyticsConstants.SHARE_WECHAT.Goal
        )

        this.events[EventTypes.GLOBAL_REQUEST_A_SAMPLE] = new AnalyticsEvent(
            AnalyticsConstants.GLOBAL_REQUEST_A_SAMPLE.Goal
        )

        this.events[EventTypes.GLOBAL_EXPORT] = new AnalyticsEvent(
            AnalyticsConstants.GLOBAL_EXPORT.Goal
        )

        this.events[EventTypes.HELP_GUIDED_TOUR] = new AnalyticsEvent(
            AnalyticsConstants.HELP_GUIDED_TOUR.Goal
        )

        this.events[EventTypes.HELP_PROMOTIONAL_VIDEO] = new AnalyticsEvent(
            AnalyticsConstants.HELP_PROMOTIONAL_VIDEO.Goal
        )

        this.events[EventTypes.HELP_CONTACT_US] = new AnalyticsEvent(
            AnalyticsConstants.HELP_CONTACT_US.Goal
        )

        //#endregion
    }

    public getEvent(type: EventTypes, label?: string): AnalyticsEvent {
        let event = this.events[type];
        if (label) {
            event.setLabel(label);
        }

        return event;
    }
}