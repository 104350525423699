import { GradesService } from '../../general/services/grades.service';
import { FavoritesService } from '../../general/services/favorites.service';
import { GlobalData } from '../../general/services/global-data.service';
import { Component, OnInit } from '@angular/core';
import { _ } from 'underscore';

@Component({
  selector: 'dps-datatable-properties',
  templateUrl: './datatable-properties.component.html',
  styleUrls: ['./datatable-properties.component.scss']
})
export class DatatablePropertiesComponent implements OnInit {

  constructor(public globalData: GlobalData, private _favoritesService: FavoritesService, private _gradesService: GradesService) { }

  ngOnInit() {
  }
}
