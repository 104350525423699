import { DpsFilterIdName } from './dpsFilterIdName';
export class DpsFilterProperty extends DpsFilterIdName {
    minimum: number;
    maximum: number;
    textValues: string[];
    unitOfMeasure: string;
    testMethod: string[];
    hasRadarMatch: boolean;
    hasFirstPlotMatch: boolean;
    hasSecondPlotMatch: boolean;
    selected: boolean;

    constructor() {
      super();
    }
}
