import { GlobalData } from './global-data.service';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { ServicesModule } from './services.module';
import { DpsExportState } from '../models/dpsExportState';
import { Injectable } from '@angular/core';
import { _ } from 'underscore';

@Injectable({
  providedIn: ServicesModule
})
export class ExportService {
  exportState: DpsExportState;
  css = '';
  commentsRegex = /<!--[\s\S]*?-->/g;

  private _apiUrl: string;

  constructor(private _http: HttpClient, public globalData: GlobalData) {
    this.exportState = new DpsExportState();
    this._apiUrl = environment.apiUrlExxon;
    this.css =  `<link rel="stylesheet" type="text/css" href="${ environment.exportUrl }export.css">`;
  }

  export(html) {
    let div = document.createElement('div');
    div.style.display = 'none';
    div.innerHTML = html;

    let pages = div.querySelectorAll('.page');
    let htmlBlocks = [];
    for (let index = 0; index < pages.length; index++) {
      const element = pages[index];
      const wrapper = document.createElement('div');
      wrapper.className = 'dps-html-export';
      wrapper.innerHTML = element.outerHTML.replace(this.commentsRegex, '');
      htmlBlocks.push({
        html: window.btoa(unescape(encodeURIComponent(this.css + wrapper.outerHTML))),
        orientation: element.className.indexOf('landscape') > -1 ? 'landscape' : 'portrait'
      });
    }

    let footer = div.querySelector('.footer');
    const footerWrapper = document.createElement('div');
    footerWrapper.className = 'dps-html-export';
    footerWrapper.innerHTML = footer.outerHTML;
    let footerHtml = window.btoa(unescape(encodeURIComponent(this.css + footerWrapper.outerHTML)));

    let url = this._apiUrl + 'data/export';
    let analyticsUrl = this._apiUrl + 'analytics/export';

    let params = {
      language: this.globalData.lang,
      email: this.exportState.exportEmail,
      acceptedTermsAndConditions: this.exportState.exportTermsAndConditions,
      acceptedPrivacyPolicy: this.exportState.exportPrivacyStatement,
      acceptedNewsletterSubscription: this.exportState.exportNewsletterSubscription,
      htmlBlocks: htmlBlocks,
      footerHtml: footerHtml,
      productTypes: _.pluck(this.globalData.filters.productTypes, 'name'),
      productDataSheets: (this.globalData.applicationState.propertyType.id === 'GP' ?
        _.pluck(this.globalData.data.filteredGrades, 'substance') : undefined),
      salesSpecificationSheets: (this.globalData.applicationState.propertyType.id === 'SP' ?
        _.pluck(this.globalData.data.filteredSalesGrades, 'substance') : undefined),
    };

    this._http.post(url, params, {responseType: 'arraybuffer'}).subscribe((data: any) => {
      // let file = new Blob([data], {type: 'application/pdf'});
      // let u = window.URL.createObjectURL(file);
      // let a = document.createElement('A');
      // (a as any).href = u;
      // (a as any).download = u.substr(u.lastIndexOf('/') + 1);
      // document.body.appendChild(a);
      // a.click();
      // document.body.removeChild(a);
      this.globalData.applicationState.showSuccesDialog = true;
      this.globalData.applicationState.showExportDialog = false;
      // window.open(u);
    }, (error) => {
      this.globalData.applicationState.showFailDialog = true;
      this.globalData.applicationState.showExportDialog = false;
    });

    this._http.post(analyticsUrl, params).subscribe();
  }
}
