import { PageUrlData } from "./page-url-data";
import { PageData } from "./page-data";

export class AnalyticsData {
    constructor(
        private event: any,
        private customData: any,
        private pageUrlData: PageUrlData,
        private pageData: PageData,
        private businessUnit: string) {
    }

    getData() {
        return Object.assign({}, this.event, this.customData, this.pageUrlData, this.pageData, { page_bu: this.businessUnit });
    }
}