<div class="radarchart-filter">
  <div class="grade-count" *ngIf="globalData.data">
    <span class="large" [innerHTML]="globalData.selectedGrades.length"></span>
    <span i18n="Grades out of label on spider chart filter tab@@gradesOutOf"> grades out of 5</span>
  </div>
  <div class="radarchart-filter-body" *ngIf="globalData.data">
    <div class="active-grades" [dragula]='"selectedGrades"' [dragulaModel]='globalData.selectedGrades'>
      <div *ngFor="let grade of globalData.selectedGrades" class="grade">
        <mat-icon svgIcon="drag"></mat-icon>
        <span class="name" [ngStyle]="{ color: grade.color }">{{ grade.tradeName }}</span>
        <div class="remove" (click)="removeGrade(grade)">
          <mat-icon svgIcon="close"></mat-icon>
        </div>
      </div>
    </div>

    <div class="add-grade" *ngIf="globalData.selectedGrades.length < 5">
      <ng-select [items]="(globalData.applicationState.propertyType.id === 'GP' ? globalData.data.filteredGrades : globalData.data.filteredSalesGrades ) | filterOut: globalData.selectedGrades:globalData.gradesChanged:this.globalData.propertiesChanged"
        [clearable]="false"
        bindLabel="name"
        (change)="addSelectedGrade($event)"
        [(ngModel)]="selectedGradeOption"
        i18n-placeholder="Add product grade in spider chart filter tab@@spiderAddProductGrade"
        placeholder="Add product grade"
        [searchFn]="filterGrades">
        <ng-template ng-option-tmp let-item="item">
          {{ item.tradeName }}
        </ng-template>
      </ng-select>
    </div>

    <div class="filter-row" [ngClass]="{ open: openProperties }" (click)="openProperties = !openProperties">
      <div class="filter-title" i18n="Available propertiesthe filters sidebar on spider chart@@availableProperties">Available properties</div>
      <button id="open-properties">
        <mat-icon svgIcon="arrow-right" [ngClass]="{ open: openProperties }"></mat-icon>
      </button>
    </div>

    <div *ngIf="openProperties" class="filter-select">
      <div *ngIf="globalData.applicationState.propertyType.id === 'GP' && globalData.data.globalProperties">

          <div class="max-properties" *ngIf="globalData.selectedRadarProperties.length === 9"
            i18n="X out of properties on spider chart filter tab@@outOfProperties"
            >{ globalData.selectedRadarProperties.length, select, other {{{ globalData.selectedRadarProperties.length }}}} of 9 property maximum</div>

        <div class="select-option"
          [ngClass]="{ faded: !property.hasRadarMatch }"
          *ngFor="let property of (globalData.data.globalProperties | filterProperties: globalData.selectedFilters.propertyFilters) | radarChartMatchingProperties: globalData.selectedGrades: globalData.data.gradeGlobalProperties: globalData.gradesChanged: globalData.propertiesChanged">
          <label class="dps-checkbox">
            <input type='checkbox'
              name='property-{{ property.id }}'
              [checked]="globalData.selectedRadarProperties.indexOf(property) > -1"
              (change)="toggleSelectedProperty(property)"
              [disabled]="globalData.selectedRadarProperties.length >= 9 && globalData.selectedRadarProperties.indexOf(property) === -1"/>
            <span></span>
            <span class="checkbox-text">{{ property.name }} {{ property.unitOfMeasure}}</span>
          </label>
        </div>
      </div>
      <div *ngIf="globalData.applicationState.propertyType.id === 'SP' && globalData.data.salesProperties">

        <div class="max-properties" *ngIf="globalData.selectedRadarSalesProperties.length === 9"
          i18n="X out of sales properties on spider chart filter tab@@outOfSalesProperties"
          >{ globalData.selectedRadarSalesProperties.length, select, other {{{ globalData.selectedRadarSalesProperties.length }}}} of 9 property maximum</div>

        <div
          class="select-option"
          [ngClass]="{ faded: !property.hasRadarMatch }"
          *ngFor="let property of (globalData.data.salesProperties | filterSalesProperties: globalData.selectedFilters.salesPropertyFilters) | radarChartMatchingSalesProperties: globalData.selectedGrades: globalData.data.gradeSalesProperties: globalData.gradesChanged: globalData.propertiesChanged">
          <label class="dps-checkbox">
            <input type='checkbox'
              name='property-{{ property.id }}'
              [checked]="globalData.selectedRadarSalesProperties.indexOf(property) > -1"
              (change)="toggleSelectedProperty(property)"
              [disabled]="globalData.selectedRadarSalesProperties.length >= 9 && globalData.selectedRadarSalesProperties.indexOf(property) === -1"/>
            <span></span>
            <span class="checkbox-text">{{ property.name }} {{ property.unitOfMeasure}}</span>
          </label>
        </div>
      </div>
    </div>
  </div>
</div>
