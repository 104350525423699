import { ExportHtmlComponent } from './../../export-html/export-html.component';
import { ExportService } from '../../general/services/export.service';
import { GlobalData } from '../../general/services/global-data.service';
import { Component, OnInit, ComponentFactoryResolver, ViewContainerRef } from '@angular/core';

@Component({
  selector: 'dps-fail-dialog',
  templateUrl: './fail-dialog.component.html',
  styleUrls: ['./fail-dialog.component.scss']
})
export class FailDialogComponent implements OnInit {

  constructor(public globalData: GlobalData, public exportService: ExportService, private _componentFactoryResolver: ComponentFactoryResolver,
    private _viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit() {

  }

  retry() {
    this.globalData.applicationState.showFailDialog = false;
    this.globalData.applicationState.showExportDialog = true;
  }
}
