export enum EventTypes {
    GRADE_DETAIL_FAVORITE,
    DATA_SET_SELECTION,
    DATATABLE_SHARE,
    EXPLORE_CLICK,
    EXPLORE_FILTER_CHOICE,
    EXPLORE_REGION,
    DATATABLE_SORT_PROPERTY,
    DATATABLE_SORT_APPLICATION,
    DATATABLE_ADD_TO_XY,
    DATATABLE_ADD_TO_SPIDER,
    DATATABLE_APPLICATIONS_TAB,
    DATATABLE_PROPERTIES_TAB,
    DATATABLE_TESTMETHODS_TAB,
    DATATABLE_FAVORITE,
    DATATABLE_DOWNLOAD,
    FILTER_REGION,
    FILTER_UNIT_SYSTEM,
    FILTER_BUSINESS_UNIT,
    FILTER_PRODUCT_TYPE,
    FILTER_APPLICATION,
    FILTER_BRAND,
    FILTER_INDUSTRY,
    FILTER_PROPERTY,
    FILTER_REFINED_PROPERTY,
    GRADE_DETAIL_ADD_TO_SPIDER,
    GRADE_DETAIL_SHARE,
    GRADE_DETAIL_DOWNLOAD,
    GRADE_DETAIL_DOWNLOAD_GA,
    GRADE_DETAIL_REQUEST_A_SAMPLE,
    XY_CHART_ADD_PROPERTY,
    XY_CHART_SWITCH_AXES,
    XY_CHART_DOWNLOAD,
    XY_CHART_SHARE,
    XY_CHART_FAVORITE,
    XY_CHART_SELECTED,
    XY_CHART_XY_SELECTED,
    SPIDER_CHART_ADD_GRADE,
    SPIDER_CHART_DOWNLOAD,
    SPIDER_CHART_SHARE,
    SPIDER_CHART_FAVORITE,
    SEARCH_SUCCESS,
    SEARCH_FAIL,
    SEARCH_CLICK,
    SHARE_LINKEDIN,
    SHARE_SOCIAL_X,
    SHARE_EMAIL,
    SHARE_WECHAT,
    GLOBAL_REQUEST_A_SAMPLE,
    GLOBAL_EXPORT,
    GLOBAL_EXPORT_GA,
    HELP_GUIDED_TOUR,
    HELP_PROMOTIONAL_VIDEO,
    HELP_CONTACT_US,
    HELP_BUTTON_SELECTED
}