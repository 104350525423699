import { AnalyticsService } from './../../general/services/analytics.service';
import { GlobalData } from './../../general/services/global-data.service';
import { FavoritesService } from './../../general/services/favorites.service';
import { Component, OnInit } from '@angular/core';
import { DragulaService } from 'ng2-dragula';
import { AnalyticsEventsService } from 'src/app/general/services/analytics-events.service';
import { EventTypes } from 'src/app/general/enums/analytics/event-types';

@Component({
  selector: 'dps-radar-chart-filters',
  templateUrl: './radar-chart-filters.component.html',
  styleUrls: ['./radar-chart-filters.component.scss']
})
export class RadarChartFiltersComponent implements OnInit {
  openProperties = true;
  selectedGradeOption = null;

  constructor(public globalData: GlobalData,
    private _favoritesService: FavoritesService,
    private _dragula: DragulaService,
    private _analyticsService: AnalyticsService,
    private _analyticsEventsService: AnalyticsEventsService) { }

  ngOnInit() {
    this._dragula
      .dropModel
      .subscribe(value => {
        this.globalData.gradesChanged = {};
        this._favoritesService.updateCurrentFavorite();
      });
  }

  checkId(a, b) {
    return a && b && a.id === b.id;
  }

  toggleSelectedProperty(property) {
    let selectedRadarProperties: any[] = this.globalData.applicationState.propertyType.id === 'GP' ?
      this.globalData.selectedRadarProperties : this.globalData.selectedRadarSalesProperties;
    let propertyIndex = selectedRadarProperties.indexOf(property);
    if (propertyIndex > -1) {
      selectedRadarProperties.splice(propertyIndex, 1);
    } else {
      if (selectedRadarProperties.length < 9) {
        selectedRadarProperties.push(property);
      }
    }
    this.globalData.propertiesChanged = {};
    this._favoritesService.updateCurrentFavorite();
  }

  addSelectedGrade() {
    this.selectedGradeOption.color = this.globalData.getFirstAvailableGradeColor();
    this.globalData.selectedGrades.unshift(this.selectedGradeOption);

    // analytics
    this._analyticsService.sendEvent(this._analyticsEventsService.getEvent(EventTypes.SPIDER_CHART_ADD_GRADE), this.selectedGradeOption.tradeName);

    setTimeout(() => {
      this.selectedGradeOption = null;
    }, 1);
    this.globalData.gradesChanged = {};
    this._favoritesService.updateCurrentFavorite();
    if (this.globalData.selectedGrades.length > 1) {
      this.globalData.setDefaultSelectedRadarProperties();
    }
  }

  removeGrade(grade) {
    grade.color = null;
    this.globalData.selectedGrades.splice(this.globalData.selectedGrades.indexOf(grade), 1);
    this.globalData.gradesChanged = {};
    this._favoritesService.updateCurrentFavorite();
  }

  filterGrades(term: string, grade: any) {
    term = term.toLocaleLowerCase();
    return grade.name.toLocaleLowerCase().indexOf(term) > -1 || grade.idesGrade.toLocaleLowerCase().indexOf(term) > -1;
  }
}
