import { AnalyticsService } from './../../general/services/analytics.service';
import { environment } from './../../../environments/environment';
import { GlobalData } from './../../general/services/global-data.service';
import { DpsFilterIdName } from './../../general/models/filter/dpsFilterIdName';
import { DpsFilterProperty } from './../../general/models/filter/dpsFilterProperty';
import { FavoritesService } from './../../general/services/favorites.service';
import { DpsGrade } from './../../general/models/data/dpsGrade';
import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { _ } from 'underscore';
import { GradesService } from '../../general/services/grades.service';
import { EventTypes } from 'src/app/general/enums/analytics/event-types';
import { AnalyticsEventsService } from 'src/app/general/services/analytics-events.service';

@Component({
  selector: 'dps-datatable-table',
  templateUrl: './datatable-table.component.html',
  styleUrls: ['./datatable-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class DatatableTableComponent implements OnInit {
  @Input() grades: DpsGrade[];
  @Input() properties: DpsFilterProperty[];
  @Input() gradeProperties: any;
  @Input() selectedGrades: DpsGrade[];
  @Input() selectedProperties: DpsFilterProperty[];
  @Input() sortProperty: DpsFilterProperty;
  @Input() sortOrder: string;
  @Input() propertiesChanged: any;
  @Input() gradesChanged: any;
  @Input() productTypes: DpsFilterIdName[];
  @Input() brands: DpsFilterIdName[];

  propertiesActivated = false;
  requestASampleUrl: string;

  constructor(public globalData: GlobalData,
    private _favoritesService: FavoritesService,
    private _gradesService: GradesService,
    private _analyticsService: AnalyticsService,
    private _analyticsEventsService: AnalyticsEventsService) {
    this.requestASampleUrl = environment.requestASampleUrl;
  }

  ngOnInit() { }

  isTouchDevice() {
    return 'ontouchstart' in document.documentElement;
  }

  toggleSort(property: DpsFilterProperty) {
    if (this.globalData.sortProperty === property) {
      if (this.globalData.sortOrder === 'desc') {
        this.globalData.sortProperty = null;
        this.globalData.sortOrder = null;
      } else {
        this.globalData.sortOrder = 'desc';
      }
    } else {
      this.globalData.sortProperty = property;
      this.globalData.sortOrder = 'asc';
    }
    this._favoritesService.updateCurrentFavorite();
    this._analyticsService.sendEvent(this._analyticsEventsService.getEvent(EventTypes.DATATABLE_SORT_PROPERTY), property.name);
  }

  togglePropertySelection(property: DpsFilterProperty) {
    let propertiesLength = this.selectedProperties.length;
    let propertyIndex = _.indexOf(this.selectedProperties, property);
    if (propertyIndex > -1) {
      this.selectedProperties.splice(propertyIndex, 1);
    } else {
      if (this.selectedProperties.length > 1) {
        this.selectedProperties.splice(0, 1);
      }
      this.selectedProperties.push(property);
      this._analyticsService.sendEvent(this._analyticsEventsService.getEvent(EventTypes.DATATABLE_ADD_TO_XY), property.name);
    }
    this._favoritesService.updateCurrentFavorite();
    this.propertiesActivated = propertiesLength === 1 && this.selectedProperties.length === 2;
  }

  toggleGradeSelection(grade: DpsGrade) {
    let gradeIndex = _.indexOf(this.selectedGrades, grade);
    if (gradeIndex > -1) {
      grade.color = null;
      this.selectedGrades.splice(gradeIndex, 1);
    } else {
      if (this.selectedGrades.length > 4) {
        this.selectedGrades.splice(4, 1);
      }
      grade.color = this.globalData.getFirstAvailableGradeColor();
      this.selectedGrades.unshift(grade);
      this._analyticsService.sendEvent(this._analyticsEventsService.getEvent(EventTypes.DATATABLE_ADD_TO_SPIDER), grade.tradeName);
    }
    this.globalData.gradesChanged = {};
    this._favoritesService.updateCurrentFavorite();
  }

  onScroll(a, b, c, d) {
    this.globalData.limit += 15;
  }

  trackById(index, item) {
    return item.id;
  }

  restoreDefaults() {
    let loadData = this.globalData.restoreDefaults();
    this._favoritesService.updateCurrentFavorite();
    if (loadData) {
      this._gradesService.getData();
    }
  }
}
