<p-dialog
  class="property-type-switch"
  [(visible)]="globalData.applicationState.showPropertyTypeSwitch"
  [modal]="true"
  [resizable]="false"
  [responsive]="true"
  [closeOnEscape]="false"
  [draggable]="false"
  [appendTo]="'body'"
  [styleClass]="'dps-dialog dps-property-switch-dialog'">
  <p-header>
    <ng-container i18n="Property type switch dialog header@@propertySwitchPopupHeader">Please note that your data set has switched from product data to sales specifications, or vice versa,
      since not all data sets are available for all regions.</ng-container>
  </p-header>
  <div class="info" i18n="Property type switch info text@@propertySwitchPopupInfo">If another data set is available for your region, you can switch under settings at any time.</div>
  <a *ngIf="!propertyTypeInfo" (click)="propertyTypeInfo = true" class="help" i18n="Property type switch help link text@@propertySwitchHelp">What is the difference between the data-sets?</a>
  <div class="info-message" *ngIf="propertyTypeInfo"
    i18n="Property type switch help explanation text@@propertySwitchHelpExplanation"
    >Product data contains typical property data.<br>Sales specifications are the specifications our products meet.</div>
</p-dialog>
