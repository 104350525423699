import { DpsFilterProperty } from './../../models/filter/dpsFilterProperty';
import { Pipe, PipeTransform } from '@angular/core';
import { _ } from 'underscore';

@Pipe({
  name: 'filterProperties'
})
export class FilterPropertiesPipe implements PipeTransform {

  transform(properties: DpsFilterProperty[], propertyFilters: any): any {
    console.log(propertyFilters);
    let selectedFilters = _.where(_.values(propertyFilters), { selected: true });
    if (selectedFilters.length > 0) {
      return _.filter(properties, (p) => {
        return propertyFilters.hasOwnProperty(p.id) && propertyFilters[p.id].selected;
      });
    } else {
      return properties;
    }
  }
}
