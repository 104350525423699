import { AnalyticsService } from './analytics.service';
import { DpsSearchResult } from './../models/data/dpsSearchResult';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { GlobalData } from './global-data.service';
import { Injectable } from '@angular/core';
import { _ } from 'underscore';
import { environment } from '../../../environments/environment';
import { AnalyticsEventsService } from './analytics-events.service';
import { EventTypes } from '../enums/analytics/event-types';
import { PageData } from '../models/analytics/page-data';
import { PageType } from '../enums/analytics/page-type';
import { PageUrlData } from '../models/analytics/page-url-data';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  private _apiUrl: string;
  private _getAutocompleteSubscription: Subscription;
  private _getResultsSubscription: Subscription;
  searched = false;

  constructor(public globalData: GlobalData,
    private _http: HttpClient,
    private _analyticsService: AnalyticsService,
    private _analyticsEventsService: AnalyticsEventsService) {
    this._apiUrl = environment.apiUrlExxon;
  }

  getAutocompleteResults(callback?) {
    const searchTerm = this.stripUnwantedCharacters(this.globalData.searchModel.searchTerm, '™');
    if(searchTerm.length < 2) {
      return false;
    }
    
    if (this._getAutocompleteSubscription && this.globalData.searchModel.loadingAutocomplete) {
      this._getAutocompleteSubscription.unsubscribe();
    }

    if (!this.globalData.searchModel.loadingResults) {
      let url = this._apiUrl + 'search/autocomplete';
      let params = {
        language: this.globalData.lang,
        searchTerm: searchTerm,
        skip: 0,
        take: 10
      };

      this.globalData.searchModel.loadingAutocomplete = true;
      this._getAutocompleteSubscription = this._http.post(url, params).subscribe((result: any) => {
        this.globalData.searchModel.autocompleteResults = result.items ? result.items.map((r) => new DpsSearchResult(r)) : [];
        this.globalData.searchModel.loadingAutocomplete = false;
      }, (error) => {
      });
    }

    return this._getAutocompleteSubscription;
  }

  getResults(reset?: boolean, callback?) {
    const searchTerm = this.stripUnwantedCharacters(this.globalData.searchModel.searchTerm, '™');
    // if(searchTerm.length < 2) {
    //   return false;
    // }
    
    if (this._getAutocompleteSubscription && this.globalData.searchModel.loadingAutocomplete) {
      this._getAutocompleteSubscription.unsubscribe();
    }

    if (this._getResultsSubscription && this.globalData.searchModel.loadingResults) {
      this._getResultsSubscription.unsubscribe();
    }

    // analytics
    if (reset) {
      this._analyticsService.sendPageView(new PageData(PageType.Selector), new PageUrlData('/search?q=' + this.globalData.searchModel.searchTerm));
    }

    let url = this._apiUrl + 'search/general';
    this.globalData.searchModel.autocompleteResults = [];
    this.globalData.searchModel.skip = reset ? 0 : this.globalData.searchModel.skip;
    this.globalData.searchModel.hasMoreItems = reset ? true : this.globalData.searchModel.hasMoreItems;
    this.globalData.searchModel.searchResults = reset ? [] : this.globalData.searchModel.searchResults;
    let params = {
      language: this.globalData.lang,
      searchTerm: searchTerm,
      skip: this.globalData.searchModel.skip,
      take: this.globalData.searchModel.take,
      filterType: this.globalData.searchModel.filterType
    };

    this.globalData.searchModel.loadingResults = true;
    this._getResultsSubscription = this._http.post(url, params).subscribe((result: any) => {
      // analytics
      if (this.globalData.searchModel.searchResults.length === 0) {
        let event = result.items && result.items.length > 0 ? this._analyticsEventsService.getEvent(EventTypes.SEARCH_SUCCESS) : this._analyticsEventsService.getEvent(EventTypes.SEARCH_FAIL);
        this._analyticsService.sendEvent(event, this.globalData.searchModel.searchTerm);
      }

      this.globalData.searchModel.searchResults = this.globalData.searchModel.searchResults.concat(result.items ? result.items.map((r) => new DpsSearchResult(r)) : []);
      this.globalData.searchModel.hasMoreItems = result.hasMoreItems;
      this.globalData.searchModel.skip += this.globalData.searchModel.take;
      this.searched = true;
      this.globalData.searchModel.loadingResults = false;
    }, (error) => {
    });

    return this._getResultsSubscription;
  }
  
  unwantedCharacters: string = '%+*?';
  stripUnwantedCharacters(searchTerm: string, extraUnwantedCharacters: string = '') {
    let newSearchTerm = searchTerm;

    while(newSearchTerm.substr(0,1) === ' ') {
      newSearchTerm = newSearchTerm.substr(1);
    }

    _.each(this.unwantedCharacters.concat(extraUnwantedCharacters), (char: string) => {
      newSearchTerm = newSearchTerm.split(char).join('');
    });
    return newSearchTerm;
  }
}
