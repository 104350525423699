import { DpsFilterIdName } from './../../general/models/filter/dpsFilterIdName';
import { AnalyticsService } from './../../general/services/analytics.service';
import { FavoritesService } from './../../general/services/favorites.service';
import { GlobalData } from '../../general/services/global-data.service';
import { GradesService } from '../../general/services/grades.service';
import { Component, OnInit } from '@angular/core';
import { _ } from 'underscore';
import { AnalyticsEventsService } from 'src/app/general/services/analytics-events.service';
import { EventTypes } from 'src/app/general/enums/analytics/event-types';

@Component({
  selector: 'dps-datatable-filters',
  templateUrl: './datatable-filters.component.html',
  styleUrls: ['./datatable-filters.component.scss']
})
export class DatatableFiltersComponent implements OnInit {
  openFilter: null;
  allPropertiesSelected: false;
  allSalesPropertiesSelected: false;

  businessUnitsOpen = false;
  productTypesOpen = false;
  industriesOpen = false;
  applicationsOpen = false;
  brandsOpen = false;
  openProperties = [];
  editRangeProperties = [];
  viewControlProperties = [];
  marketSegmentsOpen = false;
  errorMessage: any;

  customError = {
    id: null
  };

  constructor(public globalData: GlobalData,
    private _gradesService: GradesService,
    private _favoritesService: FavoritesService,
    private _analyticsService: AnalyticsService,
    private _analyticsEventsService: AnalyticsEventsService) { }

  ngOnInit() {
    this.errorMessage = [];
  }

  switchRegion() {
    this._gradesService.getData();
    this._favoritesService.updateCurrentFavorite();

    // analytics
    let region: DpsFilterIdName = _.findWhere(this.globalData.filters.regions, { id: this.globalData.selectedFilters.regionId });
    this._analyticsService.sendEvent(this._analyticsEventsService.getEvent(EventTypes.FILTER_REGION), region.name)
  }

  clearAllFilters() {
    let loadData = this.globalData.restoreDefaults();
    this._favoritesService.updateCurrentFavorite();
    if (loadData) {
      this._gradesService.getData();
    }
  }

  switchUnitSystem() {
    this.globalData.restoreDefaults(true);
    this._gradesService.getData();
    this._favoritesService.updateCurrentFavorite();
    // analytics
    this._analyticsService.sendEvent(this._analyticsEventsService.getEvent(EventTypes.FILTER_UNIT_SYSTEM), this.globalData.unitSystem);
  }

  switchDatatableType(type) {
    this.globalData.applicationState.showPropertyTypeSelection = false;
    switch (type) {
      case 'GP':
        this.globalData.applicationState.propertyType = { id: 'GP', name: 'Product datasheet' };
        break;
      case 'SP':
        this.globalData.applicationState.propertyType = { id: 'SP', name: 'Sales specifications' };

        break;
    }
    this.getData();
  }
  

  loadProperties(type) {
  }

  toggleFilter(id, idList) {
    let index = idList.indexOf(id);
    if (index > -1) {
      idList.splice(index, 1);
    } else {
      idList.push(id);
      // analytics
      this.logFilter(id, idList);
    }
    this.getData();
  }

  // analytics
  logFilter(id, idList) {
    let event;
    let selectedItem;
    let value;
    switch (idList) {
      case this.globalData.selectedFilters.businessUnitIds:
        event = this._analyticsEventsService.getEvent(EventTypes.FILTER_BUSINESS_UNIT);
        selectedItem = _.findWhere(this.globalData.filters.businessUnits, { id: id });
        value = selectedItem ? selectedItem.name : null;
        break;
      case this.globalData.selectedFilters.productTypeIds:
        event = this._analyticsEventsService.getEvent(EventTypes.FILTER_PRODUCT_TYPE);
        selectedItem = _.findWhere(this.globalData.filters.productTypes, { id: id });
        value = selectedItem ? selectedItem.name : null;
        break;
      case this.globalData.selectedFilters.brandIds:
        event = this._analyticsEventsService.getEvent(EventTypes.FILTER_BRAND);
        selectedItem = _.findWhere(this.globalData.filters.brands, { id: id });
        value = selectedItem ? selectedItem.name : null;
        break;
      case this.globalData.selectedFilters.industryIds:
        event = this._analyticsEventsService.getEvent(EventTypes.FILTER_INDUSTRY);
        selectedItem = _.findWhere(this.globalData.filters.industries, { id: id });
        value = selectedItem ? selectedItem.name : null;
        break;
      case this.globalData.selectedFilters.marketSegmentIds:
        event = this._analyticsEventsService.getEvent(EventTypes.FILTER_APPLICATION);
        selectedItem = _.findWhere(this.globalData.filters.marketSegments, { id: id });
        value = selectedItem ? selectedItem.name : null;
        break;
    }
    if (event) {
      this._analyticsService.sendEvent(event, value);
    }
  }

  // analytics
  logPropertyRange(property) {
    if (property) {
      this._analyticsService.sendEvent(this._analyticsEventsService.getEvent(EventTypes.FILTER_REFINED_PROPERTY), property.name)
    }
  }

  toggleAllFilter(list, selected) {
    let selectAll = list.length !== selected.length;
    selected.splice(0, selected.length);
    if (selectAll) {
      let ids = _.pluck(list, 'id');
      _.each(ids, (id) => {
        selected.push(id);
      });
    }
    this.getData();
  }

  togglePropertyGroup(propertyGroup) {
    let index = _.indexOf(this.openProperties, propertyGroup);
    if (index > -1) {
      this.openProperties.splice(index, 1);
    } else {
      this.openProperties.push(propertyGroup);
    }
  }

  toggleEditRanges(propertyGroup) {
    let index = _.indexOf(this.editRangeProperties, propertyGroup);
    if (index > -1) {
      this.editRangeProperties.splice(index, 1);
    } else {
      this.editRangeProperties.push(propertyGroup);
    }
  }

  resetRanges(propertyFilters, type, isSales) {
    let filtersOfType = _.where(_.values(propertyFilters), { type: type });
    console.log(filtersOfType);
    _.each(filtersOfType, (f) => {
      if(isSales) {
        f.salesRange.minimumRange = {};
        f.salesRange.maximumRange = {};
        f.textValues = [];
      } else {
        f.minimum = undefined;
        f.maximum = undefined;
        f.textValues = [];
      }
    });
    this._favoritesService.updateCurrentFavorite();
    this.getData();
  }

  toggleProperty(propertyFilters, property) {
    let propertyFilter = propertyFilters[property.id];
    if (propertyFilter) {
      propertyFilter.selected = !propertyFilter.selected;
      if (propertyFilter.selected) {
        // analytics
        this._analyticsService.sendEvent(this._analyticsEventsService.getEvent(EventTypes.FILTER_PROPERTY), property.name);
      }
    }
    this.getData();
  }

  hasAllOptionsSelected(propertyFilters, type) {
    return _.where(_.values(propertyFilters), { type: type, selected: false }).length === 0;
  }

  deselectAllProperties(propertyFilters, type) {
    let filtersOfType = _.where(_.values(propertyFilters), { type: type });
    _.each(filtersOfType, (f) => {
      f.selected = false;
    });
    this.globalData.filtersChanged = {};
    this._favoritesService.updateCurrentFavorite();
  }

  selectAllProperties(propertyFilters, type) {
    let filtersOfType = _.where(_.values(propertyFilters), { type: type });
    _.each(filtersOfType, (f) => {
      f.selected = true;
    });
    this.globalData.filtersChanged = {};
    this._favoritesService.updateCurrentFavorite();
  }

  selectAllPropertiesToggle(properyFilters, type) {
    const optionsSelectedCheck = this.hasAllOptionsSelected(properyFilters, type);
    if(optionsSelectedCheck) {
      this.deselectAllProperties(properyFilters, type);
    } else {
      this.selectAllProperties(properyFilters, type);
    }

    this.getData();
  }

  trackById(item) {
    return item.id;
  }

  trackByPropertyGroupKey(item) {
    return item.key;
  }

  trackByName(item) {
    return item.name;
  }

  trackByTextValue(item) {
    return item;
  }

  getData() {
    this._favoritesService.updateCurrentFavorite();
    this._gradesService.getData();
    this.globalData.filtersChanged = {};
  }
}
