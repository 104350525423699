<div class="content-container favorites-container">
  <div class="content">
    <div class="no-favorites" *ngIf="!globalData.favorites || globalData.favorites.length === 0">
        <h3 class="title">
          <ng-container i18n="No favorites title part one@@favoritesTitlePartOne">Lorem ipsum</ng-container>
          <mat-icon svgIcon="star-outline"></mat-icon>
          <ng-container i18n="No favorites title part two@@favoritesTitlePartTwo">dolor sit</ng-container>
        </h3>
        <br>
        <ng-container i18n="No favorites description@@favoritesDescription">
            <h3>What are favorites?</h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis et facilisis dui. Maecenas dui mauris, lacinia eu dictum quis,
              accumsan vestibulum velit. Proin id ultrices neque. Suspendisse potenti. Fusce vestibulum enim ac nulla tincidunt faucibus.
              Curabitur consectetur, eros quis lobortis vehicula, purus justo venenatis mauris, sit amet congue sapien nisi sit amet dolor.
              Proin molestie felis erat, et congue odio ornare sit amet.
            </p>
            <br>
            <h3>How can I add favorites?</h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis et facilisis dui. Maecenas dui mauris, lacinia eu dictum quis,
              accumsan vestibulum velit. Proin id ultrices neque. Suspendisse potenti. Fusce vestibulum enim ac nulla tincidunt faucibus.
              Curabitur consectetur, eros quis lobortis vehicula, purus justo venenatis mauris, sit amet congue sapien nisi sit amet dolor.
              Proin molestie felis erat, et congue odio ornare sit amet.
            </p>
        </ng-container>
        <div class="video-wrapper">
            <div class="tile">
              <a (click)="globalData.applicationState.showGuidedTourDialog = true">
                <div class="tile-wrapper">
                  <div class="image-placeholder">
                    <img width="100%" [src]="iconUrlBase + 'assets/images/showMeHow.jpg'">
                  </div>
                  <div class="text-placeholder">
                    <span i18n="Guided tour placeholder@@guidedTourPlaceholder">Show me how</span>
                  </div>
                </div>
              </a>
            </div>

            <dps-brightcove-lightbox class="lightbox"
              [image]="iconUrlBase + 'assets/images/aboutTheApplication.jpg'"
              [videoUrl]="globalData.videoLang === 'zh' ? 'https://www.exxonmobilchemical.com.cn/dmm3bwsv3/assetstream.aspx?assetid=103022&mediaformatid=50039&destinationid=10016' : (globalData.videoLang === 'es' ? 'https://www.exxonmobilchemical.com.cn/dmm3bwsv3/assetstream.aspx?assetid=103023&mediaformatid=50039&destinationid=10016' : 'https://www.exxonmobilchemical.com.cn/dmm3bwsv3/assetstream.aspx?assetid=103021&mediaformatid=50039&destinationid=10016')"
              i18n-placeholder="About the application placeholder@@aboutTheApplicationPlaceholder"
              placeholder="About the application">
            </dps-brightcove-lightbox>

            <div class="tile">
              <a href="{{ contactUsUrl }}?regionId={{ globalData.selectedFilters?.regionId }}"
                target="_blank">
                <div class="tile-wrapper">
                  <div class="image-placeholder">
                    <img width="100%" [src]="iconUrlBase + 'assets/images/contactUs.jpg'">
                  </div>
                  <div class="text-placeholder">
                    <span i18n="Contact us placeholder@@contactUsPlaceholder">Contact us</span>
                  </div>
                </div>
              </a>
            </div>

        </div>
    </div>
    <div class="favorites-list" *ngIf="globalData.favorites && globalData.favorites.length > 0">
      <div class="favorite-item" *ngFor="let favorite of globalData.favorites; let i = index;" (click)="editingIndex === i ? focusOnInput() : ''" [ngClass]="{editable: editingIndex === i}" >
        <div class="icon">
          <mat-icon *ngIf="favorite.icon" svgIcon="{{ favorite.icon }}"></mat-icon>
        </div>
        <div class="title-wrapper">
          <div *ngIf="editingIndex !== i" (click)="goToFavorite(favorite)">
            <span class="title" [innerHTML]="favorite.title"></span>
            <span class="subtitle" *ngIf="favorite.subtitle" [innerHTML]="favorite.subtitle"></span>
            <span class="region"
              *ngIf="favorite.selectedFilters && favorite.selectedFilters.regionId !== globalData.selectedFilters.regionId"
              [innerHTML]="globalData.filters.regions | nameById:favorite.selectedFilters.regionId"></span>
            <a class="edit" (click)="editFavorite(favorite, i)" *ngIf="editingIndex === -1">
              <mat-icon svgIcon="edit"></mat-icon>
            </a>
          </div>
          <div *ngIf="editingIndex === i">
            <div class="title-edit-wrapper">
              <input class="favorite-edit-input" (keyup.enter)="saveEditFavorite(favorite, i)" type="text" required [(ngModel)]="editingTitle" />
              <span class="title-placeholder" [innerHtml]="editingTitle"></span>
            </div>
            <span class="subtitle" *ngIf="favorite.subtitle" [innerHTML]="favorite.subtitle"></span>
            <span class="region"
              *ngIf="favorite.selectedFilters && favorite.selectedFilters.regionId !== globalData.selectedFilters.regionId"
              [innerHTML]="globalData.filters.regions | nameById:favorite.selectedFilters.regionId"></span>
            <a class="save" (click)="saveEditFavorite(favorite, i)" *ngIf="editingIndex === i">
              <mat-icon svgIcon="check"></mat-icon>
            </a>
            <a class="cancel" (click)="cancelEditFavorite(favorite, i)" *ngIf="editingIndex === i">
              <mat-icon svgIcon="close"></mat-icon>
            </a>
          </div>
        </div>
        <a class="remove active" (click)="activeRemovePopup = favorite;">
          <mat-icon svgIcon="star"></mat-icon>
        </a>
        <div [ngClass]="{active: activeRemovePopup === favorite}" class="inline-popup">
          <ng-container i18n="Remove favorite label@@favoritesRemove">Remove favorite?</ng-container>
          <a (click)="removeFromFavorites(favorite)"><span i18n="Remove favorite yes label@@favoritesRemoveYes">Yes</span></a>
          <a (click)="activeRemovePopup = null;" i18n="Remove favorite no label@@favoritesRemoveNo">Cancel</a>
        </div>
      </div>
    </div>
  </div>
  <div class="info-message">
      <span i18n="Info message for favorites label@@labelFavorites">Favorites are stored in your browser cookies. In order to access favorites make sure to use the same browser on the same computer.</span>
  </div>
</div>

