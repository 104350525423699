export class AnalyticsEvent {
    eventLabel: string;

    constructor(
        public goalId?: string,
        private category?: string,
        private action?: string,
        private eventId?: string,
        public sendGa: boolean = false,
        public sendSiteCore: boolean = true) { }

    setLabel(label) {
        this.eventLabel = label;
    }

    getGaEvent() {
        let gaEvent = Object.assign({}, { eventCategory: this.category, eventAction: this.action, event: this.eventId });
        if (this.eventLabel && this.eventLabel.trim() != "") {
            gaEvent = Object.assign(gaEvent, { eventLabel: this.eventLabel });
        }

        return gaEvent;
    }
}