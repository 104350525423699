import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { NavigationEnd, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { PageUrlData } from '../models/analytics/page-url-data';
import { AnalyticsEvent } from '../models/analytics/analytics-event';
import { CustomData } from '../models/analytics/custom-data';
import { AnalyticsData } from '../models/analytics/analytics-data';
import { PageData } from '../models/analytics/page-data';
import { AnalyticsConstants } from '../constants/analytics';
import { Meta } from '@angular/platform-browser';
import { PageType } from '../enums/analytics/page-type';
import { _ } from 'underscore';

@Injectable({
  providedIn: 'root'
})

export class AnalyticsService {
  private _analyticsTrackingId: string;
  private _apiUrl: string;

  constructor(public router: Router, private _http: HttpClient, private meta: Meta) {
    this.trackNavigation();
    this.initializeState();
  }

  private initializeState() {
    this._analyticsTrackingId = environment.analyticsTrackingId;
    this._apiUrl = environment.apiUrlExxon;
  }

  private trackNavigation() {
    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        if (e.url && (e.url.indexOf('/search') === -1 || e.url === '/search')) {
          this.sendPageView(new PageData(PageType.Selector), new PageUrlData(e.url.replace('/landing', '')));
        }
      }
    });
  }

  sendEvent(event: AnalyticsEvent, customData: CustomData | string, pageUrlData?: PageUrlData, pageData?: PageData) {
    if (event) {
      if (event.sendGa) {
        let value = customData instanceof CustomData ? customData.data : {};
        let data = new AnalyticsData(event.getGaEvent(), value, pageUrlData, pageData, this.getMetaContent(AnalyticsConstants.BusinessUnitTag)).getData();
        this.triggerEvent(data);
      }

      if (event.sendSiteCore) {
        this._sendSitecoreEvent(event.goalId, customData);
      }
    }
  }

  sendPageView(pageData: PageData, pageUrlData: PageUrlData) {
    let data = new AnalyticsData({ event: this._analyticsTrackingId }, {}, pageUrlData, pageData, this.getMetaContent(AnalyticsConstants.BusinessUnitTag)).getData()
    this.triggerEvent(data, AnalyticsConstants.GTriggerConfig);
  }

  private triggerEvent(data, trigger = AnalyticsConstants.GTriggerEvent) {
    if (window[AnalyticsConstants.GTag]) {
      this.pushToGTag(data, trigger);
    } else {
      //Once gtag is removed, the event can be sent as a part of AnalyticsData of sendPageView method
      if (trigger == AnalyticsConstants.GTriggerConfig) {
        data.event = AnalyticsConstants.GAPageView;
      }

      this.pushToDataLayer(data);
    }
  }

  private pushToGTag(data, trigger) {
    window[AnalyticsConstants.GTag](trigger, data.event, data);
  }

  private pushToDataLayer(data: any) {
    window[AnalyticsConstants.DataLayerTag] = window[AnalyticsConstants.DataLayerTag] || [];
    window[AnalyticsConstants.DataLayerTag].push(data);
  }

  private getMetaContent(name) {
    let meta = this.meta.getTag(AnalyticsConstants.MetaNameProperty + name);
    if (meta) {
      return meta.content;
    }

    return AnalyticsConstants.MetaDefaults[name];
  }

  private _sendSitecoreEvent(id, value: CustomData | string) {
    value = value ? (value instanceof CustomData ? value.getFirst() : value) : undefined;
    const url = this._apiUrl + 'analytics/goal';
    let params = {
      id: id,
      description: this.stripUnwantedCharacters(value)
    };
    this._http.post(url, params).subscribe();
  }
  
  unwantedCharacters: string = '%+*?';
  stripUnwantedCharacters(searchTerm: any) {
    let newSearchTerm = searchTerm;
    _.each(this.unwantedCharacters, (char: string) => {
      newSearchTerm = newSearchTerm.split(char).join('');
    });
    return newSearchTerm;
  }
}
