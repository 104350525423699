import { GlobalData } from '../../general/services/global-data.service';
import { Component, OnInit } from '@angular/core';
import { _ } from 'underscore';

@Component({
  selector: 'dps-share-dialog',
  templateUrl: './share-dialog.component.html',
  styleUrls: ['./share-dialog.component.scss']
})
export class ShareDialogComponent implements OnInit {

  constructor(public globalData: GlobalData) { }

  ngOnInit() {

  }

}
