import { DpsFilterIdName } from './../../models/filter/dpsFilterIdName';
import { DpsSalesProperty } from './../../models/data/dpsSalesProperty';
import { DpsGrade } from './../../models/data/dpsGrade';
import { Pipe, PipeTransform } from '@angular/core';
import { _ } from 'underscore';

@Pipe({
  name: 'sortSalesGrades'
})
export class SortSalesGradesPipe implements PipeTransform {

  transform(grades: DpsGrade[], property: DpsSalesProperty, sortOrder: string, gradePropertiesObject: any, productTypes: DpsFilterIdName[], brands: DpsFilterIdName[]): any[] {

    if (!grades || !gradePropertiesObject) {
      return [];
    }

    // Check if is not null
    if (!property) {
      if (productTypes) {
        let result = [];
        let gradesByProductType = _.groupBy(_.sortBy(grades, 'productTypeId'), 'productTypeId');

        _.each(gradesByProductType, (value, key) => {
          let productType = _.findWhere(productTypes, { id: +key });
          result.push({
            type: 'productType',
            name: productType ? productType.name : null,
            crossLink: productType ? productType.crossLink : null
          });
          let gradesByIdesFamily = _.groupBy(_.sortBy(value, 'brandId'), 'brandId');
          _.each(gradesByIdesFamily, (v, k) => {
            let brand = _.findWhere(brands, { id: +k });
            result.push({
              type: 'idesFamily',
              name: brand ? brand.name : null,
              crossLink: brand ? brand.crossLink : null
            });
            result = result.concat(v);
          });
        });
        return result;
      } else {
        return [];
      }
    }

    let sortCopy = [].concat(grades);

    return sortCopy.sort((a: DpsGrade, b: DpsGrade) => {
      let gradePropertyA;
      let gradePropertyB;
      let gradeA = gradePropertiesObject[a.substance];
      gradePropertyA = gradeA ? gradeA[property.id] : null;
      let gradeB = gradePropertiesObject[b.substance];
      gradePropertyB = gradeB ? gradeB[property.id] : null;
      let order = sortOrder === 'asc' ? 1 : -1;

      if (gradePropertyA && gradePropertyB) {
        return gradePropertyA[property.type + 'SortValue'] > gradePropertyB[property.type + 'SortValue'] ? order : order * (- 1);
      } else {
        return gradePropertyA ? -1 : 1;
      }
    });
  }

}
