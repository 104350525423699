import { GlobalData } from './../../general/services/global-data.service';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'dps-guided-tour',
  templateUrl: './guided-tour.component.html',
  styleUrls: ['./guided-tour.component.scss']
})
export class GuidedTourComponent implements OnInit {
  step = 0;
  iconUrlBase: string;
  mobile: boolean;

  constructor(public globalData: GlobalData) {
    this.iconUrlBase = environment.iconUrlBase;
   }

  finish() {
    this.globalData.applicationState.showGuidedTourDialog = false;
    localStorage.setItem('hideGuidedTourDialog', 'true');
  }

  onResize() {
    let self = this;
    window.addEventListener('resize', function() {
      window.innerWidth <= 768 ? self.mobile = true : self.mobile = false;
    });
  }

  ngOnInit() {
    window.innerWidth <= 768 ? this.mobile = true : this.mobile = false;
    this.onResize();
  }

}
