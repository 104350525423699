import { debug } from 'util';
import { Directive, ElementRef, Renderer, HostListener, Inject, Input  } from '@angular/core';
import { DOCUMENT } from '@angular/platform-browser';
import * as d3 from 'd3';
import { _ } from 'underscore';

@Directive({
  selector: '[dpsRadarChart]'
})

export class RadarChartDirective {
  private _data: any;
  element: any;
  lastTransform: any;

  @Input()
  options: any;

  @Input()
  set data(value: any) {
    this._data = value;
    if (value) {
      if (d3.selectAll('.radar-tooltip-box').size() !== 0) {
        d3.select('.radar-tooltip-box').remove();
      }
      this.draw(this.element, this._data.data, this._data.axis, this._data.colors.reverse());
    }
  }

  get data(): any {
    return this._data;
  }

  constructor(private el?: ElementRef) {
    this.element = el.nativeElement;
  }

  draw(id, d, axisData, colors) {
    let hoverBreakpoint = 1024;
    let mobileBreakpoint = 768;
    let legendLineHeight = this.options && this.options.legendLineHeight ? this.options.legendLineHeight : 20;
    let width = this.options ? this.options.width : Math.min(this.element.clientWidth, this.element.clientHeight);
    let height = this.options ? this.options.height : Math.min(this.element.clientWidth, this.element.clientHeight);
    let padding = this.options ? this.options.padding : (window.innerWidth < mobileBreakpoint ? 30 : (window.innerWidth <= hoverBreakpoint ? 50 : 100));
    let self = this;

    // Default configuration
    let config = {
      radius: 5,
      w: width - (padding * 2),
      h: height - (padding * 2),
      factor: 1,
      factorLegend: 0.7,
      levels: 3,
      radians: 2 * Math.PI,
      opacityArea: 0.57,
      ToRight: 5,
      TranslateX: (this.options ? this.options.width : this.element.clientWidth) / 2 - width / 2 + padding,
      TranslateY: (this.options ? this.options.height : this.element.clientHeight) / 2 - height / 2 + padding,
      ExtraWidthX: padding * 2,
      ExtraWidthY: padding * 2,
      color: d3.scaleOrdinal().range(colors),
    };

    // Variables
    let total = axisData.length;
    let radius = config.factor * Math.min(config.w / 2, config.h / 2);
    let Format = d3.format('%');
    let series = 0;
    let z;
    let tooltip;
    let tooltipBox;

    d3.select(id).select('svg').remove();
    d3.select(document.querySelector('.legend')).style('display', 'block');

    let zoom = d3.zoom()
        .scaleExtent([1, 8])
        .on('zoom', zoomed)
        .on('end', function() {
          d3.select(id).select('svg').attr('class', '');
        });

    // SVG instance
    let g1 = d3.select(id)
        .append('svg')
        .attr('class', 'radar-chart-svg')
        .attr('width', config.w + config.ExtraWidthX)
        .attr('height', config.h + config.ExtraWidthY)
        .on('mousedown', function() {
          let svgContainer = d3.select(this);
          if (self.lastTransform && self.lastTransform.k > 1) {
            svgContainer.attr('class', 'dragging');
          }
        })
        .call(zoom);

    let activeDot;
    g1
    .append('rect')
    .attr('x', 0)
    .attr('width', '100%')
    .attr('y', 0)
    .attr('height', height)
    .attr('class', 'dot-remover')
    .attr('fill', 'transparent')
    .style('stroke', 'transparent')
    .on('click', function() {
      if (window.innerWidth <= hoverBreakpoint) {
        activeDot = null;
        let radarTooltipBox = d3.select('.radar-tooltip-box');
        // radarTooltipBox.attr('class', 'radar-tooltip-box removed');
        setTimeout(() => {
          radarTooltipBox.remove();
          d3.select(document.querySelector('.legend')).style('display', 'block');
          let indexOfAxis;
          for (indexOfAxis = 0; indexOfAxis < d3.selectAll('.axis')._groups[0].length; indexOfAxis++) {
            if (document.getElementById('axis-' + indexOfAxis).querySelector('.tooltip-dot-outline').classList.contains('active')) {
              document.getElementById('axis-' + indexOfAxis).querySelector('.tooltip-dot').classList.remove('active');
              document.getElementById('axis-' + indexOfAxis).querySelector('.tooltip-dot-outline').classList.remove('active');
              document.getElementById('axis-' + indexOfAxis).querySelector('.tooltip-dot-button').classList.remove('active');
            }
          }
        }, 100);
      }
    });
    g1 = g1.append('g');

    console.log(d3.select('.radar-chart-svg'));

    let g = g1.append('g')
      .attr('transform', 'translate(' + (config.TranslateX) + ',' + (config.TranslateY) + ')');

    this.lastTransform = {
      k: 1,
      x: 0,
      y: 0
    };
    function zoomed() {
      let newZoom = d3.event.transform.k;
      if (newZoom > self.lastTransform.k) {
        g1.attr('transform', d3.event.transform);
      } else if (newZoom < self.lastTransform.k) {
        let zoomFactor = ((newZoom - 1) / (self.lastTransform.k - 1));
        d3.event.transform.x = self.lastTransform.x * zoomFactor;
        d3.event.transform.y = self.lastTransform.y * zoomFactor;
        g1.attr('transform', d3.event.transform);
      } else if (d3.event.type === 'zoom' && newZoom === 1) {
        d3.event.transform.x = self.lastTransform.x;
        d3.event.transform.y = self.lastTransform.y;
        g1.attr('transform', d3.event.transform);
      } else {
        g1.attr('transform', d3.event.transform);
      }
      self.lastTransform = {
        k: newZoom,
        x: d3.event.transform.x,
        y: d3.event.transform.y
      };
    }

    // Axis instance
    let axis = g.selectAll('.axis')
      .data(axisData)
      .enter()
      .append('g')
      .attr('class', 'axis')
      .attr('id', function(data, i) {
        return 'axis-' + i;
      });

    // Chart Axis - Lines
    axis.append('line')
      .attr('x1', config.w / 2)
      .attr('y1', config.h / 2)
      .attr('x2', function(data, i) {
        return config.w / 2 * (1 - (config.factor) * Math.sin(i * config.radians / total));
      })
      .attr('y2', function(data, i) {
        return config.h / 2 * (1 - (config.factor) * Math.cos(i * config.radians / total));
      })
      .attr('class', 'line')
      .style('stroke', '#444444')
      .style('stroke-width', '1px');

    let tooltipDot = axis.append('circle')
      .attr('cx', function(data, i) {
        return config.w / 2 * (1 - (config.factor) * Math.sin(i * config.radians / total));
      })
      .attr('cy', function(data, i) {
        return config.h / 2 * (1 - (config.factor) * Math.cos(i * config.radians / total));
      })
      .attr('class', 'tooltip-dot')
      .style('fill', '#646464');

      tooltipDot.attr('r', '8.5');

    let tooltipDotOutline = axis.append('circle')
      .attr('cx', function(data, i) {
        return config.w / 2 * (1 - (config.factor) * Math.sin(i * config.radians / total));
      })
      .attr('cy', function(data, i) {
        return config.h / 2 * (1 - (config.factor) * Math.cos(i * config.radians / total));
      })
      .attr('class', 'tooltip-dot-outline')
      .style('fill', 'transparent')
      .style('stroke', '#444444');

      tooltipDotOutline.attr('r', '12')
        .style('stroke-width', '3px');

    // Chart Axis - label
    if (window.innerWidth > hoverBreakpoint) {
      // if (navigator.userAgent.indexOf('MSIE 10.0') > -1) {
      // if (true) {
        let text = axis.append('text')
          .attr('class', 'legend')
          .attr('width', (this.options ? this.options.legendSize : 80) + 'px')
          .attr('height', (this.options ? this.options.legendSize : 80) + 'px')
          .style('overflow', 'visible')
          .attr('text-anchor', 'middle')
          .attr('fill', 'white')
          .attr('font-size', '12px')
          .attr('x', function(data, i) {
            let x = config.w / 2 * (1 - config.factor * (1 + (self.options ? (self.options.legendSize / 2) : 75) / (config.w / 2)) * Math.sin(i * config.radians / total));
            return x;
          })
          .attr('y', function(data, i) {
            let y = config.h / 2 * (1 - config.factor * (1 + (self.options ? (self.options.legendSize / 2) : 50) / (config.h / 2)) * Math.cos(i * config.radians / total));
            return y;
          });

        text
          .html( function(a, i) {
            let x = config.w / 2 * (1 - config.factor * (1 + (self.options ? (self.options.legendSize / 2) : 75) / (config.w / 2)) * Math.sin(i * config.radians / total));
            return getHtml(a, x, legendLineHeight);
          })
          .attr('x', function(data, i) {
            let x = config.w / 2 * (1 - config.factor * (1 + (self.options ? (self.options.legendSize / 2) : 75) / (config.w / 2)) * Math.sin(i * config.radians / total));
            return x;
          });

        // text
        //   .append('svg:tspan')
        //   .attr('font-weight', '400')
        //   .text( function(a) { return a.unitOfMeasure; })
        //   .attr('x', function(data, i) {
        //     let x = config.w / 2 * (1 - config.factor * (1 + (self.options ? (self.options.legendSize / 2) : 75) / (config.w / 2)) * Math.sin(i * config.radians / total));
        //     return x;
        //   })
        //   .attr('dy', '20');
      // } else {
      //   axis.append('foreignObject')
      //     .attr('class', 'legend')
      //     .attr('text-anchor', 'middle')
      //     .attr('width', '100')
      //     .attr('height', '150')
      //     .style('overflow', 'visible')
      //     .attr('x', function(data, i) {
      //       let x = config.w / 2 * (1 - config.factor * (1 + (self.options ? (self.options.legendSize / 2) : 75) / (config.w / 2)) * Math.sin(i * config.radians / total));
      //       return x;
      //     })
      //     .attr('y', function(data, i) {
      //       let y = config.h / 2 * (1 - config.factor * (1 + (self.options ? (self.options.legendSize / 2) : 50) / (config.h / 2)) * Math.cos(i * config.radians / total));
      //       return y;
      //     })
      //     .append('xhtml:div').html(function(a) {
      //       return `<div class="axis-label">
      //         <div class="name">` + a.name + `</div>
      //         <div class="unit-of-measure">` + (a.unitOfMeasure ? a.unitOfMeasure : '') + `</div>
      //       </div>`;
      //     })
      //     .style('transform', 'translateX(-50%)')
      //     .style('font-size', '11px')
      //     .style('fill', '#FFF');
      // }
    }

    function getHtml(property, x, lineHeight) {
      let name = property.name;
      let unitOfMeasure = property.unitOfMeasure;
      let result = '';

      // split name
      let maxChars = 20;
      let index = 0;
      let nameParts = [];
      while (index < name.length) {
        let np = name.substring(index, Math.min(index + maxChars, name.length));
        if ((index + maxChars < name.length) && np.lastIndexOf(' ') > -1) {
          nameParts.push(np.substring(0, np.lastIndexOf(' ')));
          index += np.lastIndexOf(' ') + 1;
        } else {
          nameParts.push(np);
          index += maxChars;
        }
      }

      for (let i = 0; i < nameParts.length; i++) {
        let namePart = nameParts[i];
        result += '<tspan class="name" font-weight="700" x="' + x +
          '" dy="' + (i === 0 ? (-(lineHeight / 2) - (nameParts.length * (lineHeight / 2))) : lineHeight) + '">' +
          namePart + '</tspan>';
      }

      result += '<tspan class="unit-of-measure" x="' + x + '" dy="' + lineHeight +  '">' + (unitOfMeasure ? unitOfMeasure : '-') + '</tspan>';

      return result;
    }

    // Areas
    for (let x = d.length - 1; x >= 0; x--) {
      let y = d[x];
      let dataValues = [];
      g.selectAll('.nodes')
        .data(y, function(j, i) {
          dataValues.push([
            config.w / 2 * (1 - ((Math.max(j.value ? j.value : j.minValue, j.minValue) - j.minValue) / (j.maxValue - j.minValue)) *
              config.factor * Math.sin(i * config.radians / total)),
            config.h / 2 * (1 - ((Math.max(j.value ? j.value : j.minValue, j.minValue) - j.minValue) / (j.maxValue - j.minValue)) *
              config.factor * Math.cos(i * config.radians / total))
          ]);
        });
      dataValues.push(dataValues[0]);
      g.selectAll('.area')
        .data([dataValues])
        .enter()
        .append('polygon')
        .attr('class', 'radar-chart-serie' + series)
        .style('stroke-width', series === 0 ? '0' : (((d.length - series) / 2) + 1 + (self.options && self.options.export ? 5 : 0) + 'px'))
        .style('stroke', config.color(series))
        .attr('points', function(data) {
          let str = '';
          for (let pti = 0; pti < data.length; pti++) {
            str = str + data[pti][0] + ',' + data[pti][1] + ' ';
          }
          return str;
        })
        .style('fill', function(j, i) { return self.options && self.options.export && series === 0 ? 'rgba(0, 0, 0, 0.15)' : config.color(series); })
        .style('fill-opacity', series === 0 ? config.opacityArea : 0);
      series++;
    }
    series = 0;

    // tooltip instance
    if (d3.selectAll('.radar-tooltip').size() === 0) {
      tooltip = d3.select('.radar-chart').append('div').attr('class', 'radar-tooltip');
    } else {
      tooltip = d3.select('.radar-tooltip');
    }
    let propertyObject = {};
    for (let i = d.length - 1; i >= 0; i--) {
      let grade = d[i];
      _.each(grade, function(p) {
        if (propertyObject.hasOwnProperty(p.propertyId + (p.propertyType ? p.propertyType : ''))) {
          propertyObject[p.propertyId + (p.propertyType ? p.propertyType : '')].push(p);
        } else {
          propertyObject[p.propertyId + (p.propertyType ? p.propertyType : '')] = [p];
        }
      });
    }
    let propertyData = _.values(propertyObject);
    let axisTooltips = g.selectAll('.axis2')
      .data(propertyData)
      .enter()
      .append('g')
      .attr('class', 'axis2');
    let radarChart = document.querySelector('.radar-chart') as HTMLElement;

    // Chart Axis - Lines
    axisTooltips.append('line')
      .attr('x1', config.w / 2)
      .attr('y1', config.h / 2)
      .attr('x2', function(data, i) {
        return config.w / 2 * (1 - config.factor * Math.sin(i * config.radians / total));
      })
      .attr('y2', function(data, i) {
        return config.h / 2 * (1 - config.factor * Math.cos(i * config.radians / total));
      })
      .attr('class', 'line')
      .style('stroke', 'transparent')
      .style('stroke-width', '15px')
      .on('mouseover', function (data) {
        let html = '<div class="property-title">' + data[0].name + '</div><div class="grades"><table>';
        for (let i = data.length - 1; i >= 0; i--) {
          let grade = data[i];
          html += `<tr class="grade">
            <td class="legend-stroke">
              <div class="stroke" style="background-color: ` + colors[i] + `;">
              </div>
            </td>
            <!--<div class="name">` + grade.gradeName + `</div>-->
            <td colspan="${ (grade.displayText ? '1' : '2') }" class="value">
              ${ grade.displayText ? grade.displayText : '(no value)' }
            </td>
            ${ grade.displayText ? ('<td class="unit-of-measure">' + (grade.unitOfMeasure ? grade.unitOfMeasure : '') + '</td>') : '' }
          </tr>`;
        }
        html += '<div class="tooltip-arrow"></div></table></div>';
        tooltip
          .style('left', (d3.event.pageX - document.querySelector('.radar-chart').getBoundingClientRect().left - window.scrollX) + 'px')
          .style('top', ((d3.event.pageY - document.querySelector('.radar-chart').getBoundingClientRect().top - window.scrollY) + 25) + 'px')
          .style('display', 'block')
          .style('position', 'absolute')
          .style('transform', 'translate3d(-50%, 0, 0)')
          .style('background-color', '#FFF')
          .style('padding', '10px')
          .style('box-shadow', '2.5px 1.7px 12.4px 0.7px rgba(0, 0, 0, 0.8)')
          .html(html);
      })
      .on('mousemove', function(data) {
        tooltip
        .style('left', (d3.event.pageX - document.querySelector('.radar-chart').getBoundingClientRect().left - window.scrollX) + 'px')
        .style('top', ((d3.event.pageY - document.querySelector('.radar-chart').getBoundingClientRect().top - window.scrollY) + 25) + 'px');
      })
      .on('mouseout', function(data) {
        tooltip.style('display', 'none');
      });

      axis.append('circle')
      .attr('cx', function(data, i) {
        return config.w / 2 * (1 - (config.factor + .1) * Math.sin(i * config.radians / total));
      })
      .attr('cy', function(data, i) {
        return config.h / 2 * (1 - (config.factor + .1) * Math.cos(i * config.radians / total));
      })
      .attr('r', '20')
      .attr('class', 'tooltip-dot-button')
      .style('fill', 'transparent')
      .style('stroke', 'transparent')
      .on('click', function(data, index) {
        if (d3.selectAll('.radar-tooltip-box').size() === 0) {
          tooltipBox = d3.select('.radar-chart-wrapper').append('div').attr('class', 'radar-tooltip-box');
        } else {
          tooltipBox = d3.select('.radar-tooltip-box');
        }

        if (this.classList.contains('active')) {
          this.classList.remove('active');
          this.parentElement.querySelector('.tooltip-dot').classList.remove('active');
          this.parentElement.querySelector('.tooltip-dot-outline').classList.remove('active');
          tooltipBox.remove();
          d3.select(document.querySelector('.legend')).style('display', 'block');
        } else {
          let indexOfAxis;
          for (indexOfAxis = 0; indexOfAxis < d3.selectAll('.axis')._groups[0].length; indexOfAxis++) {
              if (document.getElementById('axis-' + indexOfAxis).querySelector('.tooltip-dot-outline').classList.contains('active')) {
                document.getElementById('axis-' + indexOfAxis).querySelector('.tooltip-dot').classList.remove('active');
                document.getElementById('axis-' + indexOfAxis).querySelector('.tooltip-dot-outline').classList.remove('active');
                document.getElementById('axis-' + indexOfAxis).querySelector('.tooltip-dot-button').classList.remove('active');
              }
          }
          this.classList.add('active');
          this.parentElement.querySelector('.tooltip-dot').classList.add('active');
          this.parentElement.querySelector('.tooltip-dot-outline').classList.add('active');
          let propertyObjectTooltipBox = {};
          for (let i = d.length - 1; i >= 0; i--) {
            let grade = d[i];
            _.each(grade, function(p) {
              if (propertyObjectTooltipBox.hasOwnProperty(p.propertyId + (p.propertyType ? p.propertyType : ''))) {
                propertyObjectTooltipBox[p.propertyId + (p.propertyType ? p.propertyType : '')].push(p);
              } else {
                propertyObjectTooltipBox[p.propertyId + (p.propertyType ? p.propertyType : '')] = [p];
              }
            });
          }
          let propertyDataTooltipBox = _.values(propertyObjectTooltipBox[data.id + (data.propertyType ? data.propertyType : '') ]);
          let html = '<table class="property"><thead><th colspan="2"></th><th colspan="1">' + data.name + '</th></thead><tbody class="grades">';
          for (let i = propertyDataTooltipBox.length - 1; i >= 0; i--) {
            let grade = propertyDataTooltipBox[i];
            html += `<tr class="grade" style="font-size: 12px;">
              <td><div class="legend-stroke" style="background-color: ` + colors[i] + `;"></div></td>
              <td class="name">` + grade.gradeName + `</td>
              <td class="value-wrapper">
              <div class="value">
                ${ grade.displayText ? grade.displayText : '(no value)' }
              </div>
              ${ grade.displayText ? ('<div class="unit-of-measure">' + (grade.unitOfMeasure ? grade.unitOfMeasure : '' ) + '</div>') : '' }
              </td></tr>`;
          }
          html += '</tbody></table>';
          tooltipBox
            .html(html);
          d3.select(document.querySelector('.legend')).style('display', 'none');
        }
      });
      console.log(id);
  }

  ngOnInit(): void {
    let self = this;
    window.addEventListener('resize', resizeThrottler, false);
    let resizeTimeout;
    function resizeThrottler() {
      if (!resizeTimeout) {
        resizeTimeout = setTimeout(function() {
          resizeTimeout = null;
          actualResizeHandler();
          if (d3.selectAll('.radar-tooltip-box').size() !== 0) {
            d3.select('.radar-tooltip-box').remove();
          }
        }, 1000);
      }
    }
    function actualResizeHandler() {
      self.draw(self.element, self._data.data, self._data.axis, self._data.colors);
    }
  }

}
