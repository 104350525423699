import { DatatableRangeEditorComponent } from './datatable-filters/datatable-range-editor/datatable-range-editor.component';
import { RouterModule } from '@angular/router';
import { PipesModule } from '../general/pipes/pipes.module';
import { MatIconModule } from '@angular/material/icon';
import { DatatableFiltersComponent } from './datatable-filters/datatable-filters.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StickyTableDirective } from './directives/sticky-table.directive';
import { DotToCommaConverterDirective } from './directives/dot-to-comma-converter.directive';
import { NgSelectModule } from '@ng-select/ng-select';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';


import { DatatableSidebarToggleComponent } from './datatable-sidebar-toggle/datatable-sidebar-toggle.component';
import { DatatableComponent } from './datatable.component';
import { UiModule } from '../general/ui/ui.module';
import { FormsModule } from '@angular/forms';
import { DatatableTableComponent } from './datatable-table/datatable-table.component';
import { DatatablePropertiesComponent } from './datatable-properties/datatable-properties.component';
import { DatatableApplicationsComponent } from './datatable-applications/datatable-applications.component';
import { DatatableApplicationsTableComponent } from './datatable-applications/datatable-applications-table/datatable-applications-table.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { DatatableSalesTableComponent } from './datatable-sales-table/datatable-sales-table.component';
import { DatatableTestMethodsComponent } from './datatable-test-methods/datatable-test-methods.component';
import { TooltipModule } from 'primeng/tooltip';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    PipesModule,
    UiModule,
    FormsModule,
    RouterModule,
    InfiniteScrollModule,
    TooltipModule,
    NgSelectModule,
    VirtualScrollerModule
  ],
  declarations: [
    DotToCommaConverterDirective,
    StickyTableDirective,
    DatatableSidebarToggleComponent,
    DatatableComponent,
    DatatableFiltersComponent,
    DatatableTableComponent,
    DatatablePropertiesComponent,
    DatatableApplicationsComponent,
    DatatableApplicationsTableComponent,
    DatatableSalesTableComponent,
    DatatableTestMethodsComponent,
    DatatableRangeEditorComponent,
  ]
})
export class DatatableModule { }
