import { AnalyticsService } from './../general/services/analytics.service';
import { FavoritesService } from './../general/services/favorites.service';
import { GlobalData } from './../general/services/global-data.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GradesService } from './../general/services/grades.service';
import { environment } from '../../environments/environment';
import { EventTypes } from '../general/enums/analytics/event-types';
import { AnalyticsEventsService } from '../general/services/analytics-events.service';
import { CustomData } from '../general/models/analytics/custom-data';
import { PageUrlData } from '../general/models/analytics/page-url-data';
import { PageData } from '../general/models/analytics/page-data';
import { PageType } from '../general/enums/analytics/page-type';
import { DpsFilterIdName } from '../general/models/filter/dpsFilterIdName';

@Component({
  selector: 'dps-plot-chart',
  templateUrl: './plot-chart.component.html',
  styleUrls: ['./plot-chart.component.scss']
})
export class PlotChartComponent implements OnInit {
  iconUrlBase: string;
  contactUsUrl: string;
  zooming: 'none';
  requestASampleUrl: string;

  constructor(public globalData: GlobalData,
    public gradesService: GradesService,
    private _favoritesService: FavoritesService,
    private _analyticsService: AnalyticsService,
    private _analyticsEventsService: AnalyticsEventsService,
    private _router: Router) {
    this.requestASampleUrl = environment.requestASampleUrl;
    this.iconUrlBase = environment.iconUrlBase;
    this.contactUsUrl = environment.contactUsUrl;
  }

  ngOnInit() {
    if (!this.globalData.data &&
      !this.globalData.loading &&
      !this.globalData.applicationState.showInitialFilterDialog &&
      !this.globalData.applicationState.showPropertyTypeSelection) {
      if ((window as any).silo) {
        this.gradesService.getData();
      } else {
        this.globalData.applicationState.showInitialFilterDialog = true;
      }
    }
  }

  showDetail(substance, name) {
    let gradeSubstance = parseInt(substance, null);
    this._router.navigateByUrl('/grade-detail/' + gradeSubstance);
  }

  closeSidebar() {
    this.globalData.applicationState.plotChartSidebarOpen = false;
  }

  switchAxes() {
    if (this.globalData.applicationState.propertyType.id === 'GP') {
      this.globalData.selectedProperties.unshift(this.globalData.selectedProperties.pop());
    } else {
      this.globalData.selectedSalesProperties.unshift(this.globalData.selectedSalesProperties.pop());
    }
    this.globalData.propertiesChanged = {};
    this._favoritesService.updateCurrentFavorite();
    // analytics
    this._analyticsService.sendEvent(this._analyticsEventsService.getEvent(EventTypes.XY_CHART_SWITCH_AXES), null);
  }

  isTouchDevice() {
    return 'ontouchstart' in document.documentElement;
  }

  showTooltip(message) {
    if (this.isTouchDevice()) {
      return null;
    } else {
      return message;
    }
  }

  onResultsAvailable() {
    let values: { x, y } = this.globalData.getPlotChartValues();
    this._analyticsService.sendEvent(this._analyticsEventsService.getEvent(EventTypes.XY_CHART_XY_SELECTED), new CustomData({ prod_selector_x: values.x, prod_selector_y: values.y }), new PageUrlData('/plotchart'), new PageData(PageType.Selector))
  }
}
