import { GradesService } from './../general/services/grades.service';
import { GlobalData } from './../general/services/global-data.service';
import { DpsShareModel } from './../general/models/dpsShareModel';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { _ } from 'underscore';

@Component({
  selector: 'dps-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss']
})
export class LinkComponent implements OnInit {

  constructor(public route: ActivatedRoute, public globalData: GlobalData, private _gradesService: GradesService, private _router: Router) { }

  configuration: DpsShareModel;

  ngOnInit() {

    this.route.params.subscribe(params => {
      this.configuration = new DpsShareModel(params['link']);
      // set this silo
      if (this.configuration.si) {
        this.globalData.silo = {
          excludes: this.configuration.si.e,
          filters: this.configuration.si.f.toDpsFilters(),
          hasMultiplePropertyTypes: this.configuration.si.h
        };
      }

      // set propertyType
      this.globalData.applicationState.propertyType = { id: this.configuration.pt };

      // set the filters
      this.globalData.selectedFilters = this.configuration.f.toDpsFilters();

      // clear all current data
      this.globalData.sortProperty = null;
      this.globalData.sortOrder = null;
      this.globalData.sortSalesOrder = null;
      this.globalData.sortSalesProperty = null;
      this.globalData.sortApplication = null;
      this.globalData.sortApplicationOrder = null;
      this.globalData.selectedGrades = [];
      this.globalData.selectedProperties = [];
      this.globalData.selectedRadarProperties = [];
      this.globalData.selectedSalesProperties = [];
      this.globalData.selectedRadarSalesProperties = [];

      // load data
      this._gradesService.getData(() => {
        console.log(this.globalData.data);

        if (this.globalData.applicationState.propertyType.id === 'GP') {
          // apply property sort
          this.globalData.sortProperty = (this.configuration.so && this.configuration.so.sp) ?
            _.findWhere(this.globalData.data.globalProperties, { id: this.configuration.so.sp }) : null;
          this.globalData.sortOrder = this.configuration.so ? this.configuration.so.so : null;

          // set selections
          this.globalData.selectedProperties = (this.configuration.se && this.configuration.se.p) ? _.filter(this.globalData.data.globalProperties, (p) => {
            return this.configuration.se.p.indexOf(p.id) > -1;
          }) : [];
          this.globalData.selectedRadarProperties = (this.configuration.se && this.configuration.se.rp) ? _.filter(this.globalData.data.globalProperties, (p) => {
            return this.configuration.se.rp.indexOf(p.id) > -1;
          }) : [];
        } else {
          // apply property sort
          this.globalData.sortSalesProperty = (this.configuration.so && this.configuration.so.sp) ?
            _.findWhere(this.globalData.data.salesProperties, { id: this.configuration.so.sp }) : null;
          this.globalData.sortSalesOrder = this.configuration.so ? this.configuration.so.so : null;

          // set selections
          this.globalData.selectedSalesProperties = (this.configuration.se && this.configuration.se.p) ? _.filter(this.globalData.data.salesProperties, (p) => {
            return this.configuration.se.p.indexOf(p.id) > -1;
          }) : [];
          this.globalData.selectedRadarSalesProperties = (this.configuration.se && this.configuration.se.rp) ? _.filter(this.globalData.data.salesProperties, (p) => {
            return this.configuration.se.rp.indexOf(p.id) > -1;
          }) : [];
        }

        // apply application sort
        this.globalData.sortApplication = (this.configuration.so && this.configuration.so.sa) ?
          _.findWhere(this.globalData.data.applications, { id: this.configuration.so.sa }) : null;
        this.globalData.sortApplicationOrder = this.configuration.so ? this.configuration.so.sao : null;

        // set grade selection
        this.globalData.selectedGrades = (this.configuration.se && this.configuration.se.g) ? _.filter(this.globalData.data.grades, (g) => {
          return this.configuration.se.g.indexOf(g.substance) > -1;
        }) : [];

        // update selectedGrades
        if (this.configuration.se && this.configuration.se.g) {
          for (let i = this.configuration.se.g.length - 1; i >= 0; i--) {
            let g: any = this.configuration.se.g[i];
            if (!g.c) {
              g.c = this.globalData.getFirstAvailableGradeColor();
            }
            let newGrade = _.findWhere(this.globalData.data.grades, { substance: g.s });
            if (newGrade) {
              newGrade.color = g.c;
              this.globalData.selectedGrades.unshift(newGrade);
            }
          }
        }

        this.globalData.applicationState.showInitialFilterDialog = false;
        this.globalData.initialized = true;

        // redirect to correct path
        this._router.navigateByUrl(this.configuration.p);
      });
    });
  }

  // select(item: DpsSearchResult, autocomplete?: boolean) {
  //   if (item.type !== 'Grade') {
  //     this.globalData.selectedFilters = item.getFilters(this.globalData.selectedFilters.regionId);
  //     this.globalData.sortProperty = null;
  //     this.globalData.sortOrder = null;
  //     this.globalData.sortSalesOrder = null;
  //     this.globalData.sortSalesProperty = null;
  //     this.globalData.sortApplication = null;
  //     this.globalData.sortApplicationOrder = null;
  //     this.globalData.selectedGrades = [];
  //     this.globalData.selectedProperties = [];
  //     this.globalData.selectedRadarProperties = [];
  //     this.globalData.selectedSalesProperties = [];
  //     this.globalData.selectedRadarSalesProperties = [];
  //     this.globalData.applicationState.applicationActive = false;
  //     this.globalData.applicationState.propertyType = {};

  //     let excludes = ['businessUnit', 'productType'];
  //     switch (item.type) {
  //       case 'Brand':
  //         excludes.push('brand');
  //         break;
  //       case 'Application':
  //         excludes.push('application');
  //         break;
  //       case 'Market Segment':
  //         excludes.push('marketSegment');
  //         break;
  //       case 'Industry':
  //         excludes.push('industry');
  //         break;
  //     }

  //     let siloFilters = new DpsFilters();
  //     siloFilters.updateFromFilters(this.globalData.selectedFilters);

  //     this.globalData.silo = {
  //       filters: siloFilters,
  //       excludes: excludes
  //     };

  //     // check for multiplePropertyTypes
  //     this._gradesService.getData(() => {
  //       this.globalData.silo.hasMultiplePropertyTypes = this.globalData.hasOtherPropertyType;
  //         if (this.globalData.silo.hasMultiplePropertyTypes) {
  //           // open properties dialog
  //           this.globalData.data = null;
  //           this.globalData.applicationState.showPropertyTypeSelection = true;
  //         }
  //     });
  //   }

  //   // analytics
  //   this._analyticsService.sendEvent(AnalyticsEvents.SEARCH_CLICK, item.name + ' (' + item.type + ')');
  //   if (autocomplete) {
  //     this.globalData.searchModel.searchTerm = '';
  //   }
  //   this._router.navigateByUrl(item.getPath());
  // }

}
