import { DpsFilterData } from './../../models/filter/dpsFilterData';
import { DpsFilters } from './../../models/dpsFilters';
import { Pipe, PipeTransform } from '@angular/core';
import { DpsFilterIdName } from '../../models/filter/dpsFilterIdName';
import { _ } from 'underscore';

@Pipe({
  name: 'siloFilter'
})
export class SiloFilterPipe implements PipeTransform {

  transform(filterData: DpsFilterData, filters: DpsFilters, silo: any): any[] {
    let siloFilters = silo ? silo.filters as DpsFilters : null;
    let result = [];

    if (!silo || !silo.exclude || silo.exclude.indexOf('businessUnit') === -1) {
      result = result.concat(this._extractFilter(filterData.businessUnits, filters.businessUnitIds, (siloFilters ? siloFilters.businessUnitIds : []), 'businessUnit'));
    }
    if (!silo || !silo.exclude || silo.exclude.indexOf('productType') === -1) {
      result = result.concat(this._extractFilter(filterData.productTypes, filters.productTypeIds, (siloFilters ? siloFilters.productTypeIds : []), 'productType'));
    }
    if (!silo || !silo.exclude || silo.exclude.indexOf('application') === -1) {
      result = result.concat(this._extractFilter(filterData.applications, filters.applicationIds, (siloFilters ? siloFilters.applicationIds : []), 'application'));
    }
    if (!silo || !silo.exclude || silo.exclude.indexOf('brand') === -1) {
      result = result.concat(this._extractFilter(filterData.brands, filters.brandIds, (siloFilters ? siloFilters.brandIds : []), 'brand'));
    }
    if (!silo || !silo.exclude || silo.exclude.indexOf('industry') === -1) {
      result = result.concat(this._extractFilter(filterData.industries, filters.industryIds, (siloFilters ? siloFilters.industryIds : []), 'industry'));
    }
    if (!silo || !silo.exclude || silo.exclude.indexOf('marketSegment') === -1) {
      result = result.concat(this._extractFilter(filterData.marketSegments, filters.marketSegmentIds, (siloFilters ? siloFilters.marketSegmentIds : []), 'marketSegment'));
    }

    return result;
  }

  private _extractFilter(filterData, filters, siloFilters, type) {
    let ids = _.difference(filters, siloFilters);
    return _.map(_.filter(filterData, (a) => {
      return ids.indexOf(a.id) > -1;
    }), (f) => {
      f.type = type;
      return f;
    });
  }

}
